import AxiosRequest from "../../../AxiosRequest";



// get cash drawer list 
export const getListCashDrawerRequest = async ({ page, limit }) => {
    const response = await AxiosRequest.get(
        `/user/drawer/list`, {
        params: {
            page,
            limit,
        },
    }
    );

    console.log(response, "response.........................")
    return response.data.data;
};