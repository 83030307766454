import { faUser } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@material-tailwind/react";
import { ErrorMessage, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import LockClose from "../../assets/icon/lock-close.svg";
import Logo from "../../assets/logo/quetzal-logo.svg";
import loginBGImage from "../../assets/svgs/login-background.svg";
import Input from "../../components/common/Input";
import PasswordInput from "../../components/common/PasswordInput";
import { loginAction } from "../../store/auth/auth.fetch";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((store) => ({
    loading: store?.auth?.loading,
  }));
  const openGmail = () => {
    window.open(
      "https://mail.google.com/mail/?view=cm&fs=1&to=support@quetzalpos.com",
      "_blank"
    );
  };

  return (
    <div className="relative">
      <div className="flex items-center justify-center h-screen">
        <div className="grid md:grid-cols-2 xl:max-w-[1195px] lg:max-w-[900px] max-w-[730px] w-full mx-auto lg:rounded-2xl rounded-theme bg-white shadow-[0px_24px_101.8px_-16px_rgba(0,0,0,0.15)]">
          <div className="xl:py-[60px] lg:py-[44px] py-[36px] lg:px-[30px] px-5">
            <h1 className="mb-3 text-lg font-semibold text-info-400 lg:text-2xl">
              {t("Welcome to Quetzal POS")}
            </h1>
            <p className="font-medium text-secondary-400 xl:text-lg lg:text-base">
              {/* {t("Log in to manage your store with our user-friendly system.")} */}
              {t("Effortlessly manage your store with our intuitive system")}
            </p>
            <div className="lg:max-w-[323px] lg:h-[250px] max-w-[250px] h-[170px] mt-[60px]">
              <img
                src={loginBGImage}
                alt="login-img"
                className="object-cover w-full h-full"
              />
            </div>
          </div>
          <div className="xl:ml-[30px] lg:ml-2.5 lg:p-[30px] p-5 shadow-[-3px_0px_30.1px_0px_rgba(180,180,180,0.15)] rounded-tl-2xl rounded-bl-2xl">
            <div className="w-[100%]">
              <div className="xl:max-w-[147px] lg:max-w-[120px] max-w-[100px] xl:h-[54px] lg:h-[44px] h-[48px] xl:mb-10 lg:mb-[30px] mb-5">
                <img src={Logo} alt="logo" className="w-full h-full" />
              </div>
              <Formik
                enableReinitialize
                initialValues={{
                  email: "",
                  password: "",
                  device_info: {
                    platform: "web",
                    device_id: "",
                    device_type: "Web",
                    device_token: "",
                  },
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .matches(
                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      t("Invalid email address")
                    )
                    .required(t("Email is required")),
                  password: Yup.string().required(t("Password is required")),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  dispatch(loginAction(values, navigate));
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <Form
                    onSubmit={handleSubmit}
                    className="flex flex-col gap-y-5"
                  >
                    <div>
                      <Input
                        type={"email"}
                        label={t("Email")}
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={"Enter Your Email"}
                        iconStart={
                          <FontAwesomeIcon
                            icon={faUser}
                            className="text-secondary-150"
                            fontSize={18}
                          />
                        }
                        className="placeholder:text-sm placeholder:font-normal bg-gray-100"
                      />

                      <ErrorMessage
                        name="email"
                        component={"p"}
                        className="my-1 text-red-500 text-sm_18"
                      />
                    </div>
                    <div>
                      <PasswordInput
                        label="Password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        placeholder={"Enter Your Password"}
                        onBlur={handleBlur}
                        icon={
                          <img src={LockClose} alt="user-icon" className="" />
                        }
                        className="placeholder:text-sm placeholder:font-normal bg-gray-100"
                      />
                      <ErrorMessage
                        name="password"
                        component={"p"}
                        className="my-1 text-red-500 text-sm_18"
                      />
                    </div>
                    <div className="flex items-center justify-end">
                      <Link
                        to={"/forgot-password"}
                        className="mb-4 font-semibold text-primary-400 text-sm_18 lg:mb-0"
                      >
                        Forgot Your Password?
                      </Link>
                    </div>

                    <Button
                      type="submit"
                      loading={loading}
                      disabled={loading}
                      className="flex items-center justify-center text-base font-semibold capitalize bg-primary-400 w-52"
                    >
                      {t("Log In")}
                    </Button>
                    <p className=" text-secondary-300 xl:text-base text-sm_18 text-left">
                      {t("Need an account? Contact us at")}
                      <Link
                        href="#"
                        onClick={openGmail}
                        className="ml-1 underline text-primary-400"
                      >
                        {t("support@quetzalpos.com")}
                      </Link>
                    </p>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
