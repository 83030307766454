import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@material-tailwind/react";
import React, { useState } from "react";

const PasswordInput = ({
  label,
  labelProps,
  asterisk,
  className,
  icon,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => setShowPassword(!showPassword);
  return (
    <div className="flex flex-col gap-y-1">
      {label && (
        <label
          className={`${labelProps} lg:text-base_24 text-sm_18 font-medium text-secondary-400`}
        >
          {label}
          {asterisk && <span className="text-primary-400">*</span>}
        </label>
      )}
      <div className="relative w-full flex items-center">
        {icon && <div className="absolute left-0 pl-4">{icon}</div>}
        <input
          type={showPassword ? "text" : "password"}
          className={`w-full border border-secondary-200 font-semibold text-info-400 rounded-theme py-2.5 outline-none focus:border-primary-400 focus:shadow transition-all duration-200 ${className}
          ${icon ? "pl-12 pr-2" : "pl-3"}
          `}
          {...rest}
        />
        <div className="absolute right-0 pr-2.5">
          <IconButton
            variant="text"
            size="sm"
            className="hover:bg-secondary-50"
            onClick={togglePassword}
          >
            {showPassword ? (
              <FontAwesomeIcon
                icon={faEye}
                fontSize={16}
                className=" text-secondary-150"
              />
            ) : (
              <FontAwesomeIcon
                icon={faEyeSlash}
                fontSize={16}
                className=" text-secondary-150"
              />
            )}
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default PasswordInput;
