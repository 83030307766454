import React from "react";
import DownArrowBlackIcon from "../../assets/icon/down-arrow-black.svg";
import IntegrationLayout from "../../layout/IntegrationLayout";
import { Button } from "@material-tailwind/react";
import { t } from "i18next";

const IntegrationPayment = () => {
  return (
    <IntegrationLayout>
      <div className="grow overflow-y-auto xl:h-[calc(100vh-132px)] h-[calc(100vh-106px)]">
        <div className="bg-white rounded-theme py-5 px-4 px mb-5">
          <div className="text-info-500 lg:text-xl text-base font-semibold lg:mb-6 mb-3">
            {t("Basic Payment Types")}
          </div>
          <div className="flex items-center justify-between border-b border-secondary-200/30 lg:mb-4 lg:pb-4 mb-2.5 pb-2.5">
            <div className="text-secondary-400 xl:text-lg lg:text-base text-sm_18 font-medium">
              {t("Cash")}
            </div>
            <div>
              <label className="relative inline-block w-11 h-5">
                <input
                  type="checkbox"
                  className="opacity-0 w-0 h-0 peer"
                  checked
                />
                <span className="absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-secondary-200/40 rounded-full transition duration-400 peer-checked:bg-primary-150 peer-checked:before:translate-x-6 before:absolute before:content-[''] before:h-[23px] before:w-6 before:left-0 before:top-1/2 before:-translate-y-1/2 before:bg-white peer-checked:before:bg-primary-400 before:rounded-full  before:shadow-[1px_0px_8.2px_0px_#0000002E] before:transition before:duration-400"></span>
              </label>
            </div>
          </div>
          {/* <div className="flex items-center justify-between border-b border-secondary-200/30 lg:mb-4 lg:pb-4 mb-2.5 pb-2.5">
            <div className="text-secondary-400 xl:text-lg lg:text-base text-sm_18 font-medium">
              check
            </div>
            <div>
              <label className="relative inline-block w-11 h-5">
                <input
                  type="checkbox"
                  className="opacity-0 w-0 h-0 peer"
                  checked
                />
                <span className="absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-secondary-200/40 rounded-full transition duration-400 peer-checked:bg-[#FDC8B8] peer-checked:before:translate-x-6 before:absolute before:content-[''] before:h-[23px] before:w-6 before:left-0 before:top-1/2 before:-translate-y-1/2 before:bg-white peer-checked:before:bg-primary-400 before:rounded-full  before:shadow-[1px_0px_8.2px_0px_#0000002E] before:transition before:duration-400"></span>
              </label>
            </div>
          </div> */}
          <div className="flex items-center justify-between border-b border-secondary-200/30 lg:mb-4 lg:pb-4 mb-2.5 pb-2.5">
            <div className="text-secondary-400 xl:text-lg lg:text-base text-sm_18 font-medium">
              {t("Store Credit")}
            </div>
            <div>
              <label className="relative inline-block w-11 h-5">
                <input type="checkbox" className="opacity-0 w-0 h-0 peer" />
                <span className="absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-secondary-200/40 rounded-full transition duration-400 peer-checked:bg-primary-150 peer-checked:before:translate-x-6 before:absolute before:content-[''] before:h-[23px] before:w-6 before:left-0 before:top-1/2 before:-translate-y-1/2 before:bg-white peer-checked:before:bg-primary-400 before:rounded-full  before:shadow-[1px_0px_8.2px_0px_#0000002E] before:transition before:duration-400"></span>
              </label>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="text-secondary-400 xl:text-lg lg:text-base text-sm_18 font-medium">
              {t("Gift Card")}
            </div>
            <div>
              <label className="relative inline-block w-11 h-5">
                <input type="checkbox" className="opacity-0 w-0 h-0 peer" />
                <span className="absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-secondary-200/40 rounded-full transition duration-400 peer-checked:bg-primary-150 peer-checked:before:translate-x-6 before:absolute before:content-[''] before:h-[23px] before:w-6 before:left-0 before:top-1/2 before:-translate-y-1/2 before:bg-white peer-checked:before:bg-primary-400 before:rounded-full  before:shadow-[1px_0px_8.2px_0px_#0000002E] before:transition before:duration-400"></span>
              </label>
            </div>
          </div>
        </div>
        {/* <div className="bg-white rounded-theme py-5 px-4 lg:mb-[30px] mb-4">
          <div className="text-info-500 lg:text-lg text-base font-semibold lg:mb-6 mb-3">
            Advanced Payment Types
          </div>
          <div className="mb-[30px]">
            <div className="text-secondary-500 xl:text-lg lg:text-base text-sm_18 font-medium mb-3">
              Payment Type
            </div>
            <div className="bg-secondary-50 lg:rounded-theme rounded-base flex items-center justify-between lg:py-[18px] lg:px-5 py-3 px-3">
              <div className="text-info-500 font-medium xl:text-lg lg:text-base text-sm_18">
                SideCar
              </div>
              <div>
                <img
                  src={DownArrowBlackIcon}
                  alt="down arrow"
                  className="lg:w-6 lg:h-6 w-5 h-5"
                />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between border-b border-secondary-200/30 lg:mb-4 lg:pb-4 mb-2.5 pb-2.5">
            <div className="text-secondary-400 xl:text-lg lg:text-base text-sm_18 font-medium">
              Credit card
            </div>
            <div>
              <label className="relative inline-block w-11 h-5">
                <input type="checkbox" className="opacity-0 w-0 h-0 peer" />
                <span className="absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-secondary-200/40 rounded-full transition duration-400 peer-checked:bg-[#FDC8B8] peer-checked:before:translate-x-6 before:absolute before:content-[''] before:h-[23px] before:w-6 before:left-0 before:top-1/2 before:-translate-y-1/2 before:bg-white peer-checked:before:bg-primary-400 before:rounded-full  before:shadow-[1px_0px_8.2px_0px_#0000002E] before:transition before:duration-400"></span>
              </label>
            </div>
          </div>
          <div className="flex items-center justify-between border-b border-secondary-200/30 lg:mb-4 lg:pb-4 mb-2.5 pb-2.5">
            <div className="text-secondary-400 xl:text-lg lg:text-base text-sm_18 font-medium">
              Debit card
            </div>
            <div>
              <label className="relative inline-block w-11 h-5">
                <input type="checkbox" className="opacity-0 w-0 h-0 peer" />
                <span className="absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-secondary-200/40 rounded-full transition duration-400 peer-checked:bg-[#FDC8B8] peer-checked:before:translate-x-6 before:absolute before:content-[''] before:h-[23px] before:w-6 before:left-0 before:top-1/2 before:-translate-y-1/2 before:bg-white peer-checked:before:bg-primary-400 before:rounded-full  before:shadow-[1px_0px_8.2px_0px_#0000002E] before:transition before:duration-400"></span>
              </label>
            </div>
          </div>
          <div className="flex items-center justify-between border-b border-secondary-200/30 lg:mb-4 lg:pb-4 mb-2.5 pb-2.5">
            <div className="text-secondary-400 xl:text-lg lg:text-base text-sm_18 font-medium">
              Gift card
            </div>
            <div>
              <label className="relative inline-block w-11 h-5">
                <input type="checkbox" className="opacity-0 w-0 h-0 peer" />
                <span className="absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-secondary-200/40 rounded-full transition duration-400 peer-checked:bg-[#FDC8B8] peer-checked:before:translate-x-6 before:absolute before:content-[''] before:h-[23px] before:w-6 before:left-0 before:top-1/2 before:-translate-y-1/2 before:bg-white peer-checked:before:bg-primary-400 before:rounded-full  before:shadow-[1px_0px_8.2px_0px_#0000002E] before:transition before:duration-400"></span>
              </label>
            </div>
          </div>
          <div className="flex items-center justify-between border-b border-secondary-200/30 lg:mb-4 lg:pb-4 mb-2.5 pb-2.5">
            <div className="text-secondary-400 xl:text-lg lg:text-base text-sm_18 font-medium">
              integrate with card smith
            </div>
            <div>
              <label className="relative inline-block w-11 h-5">
                <input type="checkbox" className="opacity-0 w-0 h-0 peer" />
                <span className="absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-secondary-200/40 rounded-full transition duration-400 peer-checked:bg-[#FDC8B8] peer-checked:before:translate-x-6 before:absolute before:content-[''] before:h-[23px] before:w-6 before:left-0 before:top-1/2 before:-translate-y-1/2 before:bg-white peer-checked:before:bg-primary-400 before:rounded-full  before:shadow-[1px_0px_8.2px_0px_#0000002E] before:transition before:duration-400"></span>
              </label>
            </div>
          </div>
          <div className="flex items-center justify-between border-b border-secondary-200/30 lg:mb-4 lg:pb-4 mb-2.5 pb-2.5">
            <div className="text-secondary-400 xl:text-lg lg:text-base text-sm_18 font-medium">
              Allow clerks to reload this
            </div>
            <div>
              <label className="relative inline-block w-11 h-5">
                <input type="checkbox" className="opacity-0 w-0 h-0 peer" />
                <span className="absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-secondary-200/40 rounded-full transition duration-400 peer-checked:bg-[#FDC8B8] peer-checked:before:translate-x-6 before:absolute before:content-[''] before:h-[23px] before:w-6 before:left-0 before:top-1/2 before:-translate-y-1/2 before:bg-white peer-checked:before:bg-primary-400 before:rounded-full  before:shadow-[1px_0px_8.2px_0px_#0000002E] before:transition before:duration-400"></span>
              </label>
            </div>
          </div>
          <div className="lg:mb-[30px] mb-4">
            <label className="text-secondary-500 xl:text-lg lg:text-base text-sm_18 font-medium lg:mb-3 mb-1.5 block">
              Branded Card Name
            </label>
            <input
              type="text"
              className="bg-secondary-50 lg:rounded-theme rounded-base xl:p-5 p-3 text-info-500 font-medium xl:text-lg lg:text-base text-sm_18 outline-none w-full"
              value="HDFC Bank"
              placeholder="Enter Card Name"
            />
          </div>
          <div className="lg:mb-[30px] mb-4">
            <label className="text-secondary-500 xl:text-lg lg:text-base text-sm_18 font-medium lg:mb-3 mb-1.5 block">
              Merchant Id
            </label>
            <input
              type="text"
              className="bg-secondary-50 lg:rounded-theme rounded-base xl:p-5 p-3 text-info-500 font-medium xl:text-lg lg:text-base text-sm_18 outline-none w-full"
              value="42563523"
              placeholder="Enter Merchant Id"
            />
          </div>
          <div>
            <label className="text-secondary-500 xl:text-lg lg:text-base text-sm_18 font-medium lg:mb-3 mb-1.5 block">
              Branded Card Name
            </label>
            <input
              type="text"
              className="bg-secondary-50 lg:rounded-theme rounded-base xl:p-5 p-3 text-info-500 font-medium xl:text-lg lg:text-base text-sm_18 outline-none w-full"
              value="69584582"
              placeholder="Enter Terminal Id"
            />
          </div>
        </div> */}
        <Button className="bg-primary-400 xl:max-w-[230px] max-w-[180px] flex items-center justify-center w-full text-center border border-primary-400 lg:text-base text-sm_20 font-medium h-[46px] text-white rounded-theme capitalize">
          {t("Save")}
        </Button>
      </div>
    </IntegrationLayout>
  );
};

export default IntegrationPayment;
