import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { date } from "yup";

const initialState = {
  cashDrawersList: {
    loading: false,
    data: [],
    error: false,
    totalPages: null,
  },
  createCashDrawer: {
    loading: false,
    data: null,
    error: false,
  },
};


export const cashDrawerSlice = createSlice({
  name: "cash-drawer",
  initialState: initialState,
  reducers: {
    cashDrawerSate: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const { cashDrawerSate } = cashDrawerSlice.actions;
export default cashDrawerSlice.reducer;