import { toast } from "react-toastify";
import { cashDrawerSate } from "./state";
import AxiosRequest from "../../../AxiosRequest";

/* -------------------------------------------------------------------------- */
/*                         create cash-drawer request                         */
/* -------------------------------------------------------------------------- */

export const createCashDrawerRequest =
  ({ body }) =>
    async (dispatch) => {
      try {
        dispatch(
          cashDrawerSate({ key: "createCashDrawer.loading", value: true })
        );
        const response = await AxiosRequest.post("/user/drawer/create", body);
        console.log(response, "res from create cash-drawer");
        const data = await response.data;
        if (data) {
          dispatch(cashDrawerSate({ key: "createCashDrawer.data", value: data }));
          dispatch(
            cashDrawerSate({
              key: "createCashDrawer.loading",
              value: false,
            })
          );
          // toast.success(response.data.message);
        }
        return response;

      } catch (error) {
        toast.error(error);
        dispatch(
          cashDrawerSate({ key: "createCashDrawer.loading", value: false })
        );
        dispatch(cashDrawerSate({ key: "createCashDrawer.error", value: true }));
      }
    };

/* -------------------------------------------------------------------------- */
/*                          list cash-drawer request                          */
/* -------------------------------------------------------------------------- */
export const getListCashDrawerRequest =
  ({ page, limit }) =>
    async (dispatch) => {
      try {
        dispatch(cashDrawerSate({ key: "cashDrawersList.loading", value: true }));
        const response = await AxiosRequest.get(`/user/drawer/list`, {
          params: {
            page,
            limit,
          },
        });
        console.log(response, "res from list cash-drawer");
        const data = await response.data.data;
        if (data) {
          dispatch(cashDrawerSate({ key: "cashDrawersList.data", value: data }));
          dispatch(
            cashDrawerSate({ key: "cashDrawersList.loading", value: false })
          );
        }
        return response;

      } catch (error) {
        console.log(error, "error from list cash drawer");
        toast.error(error);
      }
    };
