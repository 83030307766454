import React, { useEffect, useLayoutEffect, useState } from "react";
import RightClick from "../../assets/icon/right-click.svg";
import Layout from "../../layout";
import { Radio, Spinner } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCircleNotch,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import {
  getColorFromLocalStorage,
  setColorInLocalStorage,
  setLanguageInLocalStorage,
} from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import {
  getThisDeviceRequest,
  updateThisDeviceRequest,
} from "../../store/settings/device/actions";
import Loader from "../../components/common/Loader";
import { useTranslation } from "react-i18next";

const colors = ["#F47048", "#7B02C6", "#2057E5", "#00A2C6", "#C60700"];

const laneNumberArr = [1, 2, 3, 4, 5];

const ThisDevice = () => {
  const { i18n, t } = useTranslation();

  const dispatch = useDispatch();
  const { isLoading, getThisDeviceData, languageList } = useSelector(
    (store) => ({
      isLoading: store?.device?.getThisDeviceData?.loading,
      getThisDeviceData: store?.device?.getThisDeviceData?.data,
      languageList: store?.setting?.languageList,
    })
  );

  const initialColor = getColorFromLocalStorage();
  const [color, setColor] = useState(initialColor);
  const [language, setLanguage] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [laneNumber, setLaneNumber] = useState(getThisDeviceData?.lane_number);

  console.log(languageList, "languageList...........");

  const changeColor = (newColor) => {
    setColor(newColor);
    setColorInLocalStorage(newColor);
    dispatch(
      updateThisDeviceRequest({
        id: getThisDeviceData?._id,
        body: { color: newColor },
      })
    );
  };

  useEffect(() => {
    document.documentElement.style.setProperty("--primary-color", color);
  }, [color]);

  useEffect(() => {
    dispatch(getThisDeviceRequest());
  }, [dispatch]);

  useLayoutEffect(() => {
    if (getThisDeviceData) {
      console.log(getThisDeviceData, "getThisDeviceData..................");
      setColor(getThisDeviceData?.color);
      setLanguage(getThisDeviceData?.language?._id);
      const lang = languageList.find(
        (item) => item._id === getThisDeviceData?.language?._id
      );
      console.log(lang, "lang.....................");
      setSelectedLanguage(lang);
      setLaneNumber(getThisDeviceData?.lane_number);
    }
  }, [getThisDeviceData]);

  // Update i18n when the selected language changes
  useEffect(() => {
    if (language && languageList) {
      // Find the selected language object by its ID
      const selectedLang = languageList.find((lang) => lang._id === language);

      if (selectedLang && selectedLang.code) {
        // Use the language code (e.g., 'en', 'fr') to change the language
        console.log(language, "language.....");
        setLanguageInLocalStorage(selectedLang.code);
        i18n.changeLanguage(selectedLang.code);
      }
    }
  }, [language, languageList]);

  return (
    <Layout>
      {isLoading && (
        <div className="absolute z-20 flex items-center justify-center w-full h-full bg-white/70 backdrop-blur-sm">
          <Loader />
        </div>
      )}
      <div className="grid grid-cols-3 gap-x-3">
        <div className=" bg-white rounded-theme overflow-y-auto xl:h-[calc(100vh-126px)] h-[calc(100vh-100px)] p-5">
          <p className="mb-3 text-base font-semibold text-info-500 lg:text-xl lg:mb-5">
            {t("Language")}
          </p>
          <div className="bg-secondary-50 xl:text-lg lg:text-base text-sm_18 font-medium rounded-base xl:p-5 lg:p-3.5 p-2.5 flex justify-between items-center mb-5">
            {selectedLanguage?.name}
          </div>
          <div>
            <div className="block xl:text-base lg:text-base text-sm_18 text-secondary-500 xl:pb-4 lg:pb-3 pb-2.5 font-medium">
              {t("Select Language")}
            </div>
            <div className="flex flex-col">
              {languageList.map((item, index) => (
                <Radio
                  name="lang"
                  label={item.name}
                  key={index}
                  value={item._id}
                  checked={item._id === language}
                  onChange={() => {
                    setLanguage(item._id);
                    setSelectedLanguage(item);
                    dispatch(
                      updateThisDeviceRequest({
                        id: getThisDeviceData?._id,
                        body: { language: item._id },
                      })
                    );
                  }}
                  color="themeColor"
                  labelProps={{
                    className:
                      "text-info-500 xl:text-lg lg:text-base text-sm_18 font-normal",
                  }}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="col-span-2 overflow-y-auto xl:h-[calc(100vh-126px)] h-[calc(100vh-100px)]">
          <div className="w-full h-full p-5 bg-white rounded-theme xl:p-8">
            <p className="pb-3 text-base font-semibold capitalize lg:text-xl">
              {t("Color Theme")}
            </p>
            <div className="lg:w-[60px] lg:h-[60px] w-12 h-12 bg-primary-400 rounded-full"></div>
            <div className="pt-5 mb-10">
              <div className="block xl:text-base lg:text-base text-sm_18 text-secondary-500 xl:pb-4 lg:pb-3 pb-2.5 font-medium capitalize">
                {t("Select Theme")}
              </div>
              <div className="flex gap-3 lg:gap-5">
                {colors.map((item, index) => (
                  <button
                    key={index}
                    style={{ background: item }}
                    className={`lg:w-[60px] lg:h-[60px] w-12 h-12   rounded-full relative flex justify-center items-center`}
                    onClick={() => changeColor(item)}
                  >
                    {item === color && (
                      <FontAwesomeIcon
                        icon={faCheck}
                        fontSize={20}
                        className="text-white"
                      />
                    )}
                  </button>
                ))}
              </div>
            </div>
            <div>
              <p className="pb-3 text-lg font-semibold lg:text-xl lg:pb-5">
                {t("Lane Number")}
              </p>
              <div className="flex gap-4">
                {laneNumberArr.map((item, index) => (
                  <button
                    key={index}
                    type="button"
                    className={`w-32 h-12 ${
                      item === laneNumber
                        ? "bg-primary-150 text-primary-400"
                        : "!bg-secondary-100 text-info-400"
                    } rounded-lg flex items-center text-xl justify-center font-semibold`}
                    onClick={() => {
                      setLaneNumber(item);
                      dispatch(
                        updateThisDeviceRequest({
                          id: getThisDeviceData?._id,
                          body: { lane_number: item },
                        })
                      );
                    }}
                  >
                    {item}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ThisDevice;
