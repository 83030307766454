import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  createUser: {
    loading: false,
    data: null,
    error: null,
  },
  usersList: {
    loading: false,
    data: [],
    error: false,
    totalPages: null,
  },
  singleUserDetail: {
    loading: false,
    data: null,
    error: null,
  },
  deleteUser: {
    loading: false,
    data: null,
    error: null,
  },
};



export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    userState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const { userState } = userSlice.actions;

export default userSlice.reducer;
