import {
  faCircleXmark,
  faPenToSquare,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@material-tailwind/react";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import Input from "../common/Input";
import {
  faMinus,
  faPlus,
  faSquarePen,
} from "@fortawesome/free-solid-svg-icons";
import { RotatePercentage } from "../svgs";
import {
  formatCurrencyWithNumberValue,
  getUserDetails,
  getUserOrgLocationSetting,
  useDebounce,
} from "../../utils/utils";
import { discountType } from "../../utils/enums";
import { ErrorMessage } from "formik";
import { toast } from "react-toastify";
import { t } from "i18next";

const ManageOrder = ({
  items,
  index,
  formik,
  totalTaxPercentage,
  setOpenGiftCardModal,
  setSelectedIndex,
  setEditClickProduct,
  setOpenEditModal,
  productsData,
}) => {
  // console.log(items);
  const { values, setFieldValue, handleChange, errors } = formik;

  // console.log(values, "values");

  const orgDetail = getUserOrgLocationSetting();
  const discountedValue = useDebounce(items?.discount_percentage);
  const changeQty = useDebounce(items.qty);
  const [checkIsRemove, setCheckIsRemove] = useState(false);
  const handleChangeDiscountType = (value) => {
    setFieldValue(`orderItems.[${index}].discount`, 0);
    setFieldValue(`orderItems.[${index}].discount_percentage`, 0);
    setFieldValue(`orderItems.[${index}].discount_type`, value);
  };

  const handleIncrementQty = () =>
    setFieldValue(`orderItems.[${index}].qty`, items.qty + 1);

  const handleDecrementQty = () =>
    setFieldValue(`orderItems.[${index}].qty`, items.qty - 1);

  const generateSubTotal = (price, qty) => price * qty;

  const singleDiscountedPrice = (price, discount) => {
    if (items?.discount_type === discountType.PRICE) {
      return price - discount;
    } else {
      return price - (price * discount) / 100;
    }
  };

  const discountedPrice = (price, discount, qty, type) => {
    if (type === discountType.PRICE) {
      return discount;
    } else {
      const totalPrice = price * qty;
      return (totalPrice * discount) / 100;
    }
  };

  const handleChangeDiscount = (event) => {
    const { value } = event.target;
    const updatedValue = value.replace(/^0+(?=\d)/, "");
    setFieldValue(`orderItems.[${index}].discount_percentage`, updatedValue);
  };

  const handleRemoveItems = () => {
    const newArr = [...values.orderItems];
    newArr.splice(index, 1);
    setFieldValue("orderItems", newArr);
    if (newArr.length === 0) {
      setFieldValue("order.total_qty", 0);
      setFieldValue("order.sub_total", 0);
      setFieldValue("order.total_discount", 0);
      setFieldValue("order.total_taxes", 0);
      setFieldValue("order.grand_total", 0);
    }
  };
  const handleEditItems = (items, index) => {
    console.log(items, index);
    setSelectedIndex(index);

    if (items.type === "inventory") {
      setEditClickProduct(items);
      setOpenEditModal(true);
    } else {
      setOpenGiftCardModal(true);

      setFieldValue(
        `orderItems[${index}].gift_card_code`,
        items.gift_card_code
      );
      setFieldValue(
        `orderItems[${index}].gift_card_amount`,
        items.gift_card_amount
      );
    }
  };

  useEffect(() => {
    const calculatedPerItemDiscountedPrice = generateSubTotal(
      items?.gift_card_amount || items?.selling_price,
      changeQty
    );
    const calculatedDiscountedPrice = discountedPrice(
      items?.gift_card_amount || items?.selling_price,
      Number(discountedValue),
      changeQty,
      items?.discount_type
    );
    setFieldValue(
      `orderItems[${index}].discount`,
      Number(calculatedDiscountedPrice.toFixed(2))
    );
    setFieldValue(
      `orderItems[${index}].sub_total`,
      Number(calculatedPerItemDiscountedPrice.toFixed(2))
    );
  }, [
    discountedValue,
    index,
    changeQty,
    items?.selling_price,
    items?.gift_card_amount,
    setFieldValue,
    values,
    items?.discount_type,
    items.discount,
  ]);

  useEffect(() => {
    const taxAmount =
      ((items?.sub_total - items?.discount) * totalTaxPercentage) / 100;

    const perItemDiscountedPrice = singleDiscountedPrice(
      items?.sub_total,
      Number(items.discount_percentage)
    );

    const makeGrandTotal =
      Number(perItemDiscountedPrice?.toFixed(2)) +
      Number(taxAmount?.toFixed(2));

    setFieldValue(
      `orderItems[${index}].taxes`,
      items?.type === "inventory" ? Number(taxAmount?.toFixed(2)) : 0
    );
    setFieldValue(
      `orderItems[${index}].grand_total`,
      Number(makeGrandTotal?.toFixed(2))
    );
    // Calculate the total sub total value
    const allSubTotal = (values.orderItems || [])?.reduce(
      (acc, t) => acc + t.sub_total,
      0
    );

    setFieldValue(`order.sub_total`, Number(allSubTotal?.toFixed(2)));
    // Calculate the total discount
    const totalDiscount = (values.orderItems || [])?.reduce(
      (acc, t) => acc + t.discount,
      0
    );
    setFieldValue(`order.total_discount`, Number(totalDiscount?.toFixed(2)));
  }, [
    items?.sub_total,
    items?.taxes,
    items.discount,
    items.discount_percentage,
    totalTaxPercentage,
    index,
    setFieldValue,
    values,
    items?.type,
  ]);

  useEffect(() => {
    // Calculate the total taxes
    const totalTaxes = (values.orderItems || [])?.reduce(
      (acc, t) => acc + t.taxes,
      0
    );

    // Calculate the total qty
    const totalQty = (values.orderItems || [])?.reduce(
      (acc, t) => acc + t.qty,
      0
    );
    setFieldValue(`order.total_taxes`, Number(totalTaxes?.toFixed(2)));
    setFieldValue(`order.total_qty`, totalQty);
  }, [items?.qty, items?.taxes, setFieldValue, values, index]);

  useEffect(() => {
    console.log(
      values?.order?.sub_total,
      values?.order?.total_discount,
      values?.order?.total_taxes
    );
    const calculatedTotalPrice =
      values?.order?.sub_total -
      values?.order?.total_discount +
      values?.order?.total_taxes;

    setFieldValue("order.grand_total", Number(calculatedTotalPrice.toFixed(2)));
  }, [
    setFieldValue,
    values?.order?.sub_total,
    values?.order?.total_discount,
    values?.order?.total_taxes,
  ]);

  return (
    <div className="xl:p-4 p-2.5 flex flex-col gap-y-0.5 bg-secondary-100 rounded-base border-l-2 border-primary-400 mb-4">
      {items?.type === "inventory" ? (
        <Fragment>
          <div className="flex items-center justify-between gap-x-2">
            <h2 className="text-sm font-semibold capitalize text-secondary-700 xl:text-base">
              {items?.product_name}
            </h2>
            <div className="flex items-center gap-x-2 pb-1">
              {items?.variant_name && (
                <IconButton
                  size="sm"
                  type="button"
                  variant="text"
                  className="text-secondary-400"
                  onClick={() => handleEditItems(items, index)}
                >
                  <FontAwesomeIcon icon={faPenToSquare} fontSize={16} />
                </IconButton>
              )}

              <IconButton
                size="sm"
                type="button"
                variant="text"
                className="text-secondary-400"
                onClick={handleRemoveItems}
              >
                <FontAwesomeIcon icon={faCircleXmark} fontSize={16} />
              </IconButton>
            </div>
          </div>
          <div className="flex items-center gap-x-2 pb-1">
            {items?.selling_price && (
              <p
                className={`xl:text-sm text-xs font-medium ${
                  items?.discount_type === "percentage" &&
                  items.discount_percentage > 0 &&
                  "line-through"
                } text-secondary-400`}
              >
                {formatCurrencyWithNumberValue(items?.selling_price)}
              </p>
            )}
            {items?.discount_type === "percentage" &&
              items.discount_percentage > 0 && (
                <p className="text-xs font-medium xl:text-sm text-secondary-400">
                  {formatCurrencyWithNumberValue(
                    singleDiscountedPrice(
                      items?.selling_price,
                      Number(items.discount_percentage)
                    )
                  )}
                </p>
              )}
          </div>
          {items?.variant_name && (
            <div className="flex items-center gap-x-2 pb-1">
              <p className="text-sm font-normal text-secondary-400">
                {t("Variant :")}
                <span className="font-medium">{items?.variant_name}</span>
              </p>
            </div>
          )}
          <div className="flex items-center gap-x-2">
            <div className="flex items-center w-2/3 grow gap-x-2">
              <div className="relative w-full">
                <Input
                  type={"number"}
                  label={"Discount"}
                  labelProps={"xl:!text-sm text-xs !font-normal"}
                  className={"w-full xl:!text-base text-xs"}
                  name={`orderItems.[${index}]?.discount_percentage`}
                  value={values.orderItems[index]?.discount_percentage}
                  onChange={handleChangeDiscount}
                  error={errors?.orderItems?.[index]?.discount_percentage}
                />
                {values.orderItems[index]?.discount_type ===
                discountType.PERCENTAGE ? (
                  <IconButton
                    size="sm"
                    variant="text"
                    className="!absolute right-1 bottom-1.5"
                    onClick={() => handleChangeDiscountType(discountType.PRICE)}
                  >
                    <div className="relative">
                      <RotatePercentage className={`xl:w-6 xl:h-6 w-5 h-5`} />
                      <p className="xl:text-xs text-[8px] !absolute top-1 left-0 right-0 text-secondary-400">
                        %
                      </p>
                    </div>
                  </IconButton>
                ) : (
                  <IconButton
                    size="sm"
                    variant="text"
                    className="!absolute right-1 bottom-1.5"
                    onClick={() =>
                      handleChangeDiscountType(discountType.PERCENTAGE)
                    }
                  >
                    <div className="relative">
                      <RotatePercentage className={`xl:w-6 xl:h-6 w-5 h-5`} />
                      <p className="xl:text-xs text-[8px] !absolute top-1 left-0 right-0 text-secondary-400">
                        {orgDetail?.currency?.symbol}
                      </p>
                    </div>
                  </IconButton>
                )}
              </div>
              <div className="relative w-full">
                <Input
                  type={"number"}
                  label={"Quantity"}
                  name={`orderItems.[${index}].qty`}
                  value={values?.orderItems[index]?.qty}
                  labelProps={"xl:!text-sm text-xs !font-normal"}
                  className={
                    "!pl-10 !pr-10 w-full shrink-0 text-center xl:!text-base text-xs"
                  }
                  onChange={handleChange}
                  error={errors?.orderItems?.[index]?.qty}
                />
                <IconButton
                  size="sm"
                  variant="text"
                  className="!absolute left-1  bottom-1 text-secondary-400"
                  onClick={handleDecrementQty}
                  disabled={values?.orderItems[index]?.qty <= 1}
                >
                  <FontAwesomeIcon icon={faMinus} />
                </IconButton>
                <IconButton
                  size="sm"
                  variant="text"
                  className="!absolute right-1  bottom-1 text-secondary-400"
                  onClick={handleIncrementQty}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </IconButton>
              </div>
            </div>

            <div className="flex flex-col items-end justify-center w-1/3">
              <div className="py-3"></div>
              <p className="text-sm font-semibold text-info-400 xl:text-base">
                {formatCurrencyWithNumberValue(
                  singleDiscountedPrice(
                    items?.sub_total,
                    Number(items.discount_percentage)
                  )
                )}
              </p>
            </div>
          </div>
          {errors.orderItems &&
            errors.orderItems[index]?.discount_percentage && (
              <div className="error text-[14px] text-danger">
                {errors.orderItems[index].discount_percentage}
              </div>
            )}
          {errors.orderItems && errors.orderItems[index]?.qty && (
            <div className="error text-[14px] text-danger">
              {errors.orderItems[index].qty}
            </div>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <div className="flex items-center justify-between gap-x-2">
            <h2 className="font-semibold text-secondary-700">
              Gift Card - {items?.gift_card_code}
            </h2>
            <div className="flex items-center gap-x-2">
              <IconButton
                size="sm"
                type="button"
                variant="text"
                className="text-secondary-400"
                onClick={() => handleEditItems(items, index)}
              >
                <FontAwesomeIcon icon={faPenToSquare} fontSize={16} />
              </IconButton>
              <IconButton
                size="sm"
                type="button"
                variant="text"
                className="text-secondary-400"
                onClick={handleRemoveItems}
              >
                <FontAwesomeIcon icon={faCircleXmark} fontSize={16} />
              </IconButton>
            </div>
          </div>
          <p className="font-semibold text-info-400">
            {formatCurrencyWithNumberValue(
              singleDiscountedPrice(
                items?.sub_total,
                Number(items.discount_percentage)
              )
            )}
          </p>
          {/* <div className="flex items-center gap-x-2">
            <div className="grid grid-cols-2 grow gap-x-2">
              <div className="relative w-full">
                <Input
                  type={"number"}
                  label={"Quantity"}
                  name={`orderItems.[${index}].qty`}
                  value={values?.orderItems[index]?.qty}
                  labelProps={"!text-sm !font-normal"}
                  className={"!pl-10 !pr-10 w-full shrink-0 text-center"}
                />
                <IconButton
                  size="sm"
                  variant="text"
                  className="!absolute left-1 bottom-1.5 text-secondary-400"
                  onClick={handleDecrementQty}
                >
                  <FontAwesomeIcon icon={faMinus} />
                </IconButton>
                <IconButton
                  size="sm"
                  variant="text"
                  className="!absolute right-1 bottom-1.5 text-secondary-400"
                  onClick={handleIncrementQty}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </IconButton>
              </div>
            </div>

            <div className="flex flex-col items-end justify-center w-1/3">
              <div className="py-3"></div>
              <p className="font-semibold text-info-400">
                {formatCurrencyWithNumberValue(
                  singleDiscountedPrice(
                    items?.sub_total,
                    Number(items.discount_percentage)
                  )
                )}
              </p>
            </div>
          </div> */}
        </Fragment>
      )}
    </div>
  );
};

export default ManageOrder;
