import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  getAccountData: {
    loading: false,
    data: null,
    error: false,
  },
  updateAccount: {
    loading: false,
    data: null,
    error: false,
  },
};

export const accountSettingSlice = createSlice({
  name: "accountSetting",
  initialState: initialState,
  reducers: {
    accountSettingState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const { accountSettingState } = accountSettingSlice.actions;
export default accountSettingSlice.reducer;
