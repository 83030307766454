import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialAuthState = {
  isLoggedIn: false,
  loading: false,
  user: null,
  role: null,
  section_permissions: [],
  forgotPassword: {
    loading: false,
    data: null,
    error: null,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    authSetState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const { authSetState } = authSlice.actions;

export default authSlice.reducer;
