import React, {
  createRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import Logo from "../../assets/logo/quetzal-logo.svg";
import receiptLogo from "../../assets/logo/receipt-logo.svg";
// import receiptScanner from "../../assets/receipt-scanner.svg";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  formatCurrencyWithNumberValue,
  getUserOrgLocationSetting,
} from "../../utils/utils";
import Loader from "../common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { NewOrderIcon, PrintReceiptIcon } from "../svgs";
import { Button } from "@material-tailwind/react";
import { getAllDeviceRequest } from "../../store/settings/device/actions";
import { t } from "i18next";
import { useReactToPrint } from "react-to-print";

const OrderReceipt = ({
  isReceiptLoading,
  totalOrderData,
  totalTaxPercentage,
  openOrderReceipt,
}) => {
  const { defaultOrgLocation, getDeviceData, organization } = useSelector(
    (store) => ({
      defaultOrgLocation: store?.setting?.defaultOrgLocation,
      getDeviceData: store?.device?.getAllDeviceData?.data,
      organization: store?.setting?.organization,
    })
  );

  const contentRef = useRef(null);

  console.log(organization, "organization...........");

  const { order, customer, orderItems, returnPayments, payments } =
    totalOrderData;
  console.log(defaultOrgLocation, "defaultOrgLocation................");
  console.log(getDeviceData, "getDeviceData................");

  const getOrgDetail = getUserOrgLocationSetting();

  console.log(order, "order");
  console.log(customer, "customer");
  console.log(orderItems, "orderItems");
  console.log(returnPayments, "returnPayments");
  console.log(payments, "payments");

  const earnedBalance = orderItems?.reduce((total, item) => {
    if (item?.loyalty_type === "no loyalty points") {
      return total; // No additional value for "no loyalty points"
    } else if (item?.loyalty_type === "regular points") {
      return total + item?.selling_price * item?.qty; // Regular points
    } else if (item?.loyalty_type === "double points") {
      return total + item?.selling_price * item?.qty * 2; // Double points
    } else if (item?.loyalty_type === "triple points") {
      return total + item?.selling_price * item?.qty * 3; // Triple points
    }

    return total; // Default case
  }, 0);

  const netAmnt = orderItems?.reduce((total, item) => {
    return (total +=
      Number(item?.sub_total || 0) - Number(item?.discount || 0));
  }, 0);

  const printFn = useReactToPrint({
    contentRef: contentRef,
    documentTitle: "AwesomeFileName",
  });

  return (
    <>
      {isReceiptLoading && (
        <div className="absolute z-20 flex items-center justify-center w-full h-full bg-white/70 backdrop-blur-sm">
          <Loader />
        </div>
      )}
      <div
        ref={contentRef}
        className=" max-w-[450px] print:max-w-[302px] overflow-y-auto  h-[calc(100vh-65px)] print:h-auto px-3 mx-auto font-openSans bg-white border border-gray-300 shadow-lg  print:shadow-none print:border-none"
      >
        {/* Header */}
        <div className="mb-1 text-center">
          {/* On-screen logo */}
          <img
            src={Logo}
            alt="logo"
            className="flex-shrink-0  w-[140px] h-[60px] mx-auto print:hidden"
          />
          {/* Print logo */}
          <img
            src={receiptLogo}
            alt="print logo"
            className="flex-shrink-0 hidden mx-auto print:block"
          />

          <div className=" font-openSans text-[12px] print:text-[8px] w-full mt-2 text-[#303030] font-medium">
            <p className="max-w-[200px] print:max-w-[153px] text-center mx-auto">
              {defaultOrgLocation?.location_address}
            </p>
            <p className="max-w-[200px] text-center mx-auto">
              {t("Contact:")} {organization?.email}
            </p>
          </div>
        </div>
        {/* Tax Invoice Title */}
        <h2 className="my-2 text-[12px] print:text-[8px] font-bold text-center text-[#303030]">
          {t("TAX INVOICE")}
        </h2>
        {/* Invoice Details */}
        <div className="flex justify-between gap-4 mb-3 text-[12px] print:text-[8px]">
          <div className="pe-6">
            <p className=" text-[#303030] font-semibold">{t("Billed to")}</p>
            <p className="text-[#303030]  text-start font-medium">
              {customer?.first_name + " " + customer?.last_name || "no data"}
              <br />
              {customer?.code + " " + customer?.phone || "no data"}
            </p>
          </div>
          <div className="flex-1 border-l border-r border-[#E7E5E5]  ps-8 print:ps-3">
            <p className=" text-[#303030] font-semibold">{t("Invoice Date")}</p>
            <p className="text-[#303030]  text-start font-medium">
              {moment(order?.createdAt).format(
                getOrgDetail?.date_format?.date_format
              )}
            </p>
          </div>
          <div className="pe-4">
            <p className="font-semibold text-[#303030] ">{t("Receipt No")}</p>
            <p className="text-[#303030] text-start font-medium">
              {order?.order_no}
            </p>
          </div>
        </div>
        {/* Items List */}
        <div className="text-[12px] print:text-[8px]  border-t border-[#4C4C4C] border-dashed py-2 ">
          <div className="grid grid-cols-6 pb-1 font-semibold  text-[#303030] text-center">
            <div className="col-span-2 text-start ">{t("Item")}</div>
            <div>{t("Price")}</div>
            <div>{t("Qty")}</div>
            <div>{t("Disc Amt")}</div>
            <div className="text-end">{t("Net Amt")}</div>
          </div>
          {orderItems
            ?.filter((item, index) => {
              return item?.qty !== item?.return_qty;
            })
            ?.map((item, index) =>
              item?.type === "inventory" ? (
                <div
                  key={item.id}
                  className="grid grid-cols-6 py-1 font-normal text-[#303030] "
                >
                  <div className="col-span-2 ">
                    {item?.inventory_id?.product_name}
                  </div>
                  <div className="text-center">
                    {formatCurrencyWithNumberValue(
                      item?.selling_price?.toFixed(2) || 0
                    )}
                  </div>
                  <div className="text-center">
                    {item?.qty - (item?.return_qty || 0)}
                  </div>
                  <div className="text-center">
                    {formatCurrencyWithNumberValue(
                      (item?.discount && item?.discount?.toFixed(2)) || 0
                    )}
                  </div>
                  <div className="text-end">
                    {formatCurrencyWithNumberValue(
                      (
                        Number(item?.sub_total || 0) -
                        Number(item?.discount || 0)
                      )?.toFixed(2) || 0
                    )}
                  </div>
                </div>
              ) : (
                <div
                  key={item.id}
                  className="grid grid-cols-6 py-1 font-normal text-[#303030] "
                >
                  <div className="col-span-2 capitalize">
                    {"GiftCard :" + item?.gift_card_code}
                  </div>
                  <div className="text-center">
                    {formatCurrencyWithNumberValue(
                      item?.sub_total?.toFixed(2) || 0
                    )}
                  </div>
                  <div className="text-center">{item.qty}</div>
                  <div className="text-center">
                    {formatCurrencyWithNumberValue(
                      (item?.discount && item?.discount?.toFixed(2)) || 0
                    )}
                  </div>
                  <div className="text-end">
                    {formatCurrencyWithNumberValue(
                      (
                        Number(item?.sub_total || 0) -
                        Number(item?.discount || 0)
                      )?.toFixed(2) || 0
                    )}
                  </div>
                </div>
              )
            )}

          <div className="flex items-center justify-between  font-semibold text-[#4C4C4C] text-[12px] print:text-[8px]">
            <span>{t("Total Invoice Amount :")}</span>
            <span> {formatCurrencyWithNumberValue(netAmnt || 0)}</span>
          </div>
        </div>

        {/* Total Invoice Amount */}
        {/* Tax Details */}

        <div className="py-2  border-t border-[#4C4C4C] border-dashed text-[12px] print:text-[8px] ">
          <h3 className="mb-1 font-semibold text-[#303030]">
            {" "}
            {t("Tax Details")}
          </h3>
          <div className="flex justify-between mb-1 text-[#303030]">
            <span className="font-normal">
              {t("Taxes")} ({totalTaxPercentage || 0}%)
            </span>
            <span className="font-medium">
              {formatCurrencyWithNumberValue(order?.total_taxes || 0)}
            </span>
          </div>
          <div className="flex justify-between mb-1 text-[#303030]">
            <span className="font-normal">{t("Loyalty Points")}</span>
            <span className="font-medium">
              - {formatCurrencyWithNumberValue(order?.redeemed_dollar || 0)}
            </span>
          </div>

          <div className="flex items-center justify-between font-semibold text-[#4C4C4C]">
            <span>{t("Total Received Amount :")}</span>
            <span>
              {formatCurrencyWithNumberValue(order?.payable_amount || 0)}
            </span>
          </div>
        </div>
        {/* Loyalty Points */}
        {order?.order_status !== "hold" && (
          <div className="py-2 border-t border-[#4C4C4C] border-dashed text-[12px] print:text-[8px]">
            <h3 className="mb-1 font-semibold text-[#303030]">
              {t("Loyalty Points")}
            </h3>
            <div className="flex justify-between mb-1 text-[#303030]">
              <span className="font-normal">{t("Starting Balance")}</span>
              <span className="font-medium">
                {customer?.loyalty_points || 0} {t("Pts")}
              </span>
            </div>

            <div className="flex justify-between mb-1 text-[#303030]">
              <span className="font-normal">{t("Earned Balance")}</span>
              <span className="font-medium">{earnedBalance || 0} Pts</span>
            </div>
            <div className="flex justify-between text-[#303030]">
              <span className="font-normal">{t("Remain Balance")}</span>
              <span className="font-medium">
                {customer?.loyalty_points + earnedBalance || 0} {t("Pts")}
              </span>
            </div>
          </div>
        )}
        {/* Payment Type */}
        {order?.order_status !== "hold" && (
          <div className="py-2  border-[#4C4C4C] border-dashed border-t font-semibold  text-[12px] print:text-[8px]">
            <h3 className="mb-1  text-[#303030]">{t("Payment Type")}</h3>
            <div className=" text-[#4C4C4C]">
              {payments?.map((payment) => {
                return (
                  <div className="flex justify-between ">
                    <p className="capitalize">{payment?.payment_type}</p>
                    <p>{formatCurrencyWithNumberValue(payment?.amount || 0)}</p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {returnPayments?.length > 0 && (
          <div className="py-2  border-[#4C4C4C] border-dashed border-t font-semibold  text-[12px] print:text-[8px]">
            <h3 className="mb-1  text-[#303030]">{t("Returned Payment")}</h3>
            <div className=" text-[#4C4C4C]">
              {returnPayments?.map((payment) => {
                return (
                  <div className="flex justify-between ">
                    <p className="capitalize">{payment?.payment_type}</p>
                    <p>{formatCurrencyWithNumberValue(payment?.amount || 0)}</p>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {/* Footer Note */}
        <div className="flex items-center justify-center ">
          <div className="flex-grow border-t border-[#4C4C4C] border-dashed"></div>
        </div>
        <div className="mt-2 text-center  text-[12px] print:text-[8px] ">
          <p className="text-[#303030] max-w-[200px] mx-auto font-medium">
            {organization?.footer}
          </p>
          <p className="text-[#303030] max-w-[200px] mx-auto font-medium">
            {defaultOrgLocation?.extend_footer}
          </p>
        </div>
        {/* <div className=" text-center  text-[12px] print:text-[8px] py-1 flex flex-col gap-y-1 mb-4">
          <p className="text-[#4C4C4C] max-w-[223px] mx-auto font-semibold">
            GST/HST 123456789 RT 0001
          </p>

          <p className="text-[#4C4C4C] max-w-[150.63px] mx-auto font-semibold">
            9990207521032002262510
          </p>
        </div> */}
      </div>

      <div className="sticky bottom-0 p-2 bg-white btn-print">
        <div className="flex items-center justify-between px-2 gap-x-4">
          <Button
            type="button"
            className="flex items-center justify-center text-sm font-medium capitalize bg-white border rounded-theme border-secondary-500 text-info-400 px-4 py-2.5 gap-x-2.5"
            fullWidth
          >
            <NewOrderIcon className="w-4 h-4" />{" "}
            {/* Adjust icon size if needed */}
            {t("Send Email")}
          </Button>
          <Button
            type="button"
            className="flex items-center justify-center text-sm font-medium capitalize border rounded-theme gap-x-2.5 bg-primary-400 border-primary-400 px-4 py-2.5"
            fullWidth
            onClick={printFn}
            // disabled={!contentRef?.current}
          >
            <PrintReceiptIcon className="w-4 h-4" />
            {/* Adjust icon size if needed */}
            {t(" Print Receipt")}
          </Button>
        </div>
        {/* <ComponentToPrint ref={contentRef} /> */}
      </div>
    </>
  );
};

export default OrderReceipt;
