import { toast } from "react-toastify";
import AxiosRequest from "../../../AxiosRequest";
import { brandsState } from "./state";

/* -------------------------------------------------------------------------- */
/*                           get brand list request                           */
/* -------------------------------------------------------------------------- */

export const getBrandListRequest =
  ({ search = "", page, limit }) =>
    async (dispatch) => {
      try {
        dispatch(brandsState({ key: "brandList.loading", value: true }));
        const response = await AxiosRequest.get(
          `/user/brand/list?search=${search}&page=${page}&limit=${limit}`
        );
        const data = await response.data.data;
        if (data) {
          dispatch(brandsState({ key: "brandList.data", value: data?.brands }));
          dispatch(
            brandsState({ key: "brandList.totalPages", value: data?.totalPages })
          );
          dispatch(brandsState({ key: "brandList.loading", value: false }));
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(brandsState({ key: "brandList.loading", value: false }));
        dispatch(brandsState({ key: "brandList.error", value: error }));
      }
    };

/* -------------------------------------------------------------------------- */
/*                           delete brand request                           */
/* -------------------------------------------------------------------------- */

export const deleteBrandRequest =
  ({ brandId }) =>
    async (dispatch) => {
      try {
        dispatch(brandsState({ key: "deleteBrand.loading", value: true }));
        const response = await AxiosRequest.delete(
          `/user/brand/delete/${brandId}`
        );
        const data = await response.data;
        if (data) {
          dispatch(brandsState({ key: "deleteBrand.loading", value: false }));
          dispatch(brandsState({ key: "deleteBrand.data", value: data }));
          toast.success(response.data.message);
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(brandsState({ key: "deleteBrand.loading", value: false }));
        dispatch(brandsState({ key: "deleteBrand.error", value: error }));
      }
    };

/* -------------------------------------------------------------------------- */
/*                           add new  brand request                           */
/* -------------------------------------------------------------------------- */

export const createBrandRequest =
  ({ name }) =>
    async (dispatch) => {
      try {
        dispatch(brandsState({ key: "createBrand.loading", value: true }));
        const response = await AxiosRequest.post("/user/brand/create", { name });
        const data = await response.data.data;
        if (data) {
          dispatch(brandsState({ key: "createBrand.loading", value: false }));
          dispatch(getBrandListRequest({ search: name, page: 1, limit: 15 }));
          toast.success(response.data.message);
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(brandsState({ key: "createBrand.loading", value: false }));
        dispatch(brandsState({ key: "createBrand.error", value: error }));
      }
    };

/* -------------------------------------------------------------------------- */
/*                           update single Brand request                */
/* -------------------------------------------------------------------------- */

export const updateBrandRequest =
  ({ brandId, name }) =>
    async (dispatch) => {
      try {
        dispatch(brandsState({ key: "updateBrand.loading", value: true }));
        const response = await AxiosRequest.put(`/user/brand/update/${brandId}`, {
          name,
        });
        const data = await response.data.data;
        if (data) {
          dispatch(brandsState({ key: "updateBrand.loading", value: false }));
          dispatch(brandsState({ key: "updateBrand.data", value: data }));
          toast.success(response.data.message);
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(brandsState({ key: "updateBrand.loading", value: false }));
        dispatch(brandsState({ key: "updateBrand.error", value: error }));
      }
    };
