import { toast } from "react-toastify";
import AxiosRequest from "../../../AxiosRequest";
import { productState } from "./state";

/* -------------------------------------------------------------------------- */
/*                              get product list                              */
/* -------------------------------------------------------------------------- */

export const getProducts =
  ({ page, limit, search = "" }) =>
    async (dispatch) => {
      try {
        dispatch(productState({ key: "productList.loading", value: true }));
        const response = await AxiosRequest.get(
          `/user/inventory/product-list?page=${page}&&limit=${limit}&&search=${search}`
        );
        const data = await response.data.data;
        if (data) {
          dispatch(
            productState({ key: "productList.data", value: data?.products })
          );
          dispatch(
            productState({
              key: "productList.totalPages",
              value: data?.totalPages,
            })
          );
          dispatch(productState({ key: "productList.loading", value: false }));
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(productState({ key: "productList.loading", value: false }));
        dispatch(productState({ key: "productList.error", value: error }));
      }
    };

/* -------------------------------------------------------------------------- */
/*                               delete product                               */
/* -------------------------------------------------------------------------- */

export const deleteProductRequest =
  ({ productId, setModalClose }) =>
    async (dispatch) => {
      try {
        dispatch(productState({ key: "deleteProduct.loading", value: true }));
        const response = await AxiosRequest.delete(
          `/user/inventory/delete/${productId}`
        );
        const data = await response.data;
        if (data) {
          toast.success(response.data.message);
          dispatch(productState({ key: "deleteProduct.loading", value: false }));
          dispatch(productState({ key: "deleteProduct.data", value: data }));
          setModalClose(false);
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(productState({ key: "deleteProduct.loading", value: false }));
        dispatch(productState({ key: "deleteProduct.error", value: error }));
      }
    };

/* -------------------------------------------------------------------------- */
/*                           add new Product request                           */
/* -------------------------------------------------------------------------- */

export const createProductRequest =
  ({ body, resetForm, navigate }) =>
    async (dispatch) => {
      try {
        dispatch(productState({ key: "createProduct.loading", value: true }));
        const response = await AxiosRequest.post("/user/inventory/create", body);
        const data = await response.data.data;
        if (data) {
          resetForm();
          dispatch(productState({ key: "createProduct.loading", value: false }));
          toast.success(response.data.message);
          navigate("/inventory/items");
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(productState({ key: "createProduct.loading", value: false }));
        dispatch(productState({ key: "createProduct.error", value: error }));
      }
    };

/* -------------------------------------------------------------------------- */
/*                           update Product request                */
/* -------------------------------------------------------------------------- */

export const updateProductRequest =
  ({ productId, body, resetForm, navigate }) =>
    async (dispatch) => {
      try {
        dispatch(productState({ key: "updateProduct.loading", value: true }));
        const response = await AxiosRequest.put(
          `/user/inventory/update/${productId}`,
          body
        );
        const data = await response.data.data;

        if (data) {
          dispatch(productState({ key: "updateProduct.loading", value: false }));
          toast.success(response.data.message);
          resetForm();
          navigate("/inventory/items");
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(productState({ key: "updateProduct.loading", value: false }));
        dispatch(productState({ key: "updateProduct.error", value: error }));
      }
    };

/* -------------------------------------------------------------------------- */
/*                              get single product                            */
/* -------------------------------------------------------------------------- */

export const getSingleProduct =
  ({ productId }) =>
    async (dispatch) => {
      try {
        dispatch(productState({ key: "singleProduct.loading", value: true }));
        const response = await AxiosRequest.get(
          `/user/inventory/product/${productId}`
        );

        const data = await response.data.data;

        if (data) {
          dispatch(productState({ key: "singleProduct.data", value: data }));
          dispatch(productState({ key: "singleProduct.loading", value: false }));
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(productState({ key: "singleProduct.loading", value: false }));
        dispatch(productState({ key: "singleProduct.error", value: error }));
      }
    };



/* -------------------------------------------------------------------------- */
/*                              Delete Single product Image                            */
/* -------------------------------------------------------------------------- */

export const deleteProductImageRequest =
  ({ productId, setImageDeleted }) =>
    async (dispatch) => {
      try {
        dispatch(productState({ key: "deleteProductImage.loading", value: true }));
        const response = await AxiosRequest.delete(
          `/user/remove-inventory-image/${productId}`
        );

        const data = await response.data.data;

        if (data) {
          dispatch(productState({ key: "deleteProductImage.data", value: data }));
          dispatch(productState({ key: "deleteProductImage.loading", value: false }));
          setImageDeleted(true)
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(productState({ key: "deleteProductImage.loading", value: false }));
        dispatch(productState({ key: "deleteProductImage.error", value: error }));
      }
    };
