import React from "react";
import { formatCurrencyWithNumberValue } from "../../utils/utils";
import { t } from "i18next";

const ReturnOrderItemsTable = ({
  returnOrderItem,
  returnOrder,
  totalDiscountedAmount,
}) => {
  console.log(totalDiscountedAmount, "return order");

  // const total = returnOrderItem.reduce();

  return (
    <div className="h-full payment-table-container">
      <table className="w-full h-full table-auto text-[14px]">
        <thead>
          <tr>
            <th className="px-4 py-2 text-left Capitalize min-w-80">
              {t("Items")}
            </th>
            <th className="px-4 py-2 text-left Capitalize">
              {t("Return Qty")}
            </th>
            <th className="px-4 py-2 text-left Capitalize">
              {t("Selling Price")}
            </th>
            <th className="px-4 py-2 text-left Capitalize">{t("Sub Total")}</th>
            <th className="px-4 py-2 text-left Capitalize">{t("Discount")}</th>
            <th className="px-4 py-2 text-left Capitalize">{t("Total")}</th>
          </tr>
        </thead>
        <tbody>
          {(returnOrderItem || []).map((item, ind) => (
            <tr key={ind}>
              <td className="px-4 py-2 capitalize">
                {item.product_name || item.sku}
              </td>
              <td className="px-4 py-2">{item.return_qty}</td>
              <td className="px-4 py-2">
                {formatCurrencyWithNumberValue(item.selling_price)}
              </td>
              <td className="px-4 py-2">
                {formatCurrencyWithNumberValue(item.sub_total)}
              </td>
              <td className="px-4 py-2">
                {formatCurrencyWithNumberValue(item.discount)}
              </td>
              <td className="px-4 py-2">
                {formatCurrencyWithNumberValue(item.sub_total - item.discount)}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot className="">
          <tr className="bg-gray-100">
            <td className="px-4 py-2 font-bold border-y">{t("Total")}</td>
            <td className="px-4 py-2 font-bold border-y">
              {returnOrder?.total_qty}
            </td>

            <td className="px-4 py-2 font-bold border-y">
              {/* {formatCurrencyWithNumberValue(returnOrder?.sub_total)} */}
            </td>
            <td className="px-4 py-2 font-bold border-y">
              {formatCurrencyWithNumberValue(returnOrder?.sub_total)}
            </td>
            <td className="px-4 py-2 font-bold border-y">
              {formatCurrencyWithNumberValue(returnOrder?.total_discount)}
            </td>
            <td className="px-4 py-2 font-bold border-y">
              {formatCurrencyWithNumberValue(totalDiscountedAmount)}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default ReturnOrderItemsTable;
