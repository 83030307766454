import moment from "moment";
import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoyaltyPointHistoryRequest } from "../../store/customer/actions";
import { customerState } from "../../store/customer/state";
import { adjustPoints } from "../../utils/enums";

const LoyaltyPointsHistory = ({ selectedCustomerId }) => {
  const dispatch = useDispatch();
  const {
    isLoading,
    getLoyaltyPointsHistory,
    totalPages,
    adjustPoint,
  } = useSelector((store) => ({
    isLoading: store?.customer?.getLoyaltyPoints?.loading,
    getLoyaltyPointsHistory: store?.customer?.getLoyaltyPoints?.data,
    totalPages: store?.customer?.getLoyaltyPoints?.totalPages,
    adjustPoint: store?.customer?.updateAdjustPoint?.data,
  }));
  let limit = 5;
  const [page, setPage] = useState(1);
  const [pointsData, setPointsData] = useState([]);

  const handleScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    let scrollOffset = scrollHeight - scrollTop - clientHeight;
    if (scrollOffset === 0 && totalPages > page && selectedCustomerId) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useLayoutEffect(() => {
    if (selectedCustomerId) {
      dispatch(
        getLoyaltyPointHistoryRequest({
          customerId: selectedCustomerId,
          page,
          limit,
        })
      );
    }
  }, [dispatch, page, selectedCustomerId]);

  useLayoutEffect(() => {
    if (getLoyaltyPointsHistory?.length > 0) {
      setPointsData([...pointsData, ...getLoyaltyPointsHistory]);
    }
  }, [getLoyaltyPointsHistory]);

  useEffect(() => {
    if (adjustPoint !== null) {
      setPointsData([]);
      dispatch(customerState({ key: "getLoyaltyPoints.data", value: [] }));
      dispatch(
        customerState({
          key: "getLoyaltyPoints.totalPages",
          value: null,
        })
      );
      if (page > 1) {
        setPage(1);
      } else {
        dispatch(
          getLoyaltyPointHistoryRequest({
            customerId: selectedCustomerId,
            page: 1,
            limit,
          })
        );
      }
      dispatch(customerState({ key: "updateAdjustPoint.data", value: null }));
    }
  }, [adjustPoint, dispatch]);

  useEffect(() => {
    return () => {
      setPointsData([]);
      setPage(1);
      dispatch(customerState({ key: "getLoyaltyPoints.data", value: [] }));
      dispatch(
        customerState({
          key: "getLoyaltyPoints.totalPages",
          value: null,
        })
      );
    };
  }, []);

  return (
    <Fragment>
      {pointsData?.length > 0 && (
        <div
          className="p-4 pr-2 overflow-y-auto rounded-theme bg-secondary-100 min-h-32 max-h-32"
          onScroll={handleScroll}
        >
          {pointsData?.map((item, index) => (
            <div
              className="w-full flex items-center justify-between gap-x-3.5"
              key={index}
            >
              <h4 className="flex-shrink-0 font-medium text-info-400 min-w-[200px]">
                {moment(item?.date).format("Do MMMM, YYYY")}
              </h4>
              <h6
                className={`grow ${
                  item.type === adjustPoints?.ADJUSTED
                    ? "text-red-500"
                    : adjustPoints?.EARNED
                    ? "text-green-500"
                    : "text-green-500"
                } capitalize`}
              >
                {item.type}
              </h6>
              <p className="flex-shrink-0 font-medium text-info-400">
                {Math.round(item.loyalty_points) + " " + "pts"}
              </p>
            </div>
          ))}
        </div>
      )}
    </Fragment>
  );
};

export default LoyaltyPointsHistory;
