import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Checkbox,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";
import React, {
  Fragment,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/common/Loader";
import Modal from "../../components/common/Modal";
import CustomerOrderWrapper from "../../components/CustomerOrderWrapper";
import { OrderContext } from "../../helpers/context/OrderContext";
import Layout from "../../layout";
// import { getProductListRequest } from "../../store/register/products/action";
import { discountType } from "../../utils/enums";
import { formatCurrencyWithNumberValue, useDebounce } from "../../utils/utils";
import NoProduct from "./NoProduct";
import TopBar from "./TopBar";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { getOrderDetailRequest } from "../../store/order/action";
import { orderState } from "../../store/order/state";
import { profileAction } from "../../store/settings/settings.fetch";
import ProductSkeleton from "../../components/register/ProductSkeleton";
import { getProductListRequest } from "../../rq-store/register/products";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";

const Register = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [query, setQuery] = useSearchParams();
  const orderId = query.get("q");
  const { formik } = useContext(OrderContext);
  // const {
  //   // productsData,
  //   totalPages,
  //   isOrderLoading,
  //   holdOrderData,
  // } = useSelector((store) => ({
  //   // isLoading: store?.products?.products?.loading,
  //   // productsData: store?.products?.products?.data,
  //   totalPages: store?.products?.products?.totalPages,
  //   holdOrderData: store?.order?.orderDetail?.data,
  // }));
  // const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);
  const [openModel, setOpenModel] = useState(false);
  const [search, setSearch] = useState("");
  const searchValue = useDebounce(search); //for search
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [scrollingText, setScrollingText] = useState([]);

  const queryClient = useQueryClient();

  console.log(selectedCategory, "selectedCategory..............");

  // product list api request
  const {
    data,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteQuery({
    queryKey: ["productsData", search, selectedCategory],
    queryFn: ({ pageParam = 1 }) =>
      getProductListRequest({
        search,
        page: pageParam,
        limit,
        category_ids: [selectedCategory],
      }),
    getNextPageParam: (lastPage, allPages) => {
      console.log(lastPage, "lastPage.............");
      console.log(allPages, "allPages.............");
      return lastPage?.products?.length >= 12
        ? allPages?.length + 1
        : undefined;
    },
    retry: 1, // Set to retry only 1 time upon failure
    staleTime: 5 * 60 * 1000,
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const productsData =
    data?.pages?.flatMap((page) => page.products || []) || [];

  // add ref when first page data over for fetch new data
  const { ref, inView } = useInView();

  // fetch next page on scroll
  useEffect(() => {
    if (!isFetching && inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  // check if product_name is overflow then make it auto scrollable
  const checkOverflow = (index) => {
    const element = document.getElementById(`product_name_${index}`);

    if (element) {
      // Clone the element to measure full content width
      const clone = element.cloneNode(true);
      clone.style.cssText = `
      position: absolute;
      visibility: hidden;
      white-space: nowrap;
      width: auto;
    `;

      document.body.appendChild(clone);
      const contentWidth = clone.scrollWidth;
      document.body.removeChild(clone);

      // Use clientWidth to get the visible width of the element
      const elementWidth = element.clientWidth;

      // If the content width is greater than the visible width, enable scrolling
      if (contentWidth > elementWidth) {
        setScrollingText((prev) => [...prev, index]);
      } else {
        setScrollingText((prev) => prev.filter((item) => item !== index));
      }
    }
  };

  // after check set all conditional true products into scrollingText array
  const handleResize = () => {
    setScrollingText([]); // Clear previous states
    productsData?.forEach((_, index) => {
      checkOverflow(index);
    });
  };

  // on size change of the screen its call
  useEffect(() => {
    // Initial check for overflow
    handleResize();

    // Debounced resize handler
    const debounceResize = debounce(handleResize, 100);

    // Add resize event listener
    window.addEventListener("resize", debounceResize);

    // Clean up on unmount
    return () => {
      window.removeEventListener("resize", debounceResize);
    };
  }, [data]);

  // Debounce function to optimize resize handling
  const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    // if (page > 1) setPage(1);
  };

  // // const handleScroll = (event) => {
  // //   const { scrollHeight, scrollTop, clientHeight } = event.target;
  // //   let scrollOffset = scrollHeight - scrollTop - clientHeight;
  // //   if (scrollOffset < 50 && totalPages > page && !isLoading) {
  // //     setPage((prevPage) => prevPage + 1);
  // //   }
  // };

  // on click product add to order
  const handleAddToOrder = (items) => {
    const productArr = [...formik.values.orderItems];

    // Check if variants exist and selectedVariants has items
    if (selectedVariants?.length > 0) {
      const newArr = selectedVariants?.map((item) => {
        const existingProduct = productArr.find(
          (orderItem) =>
            orderItem.inventory_id === selectedProduct._id &&
            orderItem.variant_name === item?.name
        );

        if (existingProduct) {
          // If product exists, increment the quantity
          existingProduct.qty += 1;
          existingProduct.sub_total =
            existingProduct.qty * existingProduct.selling_price;
          existingProduct.grand_total =
            existingProduct.sub_total -
            existingProduct.discount +
            existingProduct.taxes;
          return null; // Do not add duplicate product
        }

        return {
          type: "inventory",
          inventory_id: selectedProduct?._id,
          variant_name: item?.name,
          product_name: selectedProduct?.product_name,
          sku: item?.sku,
          qty: 1,
          total_qty: item?.stock,
          selling_price: item?.price,
          sub_total: item?.price,
          discount_type: discountType.PERCENTAGE,
          discount_percentage: 0,
          discount: 0,
          taxes: 0,
          grand_total: item?.price,
        };
      });

      const filteredArr = newArr.filter((product) => product !== null);
      const tempArr = [...productArr, ...filteredArr];
      formik.setFieldValue("orderItems", tempArr);
    } else {
      // Handle for non-variant products
      const existingProduct = productArr.find(
        (orderItem) => orderItem.inventory_id === items?._id
      );

      if (existingProduct) {
        // If product exists, increment the quantity
        existingProduct.qty += 1;
        existingProduct.sub_total =
          existingProduct.qty * existingProduct.selling_price;
        existingProduct.grand_total =
          existingProduct.sub_total -
          existingProduct.discount +
          existingProduct.taxes;
      } else {
        const newArr = {
          type: "inventory",
          inventory_id: items?._id,
          product_name: items?.product_name,
          sku: items?.sku,
          qty: 1,
          total_qty: items?.quantities,
          selling_price: items?.selling_price,
          sub_total: items?.selling_price,
          discount_type: discountType.PERCENTAGE,
          discount_percentage: 0,
          discount: 0,
          taxes: 0,
          grand_total: items?.selling_price,
        };
        productArr.push(newArr);
      }

      formik.setFieldValue("orderItems", productArr);
    }

    setSelectedVariants([]);
    setSelectedProduct(null);
    setOpenModel(false);
  };

  const handleProductClick = (items) => {
    if (items?.variants_possibilities?.length > 0) {
      setSelectedProduct(items);
      setOpenModel(true);
    } else {
      handleAddToOrder(items);
      console.log("No variant possibilities available for this product");
    }
  };

  // useEffect(() => {
  //   dispatch(profileAction());
  //   dispatch(
  //     getProductListRequest({
  //       search,
  //       page,
  //       limit,
  //       category_ids: [selectedCategory],
  //     })
  //   );
  // }, [dispatch, page, searchValue, selectedCategory]);

  // useEffect(() => {
  //   if (page === 1) {
  //     setProducts(productsData);
  //   } else if (productsData?.length > 0) {
  //     setProducts((prevProducts) => [...prevProducts, ...productsData]);
  //   }
  // }, [productsData, page]);

  // useEffect(() => {
  //   // setPage(1);
  //   // setProducts([]);
  //   console.log(
  //     "this effect call ............................................"
  //   );
  // }, [selectedCategory, search]);

  // when category change invalidate query
  useEffect(() => {
    // setPage(1);
    // Get the current state of the query
    const queryState = queryClient.getQueryState([
      "productsData",
      searchValue,
      selectedCategory,
    ]);

    // If queryState exists and the data is not stale, do not invalidate
    if (!queryState || queryState.isStale) {
      queryClient.invalidateQueries({
        queryKey: ["productsData", searchValue, selectedCategory],
      });
    }
  }, [dispatch, page, searchValue, selectedCategory]);

  // set all selected product sku array so checkbox remain disabled for that particular item
  useEffect(() => {
    const selectedSkus = formik?.values?.orderItems.map((items) => {
      return items.sku;
    });
    setSelectedOrder(selectedSkus);
  }, [formik?.values?.orderItems]);

  console.log(selectedOrder, "selectedOrder............");

  useLayoutEffect(() => {
    if (orderId) {
      dispatch(getOrderDetailRequest({ orderId }));
    }
  }, [orderId, dispatch]);

  // esc click moadl is close
  useEffect(() => {
    // Function to handle the ESC key press
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        setOpenModel(false); // Close the modal
      }
    };

    // Add event listener for keydown
    document.addEventListener("keydown", handleEsc);

    // Cleanup the event listener when component unmounts
    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, []); // Empty dependency array to run this effect only on mount/unmount

  return (
    <Layout search={search} handleChange={handleSearch}>
      <div className="flex w-full gap-x-3">
        <div className="overflow-hidden grow">
          <TopBar
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
          <div
            // onScroll={handleScroll}
            className="xl:h-[calc(100vh-201px)] h-[calc(100vh-166px)] overflow-y-auto"
          >
            {isLoading === false &&
              // products.length === 0 &&
              productsData.length === 0 && <NoProduct />}
            <div className="grid gap-3 md:grid-cols-2 2xl:grid-cols-4 lg:grid-cols-3 xl:gap-5">
              {productsData?.length > 0 &&
                productsData?.map((items, index) => (
                  <button
                    key={index}
                    onClick={() => handleProductClick(items)}
                    className="overflow-hidden bg-white rounded-theme"
                  >
                    <div className="w-full xl:h-[144px] h-28">
                      <img
                        src={items?.image}
                        alt="img"
                        className="object-contain w-full h-full"
                      />
                    </div>
                    <div className="xl:p-[15px] xl:pt-2.5 p-2.5">
                      <h2
                        id={`product_name_${index}`}
                        className={`text-info-600 text-sm_20 font-semibold w-full text-start mb-1.5 capitalize ${
                          scrollingText.includes(index) ? "scrolling-text" : ""
                        }`}
                      >
                        {items?.product_name}
                      </h2>
                      <div className="flex items-center gap-2">
                        <div className="font-bold text-primary-400 2xl:text-lg xl:text-base text-sm_18">
                          {formatCurrencyWithNumberValue(items?.selling_price)}
                        </div>
                      </div>
                    </div>
                  </button>
                ))}

              <div
                className={`${
                  !hasNextPage && "hidden"
                } flex justify-center my-1 ${isFetching && "opacity-50"}`}
                ref={ref}
              >
                {/* {(isLoading || isFetching) && <ProductSkeleton />} */}
              </div>
            </div>
            {(isLoading || isFetching) && (
              // <div className="flex items-center justify-center h-full">
              //   <Loader />
              // </div>
              <ProductSkeleton />
            )}
          </div>
        </div>
        <div className="w-[444px] xl:min-w-[444px] lg:min-w-[331px] md:min-w-[289px]">
          <CustomerOrderWrapper orderId={orderId} />
        </div>
      </div>

      <Modal
        size="md"
        open={openModel}
        handler={() => {
          setOpenModel(false);
        }}
      >
        {selectedProduct && (
          <div className="flex flex-col w-full gap-y-4">
            <div className="flex items-center justify-between">
              <h5 className="text-lg font-bold capitalize text-info-500 xl:text-xl lg:text-lg">
                {selectedProduct?.product_name}
              </h5>
              <IconButton
                size=""
                variant="text"
                className=""
                onClick={() => setOpenModel(false)}
              >
                <FontAwesomeIcon icon={faClose} fontSize={18} />
              </IconButton>
            </div>
            <div className="flex-grow overflow-y-auto border rounded-theme">
              <div className="flex items-center justify-between px-2 py-3 bg-gray-200">
                <h5 className="pl-3 text-lg font-medium text-black">
                  {t("Variant")}
                </h5>
                <div className="text-center w-36">
                  <h5 className="text-lg font-medium text-black">
                    {t("Price")}
                  </h5>
                </div>
              </div>
              <div className="p-2">
                {selectedProduct?.variants_possibilities?.map(
                  (items, index) => (
                    console.log(items, "items"),
                    console.log(selectedOrder, "selectedOrder"),
                    (
                      <Fragment>
                        <div
                          className="flex items-center justify-between"
                          key={index}
                        >
                          <label className="flex items-center gap-3 cursor-pointer">
                            <Checkbox
                              className="w-5 h-5"
                              label={items?.name}
                              labelProps={{
                                className:
                                  "text-info-700 text-base font-medium",
                              }}
                              disabled={selectedOrder.includes(items.sku)}
                              checked={
                                selectedVariants.includes(items) ||
                                selectedOrder.includes(items.sku)
                              }
                              onChange={(e) => {
                                const tempArr = [...selectedVariants];
                                if (e.target.checked) {
                                  tempArr.push(items);
                                } else {
                                  const findObject = tempArr.indexOf(items);
                                  if (findObject > -1) {
                                    tempArr.splice(findObject, 1);
                                  }
                                }
                                setSelectedVariants(tempArr);
                              }}
                            />
                          </label>
                          <div className="text-base font-medium text-center text-info-700 w-36">
                            {formatCurrencyWithNumberValue(items?.price)}
                          </div>
                        </div>
                        {index <
                          selectedProduct?.variants_possibilities?.length -
                            1 && <hr className="" />}
                      </Fragment>
                    )
                  )
                )}
              </div>
            </div>

            <div className="flex justify-end">
              <Button
                type="button"
                className="text-sm font-semibold capitalize bg-primary-400 rounded-theme"
                onClick={handleAddToOrder}
                disabled={selectedVariants.length === 0}
              >
                {t("Add To Order")}
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </Layout>
  );
};

export default Register;
