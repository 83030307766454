import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import { getCategoriesList } from "../../store/register/category/action";
import Loader from "../../components/common/Loader";
import { useTranslation } from "react-i18next";
import { categoryState } from "../../store/register/category/state";
import { Typography } from "@material-tailwind/react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getCategoriesList } from "../../rq-store/register/category";
import { toast } from "react-toastify";

const TopBar = ({ selectedCategory, setSelectedCategory }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { categoryData, totalPages } = useSelector((store) => ({
    // isLoading: store?.category?.categories?.loading,
    categoryData: store?.category?.categories?.data,
    totalPages: store?.category?.categories?.totalPages,
  }));

  // cashDrawerList api request
  const {
    data,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteQuery({
    queryKey: ["categoryData"],
    queryFn: ({ pageParam = 1 }) =>
      getCategoriesList({
        page: pageParam,
        limit,
      }),
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.categories?.length === 10
        ? allPages?.length + 1
        : undefined;
    },
    retry: 1, // Set to retry only 1 time upon failure
    staleTime: 5 * 60 * 1000,
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const [limit, setLimit] = useState(10);
  // const [category, setcategory] = useState([]);
  const [skeletonCount, setSkeletonCount] = useState(12);

  console.log(data, "data...............");

  // total category
  const category = data?.pages?.flatMap((page) => page.categories || []) || [];

  // Horizontal scroll handler
  const handleScroll = (e) => {
    const scrollLeft = e.target.scrollLeft;
    const scrollWidth = e.target.scrollWidth;
    const clientWidth = e.target.clientWidth;

    // If user has scrolled near the end of the horizontal container
    if (scrollLeft + clientWidth >= scrollWidth - 10) {
      if (!isFetching && hasNextPage) {
        fetchNextPage(); // Fetch the next page
      }
    }
  };

  const handleTabClick = (category) => {
    setSelectedCategory(category);
  };

  // // fetch next page on scroll
  // useEffect(() => {
  //   console.log(hasNextPage, "hasNextPage........");
  //   console.log(inView, "inView........");
  //   if (!isFetching && inView && hasNextPage) {
  //     fetchNextPage();
  //   }
  // }, [inView, hasNextPage, fetchNextPage]);

  // console.log(category, "category..........");

  // const handleScroll = (event) => {
  //   const { scrollHeight, scrollTop, clientHeight } = event.target;
  //   let scrollOffset = scrollHeight - scrollTop - clientHeight;
  //   if (scrollOffset < 50 && totalPages > page && !isLoading) {
  //     fetchNextPage();
  //   }
  // };

  // useEffect(() => {
  //   dispatch(getCategoriesList({ page, limit }));
  // }, [dispatch, page, limit]);

  // useEffect(() => {
  //   if (categoryData?.length > 0) {
  //     setcategory([...category, ...categoryData]);
  //   }
  // }, [categoryData]);

  // useEffect(() => {
  //   // On component unmount, reset the customer data
  //   return () => {
  //     setcategory([]); // Reset local state
  //     dispatch(categoryState({ key: "categories.data", value: [] })); // Reset Redux state
  //   };
  // }, [dispatch]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1280) {
        setSkeletonCount(9); // Large screens (lg and above)
      } else if (window.innerWidth >= 768) {
        setSkeletonCount(5); // Medium screens (md)
      } else {
        setSkeletonCount(5); // Small screens (default)
      }
    };

    // Set initial count
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      onScroll={handleScroll}
      className="flex items-center max-w-full gap-2 pb-2 mb-5 overflow-x-auto cursor-pointer lg:gap-4"
    >
      <Link
        href="#"
        onClick={() => handleTabClick(null)}
        className={`border border-secondary-200 flex items-center justify-center xl:text-base text-sm_18 font-medium ${
          !selectedCategory
            ? "bg-primary-400 text-white border-primary-400"
            : "text-secondary-400"
        } xl:rounded-theme rounded-base xl:px-[30px] lg:px-5 px-3.5 xl:py-4 py-2 hover:bg-primary-400 hover:text-white transition-all duration-300 hover:border-primary-400 text-nowrap`}
      >
        {t("All")}
      </Link>
      {category?.map((items, index) => (
        <Link
          href="#"
          key={index}
          onClick={() => handleTabClick(items?._id)}
          className={`border border-secondary-200 flex items-center justify-center xl:text-base text-sm_18 font-medium ${
            selectedCategory === items?._id
              ? "bg-primary-400 text-white border-primary-400"
              : "text-secondary-400"
          } xl:rounded-theme rounded-base xl:px-[30px] lg:px-5 px-3.5 xl:py-4 py-2 hover:bg-primary-400 hover:text-white transition-all duration-300 hover:border-primary-400 text-nowrap capitalize`}
        >
          {items?.name}
        </Link>
      ))}
      {/* Loading Skeleton while fetching */}

      {(isLoading || isFetching) &&
        Array(skeletonCount)
          .fill()
          .map((_, index) => (
            <div>
              <Typography className="bg-gray-200 animate-pulse xl:rounded-theme rounded-base xl:px-[30px] lg:px-5 px-3.5 xl:py-4 py-2 text-sm_18  text-gray-400 xl:w-28 w-20 xl:h-[55px] h-[34px]"></Typography>
            </div>
          ))}
    </div>
  );
};

export default TopBar;
