import {
  faPercent,
  faPlus,
  faTrash,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, IconButton } from "@material-tailwind/react";
import { useFormik } from "formik";
import React, { Fragment, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Input from "../../components/common/Input";
import Loader from "../../components/common/Loader";
import {
  LocationSettingSvg,
  ManageDeleteProductIcon,
} from "../../components/svgs";
import Layout from "../../layout";
import {
  getLocation,
  updateLocation,
} from "../../store/settings/location/actions";
import { getUserDetails } from "../../utils/utils";
import { profileAction } from "../../store/settings/settings.fetch";
import { useTranslation } from "react-i18next";

const LocationSettings = () => {
  const { t } = useTranslation();

  const userDetail = getUserDetails();
  const locationId = userDetail?.default_orgs_location_id;
  const dispatch = useDispatch();
  const { isLoading, locationData, isLoadingUpdate } = useSelector((store) => ({
    locationData: store?.location?.getLocationData?.data,
    isLoading: store?.location?.getLocationData?.loading,
    isLoadingUpdate: store?.location?.updateLocation?.loading,
  }));
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      location_name: locationData?.location_name ?? "",
      location_address: locationData?.location_address ?? "",
      extend_footer: locationData?.extend_footer ?? "",
      tax_details:
        locationData?.tax_details?.length > 0
          ? locationData?.tax_details.map(({ tax_name, tax_percentage }) => ({
              tax_name,
              tax_percentage,
            }))
          : [{ tax_name: "", tax_percentage: 0 }],
    },
    // validation
    validationSchema: Yup.object({
      location_name: Yup.string().required(t("Location name is required")),
      location_address: Yup.string().required(
        t("Location address is required")
      ),
      tax_details: Yup.array().of(
        Yup.object().shape({
          tax_name: Yup.string()
            .trim()
            .test(
              "taxNameAndPercentage",
              t("Tax name and percentage must be provided together"),
              function (value) {
                const { tax_percentage } = this.parent;
                return !(value && (!tax_percentage || tax_percentage === 0));
              }
            ),
          tax_percentage: Yup.number()
            .nullable()
            .test(
              "taxNameAndPercentage",
              t("Tax name and percentage must be provided together"),
              function (value) {
                const { tax_name } = this.parent;
                return !(value && !tax_name);
              }
            ),
        })
      ),
    }),
    onSubmit: (values) => {
      // Filter out empty tax details
      const processedValues = {
        ...values,
        tax_details: values.tax_details.map((tax) => ({
          tax_name: tax.tax_name.trim(), // Trim tax_name
          tax_percentage: isNaN(Number(tax.tax_percentage))
            ? 0
            : Number(tax.tax_percentage),
        })),
        // .filter((tax) => tax.tax_name !== ""),
      };
      // Dispatch update location
      dispatch(updateLocation({ locationId, body: processedValues })).then(
        (res) => {
          if (res?.status === 200) {
            dispatch(profileAction());
          }
        }
      );
    },
  });

  // Dispatch get location
  useLayoutEffect(() => {
    if (locationId) {
      dispatch(getLocation({ locationId }));
    }
  }, [dispatch, locationId]);

  // Function to add a new tax field
  const addNewTax = () => {
    formik.setFieldValue("tax_details", [
      ...formik.values.tax_details,
      { tax_name: "", tax_percentage: 0 },
    ]);
  };

  // Handler to remove a tax
  const handleRemoveTax = (index) => {
    const updatedTaxDetails = formik.values.tax_details.filter(
      (_, i) => i !== index
    );
    formik.setFieldValue("tax_details", updatedTaxDetails);
  };

  return (
    <Layout>
      {/* Loader */}
      {isLoading && (
        <div className="absolute z-20 flex items-center justify-center w-full h-full bg-white/70 backdrop-blur-sm">
          <Loader />
        </div>
      )}
      {/* Form section */}

      <form onSubmit={formik.handleSubmit}>
        <div className="bg-white xl:h-[calc(100vh-120px)] h-[calc(100vh-100px)]  py-[30px] pl-5 rounded-theme">
          <div className="">
            <div className="2xl:col-span-3 col-span-2 xl:h-[calc(100vh-162px)] h-[calc(100vh-136px)] overflow-y-auto no-scrollbar  pr-2 ">
              <div className="flex lg:flex-row flex-col lg:gap-[30px] gap-5 border-b border-secondary-200  pb-[50px] lg:w-[781px]">
                <div className="lg:max-w-[190px] max-w-[150px] w-full">
                  <p className="text-base font-medium capitalize xl:text-xl lg:text-lg text-info-500">
                    {t("Additional receipt Details")}
                  </p>
                </div>
                <div className="max-w-[425px] w-full flex flex-col gap-y-7">
                  <div className="">
                    <label className="block pb-2 font-medium xl:text-base lg:text-base text-sm_18 text-secondary-500 lg:pb-3">
                      {t("Location Name")}
                    </label>
                    <Input
                      type={"text"}
                      placeholder="Location Name"
                      id="location_name"
                      name="location_name"
                      value={formik?.values?.location_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={
                        "focus:outline-none bg-secondary-50 w-full xl:text-lg lg:text-base text-sm_18 font-medium rounded-base h-[46px]"
                      }
                    />
                    <p className="text-red-600 capitalize">
                      {formik.errors.location_name}
                    </p>
                  </div>

                  <div className="">
                    <label className="block pb-2 font-medium xl:text-base lg:text-base text-sm_18 text-secondary-500 lg:pb-3">
                      {t("Address")}
                    </label>
                    <textarea
                      type={"text"}
                      id="location_address"
                      placeholder={t("Location Address")}
                      name="location_address"
                      rows="4"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik?.values?.location_address}
                      className="outline-none resize-none bg-secondary-50 text-info-500 xl:text-lg lg:text-base text-sm_18 font-medium w-full placeholder:text-secondary-500 border p-2.5 rounded-theme border-secondary-200 focus:border-primary-400 focus:shadow transition-all duration-200"
                    ></textarea>
                    <p className="text-red-600">
                      {formik.errors.location_address}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex lg:flex-row flex-col lg:gap-[30px] gap-5 border-b border-secondary-200 lg:w-[781px] pb-[50px] pt-7">
                <div className="lg:max-w-[190px] max-w-[150px] w-full">
                  <p className="text-base font-medium capitalize xl:text-xl lg:text-lg text-info-500">
                    {t("sales taxes")}
                  </p>
                </div>
                <div className="lg:max-w-[543px] w-full flex flex-col gap-y-7">
                  {formik.values.tax_details.map((tax, index) => (
                    <div className="mb-4">
                      <div key={index} className="flex items-center gap-4">
                        <Input
                          type="text"
                          id={`tax_details[${index}].tax_name`}
                          name={`tax_details[${index}].tax_name`}
                          placeholder={`Tax Name`}
                          value={formik?.values?.tax_details[index]?.tax_name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={
                            "bg-secondary-100 lg:min-w-[343px] h-[46px]"
                          }
                        />
                        <Input
                          type={"number"}
                          className={"bg-secondary-100 lg:w-[117px] h-[46px]"}
                          name={`tax_details[${index}].tax_percentage`}
                          placeholder={`0`}
                          value={
                            formik?.values?.tax_details[index]?.tax_percentage
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          iconEnd={
                            <FontAwesomeIcon icon={faPercent} fontSize={18} />
                          }
                        />

                        {/* <FontAwesomeIcon
                          onClick={() => handleRemoveTax(index)}
                          icon={faTrashAlt}
                          className="px-5 text-red-500"
                        /> */}

                        <IconButton
                          type="button"
                          size="sm"
                          variant="text"
                          className="hover:bg-transparent" // Set hover background to transparent
                          onClick={() => handleRemoveTax(index)}
                        >
                          <ManageDeleteProductIcon className="w-4 h-4" />
                        </IconButton>
                      </div>

                      {formik.errors.tax_details?.length > 0 &&
                        formik.errors.tax_details[index]?.tax_name && (
                          <p className="my-1 text-sm text-red-500">
                            {formik.errors.tax_details[index].tax_name}
                          </p>
                        )}
                      {formik.errors.tax_details?.length > 0 &&
                        formik.errors.tax_details[index]?.tax_percentage && (
                          <p className="my-1 text-sm text-red-500">
                            {formik.errors.tax_details[index].tax_percentage}
                          </p>
                        )}
                    </div>
                  ))}
                  <div>
                    <Button
                      onClick={addNewTax}
                      className="text-lg font-semibold text-primary-400 border border-primary-400 h-[46px] px-3.5 bg-transparent min-w-[136px] capitalize flex items-center gap-2"
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      <p>{t("New Tax")}</p>
                    </Button>
                  </div>
                  <div className="space-y-1">
                    <p className="text-base">Notes:</p>
                    <p className="text-base text-secondary-400">
                      {t(
                        "A Configured requires a name and a non-zero percentage.Quetzal supports tax precision upto three decimal places.Embed a tax only to include that tax in printed sticker prices."
                      )}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex lg:flex-row flex-col lg:gap-[30px] gap-5 border-b border-secondary-200 lg:w-[781px] pb-[50px] pt-7">
                <div className="lg:max-w-[190px] max-w-[150px] w-full">
                  <p className="text-base font-medium capitalize xl:text-xl lg:text-lg text-info-500">
                    {t("Receipt Footer Message")}
                  </p>
                </div>
                <div className="max-w-[425px] w-full flex flex-col ">
                  <label className="block xl:text-base lg:text-base text-sm_18 text-secondary-500 lg:pb-3 pb-2.5 font-semibold">
                    {t("Extended Message")}
                  </label>
                  <textarea
                    id="extend_footer"
                    name="extend_footer"
                    rows="4"
                    placeholder="Enter Message"
                    value={formik?.values?.extend_footer}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="focus:outline-none resize-none bg-secondary-50 text-info-500 xl:text-lg lg:text-base text-sm_18 font-medium rounded-theme p-2.5 w-full placeholder:text-secondary-500 border border-secondary-200 outline-none focus:border-primary-400 focus:shadow transition-all duration-200"
                  ></textarea>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col pt-8 pb-2 lg:w-[781px] w-full justify-center">
                {/* <Button
                  type="reset"
                  className="text-base font-medium capitalize bg-white border border-primary-400 text-primary-400 min-w-52"
                  // onClick={() => navigate(-1)}
                >
                  {t("Cancel")}
                </Button> */}
                <Button
                  type="submit"
                  loading={isLoadingUpdate}
                  disabled={!formik.dirty || !formik.isValid || isLoadingUpdate}
                  className="flex items-center justify-center text-base font-medium capitalize border border-primary-400 bg-primary-400 min-w-52"
                >
                  {t("Save changes")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Layout>
  );
};

export default LocationSettings;
