import React from "react";
import ReactDOM from "react-dom";

const SidePanel = ({ open, handler, width = "w-96", children, customZ }) => {
  return ReactDOM.createPortal(
    <div
      className={`fixed inset-0 ${
        customZ ? customZ : "z-[9998]"
      } bg-gray-800 bg-opacity-50 transition-opacity duration-300  ${
        open ? "opacity-100" : "opacity-0 pointer-events-none"
      } sidepanel`}
      onClick={handler}
    >
      <div
        className={`fixed right-0 top-0 h-full bg-white shadow-lg transform transition-transform duration-300 ${
          open ? `translate-x-0 ${width}` : "translate-x-full"
        } sidepanel-content`}
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the drawer
      >
        {children}
      </div>
    </div>,
    document.body
  );
};

export default SidePanel;
