import { Button } from "@material-tailwind/react";
import { Form, Formik } from "formik";
import React, { Fragment, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import OTPInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Logo from "../../assets/logo/quetzal-logo.svg";
import OtpImg from "../../assets/otp-img.svg";
import { verifyPinLock } from "../../store/auth/auth.fetch";
const Otp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { loading } = useSelector((store) => ({
    loading: store?.auth?.loading,
  }));

  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={{ pin: "" }}
        validationSchema={Yup.object().shape({
          pin: Yup.string()
            .matches(/^\d{6}$/, t("Pin must be exactly 6 digits"))
            .required(t("Pin is required")),
        })}
        onSubmit={(values, { setSubmitting, setFieldValue }) => {
          dispatch(verifyPinLock({ body: values, navigate, setFieldValue }))
            .then((result) => {
              if (result?.response?.status === 400) {
                // Focus the first input if OTP is incorrect
                const otpInputs = document.querySelectorAll("input");
                // Focus the first input if OTP is incorrect
                if (otpInputs.length > 0) {
                  otpInputs[0].focus(); // Focus the first OTP input
                }
              }
            })
            .catch((err) => {
              console.log({ err });
            });
          setSubmitting(false);
        }}
      >
        {({ values, handleSubmit, setFieldValue, isSubmitting }) => (
          <Form
            onSubmit={handleSubmit}
            className="flex items-center justify-center w-full h-screen"
          >
            <div className="grid md:grid-cols-2 xl:max-w-[1195px] lg:max-w-[900px] max-w-[730px] w-full mx-auto lg:rounded-2xl rounded-theme bg-white shadow-[0px_24px_101.8px_-16px_rgba(0,0,0,0.15)]">
              <div className="lg:p-[30px] py-7 p-5">
                {/* <div className="flex items-center gap-2 mb-5">
                  <IconButton
                    size="sm"
                    className=""
                    variant="text"
                    onClick={() => navigate("/")}
                  >
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      className="text-primary-400"
                      fontSize={18}
                    />
                  </IconButton>

                  <span className="font-semibold text-secondary-400 lg:text-base text-sm_18">
                    Back
                  </span>
                </div> */}
                <h1 className="mb-3 text-lg font-semibold text-info-400 lg:text-2xl">
                  {t("Enter Your 6-Digit PIN")}
                </h1>
                <p className="text-secondary-400 xl:text-lg lg:text-base text-sm_18 font-medium max-w-[456px] w-full">
                  {t(
                    "Please enter the 6-digit PIN assigned to your organization's Quetzal POS."
                  )}
                </p>
                <div className="lg:max-w-[386px] lg:h-[266px] max-w-[240px] h-[200px] xl:mt-[60px]">
                  <img
                    src={OtpImg}
                    alt="otp-img"
                    className="object-cover w-full h-full"
                  />
                </div>
              </div>
              <div className="xl:ml-[30px] ml-2.5 lg:p-[30px] p-5 shadow-[-3px_0px_30.1px_0px_rgba(180,180,180,0.15)] rounded-tl-2xl rounded-bl-2xl">
                <div className="xl:max-w-[147px] lg:max-w-[120px] max-w-[100px] xl:h-[54px] lg:h-[44px] h-[48px] xl:mb-10 lg:mb-[30px] mb-3">
                  <img src={Logo} alt="logo" className="w-full h-full" />
                </div>
                <p className="mb-5 font-medium text-secondary-400 lg:text-base_24 text-sm_18 ">
                  {/* {t(
                    "A Pin Protects Your Data And Is Used To Unlock Your POS Quetzal"
                  )} */}
                  {t(
                    "Your PIN ensures the security of your data and grants access to your Quetzal POS."
                  )}
                </p>
                <div className="xl:mb-10 mb-7">
                  <OTPInput
                    value={values.pin}
                    onChange={(e, i) => {
                      setFieldValue("pin", e);
                    }}
                    inputType="password"
                    numInputs={6}
                    renderSeparator={<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                    shouldAutoFocus={false}
                    renderInput={(props, index) => (
                      <input
                        {...props}
                        autoFocus={index === 0}
                        className={
                          "!w-12 !h-14 text-center border border-secondary-200 font-semibold text-info-400 rounded-theme outline-none focus:border-primary-400 focus:shadow transition-all duration-200"
                        }
                      />
                    )}
                  />
                </div>
                <Button
                  type="submit"
                  size="lg"
                  loading={loading}
                  disabled={loading}
                  className="bg-primary-400 min-w-[150px] rounded-theme capitalize"
                >
                  {t("proceed")}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default Otp;
