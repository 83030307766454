import React, { Fragment } from "react";
import Input from "../common/Input";
import { Button, IconButton } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { setAttributes } from "../../store/inventory/variants/actions";
import { cloneDeep } from "lodash";
import { useTranslation } from "react-i18next";

const ManageAttribute = ({
  attributes,
  // setAttributes,
  handleSaveAttributes,
  allAttributes,
  setOpenAttribute,
  selectedAttribute,
  setSelectedAttribute,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  /* ------------ check inside attributes value is empty or filled ------------ */
  const isAttributesValid = () => {
    return attributes.every(
      (attribute) =>
        attribute.attribute_name !== "" &&
        attribute.options.every((option) => option.val !== "")
    );
  };

  const addInputField = (mainIndex) => {
    const newAttributes = cloneDeep(attributes);
    newAttributes[mainIndex].options.push({ val: "" });
    dispatch(setAttributes(newAttributes));
  };

  const handleInputChangeAttribute = (mainIndex, event) => {
    const values = cloneDeep(attributes);
    values[mainIndex].attribute_name = event.target.value;
    dispatch(setAttributes(values));
  };

  const handleInputChange = (mainIndex, subIndex, event) => {
    const values = cloneDeep(attributes);
    values[mainIndex].options[subIndex].val = event.target.value;
    dispatch(setAttributes(values));
  };

  const removeInputField = (mainIndex, subIndex) => {
    const values = cloneDeep(attributes);
    values[mainIndex].options.splice(subIndex, 1);
    dispatch(setAttributes(values));
  };
  return (
    <Fragment>
      <div
        className={`${
          selectedAttribute ? "border-y border-secondary-200 my-5" : ""
        } py-5 flex gap-x-8`}
      >
        {attributes?.map((item, mainIndex) => (
          <Fragment key={mainIndex}>
            <div className="flex-shrink-0 w-60">
              <div>
                <Input
                  type={"text"}
                  value={item.attribute_name}
                  placeholder={t("Ex. Colour")}
                  onChange={(e) => handleInputChangeAttribute(mainIndex, e)}
                  className={"h-12 bg-secondary-50"}
                />
              </div>
            </div>
            <div className="grow ">
              <div className="w-full max-w-lg">
                {item.options?.map((opt, subIndex) => (
                  <div
                    key={subIndex}
                    className="flex items-center w-full mb-5 gap-x-4"
                  >
                    <Input
                      type={"text"}
                      value={opt.val}
                      placeholder={t("Ex. Black")}
                      className={"h-12 bg-secondary-50 w-full grow"}
                      onChange={(e) =>
                        handleInputChange(mainIndex, subIndex, e)
                      }
                    />
                    {item?.options?.length > 1 && (
                      <IconButton
                        type="button"
                        variant="text"
                        className="flex-shrink-0"
                        onClick={() => removeInputField(mainIndex, subIndex)}
                      >
                        <FontAwesomeIcon icon={faClose} fontSize={20} />
                      </IconButton>
                    )}
                  </div>
                ))}
                <button
                  type="button"
                  className="flex items-center gap-x-2.5 capitalize text-primary-400 font-semibold text-base mt-4 hover:underline decoration-primary-400 transition-all ease-in-out duration-200"
                  onClick={() => addInputField(mainIndex)}
                >
                  <FontAwesomeIcon icon={faPlus} fontSize={18} />
                  <p>{t("Add Option Value")}</p>
                </button>
              </div>
            </div>
          </Fragment>
        ))}
      </div>
      <div className="flex items-center justify-end flex-grow-0 px-5 py-3 gap-x-5">
        {allAttributes?.length > 0 && (
          <Button
            type="button"
            className="text-base font-semibold capitalize bg-white border border-secondary-400 text-secondary-400 min-w-48"
            onClick={() => {
              setOpenAttribute(false);
              setSelectedAttribute(null);
              dispatch(
                setAttributes([{ attribute_name: "", options: [{ val: "" }] }])
              );
            }}
          >
            {t("Cancel")}
          </Button>
        )}
        <Button
          type="button"
          className="text-base font-semibold capitalize border border-primary-400 bg-primary-400 min-w-48"
          onClick={handleSaveAttributes}
          disabled={!isAttributesValid()}
        >
          {t("Save")}
        </Button>
      </div>
    </Fragment>
  );
};

export default ManageAttribute;
