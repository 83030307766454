import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import CalendarIcon from "../../assets/icon/calendar.svg";
import FilterDarkGray from "../../assets/icon/filter-dark-grey.svg";
import { Link } from "react-router-dom";
import ReportWrapper from "../../layout/ReportWrapper";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentSummaryRequest } from "../../store/report/actions";
import Loader from "../common/Loader";
import ReactSelect from "../../components/common/ReactSelect";

import {
  filterOptions,
  formatCurrencyWithNumberValue,
  paymentTypeReportOptions,
  paymentTypesReport,
} from "../../utils/utils";

import * as XLSX from "xlsx"; // Import XLSX for Excel export
import {
  Button,
  Chip,
  Popover,
  PopoverContent,
  PopoverHandler,
  Radio,
} from "@material-tailwind/react";
import { t } from "i18next";
import moment from "moment";
import { ExportIcon, ShortingIcon } from "../svgs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faArrowRightLong,
  faCircleXmark,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import Input from "../common/Input";
import { getCustomerListRequest } from "../../store/customer/actions";
import { AsyncPaginate } from "react-select-async-paginate";
import NoDataFound from "../common/NoDataFound";
import { useTranslation } from "react-i18next";

const PaymentReport = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  // get paymentSummary listing
  const {
    isLoading,
    paymentSummaryList,
    customersData,
    totalPages,
  } = useSelector((store) => ({
    isLoading: store?.report?.paymentSummary?.loading,
    paymentSummaryList: store?.report?.paymentSummary?.data,
    customersData: store?.customer?.customerList?.data,
    totalPages: store?.customer?.customerList?.totalPages,
  }));

  const filterOptions = [
    { label: `${t("This Year")}`, value: "this_year" },
    { label: `${t("Last Week")}`, value: "last_week" },
    { label: `${t("Last Month")}`, value: "last_month" },
    { label: `${t("Last 6 Months")}`, value: "last_6_months" },
    { label: `${t("Last Year")}`, value: "last_year" },
  ];

  const dispatch = useDispatch();

  // const [selectedPaymentType, setSelectedPaymentType] = useState(
  //   paymentTypeReportOptions[0]
  // );
  const [selectedCustomer, setSelectedCustomer] = useState(false);
  const [selectedDateType, setSelectedDateType] = useState("today");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [openPopover, setOpenPopover] = useState(false);

  // Fetch customers when component mounts or when search query changes
  const loadOptions = useCallback(
    async (searchQuery, loadedOptions, { page }) => {
      // Dispatch the action to fetch customer data
      await dispatch(
        getCustomerListRequest({
          page,
          limit: 15,
          search: searchQuery || "", // Use search query or empty string for initial load
        })
      );

      // Update customers state when data is available
      if (customersData && customersData.length > 0) {
        const hasMore = customersData.length === 15; // Assuming 15 is the limit per page
        return {
          options: customersData.map((customer) => ({
            label: `${customer.first_name} ${customer.last_name}`,
            value: `${customer._id}`,
          })),
          hasMore,
          additional: {
            page: page + 1,
          },
        };
      }

      // Return empty options if no data
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page + 1,
        },
      };
    },
    [customersData, dispatch] // Add dependencies
  );

  const handleCustomerChange = (selectedOption) => {
    setSelectedCustomer(selectedOption);
  };

  // called get customerList request
  useEffect(() => {
    dispatch(
      getCustomerListRequest({
        page: 1,
        limit: 15,
      })
    );
  }, [dispatch]);

  // pass filter data in API when press filter button then call
  const applyFilter = () => {
    console.log("apply");
    dispatch(
      getPaymentSummaryRequest({
        dateType: selectedDateType?.value || selectedDateType,
        start_date: selectedDateType === "custom" ? startDate : "",
        end_date: selectedDateType === "custom" ? endDate : "",
        customerID: selectedCustomer ? selectedCustomer?.value : "",
      })
    );
    setOpenPopover(false);
  };

  // Function to handle Export
  const handleExport = async () => {
    await dispatch(
      getPaymentSummaryRequest({
        // paymentType: selectedPaymentType.value,
        dateType: selectedDateType?.value || selectedDateType,
        start_date: selectedDateType === "custom" ? startDate : "",
        end_date: selectedDateType === "custom" ? endDate : "",
        customerID: selectedCustomer ? selectedCustomer?.value : "",
      })
    ).then((response) => {
      const data = response?.data?.data || {};

      // Destructure the necessary fields
      const { paymentSummary, returnPaymentSummary } = data;

      // Structure for the Excel export, with titles and tables
      const exportData = [
        // Order Summary section
        ["Payment Summary"], // Title
        ["Type", "Order Count", "Total", "Maximum", "Minimum"], // Headers
        ...paymentSummary.map((payment) => [
          payment.type || "",
          payment.orderCount || 0,
          payment.total || 0,
          payment.maximum || 0,
          payment.minimum || 0,
        ]), // Values

        [], // Empty row for spacing

        // Return Payment Summary section
        ["Return Payment Summary"], // Title
        ["Type", "Order Count", "Total", "Maximum", "Minimum"], // Headers
        ...returnPaymentSummary.map((returnPayment) => [
          returnPayment.type || "",
          returnPayment.orderCount || 0,
          returnPayment.total || 0,
          returnPayment.maximum || 0,
          returnPayment.minimum || 0,
        ]), // Values
      ];

      // Convert the array into an Excel worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(exportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "PaymentReport");

      // Export the Excel file
      XLSX.writeFile(workbook, "PaymentReport.xlsx");
    });
  };

  console.log(selectedCustomer, "selectedCustomer.............");

  //   payment summary api listing api request
  useEffect(() => {
    // Dispatch the action correctly with required parameters
    dispatch(
      getPaymentSummaryRequest({
        // paymentType: selectedPaymentType.value
        dateType: selectedDateType?.value || selectedDateType,
        start_date: selectedDateType === "custom" ? startDate : "",
        end_date: selectedDateType === "custom" ? endDate : "",
        customerID: selectedCustomer ? selectedCustomer?.value : "",
      })
    );
  }, [dispatch, selectedCustomer]);
  return (
    <ReportWrapper>
      <div className="grow overflow-y-auto xl:h-[calc(100vh-132px)] h-[calc(100vh-106px)]">
        <div className="flex items-center justify-between p-3 mb-4 bg-white rounded-theme xl:py-5 lg:px-5 lg:py-4">
          <div className="text-base font-semibold lg:text-lg 2xl:font-bold text-info-500 2xl:text-xl">
            {t("Payment Report")}
          </div>
          <div className="flex flex-wrap items-center justify-end gap-4 2xl:gap-8">
            {/* <div className="gap-2.5  xl:py-[14px] lg:py-2.5 py-1.5 px-[18px] ">
              <div>
                <ReactSelect
                  className="border-none min-w-[115px] w-full"
                  labelProps={"!text-xs font-semibold capitalize"}
                  options={paymentTypeReportOptions}
                  value={selectedPaymentType}
                  onChange={(data) => setSelectedPaymentType(data)}
                />
              </div>
            </div> */}
            <div className="flex items-center gap-4">
              <div>
                <Popover
                  placement="bottom-end"
                  open={openPopover}
                  handler={setOpenPopover}
                >
                  <PopoverHandler>
                    <Button className="flex items-center gap-3 text-sm font-semibold capitalize bg-white border border-gray-500 text-secondary-400">
                      <ShortingIcon />
                      {t("Filter")}
                      <FontAwesomeIcon
                        icon={openPopover ? faAngleUp : faAngleDown}
                      />
                    </Button>
                  </PopoverHandler>
                  <PopoverContent className="z-10  lg:w-[900px] w-[calc(100%-16px)] space-y-4">
                    <div className="flex items-center justify-between">
                      <p className="text-lg font-semibold capitalize text-info-500">
                        {t("Filter Options")}
                      </p>
                      <FontAwesomeIcon
                        onClick={() => setOpenPopover(false)}
                        icon={faClose}
                        className="w-5 h-5 text-info-500"
                      />
                    </div>
                    <div className="p-5 border border-secondary-200 rounded-theme">
                      <p className="text-lg font-medium capitalize text-secondary-400">
                        {t("Date Range")}
                      </p>
                      <div className="flex items-center gap-2 pt-4">
                        {[
                          "custom",
                          "today",
                          "yesterday",
                          "this_week",
                          "this_month",
                        ].map((type) => (
                          <p
                            key={type}
                            onClick={() => setSelectedDateType(type)}
                            className={`border ${
                              selectedDateType === type
                                ? "border-primary-400 bg-primary-1500 text-primary-400"
                                : "border-secondary-200 text-secondary-400"
                            } flex items-center justify-center xl:text-base text-sm_18 font-medium  rounded-3xl xl:px-[30px] lg:px-5 px-3.5 xl:py-3 py-2 cursor-pointer transition-all duration-300 text-nowrap capitalize`}
                          >
                            {t(type.split("_").join(" "))}
                          </p>
                        ))}
                        <ReactSelect
                          labelProps={"!text-xs font-semibold capitalize"}
                          placeholder={language === "fr" ? "plus" : "More"}
                          options={filterOptions}
                          value={selectedDateType}
                          onChange={(data) => setSelectedDateType(data)}
                        />
                      </div>
                      {selectedDateType === "custom" && (
                        <div className="flex items-center gap-5 pt-6">
                          <div>
                            <Input
                              label={t("Start Date")}
                              type={"date"}
                              value={startDate}
                              max={new Date().toISOString().split("T")[0]}
                              onChange={(e) => setStartDate(e.target.value)}
                              className={
                                "bg-secondary-50 xl:max-w-[248px] lg:max-w-[190px] max-w-[170px] placeholder:font-medium placeholder:text-secondary-500 text-base px-2"
                              }
                            />
                          </div>
                          <div className="">
                            <FontAwesomeIcon
                              icon={faArrowRightLong}
                              className="w-5 h-5 "
                            />
                          </div>
                          <div>
                            <Input
                              label={t("End Date")}
                              type={"date"}
                              value={endDate}
                              max={new Date().toISOString().split("T")[0]}
                              onChange={(e) => setEndDate(e.target.value)}
                              className={
                                "bg-secondary-50 xl:max-w-[248px] lg:max-w-[190px] max-w-[170px] placeholder:font-medium placeholder:text-secondary-500 text-base px-2"
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <Button
                      type="submit"
                      fullWidth
                      onClick={applyFilter}
                      className="bg-primary-400 capitalize font-medium text-base w-[143px] h-[46px]"
                    >
                      {t("Filter")}
                    </Button>
                  </PopoverContent>
                </Popover>
              </div>
            </div>

            {/* customer filter  */}
            <AsyncPaginate
              value={selectedCustomer}
              loadOptions={loadOptions}
              onChange={handleCustomerChange}
              additional={{
                page: 1,
              }}
              placeholder={t("Select Customer")}
              isClearable
              className=" min-w-[200px]  my-react-select-async-paginate"
              classNamePrefix="react-select"
            />

            {/* export report  */}
            <Button
              onClick={handleExport}
              className="flex items-center gap-3 text-sm font-semibold capitalize border bg-primary-150 text-primary-400 border-primary-400"
            >
              <ExportIcon />
              {/* <FontAwesomeIcon icon={faFolder} className="w-5 h-5" /> */}
              {t("Export")}
            </Button>
          </div>
        </div>

        <div className="gap-2.5">
          <div className="bg-white rounded-theme">
            <div className="h-full overflow-x-auto payment-table-container">
              <table className="w-full h-full table-auto 2xl:text-base_24 text-sm_18">
                <thead className="border-b border-gray-200">
                  <tr>
                    <th className="px-4 py-2 text-left capitalize whitespace-nowrap">
                      {t("Payment Type")}
                    </th>
                    <th className="px-4 py-2 text-left capitalize whitespace-nowrap">
                      {t("Order Count")}
                    </th>
                    <th className="px-4 py-2 text-left capitalize whitespace-nowrap">
                      {t("Total Amount")}
                    </th>
                    <th className="px-4 py-2 text-left capitalize whitespace-nowrap">
                      {t("Highest")}
                    </th>
                    <th className="px-4 py-2 text-left capitalize whitespace-nowrap">
                      {t("Lowest")}
                    </th>
                  </tr>
                </thead>

                {!isLoading &&
                  (paymentSummaryList?.paymentSummary?.length > 0 ||
                    paymentSummaryList?.returnPaymentSummary?.length > 0) && (
                    <>
                      <tbody className="divide-y divide-gray-200">
                        {/* Returned Payment Summary */}
                        {paymentSummaryList?.paymentSummary?.map((item) => {
                          return (
                            <tr>
                              <td className="px-4 py-2 capitalize whitespace-nowrap ">
                                {paymentTypesReport(t).find(
                                  (type) => type?.label === item.type
                                )?.value || item.type}
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap">
                                {item.orderCount}
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap">
                                {formatCurrencyWithNumberValue(
                                  item.total.toFixed(2)
                                )}
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap">
                                {formatCurrencyWithNumberValue(
                                  item.maximum.toFixed(2)
                                )}
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap">
                                {formatCurrencyWithNumberValue(
                                  item.minimum.toFixed(2)
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>

                      {paymentSummaryList?.returnPaymentSummary.length > 0 && (
                        <h2 className="py-3 font-bold ps-3">
                          {t("Returned Payment Summary")}
                        </h2>
                      )}

                      <tbody className="divide-y divide-gray-200">
                        <hr className="h-[0.5px]" />
                        {paymentSummaryList?.returnPaymentSummary?.map(
                          (item) => {
                            return (
                              <tr>
                                <td className="px-4 py-2 capitalize">
                                  {paymentTypesReport(t).find(
                                    (type) => type?.label === item.type
                                  )?.value || item.type}
                                </td>
                                <td className="px-4 py-2 whitespace-nowrap">
                                  {item.orderCount}
                                </td>
                                <td className="px-4 py-2 whitespace-nowrap">
                                  {formatCurrencyWithNumberValue(
                                    item.total.toFixed(2)
                                  )}
                                </td>
                                <td className="px-4 py-2 whitespace-nowrap">
                                  {formatCurrencyWithNumberValue(
                                    item.maximum.toFixed(2)
                                  )}
                                </td>
                                <td className="px-4 py-2 whitespace-nowrap">
                                  {formatCurrencyWithNumberValue(
                                    item.minimum.toFixed(2)
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </>
                  )}
              </table>
            </div>
          </div>
        </div>
        {isLoading && (
          <div className="flex items-center justify-center w-full min-h-96">
            <Loader />
          </div>
        )}
        {!isLoading && paymentSummaryList?.paymentSummary?.length === 0 && (
          <div className="flex items-center justify-center w-full min-h-96">
            <NoDataFound message={"No Data Found"} />
          </div>
        )}
      </div>
    </ReportWrapper>
  );
};

export default PaymentReport;
