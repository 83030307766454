import React from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";

const Layout = ({ children, search, handleChange }) => {
  return (
    <div className="bg-secondary-50 h-screen">
      <Header search={search} handleChange={handleChange} />
      <main className="pt-24 overflow-hidden">
        <Sidebar />
        <div className="xl:ml-[160px] lg:ml-[120px] ml-[110px] py-2.5 xl:pr-[26px] lg:pr-5 pr-3 relative">
          {children}
        </div>
      </main>
    </div>
  );
};

export default Layout;
