import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const setColorInLocalStorage = (color) => {
  return localStorage.setItem("primaryColor", color);
};

export const setLanguageInLocalStorage = (language) => {
  return localStorage.setItem("language", language);
};

/* ---------------------- get user org setting details ---------------------- */
export const getUserOrgLocationSetting = () =>
  typeof localStorage !== "undefined"
    ? JSON.parse(localStorage.getItem("user_org_setting"))
    : null;

export const getColorFromLocalStorage = () => {
  return localStorage.getItem("primaryColor")
    ? localStorage.getItem("primaryColor")
    : "#f47048"; // Default primary color
};

export const getLanguageFromLocalStorage = () => {
  return localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "en"; // Default primary color
};

/**
 * Converts a HEX color code to an RGBA string.
 * @param {string} hex - The HEX color code (e.g., "#ff5733" or "ff5733").
 * @param {number} alpha - The alpha (opacity) value (0 to 1).
 * @returns {string} The RGBA color string (e.g., "rgba(255, 87, 51, 0.5)").
 */
export function hexToRgba(hex, alpha = 0.3) {
  // Remove the hash if present
  hex = hex.replace(/^#/, "");

  // Parse the r, g, b values
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Return the RGBA string
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export const getUserDetails = () =>
  localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;

/* -------------------------------------------------------------------------- */
/*                       generate every new 6 digit pin                       */
/* -------------------------------------------------------------------------- */
export const generatePin = () => {
  const newPin = Math.floor(100000 + Math.random() * 900000);
  return newPin;
};

/* -------------------------------------------------------------------------- */
/*                             use debounce value                             */
/* -------------------------------------------------------------------------- */
export const useDebounce = (value, delay = 1000) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);

  return debouncedValue;
};

// options for dropdown in loyalty points add product //
export const loyaltyPointsOptions = (t) => {
  return ([
    { label: t("No loyalty points"), value: "no loyalty points" },
    { label: t("Regular points"), value: "regular points" },
    { label: t("Double points"), value: "double points" },
    { label: t("Triple points"), value: "triple points" },
  ])
}

// options for dropdown in all status in order //
// export const orderStatusOptions = [
//   { label: "All Status", value: null },
//   { label: "Completed", value: "completed" },
//   { label: "Hold", value: "hold" },
//   { label: "Returned", value: "return" },
// ];
export const orderStatusOptions = [
  { label: `${t("All")}`, value: null },
  { label: `${t("Completed")}`, value: "completed" },
  { label: `${t("Hold")}`, value: "hold" },
  { label: `${t("Returned")}`, value: "return" },
];
// options for dropdown in filter in order //

export const filterOptions = [
  { label: `${t("This Year")}`, value: "this_year" },
  { label: `${t("Last Week")}`, value: "last_week" },
  { label: `${t("Last Month")}`, value: "last_month" },
  { label: `${t("Last 6 Months")}`, value: "last_6_months" },
  { label: `${t("Last Year")}`, value: "last_year" },
];
// options for dropdown in report module payment summary  //
export const paymentTypeReportOptions = [
  { label: "All", value: "" },
  { label: "Cash", value: "cash" },
  { label: "Credit", value: "credit" },
  { label: "Store Credit", value: "storecredit" },
  // { label: "Triple points", value: "triple points" },
];

// options for dropdown in loyalty points add product //
export const paymentTypesReport = (t) => {
  return (
    [
      { label: "cash", value: t("cash") },
      { label: "credit", value: t("credit") },
      { label: "debit", value: t("debit") },
      { label: "giftcard", value: t("gift card") },
      { label: "storecredit", value: t("store credit") },
      { label: "loyalty", value: t("loyalty") },
    ]
  )
}
/* -------------------------- transform attributes -------------------------- */
export const transformAttributes = (attributesObj) => {
  return Object.keys(attributesObj).map((key) => ({
    attribute_name: key,
    options: attributesObj[key].map((value) => ({ val: value })),
  }));
};

/* -------- currency format with currency symbol before or after value ------- */
export const formatCurrency = (value, currencySymbol, isBefore) => {
  if (isBefore) {
    return `${currencySymbol}${value}`;
  } else {
    return `${value}${currencySymbol}`;
  }
};

/* ----------- detect format like ex. 1,000.00 , 1000.00, 1000,00 ----------- */
export function detectFormat(format) {
  const hasComma = format.includes(",");
  const hasDot = format.includes(".");
  const commaPos = format.indexOf(",");
  const dotPos = format.indexOf(".");

  return {
    useGrouping: hasComma && (commaPos < dotPos || dotPos === -1),
    decimalSeparator: hasDot ? "." : hasComma && !hasDot ? "," : ".",
  };
}

/* ------------------ every time generate new code function ----------------- */
export const generateCode = (length) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

/* -------- create first name and last name return only first charAt -------- */
export function getInitialsFirstCharAt(firstName, lastName) {
  const firstInitial = firstName.charAt(0).toUpperCase();
  const lastInitial = lastName.charAt(0).toUpperCase();
  return `${firstInitial}${lastInitial}`;
}

/* ------------------ set format currency with number value ----------------- */
export const formatCurrencyWithNumberValue = (value) => {
  const orgDetail = getUserOrgLocationSetting();
  const { useGrouping, decimalSeparator } = detectFormat(
    orgDetail?.number_format
  );
  const formattedNumber = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: useGrouping,
  }).format(value);

  const finalFormattedNumber =
    decimalSeparator === ","
      ? formattedNumber.replace(".", ",")
      : formattedNumber;

  return orgDetail?.is_symbol_before
    ? `${orgDetail?.currency?.symbol}${finalFormattedNumber}`
    : `${finalFormattedNumber}${orgDetail?.currency?.symbol}`;
};

/* --------------------------- Options for create cash-drawer--------------------------- */
export const createCashDrawerOptions = [
  { label: `${t("Cash In Amount")}`, value: "cash in amount" },
  {
    label: `${t("Amount Removed To Close Drawer")}`,
    value: "amount removed to close drawer",
  },
  { label: `${t("Amount Paid Out")}`, value: "amount paid out" },
  { label: `${t("Drawer Count")}`, value: "drawer count" },
  { label: `${t("Count To Open Drawer")}`, value: "count to open drawer" },
];
/* ------------------ set format currency with string value ----------------- */
export const formatCurrencyWithStringValue = (value) => {
  const orgDetail = getUserOrgLocationSetting();

  return orgDetail?.is_symbol_before
    ? `${orgDetail?.currency?.symbol}${value}`
    : `${value}${orgDetail?.currency?.symbol}`;
};
