import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { store } from "./store/store";
import register from "./swDev";
import { ThemeProvider } from "@material-tailwind/react";
import { theme } from "./utils/themeConfig";
import "./i18n"; // Import the i18n configuration
import "react-phone-input-2/lib/plain.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider value={theme}>
    <Provider store={store}>
      <BrowserRouter>
        <ToastContainer
          limit={3}
          position="bottom-center"
          // style={{
          //   // width: "400px",
          // }}
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          pauseOnFocusLoss
          pauseOnHover
          draggable
          transition={Bounce}
          theme="colored"
        />
        <App />
      </BrowserRouter>
    </Provider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
// Register service worker
// register();
