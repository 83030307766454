import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox } from "@material-tailwind/react";
import React, { useEffect } from "react";
import { formatCurrencyWithNumberValue, useDebounce } from "../../utils/utils";
import { faGift } from "@fortawesome/free-solid-svg-icons";
import CloseIcon from "../../assets/icon/close.svg";
import Input from "../common/Input";
import { discountType } from "../../utils/enums";
import { toast } from "react-toastify";

const SingleReturnOrder = ({
  items,
  index,
  selectedItems,
  totalOrderData,
  openEditOrder,
  orderData,
  handleCheckboxChange,
  formik,
  totalTaxPercentage,
}) => {
  const {
    values,
    setFieldValue,
    handleChange,
    errors,
    setFieldError,
    validateField,
  } = formik;
  const changeQty = useDebounce(values.returnOrderItems[index]?.return_qty);

  const already_return_qty = totalOrderData.orderItems[index]?.return_qty;

  // Find the current item
  const currentItem = values.returnOrderItems.find(
    (item) => item.sku === items.sku
  );

  const generateSubTotal = (price, qty) => price * qty;

  const calculateDiscountedPrice = (item, index, totalOrderData) => {
    let totalDiscount = 0;

    // When discount is percentage-based
    if (item?.discount_percentage) {
      totalDiscount =
        (item.return_qty * item.selling_price * item.discount_percentage) / 100;
    } else {
      // When discount is a fixed amount
      const orderItem = totalOrderData?.orderItems?.find((i) => {
        return String(i._id) === String(item?.orderItemId);
      });
      const returnOrderItem = totalOrderData?.returnOrderItems?.find((i) => {
        return String(i.orderItem_id) === String(item?.orderItemId);
      });
      if (orderItem) {
        // Calculate the discount proportionate to the return_qty
        if (
          // orderItem?.discount === 0 &&
          values?.returnOrderItems[index]?.qty === changeQty
        ) {
          console.log("discount at 1 ..................");
          totalDiscount =
            (returnOrderItem?.discount || 0) + orderItem?.discount;
          // } else if (changeQty !== values.returnOrderItems[index]?.qty) {
          //   totalDiscount = returnOrderItem?.discount;
          // } else if (changeQty === values.returnOrderItems[index]?.qty) {
          //   totalDiscount = returnOrderItem?.discount + orderItem.discount;
        } else {
          // console.log("discount at 2 ..................");
          // console.log(orderItem?.discount, "orderItem..................");
          // console.log(
          //   returnOrderItem?.discount,
          //   "returnOrderItem .................."
          // );
          // totalDiscount =
          //   (orderItem?.discount /
          //     (orderItem?.qty - (orderItem?.return_qty || 0))) *
          //   item?.return_qty;
          totalDiscount =
            ((orderItem?.discount + (returnOrderItem?.discount || 0))?.toFixed(
              2
            ) /
              orderItem?.qty) *
            item?.return_qty;

          // Round the result to two decimal places to avoid floating-point precision issues
          // totalDiscount = Math.round(totalDiscount * 100) / 100;

          console.log(totalDiscount, "totalDiscount........................");
        }
      }
      // const orderItem = totalOrderData?.orderItems?.find((i) => {
      //   return String(i._id) === String(item?.orderItemId);
      // });
      // if (orderItem) {
      //   // Calculate the discount proportionate to the return_qty
      //   totalDiscount =
      //     (orderItem?.discount /
      //       (orderItem?.qty - (orderItem?.return_qty || 0))) *
      //     item?.return_qty;
      // }
    }

    return totalDiscount;
  };

  const handleQtyChange = (e) => {
    const newQty = e.target.value;
    const currentItemSKU = items.sku;

    // Clear previous errors
    setFieldError(`returnOrderItems[${index}].return_qty`, "");

    // Check if the newQty is not a number or is not an integer
    if (!Number.isInteger(Number(newQty))) {
      // Set an error message if the quantity is not an integer
      setFieldError(
        `returnOrderItems[${index}].return_qty`,
        "Return quantity must be a whole number."
      );
      return;
    }

    // Check if newQty exceeds available quantity
    if (newQty > items.qty) {
      // Set an error message if the quantity exceeds the available quantity
      setFieldError(
        `returnOrderItems[${index}].return_qty`,
        "Return quantity not more than total quantity."
      );
    } else {
      // Clear the error if the value is valid
      setFieldError(`returnOrderItems[${index}].return_qty`, "");

      // Update quantity using SKU
      const updatedItems = values.returnOrderItems.map((item) =>
        item.sku === currentItemSKU
          ? { ...item, return_qty: Number(newQty) }
          : item
      );

      // Update form state
      setFieldValue("returnOrderItems", updatedItems);
    }
  };

  // First useEffect: Calculates sub_total and discount
  useEffect(() => {
    if (changeQty !== undefined && values.returnOrderItems?.length > 0) {
      const item = values.returnOrderItems[index];
      if (item) {
        const calculatedPerItemDiscountedPrice = generateSubTotal(
          item.selling_price,
          changeQty
        );

        const calculatedDiscountedPrice = calculateDiscountedPrice(
          item,
          index,
          totalOrderData
        );
        const taxAmount =
          ((item.sub_total - item.discount) * totalTaxPercentage) / 100;

        const calculateGrandTotal =
          Number(calculatedPerItemDiscountedPrice?.toFixed(2)) -
          Number(calculatedDiscountedPrice?.toFixed(2)) +
          Number(taxAmount?.toFixed(2));

        setFieldValue(
          `returnOrderItems[${index}].grand_total`,
          Number(calculateGrandTotal?.toFixed(2))
        );

        if (item.taxes !== taxAmount) {
          setFieldValue(
            `returnOrderItems[${index}].taxes`,
            Number(taxAmount.toFixed(2))
          );
        }

        if (item.sub_total !== calculatedPerItemDiscountedPrice) {
          setFieldValue(
            `returnOrderItems[${index}].discount`,
            Number(calculatedDiscountedPrice?.toFixed(2))
          );
          setFieldValue(
            `returnOrderItems[${index}].sub_total`,
            Number(calculatedPerItemDiscountedPrice.toFixed(2))
          );
        }
      }
    }
  }, [changeQty, values.returnOrderItems]);

  // Second useEffect: Calculates taxes and grand_total
  useEffect(() => {
    if (values.returnOrderItems?.length > 0) {
      const item = values.returnOrderItems[index];
      if (item && item.sub_total !== undefined && item.discount !== undefined) {
        // const taxAmount =
        //   ((item.sub_total - item.discount) * totalTaxPercentage) / 100;

        // if (item.taxes !== taxAmount) {
        //   setFieldValue(
        //     `returnOrderItems[${index}].taxes`,
        //     Number(taxAmount.toFixed(2))
        //   );
        // }

        const allSubTotal = (values.returnOrderItems || [])?.reduce(
          (acc, t) => acc + t.sub_total,
          0
        );
        const totalDiscount = (values.returnOrderItems || []).reduce(
          (acc, t) => {
            return acc + t.discount; // Return the accumulated value
          },
          0 // Initial value for the accumulator
        );

        // const totalDiscount = (values.returnOrderItems || [])?.reduce(
        //   (acc, t) => {
        //     console.log(t, "ttttttttttttttttttttttttttt");
        //     if (t?.discount) {
        //       if (t?.discount_percentage) {
        //         const totalDiscount =
        //           (t?.return_qty * t?.selling_price * t?.discount_percentage) /
        //           100;
        //         acc += totalDiscount;
        //         // setFieldValue(
        //         //   `returnOrderItems[${index}].discount`,
        //         //   totalDiscount
        //         // );
        //       } else {
        //         const orderItem = totalOrderData?.orderItems?.find(
        //           (i) => String(i._id) === String(t?.orderItem_id)
        //         );
        //         if (orderItem) {
        //           const totalDiscount =
        //             (orderItem?.discount / orderItem?.qty) * t?.return_qty;
        //           acc += totalDiscount;
        //           // setFieldValue(
        //           //   `returnOrderItems[${index}].discount`,
        //           //   totalDiscount
        //           // );
        //         }
        //       }
        //     }

        //     return acc;
        //   },
        //   0
        // );

        if (
          values.returnOrder.sub_total !== allSubTotal ||
          values.returnOrder.total_discount !== totalDiscount
        ) {
          setFieldValue(
            `returnOrder.sub_total`,
            Number(allSubTotal.toFixed(2))
          );
          setFieldValue(
            `returnOrder.total_discount`,
            Number(totalDiscount.toFixed(2))
          );
        }
      }
    } else {
      setFieldValue("returnOrder.sub_total", 0);
      setFieldValue("returnOrder.total_discount", 0);
    }
  }, [values.returnOrderItems]);

  useEffect(() => {
    if (values.returnOrderItems?.length > 0) {
      const totalTaxes = (values.returnOrderItems || [])?.reduce((acc, t) => {
        const taxAmount =
          ((t?.sub_total - t?.discount) * totalTaxPercentage) / 100;

        acc += Number(taxAmount.toFixed(2));

        return acc;
      }, 0);

      const totalQty = (values.returnOrderItems || [])?.reduce(
        (acc, t) => acc + t.return_qty,
        0
      );

      if (
        values.returnOrder.total_taxes !== totalTaxes ||
        values.returnOrder.total_qty !== totalQty
      ) {
        setFieldValue(`returnOrder.total_taxes`, Number(totalTaxes.toFixed(2)));
        setFieldValue(`returnOrder.total_qty`, totalQty);
      }
    } else {
      setFieldValue("returnOrder.total_taxes", 0);
      setFieldValue("returnOrder.total_qty", 0);
    }
  }, [values.returnOrderItems]);

  useEffect(() => {
    if (values.returnOrderItems?.length > 0) {
      // Calculate total price, discount, and taxes based on all items, including items with qty of 0
      const calculatedTotalPrice =
        values?.returnOrder?.sub_total -
        values?.returnOrder?.total_discount +
        values?.returnOrder?.total_taxes;

      // Check if at least one item has a qty greater than 0
      const hasQtyGreaterThanZero = values.returnOrderItems.some(
        (item) => item.return_qty > 0
      );

      setFieldValue(
        "returnOrder.grand_total",
        Number(calculatedTotalPrice.toFixed(2))
      );
      const totalLoyaltyPoints =
        Number(totalOrderData.returnOrder?.loyalty_amount_return || 0) +
        Number(orderData?.redeemed_dollar || 0);

      if (hasQtyGreaterThanZero) {
        if (calculatedTotalPrice <= totalLoyaltyPoints) {
          setFieldValue(
            "returnOrder.redeemed_dollar",
            Number(calculatedTotalPrice.toFixed(2))
          );
          setFieldValue("returnOrder.payable_amount", 0);
        } else {
          setFieldValue(
            "returnOrder.redeemed_dollar",
            Number(totalLoyaltyPoints)
          );
          setFieldValue(
            "returnOrder.payable_amount",
            Number((calculatedTotalPrice - totalLoyaltyPoints).toFixed(2))
          );
        }
      } else {
        // If no items have qty > 0, reset grand_total and return_loyalty_point
        setFieldValue("returnOrder.return_loyalty_point", 0);
        setFieldValue("returnOrder.payable_amount", 0);
      }
    } else {
      // If no items have qty > 0, reset grand_total and return_loyalty_point
      setFieldValue("returnOrder.return_loyalty_point", 0);
      setFieldValue("returnOrder.payable_amount", 0);
    }
  }, [
    values.returnOrderItems,
    values?.returnOrder?.sub_total,
    values?.returnOrder?.total_discount,
    values?.returnOrder?.total_taxes,
  ]);

  useEffect(() => {
    console.log(values.returnOrderItems, "return order Item");
    console.log(values.returnOrder, "return order");
  }, [handleCheckboxChange]);

  console.log(items, "items.........");

  return (
    <tr
      className={`${
        totalOrderData.orderItems?.length - 1 !== index ? "border-b-2" : ""
      }`}
    >
      <td className="py-3 pr-1 align-top 2xl:align-middle 2xl:py-5 2xl:pr-2">
        {openEditOrder && items.type === "inventory" && items.qty > 0 && (
          <Checkbox
            className="2xl:w-6 2xl:h-6 w-4 h-4 border border-[#0F1A2A]"
            onChange={(e) => handleCheckboxChange(e, index, items)}
            checked={selectedItems.includes(index)}
          />
        )}
      </td>
      <td className="flex gap-2 py-3 pr-3 align-top 2xl:py-5 2xl:pr-4 xl:gap-4">
        <div className="xl:h-16 xl:w-16 w-10 h-10 min-w-[40px] flex justify-center items-center overflow-hidden 2xl:rounded-theme rounded-md">
          {items?.inventory_id?.image ? (
            <img
              src={items?.inventory_id?.image}
              alt="product img"
              className="object-cover w-full h-full "
            />
          ) : (
            <FontAwesomeIcon
              icon={faGift}
              fontSize={30}
              className="text-primary-400"
            />
          )}
        </div>
        <div>
          <p className="pb-2 text-xs font-medium capitalize text-info-500 2xl:text-base">
            {items?.inventory_id?.product_name ||
              items?.gift_card_id?.gift_code}
          </p>
          {items?.inventory_id?.variants_possibilities?.length > 0 && (
            <p className="pb-2 text-xs font-medium capitalize text-secondary-400 2xl:text-base">
              {items?.inventory_id?.variants_possibilities?.find(
                (val) => val?.sku === items?.sku
              )?.name || "Variant not available"}
            </p>
          )}
        </div>
      </td>
      <td className="py-3 pr-3 text-xs font-medium text-center align-top 2xl:py-5 2xl:pr-4 text-secondary-400 2xl:text-base">
        {items?.sku || "-"}
      </td>
      {/* <td className="py-3 pr-3 text-xs font-medium text-center 2xl:py-5 2xl:pr-4 text-secondary-400 xl:text-base lg:text-sm_18">
                          {items?.discount_percentage || "0" + " " + "%"}
                        </td> */}
      <td className="py-3 pr-3 font-medium text-center align-top 2xl:py-5 2xl:pr-4 text-info-500 xl:text-lg lg:text-base text-sm_18">
        <div className="2xl:col-span-1 col-span-1 flex xl:gap-2.5 gap-1.5 items-center justify-center">
          <p className="text-xs font-medium text-secondary-400 2xl:text-base ">
            {formatCurrencyWithNumberValue(
              items?.selling_price || items?.sub_total || "0"
            )}
          </p>
          <img
            src={CloseIcon}
            alt="close"
            className="2xl:h-[14px] 2xl:w-[14px] w-2 h-2"
          />
          <p className="text-xs font-medium text-secondary-400 2xl:text-base ">
            {items?.qty}
          </p>
        </div>
      </td>

      <td className="py-3 pr-3 font-medium text-center align-top 2xl:py-5 2xl:pr-4 text-info-500 2xl:text-lg text-sm_18">
        {selectedItems.includes(index) ? (
          <>
            <Input
              type="number"
              name={`returnOrderItems[${index}].qty`}
              labelProps={"!text-sm !font-normal"}
              className={" max-w-[82px] mx-auto"} // Set the width and ensure it's centered
              value={currentItem?.return_qty || ""}
              onChange={handleQtyChange}
              error={errors.returnOrderItems?.[index]?.return_qty}
            />
            {/* Display error message */}
            {errors.returnOrderItems &&
              errors.returnOrderItems[index]?.return_qty && (
                <div className="mt-1 text-xs text-red-500 w-[120px] mx-auto">
                  {errors.returnOrderItems[index]?.return_qty}
                </div>
              )}
          </>
        ) : (
          <p>{already_return_qty || 0}</p>
        )}
      </td>
      <td className="py-3 pr-3 font-medium text-center align-top 2xl:py-5 2xl:pr-4 text-info-500 2xl:text-lg text-sm_18">
        {openEditOrder
          ? formatCurrencyWithNumberValue(
              items?.selling_price * currentItem?.return_qty || 0
            )
          : items.type === "inventory"
          ? formatCurrencyWithNumberValue(items?.selling_price * items?.qty)
          : formatCurrencyWithNumberValue(items.sub_total || 0)}
      </td>
    </tr>
  );
};

export default SingleReturnOrder;
