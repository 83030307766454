import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "../../utils/utils";
import {
  Button,
  Checkbox,
  IconButton,
  Spinner,
} from "@material-tailwind/react";
import Modal from "../common/Modal";
import NoDataFound from "../common/NoDataFound";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faClose,
  faPencil,
  faSearch,
  faTrash,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import Input from "../common/Input";
import {
  createCategoryRequest,
  deleteCategoryRequest,
  getCategoriesListRequest,
  updateCategoryRequest,
} from "../../store/inventory/categories/actions";
import { categoriesState } from "../../store/inventory/categories/state";
import Loader from "../common/Loader";
import { useTranslation } from "react-i18next";
import { ManageDeleteProductIcon, ManageRenameProductIcon } from "../svgs";

const ManageCategories = ({ openCategoriesDrawer, handler, formik }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const {
    isLoading,
    categoriesData,
    totalPages,
    isCategoryDeleteLoading,
    isCreateCategoryLoading,
    isCategoryUpdateLoading,
    updateCategoryData,
    deleteCategoryData,
  } = useSelector((store) => ({
    isLoading: store?.categories?.categoryList?.loading,
    categoriesData: store?.categories?.categoryList?.data,
    totalPages: store?.categories?.categoryList?.totalPages,
    isCategoryDeleteLoading: store?.categories?.deleteCategory?.loading,
    isCreateCategoryLoading: store?.categories?.createCategory?.loading,
    isCategoryUpdateLoading: store?.categories?.updateCategory?.loading,
    updateCategoryData: store?.categories?.updateCategory?.data,
    deleteCategoryData: store?.categories?.deleteCategory?.data,
  }));
  const [categoriesList, setCategoriesList] = useState([]);
  const [openEditInput, setOpenEditInput] = useState(false);
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const searchVal = useDebounce(search);
  const [editValue, setEditValue] = useState("");
  const [selectedCategoryData, setSelectedCategoryData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [alreadyHaveCategory, setAlreadyHaveCategory] = useState([]);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  console.log(categoriesList, "categoriesList............");

  // handle search change in brands //
  const handleChangeSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    setIsSearch(true);
    if (page > 1) setPage(1);
    setCategoriesList([]);
  };

  // handle change edit categories //
  const handleChangeEditCategory = (e) => {
    const value = e.target.value;
    setEditValue(value);
  };

  // for create category
  const handleCreateCategory = () => {
    setCategoriesList([]);
    dispatch(createCategoryRequest({ name: search }));
  };

  // for update category
  const handleUpdateCategory = () => {
    dispatch(
      updateCategoryRequest({
        name: editValue,
        categoryId: selectedCategoryData._id,
      })
    );
  };

  const handleDeleteCategory = () => {
    dispatch(
      deleteCategoryRequest({
        categoryId: selectedCategoryData._id,
      })
    );
  };

  const handleScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    let scrollOffset = scrollHeight - scrollTop - clientHeight;
    if (scrollOffset === 0 && totalPages > page && openCategoriesDrawer) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    if (formik?.values?.categories) {
      setSelectedCategory(formik?.values?.categories);
    }
  }, [formik?.values?.categories]);

  useEffect(() => {
    if (openCategoriesDrawer) {
      setCategoriesList([]); // Clear the previous categories

      !isLoading && dispatch(getCategoriesListRequest({ search, page, limit }));
      setIsSearch(false);
    }
  }, [openCategoriesDrawer, dispatch, searchVal]);

  useEffect(() => {
    if (openCategoriesDrawer && page > 1) {
      !isLoading && dispatch(getCategoriesListRequest({ search, page, limit }));
      setIsSearch(false);
    }
  }, [openCategoriesDrawer, dispatch, page]);

  useEffect(() => {
    if (categoriesData?.length > 0) {
      setCategoriesList([...categoriesList, ...categoriesData]);
    }
  }, [categoriesData]);

  useEffect(() => {
    if (updateCategoryData !== null) {
      const oldArr = [...categoriesList];
      const findIndex = oldArr?.findIndex(
        (item) => item?._id === updateCategoryData?._id
      );
      if (findIndex !== -1) {
        oldArr[findIndex] = updateCategoryData;
        setCategoriesList(oldArr);
        setEditValue("");
        setSelectedCategoryData(null);
        setOpenEditInput(false);
        dispatch(categoriesState({ key: "updateCategory.data", value: null }));
      }
    }
  }, [updateCategoryData, dispatch]);

  useEffect(() => {
    if (deleteCategoryData !== null) {
      const oldArr = [...categoriesList];
      const findIndex = oldArr?.findIndex(
        (item) => item?._id === selectedCategoryData?._id
      );
      if (findIndex !== -1) {
        setOpenDeleteModal(false);
        oldArr.splice(findIndex, 1);
        setCategoriesList(oldArr);
        setSelectedCategoryData(null);
        dispatch(categoriesState({ key: "deleteCategory.data", value: null }));
      }
    }
  }, [deleteCategoryData, dispatch]);

  useLayoutEffect(() => {
    if (!openCategoriesDrawer) {
      setPage(1);
      setCategoriesList([]);
      setSearch("");
      setEditValue("");
      setSelectedCategoryData(null);
      setOpenEditInput(false);
    }
  }, [openCategoriesDrawer]);

  console.log(categoriesList, "categoriesList.......................");

  // check already have category or not
  useEffect(() => {
    if (searchVal.trim()) {
      const matchedCategory = categoriesList.find((value) => {
        return value.name === searchVal.trim().toLowerCase();
      });
      setAlreadyHaveCategory(matchedCategory || null);
    } else {
      setAlreadyHaveCategory(null); // Reset if searchVal is empty
    }
  }, [searchVal, categoriesList]);

  useEffect(() => {
    // On component unmount, reset the customer data
    return () => {
      setCategoriesList([]); // Reset local state
      dispatch(categoriesState({ key: "categoryList.data", value: [] })); // Reset Redux state
    };
  }, [dispatch]);

  return (
    <Fragment>
      <div className="flex flex-col flex-grow h-full overflow-y-auto">
        <div className="flex items-center justify-between gap-5 px-5 py-3">
          <p className="text-lg font-semibold text-info-500">
            {t("Manage Your Categories")}
          </p>
          <IconButton size="sm" variant="text" onClick={handler}>
            <FontAwesomeIcon icon={faClose} fontSize={18} />
          </IconButton>
        </div>
        <div className="flex-grow px-5 py-3 overflow-y-auto">
          <div className="flex flex-col h-full gap-y-3">
            {openEditInput ? (
              <Fragment>
                <Input
                  type={"search"}
                  placeholder={t("Edit Category")}
                  iconStart={
                    <FontAwesomeIcon
                      icon={faPencil}
                      fontSize={16}
                      className="text-secondary-500"
                    />
                  }
                  className={
                    "bg-secondary-100 text-info-400 placeholder:text-secondary-500 font-medium placeholder:font-medium"
                  }
                  value={editValue}
                  onChange={handleChangeEditCategory}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      setSelectedCategoryData(null);
                      setOpenEditInput(false);
                      setEditValue("");
                    }
                  }}
                />
                {selectedCategoryData?.name !== editValue && (
                  <div
                    className={`flex justify-between items-center border border-primary-400 text-primary-400 rounded-theme p-2`}
                  >
                    <span className="text-base font-medium capitalize">
                      {t("Rename")} {selectedCategoryData?.name} {t("To")}{" "}
                      {editValue} !
                    </span>
                    <Button
                      type="button"
                      className="font-semibold capitalize rounded-md bg-primary-400"
                      size="sm"
                      onClick={handleUpdateCategory}
                      loading={isCategoryUpdateLoading}
                      disabled={!editValue || isCategoryUpdateLoading}
                    >
                      {t("Save")}
                    </Button>
                  </div>
                )}
              </Fragment>
            ) : (
              <div>
                <Input
                  type={"search"}
                  placeholder={t("Search Or Create Category")}
                  iconStart={
                    <FontAwesomeIcon
                      icon={faSearch}
                      fontSize={16}
                      className="text-secondary-500"
                    />
                  }
                  className={
                    "bg-secondary-100 text-info-400 placeholder:text-secondary-500 font-medium placeholder:font-medium placeholder:text-sm"
                  }
                  value={search}
                  onChange={handleChangeSearch}
                />
              </div>
            )}
            {!isLoading && !alreadyHaveCategory && search !== "" && !isSearch && (
              <div
                className={`flex justify-between items-center bg-primary-150 border border-primary-400 text-primary-400 rounded-theme p-2`}
              >
                <span className="text-base font-medium capitalize">
                  {search} {t("create new Category")}
                </span>
                <IconButton
                  type="button"
                  className="rounded-md text-primary-400"
                  size="sm"
                  variant="text"
                  disabled={isCreateCategoryLoading}
                  onClick={handleCreateCategory}
                >
                  <FontAwesomeIcon icon={faCirclePlus} fontSize={18} />
                </IconButton>
              </div>
            )}
            <div
              className="flex-grow overflow-hidden overflow-y-auto border rounded-lg border-secondary-200"
              onScroll={handleScroll}
            >
              <div className="sticky top-0 z-10 grid grid-cols-5 gap-6 p-3 border-b bg-secondary-50 border-secondary-200">
                <p className="col-span-3 text-base font-medium capitalize text-info-500">
                  {t("Categories")}
                </p>
                {/* <p className="text-base font-medium text-center text-info-500">
                  {t("Rename")}
                </p>
                <p className="text-base font-medium text-center text-info-500">
                  {t("Delete")}
                </p> */}
              </div>
              <div className="">
                <div className="">
                  {categoriesList?.length > 0 &&
                    categoriesList.map((item, index) => (
                      <Fragment key={index}>
                        <div className="px-1">
                          <div className="grid grid-cols-5 border-b gap-x-6 border-secondary-200">
                            <div className="flex items-center col-span-3 gap-2 p-1">
                              <Checkbox
                                checked={
                                  formik.values.categories_tag.includes(
                                    item._id
                                  )
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  const arr = [...formik.values.categories_tag];
                                  const tempArr = [...selectedCategory];

                                  if (e.target.checked) {
                                    arr.push(item._id);
                                    tempArr.push(item);
                                  } else {
                                    const idx = arr.indexOf(item._id);
                                    const findObject = tempArr.findIndex(
                                      (obj) => obj._id === item._id
                                    );

                                    if (idx > -1) {
                                      arr.splice(idx, 1);
                                    }
                                    if (findObject > -1) {
                                      tempArr.splice(findObject, 1);
                                    }
                                  }
                                  formik.setFieldValue("categories_tag", arr);
                                  // formik.setFieldValue("categories", temArray);
                                  setSelectedCategory(tempArr);
                                }}
                                label={item.name}
                                labelProps={{
                                  className:
                                    "text-info-400 font-medium capitalize",
                                }}
                              />
                            </div>
                            <div className="flex items-center justify-center">
                              <IconButton
                                type="button"
                                size="sm"
                                variant="text"
                                onClick={() => {
                                  setSelectedCategoryData(item);
                                  setOpenEditInput(true);
                                  setEditValue(item.name);
                                }}
                              >
                                {/* <FontAwesomeIcon
                                  icon={faPencil}
                                  fontSize={16}
                                  className="text-primary-400"
                                /> */}
                                <ManageRenameProductIcon className="w-4 h-4" />
                              </IconButton>
                            </div>
                            <div className="flex items-center justify-center">
                              <IconButton
                                type="button"
                                size="sm"
                                variant="text"
                                onClick={() => {
                                  setOpenDeleteModal(true);
                                  setSelectedCategoryData(item);
                                }}
                              >
                                {/* <FontAwesomeIcon
                                  icon={faTrashAlt}
                                  fontSize={16}
                                  className="text-red-500"
                                /> */}
                                <ManageDeleteProductIcon className="w-4 h-4" />
                              </IconButton>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    ))}
                  {(isSearch || isLoading) && (
                    <div className="flex items-center justify-center pt-6 col-span-full">
                      <Loader />
                    </div>
                  )}
                  {categoriesData?.length === 0 && !isSearch && !isLoading && (
                    <div className="col-span-full">
                      <NoDataFound message={"No Categories Available"} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center gap-x-5 p-6 flex-grow-0 bg-white shadow-[0px_4px_16.3px_0px_#64748B4D]">
          <Button
            type="button"
            className="text-base font-semibold capitalize bg-white border border-secondary-400 text-secondary-400"
            fullWidth
            onClick={() => {
              handler();
              // setSelectedCategory([]);
              // formik.setFieldValue("categories_tag", []);
            }}
          >
            {t("Cancel")}
          </Button>
          <Button
            type="button"
            className="text-base font-semibold capitalize border border-primary-400 bg-primary-400"
            fullWidth
            onClick={() => {
              console.log(
                selectedCategory,
                "selectedCategory...................."
              );
              formik.setFieldValue("categories", [...selectedCategory]);
              // setSelectedCategory([]);
              handler();
            }}
          >
            {t("Done")}
          </Button>
        </div>
      </div>
      <Modal
        open={openDeleteModal}
        handler={() => {
          setOpenDeleteModal(false);
          setSelectedCategoryData(null);
        }}
      >
        <div className="flex flex-col ">
          <p className="pb-3 text-lg font-semibold text-info-400">
            {t("Delete")}
          </p>
          <p className="text-base font-medium text-secondary-400 pb-7">
            {t("Are you sure to delete this Category ?")}
          </p>
          <div className="flex items-center justify-end gap-x-4">
            <Button
              type="button"
              size="sm"
              className="text-sm font-semibold capitalize border border-primary-400 bg-primary-400"
              onClick={() => {
                setOpenDeleteModal(false);
                setSelectedCategoryData(null);
              }}
            >
              {t("No, Cancel")}
            </Button>
            <Button
              type="button"
              size="sm"
              className="text-sm font-semibold text-red-500 capitalize bg-white border border-red-500"
              loading={isCategoryDeleteLoading}
              onClick={handleDeleteCategory}
            >
              {t("Yes, delete category")}
            </Button>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default ManageCategories;
