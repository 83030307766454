import React, { useEffect, useRef } from "react";
import { Button } from "@material-tailwind/react";
import { Cropper } from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrop } from "@fortawesome/free-solid-svg-icons";

const ImageCropperModal = ({
  setOpenCropperImage,
  formik,
  originalFile,
  uploadedImage,
  setUploadedImage,
}) => {
  const cropperRef = useRef(null);

  const onCropperChange = async () => {
    if (cropperRef.current) {
      const croppedCanvas = cropperRef.current.getCanvas(); // Get cropped canvas
      if (croppedCanvas) {
        // Convert the canvas to a Blob
        croppedCanvas.toBlob((blob) => {
          if (blob) {
            // Create a new File object from the Blob
            const croppedImageFile = new File([blob], originalFile.name, {
              type: originalFile.type,
              lastModified: originalFile.lastModified,
            });

            // Set the cropped image file in Formik
            formik.setFieldValue("image", croppedImageFile);
            setUploadedImage(URL.createObjectURL(croppedImageFile));
            console.log(croppedImageFile, "Cropped Image File"); // Log the cropped image file
          }
        }, originalFile.type); // Use the original file type
      }
    }
  };

  return (
    <div className="p-1">
      <h3 className="text-lg font-bold">Crop Image</h3>
      <div className="relative w-full mt-2 h-96">
        <Cropper
          ref={cropperRef}
          className="z-10 cropper"
          src={uploadedImage}
          style={{ width: "100%", height: "100%" }} // Fill the parent div
          stencilProps={{
            aspectRatio: 303.5 / 144, // Example: 1 for square aspect ratio
            movable: true,
            scalable: true,
            resizable: true,
            rotatable: true,
          }}
          backgroundClassName="example__cropper-background"
          zoomable={true} // Ensure zooming is enabled
        />
      </div>
      <div className="flex items-center justify-end gap-4 pt-2">
        <Button
          type="reset"
          size="sm"
          onClick={() => {
            setOpenCropperImage(false); // Close modal after saving
            setUploadedImage(null);
          }}
          className="text-sm font-medium capitalize bg-white border border-secondary-400 text-info-400"
        >
          Cancel
        </Button>
        <Button
          size="sm"
          className="text-sm font-medium capitalize border bg-primary-400 border-primary-400"
          onClick={() => {
            onCropperChange();
            setOpenCropperImage(false); // Close modal after saving
          }}
        >
          save
        </Button>
      </div>
    </div>
  );
};

export default ImageCropperModal;
