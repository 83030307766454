import React from "react";
import QuickBookLockupLogo from "../../assets/quickbooks_lockup-logo.svg";
import QuickBookIcon from "../../assets/icon/quick-book-icon.svg";
import IntegrationLayout from "../../layout/IntegrationLayout";

const IntegrationQuickBookTab = () => {
  return (
    <IntegrationLayout>
      <div className="grow overflow-y-auto xl:h-[calc(100vh-132px)] h-[calc(100vh-106px)]">
        <div className="bg-white rounded-theme py-10 flex justify-center items-center mb-2">
          <img
            src={QuickBookLockupLogo}
            alt="quick-books-logo"
            className="xl:max-w-[353px] w-full xl:h-[80px] lg:max-w-[280px] lg:h-[50px] max-w-[250px] h-[40px]"
          />
        </div>
        <div className="bg-white rounded-theme py-5 px-4 flex flex-col items-center justify-center overflow-y-auto xl:h-[calc(100vh-300px)] lg:h-[calc(100vh-240px)] h-[calc(100vh-230px)]">
          <div className="text-info-500 lg:text-lg text-base font-semibold mb-4">
            Integrate Quetzal With Quickbooks
          </div>
          <button className="bg-success lg:rounded-theme rounded-base lg:px-[30px] py-2 px-3 lg:py-4 flex items-center lg:gap-4 gap-2">
            <div>
              <img
                src={QuickBookIcon}
                alt="quickBook-icon"
                className="lg:w-6 lg:h-6 w-5 h-5"
              />
            </div>
            <div className="text-white lg:text-lg text-sm_18 font-semibold">
              Connect To QuickBook
            </div>
          </button>
        </div>
      </div>
    </IntegrationLayout>
  );
};

export default IntegrationQuickBookTab;
