import Dexie from "dexie";

const databaseName = "quetzal-pos-frontend";

const indexedDB = new Dexie(databaseName);

indexedDB.version(1).stores({
  loginUser: "_id",
});

export default indexedDB;
