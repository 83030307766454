import React, { useEffect, useLayoutEffect, useState } from "react";
import CalendarIcon from "../../assets/icon/calendar.svg";
import FilterDarkGray from "../../assets/icon/filter-dark-grey.svg";
import { Link } from "react-router-dom";
import ReportWrapper from "../../layout/ReportWrapper";
import { useDispatch, useSelector } from "react-redux";
import {
  getExportedData,
  getInventoryValuationRequest,
  getReturnAndRefundRequest,
  getTaxReportRequest,
} from "../../store/report/actions";
import Loader from "../common/Loader";
import ReactSelect from "../../components/common/ReactSelect";
import {
  filterOptions,
  formatCurrencyWithNumberValue,
  getUserOrgLocationSetting,
  paymentTypeReportOptions,
} from "../../utils/utils";

import {
  Button,
  Chip,
  Input,
  Popover,
  PopoverContent,
  PopoverHandler,
  Radio,
} from "@material-tailwind/react";
import { t } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faArrowRightLong,
  faCircleInfo,
  faClose,
  faInfo,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx"; // Import XLSX for Excel export
import { ExportIcon, ShortingIcon, TaxReportInfoIcon } from "../svgs";
import DataTable from "../common/DataTable";
import moment from "moment";
import { transform } from "lodash";
import { useTranslation } from "react-i18next";

const TaxReport = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  // get staleInventory summary details
  const {
    isLoading,
    totalPages,
    taxReportSummary,
    taxReportTotalSummary,
  } = useSelector((store) => ({
    isLoading: store?.report?.taxReport?.loading,
    totalPages: store?.report?.taxReport?.data?.orders?.totalPages,
    taxReportSummary: store?.report?.taxReport?.data?.orders?.orders,
    taxReportTotalSummary: store?.report?.taxReport?.data?.summary,
  }));

  const filterOptions = [
    { label: `${t("This Year")}`, value: "this_year" },
    { label: `${t("Last Week")}`, value: "last_week" },
    { label: `${t("Last Month")}`, value: "last_month" },
    { label: `${t("Last 6 Months")}`, value: "last_6_months" },
    { label: `${t("Last Year")}`, value: "last_year" },
  ];

  console.log(taxReportSummary, "taxReportSummary");

  const dispatch = useDispatch();

  const [selectedDateType, setSelectedDateType] = useState("today");
  const [startDate, setStartDate] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [endDate, setEndDate] = useState(null);
  const [openPopover, setOpenPopover] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);

  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  const getOrgDetail = getUserOrgLocationSetting();

  const triggers = (id) => ({
    onMouseEnter: () => setHoveredRowIndex(id),
    onMouseLeave: () => setHoveredRowIndex(null),
  });

  // Define columns for table
  const columns = () => [
    {
      Header: t("Invoice Number"),
      key: "invoiceNumber",
      //   render: (row) => (
      //     <div>
      //       <p>{row.customer.name}</p>
      //       <p>{row.customer.phone}</p>
      //     </div>
      //   ),
    },
    {
      Header: t("Gross Sales"),
      key: "",
      render: (row) => (
        <div>{formatCurrencyWithNumberValue(row.grossSales || 0)}</div>
      ),
    },
    {
      Header: t("Total Taxes"),
      key: "",
      render: (row) => (
        <div>
          {formatCurrencyWithNumberValue(
            (row.total_taxes || 0) - (row.return_total_taxes || 0)
          )}
        </div>
      ),
    },

    {
      Header: t("Transaction Date"),
      key: "",
      render: (row) => (
        <div>
          {row.transactionDate
            ? moment(row.transactionDate).format(
                getOrgDetail?.date_format?.date_format
              )
            : "-"}
        </div>
      ),
    },

    {
      Header: t(""),
      key: "",
      render: (row, index) => (
        <div>
          <Popover
            key={row._id}
            placement="bottom-end"
            open={hoveredRowIndex === row._id} // Only open for the hovered row
            handler={() => setHoveredRowIndex(row._id)}
          >
            <PopoverHandler {...triggers(row._id)} className="cursor-pointer">
              {/* <FontAwesomeIcon icon={faInfoCircle} fontSize={20} color="gray" /> */}
              <button>
                <TaxReportInfoIcon className="w-4 h-4 " />
              </button>
            </PopoverHandler>
            <PopoverContent className="z-10 w-[350px] space-y-4">
              <div>
                <div className="flex justify-between font-bold text-black border-b-2">
                  <h3 className="text-[16px]">Tax Name</h3>
                  <h3 className="text-[16px]">Percentage</h3>
                  <h3 className="text-[16px]">Amount</h3>
                </div>
                {row.taxes_details ? (
                  Object.entries(row.taxes_details).map(([key, value]) => {
                    const returnTaxValue = row.return_taxes_details?.[key]; // Get the corresponding return tax value
                    const difference =
                      returnTaxValue !== undefined
                        ? value - returnTaxValue
                        : value; // Calculate difference or use original value

                    // Find the matching tax rate from tax_rates
                    const matchingTaxRate = row.tax_rates?.find(
                      (rate) => rate.tax_name === key
                    );
                    const taxPercentage = matchingTaxRate
                      ? matchingTaxRate.tax_percentage
                      : null; // Get the tax percentage

                    return (
                      <div
                        key={key}
                        className="flex justify-between text-[14px] text-black"
                      >
                        <p>{key || "-"}</p>
                        <p>
                          {taxPercentage !== null ? `${taxPercentage}%` : "-"}
                        </p>{" "}
                        {/* Show percentage if available */}
                        <p>
                          {formatCurrencyWithNumberValue(
                            difference.toFixed(2) || 0
                          )}
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <div className="flex justify-between text-[14px]">
                    <p>-</p>
                    <p>-</p> {/* Show percentage if available */}
                    <p>-</p>
                  </div>
                )}
              </div>
            </PopoverContent>
          </Popover>
        </div>
      ),
    },
  ];

  console.log(selectedCategory, "selectedCategory");

  const handlePageChange = (page) => {
    setPage(page.selected + 1);
  };

  const handlePerPageChange = (limit) => {
    setLimit(limit);
  };

  // pass filter data in API when press filter button then call
  const applyFilter = () => {
    console.log("apply");
    dispatch(
      getTaxReportRequest({
        page: page,
        limit,
        dateType: selectedDateType?.value || selectedDateType,
        start_date: selectedDateType === "custom" ? startDate : "",
        end_date: selectedDateType === "custom" ? endDate : "",
      })
    );
    setOpenPopover(false);
  };

  // Function to handle Export
  const handleExport = async () => {
    const totalLimit = totalPages * limit || limit; // Calculate the total limit for the export

    await dispatch(
      getExportedData({
        path: "/reports/tax-report",
        page: page,
        limit: totalLimit,
        dateType: selectedDateType?.value || selectedDateType,
        start_date: selectedDateType === "custom" ? startDate : "",
        end_date: selectedDateType === "custom" ? endDate : "",
        category: selectedCategory,
      })
    ).then((response) => {
      const salesData = response?.data?.data?.orders?.orders || [];

      // Format the data for Excel export
      const formattedData = salesData.map((row) => ({
        "Invoice Number": row.invoiceNumber,
        "Gross Sales": formatCurrencyWithNumberValue(row.grossSales || 0),
        "Total Taxes": formatCurrencyWithNumberValue(row.total_taxes || 0),
        "Transaction Date": row.transactionDate
          ? moment(row.transactionDate).format(
              getOrgDetail?.date_format?.date_format
            )
          : "-",
        "Tax Details": JSON.stringify(row.taxes_details), // Convert object to string
        "Return Tax Details": JSON.stringify(row.return_taxes_details), // Convert object to string
        "Tax Rates":
          row?.tax_rates
            ?.map((tax) => `${tax.tax_name}: ${tax.tax_percentage}%`)
            .join(", ") || "-", // Concatenate tax names and percentages
      }));

      console.log(formattedData, "formattedData......................");

      // Convert data to Excel format
      const worksheet = XLSX.utils.json_to_sheet(formattedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "TaxReport");

      // Export the Excel file
      XLSX.writeFile(workbook, "TaxReport.xlsx");
    });
  };

  // dispatch get stale inventory summary list request
  useLayoutEffect(() => {
    dispatch(
      getTaxReportRequest({
        page: page,
        limit,
        dateType: selectedDateType?.value || selectedDateType,
        start_date: selectedDateType === "custom" ? startDate : "",
        end_date: selectedDateType === "custom" ? endDate : "",
      })
    );
  }, [dispatch, limit, page]);

  return (
    <ReportWrapper>
      <div className="grow overflow-y-auto xl:h-[calc(100vh-132px)] h-[calc(100vh-106px)]">
        <div className="flex items-center justify-between p-3 mb-4 bg-white rounded-theme xl:py-5 lg:px-5 lg:py-4">
          <div className="text-base font-semibold lg:text-lg 2xl:font-bold text-info-500 2xl:text-xl">
            {t("Tax Breakdown")}
          </div>
          <div className="flex items-center ">
            {/* <div className="gap-2.5  xl:py-[14px] lg:py-2.5 py-1.5 px-[18px] ">
              <div>
                <ReactSelect
                  className="border-none min-w-[115px] w-full"
                  labelProps={"!text-xs font-semibold capitalize"}
                  options={paymentTypeReportOptions}
                  value={selectedPaymentType}`
                  onChange={(data) => setSelectedPaymentType(data)}
                />
              </div>
            </div> */}
            <div className="flex items-center gap-3">
              <div>
                <Popover
                  placement="bottom-end"
                  open={openPopover}
                  handler={setOpenPopover}
                >
                  <PopoverHandler>
                    <Button className="flex items-center gap-3 text-sm font-semibold capitalize bg-white border border-gray-500 text-secondary-400">
                      <ShortingIcon />
                      {t("Filter")}
                      <FontAwesomeIcon
                        icon={openPopover ? faAngleUp : faAngleDown}
                      />
                    </Button>
                  </PopoverHandler>
                  <PopoverContent className="z-10  lg:w-[900px] w-[calc(100%-16px)] space-y-4">
                    <div className="flex items-center justify-between">
                      <p className="text-lg font-semibold capitalize text-info-500">
                        {t("Filter Options")}
                      </p>
                      <FontAwesomeIcon
                        onClick={() => setOpenPopover(false)}
                        icon={faClose}
                        className="w-5 h-5 text-info-500"
                      />
                    </div>
                    <div className="p-5 border border-secondary-200 rounded-theme">
                      <p className="text-lg font-medium capitalize text-secondary-400">
                        {t("Date Range")}
                      </p>
                      <div className="flex items-center gap-2 pt-4">
                        {[
                          "custom",
                          "today",
                          "yesterday",
                          "this_week",
                          "this_month",
                        ].map((type) => (
                          <p
                            key={type}
                            onClick={() => setSelectedDateType(type)}
                            className={`border ${
                              selectedDateType === type
                                ? "border-primary-400 bg-primary-150 text-primary-400"
                                : "border-secondary-200 text-secondary-400"
                            } flex items-center justify-center xl:text-base text-sm_18 font-medium  rounded-3xl xl:px-[30px] lg:px-5 px-3.5 xl:py-3 py-2 cursor-pointer transition-all duration-300 text-nowrap capitalize`}
                          >
                            {t(type.split("_").join(" "))}
                          </p>
                        ))}
                        <ReactSelect
                          labelProps={"!text-xs font-semibold capitalize"}
                          placeholder={language === "fr" ? "plus" : "More"}
                          options={filterOptions}
                          value={selectedDateType}
                          onChange={(data) => setSelectedDateType(data)}
                        />
                      </div>
                      {selectedDateType === "custom" && (
                        <div className="flex items-center gap-5 pt-6">
                          <div>
                            <Input
                              label={t("Start Date")}
                              type={"date"}
                              value={startDate}
                              max={new Date().toISOString().split("T")[0]}
                              onChange={(e) => setStartDate(e.target.value)}
                              className={
                                "bg-secondary-50 xl:max-w-[248px] lg:max-w-[190px] max-w-[170px] placeholder:font-medium placeholder:text-secondary-500 text-base px-2"
                              }
                            />
                          </div>
                          <div className="">
                            <FontAwesomeIcon
                              icon={faArrowRightLong}
                              className="w-5 h-5 "
                            />
                          </div>
                          <div>
                            <Input
                              label={t("End Date")}
                              type={"date"}
                              value={endDate}
                              max={new Date().toISOString().split("T")[0]}
                              onChange={(e) => setEndDate(e.target.value)}
                              className={
                                "bg-secondary-50 xl:max-w-[248px] lg:max-w-[190px] max-w-[170px] placeholder:font-medium placeholder:text-secondary-500 text-base px-2"
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <Button
                      type="submit"
                      fullWidth
                      onClick={applyFilter}
                      className="bg-primary-400 capitalize font-medium text-base w-[143px] h-[46px]"
                    >
                      {t("Filter")}
                    </Button>
                  </PopoverContent>
                </Popover>
              </div>
              <Button
                onClick={handleExport}
                className="flex items-center gap-3 text-sm font-semibold capitalize border bg-primary-150 text-primary-400 border-primary-400"
              >
                <ExportIcon />
                {/* <FontAwesomeIcon icon={faFolder} className="w-5 h-5" /> */}
                {t("Export")}
              </Button>
            </div>
          </div>
        </div>

        {/* <div className="gap-2.5">
          <div className="bg-white rounded-theme">
            <div className="h-full payment-table-container">
              <table className="w-full h-full table-auto">
                <thead>
                  <tr>
                    <th className="px-4 py-2 text-left capitalize ">
                      Product Name
                    </th>
                    <th className="px-4 py-2 text-left capitalize ">Variant</th>
                    <th className="px-4 py-2 text-left capitalize ">
                      Cost Price
                    </th>
                    <th className="px-4 py-2 text-left capitalize">
                      Selling price
                    </th>
                    <th className="px-4 py-2 text-left capitalize">Quantity</th>
                    <th className="px-4 py-2 text-left capitalize">
                      Total Cost
                    </th>
                    <th className="px-4 py-2 text-left capitalize">
                      Total Retail
                    </th>
                    <th className="px-4 py-2 text-left capitalize">
                      Sold Items
                    </th>
                  </tr>
                </thead>

                {!isLoading && (
                  <>
                    <tbody className="divide-y divide-gray-200">
                      {inventoryValuation?.orders?.map((item) => {
                        return (
                          <tr>
                            <td className="px-4 py-3">
                              {item.product_name || "-"}
                            </td>
                            <td className="px-4 py-3">{item.variant || "-"}</td>
                            <td className="px-4 py-3">
                              {item.cost_price || "-"}
                            </td>
                            <td className="px-4 py-3">
                              {item.selling_price || "-"}
                            </td>
                            <td className="px-4 py-3">
                              {item.quantities || "-"}
                            </td>
                            <td className="px-4 py-3">
                              {item.totalCostOfInventory || "-"}
                            </td>
                            <td className="px-4 py-3">
                              {item.totalRetailValue || "-"}
                            </td>
                            <td className="px-4 py-3">
                              {item.itemSold || "-"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </>
                )}
              </table>
            </div>
          </div>
        </div> */}

        <div className="flex justify-between w-full mb-4 gap-x-6 rounded-theme">
          <div className="w-full p-4 space-y-2 text-base bg-white 2xl:space-y-5 rounded-theme">
            <p className="2xl:text-[16px] text-sm text-[#1E2A3B]">
              {t("Total Gross Sales")}
            </p>
            <p className="font-semibold 2xl:text-[22px] text-sm text-info-500">
              {formatCurrencyWithNumberValue(
                taxReportTotalSummary?.totalGrossSales || 0
              )}
            </p>
          </div>
          <div className="w-full p-4 space-y-2 text-base bg-white 2xl:space-y-5 rounded-theme">
            <p className="2xl:text-[16px] text-sm text-[#1E2A3B]">
              {t("Total Taxes")}
            </p>
            <p className="font-semibold 2xl:text-[22px] text-sm text-info-500">
              {formatCurrencyWithNumberValue(
                taxReportTotalSummary?.totalTaxes || 0
              )}
            </p>
          </div>
          <div className="w-full p-4 space-y-2 text-base bg-white 2xl:space-y-5 rounded-theme">
            <p className="2xl:text-[16px] text-sm text-[#1E2A3B]">
              {t("Total Tax Sales")}
            </p>
            <p className="font-semibold 2xl:text-[22px] text-sm text-info-500">
              {formatCurrencyWithNumberValue(
                taxReportTotalSummary?.totalTaxSales || 0
              )}
            </p>
          </div>
        </div>

        <div className="w-full p-5 bg-white">
          <DataTable
            columns={columns()}
            data={taxReportSummary}
            maxHeight="max-h-[calc(100vh-195px)]"
            loading={isLoading}
            search={""}
            page={page}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
            handlePerPageChange={handlePerPageChange}
            message="No Data Found"
          />
        </div>
      </div>
    </ReportWrapper>
  );
};

export default TaxReport;
