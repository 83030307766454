import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  products: {
    loading: false,
    data: [],
    error: false,
    totalPages: null,
  },
};

export const productsSlice = createSlice({
  name: "products",
  initialState: initialState,
  reducers: {
    productsState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const { productsState } = productsSlice.actions;
export default productsSlice.reducer;
