import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Login from "../pages/auth/Login";
import Otp from "../pages/auth/Otp";
import ResetPassword from "../pages/auth/ResetPassword";
import Dashboard from "../pages/Dashboard/Dashboard";
import Inventory from "../pages/Inventory/index";
import Order from "../pages/Order/index";
import Report from "../pages/Report/index";
import CashDrawer from "../pages/Register/CashDrawer";
import Register from "../pages/Register/index";
import Transaction from "../pages/Register/Transaction";
import Settings from "../pages/Settings";
import ProtectedRoutes from "./ProtectedRoutes";
// import DailySummary from "../pages/Register/DailySummary";
import { ProductProvider } from "../helpers/context/ProductContext";
import AccountSetting from "../pages/Account/AccountSetting";
import ProductForm from "../pages/Inventory/ProductForm";
import ProductVariant from "../pages/Inventory/ProductVariant";
import UploadFile from "../pages/Inventory/UploadFile";
import ViewProductDetails from "../pages/Inventory/ViewProductDetails";
import CustomerTable from "../pages/Report/CustomerTable";
import TransactionHistory from "../pages/Report/TransactionHistory";
import AddUserDetails from "../pages/Settings/AddUserDetails";
import AllDeviceSettings from "../pages/Settings/AllDeviceSettings";
import IntegrationEcommerce from "../pages/Settings/IntegrationEcommerce";
import IntegrationPayment from "../pages/Settings/IntegrationPayment";
import IntegrationQuickBookTab from "../pages/Settings/IntegrationQuickBookTab";
import IntegrationSystemLogout from "../pages/Settings/IntegrationSystemLogout";
import LocationSettings from "../pages/Settings/LocationSettings";
import ThisDevice from "../pages/Settings/ThisDevice";
import ViewOrderDetails from "../pages/Order/ViewOrderDetails";
import Receipt from "../pages/Order/Receipt";
import { OrderProvider } from "../helpers/context/OrderContext";
import Payment from "../pages/Order/Payment";
import InventoryStaleReport from "../components/report/StaleInventory";
import StaleInventory from "../components/report/StaleInventory";
import InventoryValuation from "../components/report/InventoryValuation";
import SalesByProduct from "../components/report/SalesByProduct";
import ReturnAndRefund from "../components/report/ReturnAndRefund";
import DeadStock from "../components/report/DeadStock";
import GiftCardUsage from "../components/report/GiftCardUsage";
import PaymentReport from "../components/report/PaymentReport";
import DailySummary from "../components/report/DailySummary";
import TaxReport from "../components/report/TaxReport";
import GiftCardSales from "../components/report/GiftCardSales";
import StoreCreditReport from "../components/report/StoreCreditReport";

const AllRoutes = () => {
  return (
    <Suspense>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoutes>
              <Login />
            </ProtectedRoutes>
          }
        />
        <Route path="/pin" element={<Otp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:id" element={<ResetPassword />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route
          path="/register"
          element={
            <ProtectedRoutes>
              <OrderProvider>
                <Register />
              </OrderProvider>
            </ProtectedRoutes>
          }
        />
        <Route
          path="/payment/:id"
          element={
            <ProtectedRoutes>
              <OrderProvider>
                <Payment />
              </OrderProvider>
            </ProtectedRoutes>
          }
        />
        <Route
          path="/cash-drawer"
          element={
            <ProtectedRoutes>
              <CashDrawer />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/register/transaction"
          element={
            <ProtectedRoutes>
              <Transaction />
            </ProtectedRoutes>
          }
        />
        {/* <Route
          path="/register/daily-summary"
          element={
            <ProtectedRoutes>
              <DailySummary />
            </ProtectedRoutes>
          }
        /> */}
        <Route
          path="/inventory/items"
          element={
            <ProtectedRoutes>
              <Inventory />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/inventory/items/form"
          element={
            <ProtectedRoutes>
              <ProductProvider>
                <ProductForm />
              </ProductProvider>
            </ProtectedRoutes>
          }
        />
        <Route
          path="/inventory/items/variant"
          element={
            <ProtectedRoutes>
              <ProductProvider>
                <ProductVariant />
              </ProductProvider>
            </ProtectedRoutes>
          }
        />
        <Route
          path="/inventory/items/view-product/:id"
          element={
            <ProtectedRoutes>
              <ViewProductDetails />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/inventory/import"
          element={
            <ProtectedRoutes>
              <UploadFile />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/inventory/export"
          element={
            <ProtectedRoutes>
              <Inventory />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/order"
          element={
            <ProtectedRoutes>
              <Order />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/order/view-order/:id"
          element={
            <ProtectedRoutes>
              <ViewOrderDetails />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/order/receipt/:id"
          element={
            <ProtectedRoutes>
              <Receipt />
            </ProtectedRoutes>
          }
        />
        {/* <Route
          path="/report"
          element={
            <ProtectedRoutes>
              <Report />
            </ProtectedRoutes>
          }
        /> */}
        <Route
          path="/report/daily-summary"
          element={
            <ProtectedRoutes>
              <DailySummary />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/report/sales-by-product"
          element={
            <ProtectedRoutes>
              <SalesByProduct />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/report/refunds-and-returns"
          element={
            <ProtectedRoutes>
              <ReturnAndRefund />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/report/valuation-inventory"
          element={
            <ProtectedRoutes>
              <InventoryValuation />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/report/stale-inventory"
          element={
            <ProtectedRoutes>
              <StaleInventory />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/report/dead-stock"
          element={
            <ProtectedRoutes>
              <DeadStock />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/report/payment-reports"
          element={
            <ProtectedRoutes>
              <PaymentReport />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/report/payment-activity"
          element={
            <ProtectedRoutes>
              <PaymentReport />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/report/tax-breakdown"
          element={
            <ProtectedRoutes>
              <TaxReport />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/report/gift-card-uses"
          element={
            <ProtectedRoutes>
              <GiftCardUsage />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/report/gift-card-sales"
          element={
            <ProtectedRoutes>
              <GiftCardSales />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/report/store-credit"
          element={
            <ProtectedRoutes>
              <StoreCreditReport />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/settings/users"
          element={
            <ProtectedRoutes>
              <Settings />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/settings/users/form"
          element={
            <ProtectedRoutes>
              <AddUserDetails />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/settings/location"
          element={
            <ProtectedRoutes>
              <LocationSettings />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/settings/all-device"
          element={
            <ProtectedRoutes>
              <AllDeviceSettings />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/settings/this-device"
          element={
            <ProtectedRoutes>
              <ThisDevice />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/settings/integration/payment"
          element={
            <ProtectedRoutes>
              <IntegrationPayment />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/settings/integration/e-commerce"
          element={
            <ProtectedRoutes>
              <IntegrationEcommerce />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/settings/integration/quick-book"
          element={
            <ProtectedRoutes>
              <IntegrationQuickBookTab />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/settings/integration/system-logout"
          element={
            <ProtectedRoutes>
              <IntegrationSystemLogout />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/Account"
          element={
            <ProtectedRoutes>
              <AccountSetting />
            </ProtectedRoutes>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default AllRoutes;
