import React from "react";
import NoProductImg from "../../assets/no-product-img.svg";
import DownArrowIcon from "../../assets/icon/down-arrow.svg";
import NoProductIcon from "../../assets/icon/no-product.svg";
import GiftIcon from "../../assets/icon/gift-box.svg";
import HoldOrderIcon from "../../assets/icon/pause-btn.svg";
import TopBar from "./TopBar";
import Layout from "../../layout";
import { Link } from "react-router-dom";
import { NoDataFoundSvg } from "../../components/svgs/NoDataFoundSvg";
import { useTranslation } from "react-i18next";

const NoProduct = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center h-[calc(100vh-211px)] w-full">
      <NoDataFoundSvg />
      <div className="xl:text-base text-sm_18 text-secondary-400 text-center max-w-[180px] w-full">
        {t("No Products Found In This Category")}
      </div>
    </div>
  );
};

export default NoProduct;
