import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, IconButton } from "@material-tailwind/react";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

const ViewLocationsAccess = ({ location_access, closeHandler }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className="flex flex-col h-full bg-white p-7 gap-y-5">
        <div className="flex items-center justify-between">
          <div className="flex flex-col">
            <p className="font-medium capitalize text-info-500 xl:text-xl lg:text-lg text-base_24">
              {t("locations access")}
            </p>
            <p className="font-normal text-sm_20 text-secondary-300">
              {location_access?.length} of {location_access?.length}{" "}
              {t("Selected")}
            </p>
          </div>
          <IconButton
            size="sm"
            type="button"
            variant="text"
            className=""
            onClick={closeHandler}
          >
            <FontAwesomeIcon icon={faClose} fontSize={16} />
          </IconButton>
        </div>
        {location_access?.length > 0 && (
          <div>
            <p className="mb-2 font-medium capitalize text-base_24 text-info-500">
              {t("selected location")}
            </p>
            <div className="p-3 overflow-hidden border border-secondary-200 rounded-theme">
              {location_access?.map((item, index) => (
                <Fragment>
                  <div className="flex py-3 checkbox-label-between">
                    <div>
                      <span className="font-semibold capitalize text-info-700 xl:text-lg lg:text-base text-sm_18">
                        {item.location_address}
                        <p className="font-medium capitalize lg:text-base text-sm_18 text-secondary-400">
                          {item.location_name}
                        </p>
                      </span>
                    </div>
                    <Checkbox
                      containerProps={{
                        className: "order-2",
                      }}
                      checked={location_access?.includes(item)}
                    />
                  </div>
                  {index !== location_access?.length - 1 && (
                    <hr className="border-secondary-200" />
                  )}
                </Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default ViewLocationsAccess;
