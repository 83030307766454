import { configureStore } from "@reduxjs/toolkit";
import authSetState from "./auth/auth.slice";
import settingState from "./settings/settings.slice";
import userState from "./settings/user/state";
import locationState from "./settings/location/state";
import accountSettingState from "./settings/account/state";
import deviceState from "./settings/device/state";
import vendorState from "./inventory/vendors/state";
import categoriesState from "./inventory/categories/state";
import brandsState from "./inventory/brands/state";
import productState from "./inventory/product/state";
import variantState from "./inventory/variants/state";
// import productState from "./Inventory/productList/state";
import importState from "./inventory/import/state";
import productsState from "./register/products/state";
import categoryState from "./register/category/state";
import customerState from "./customer/state";
import cashDrawerSate from "./register/cashDrawer/state";
import transactionState from "./register/transaction/state";
import orderState from "./order/state";
import reportState from "./report/state";
import integrationState from "./settings/integration/state";

export const store = configureStore({
  reducer: {
    auth: authSetState,
    setting: settingState,
    user: userState,
    location: locationState,
    account: accountSettingState,
    device: deviceState,
    product: productState,
    vendor: vendorState,
    categories: categoriesState,
    brands: brandsState,
    variant: variantState,
    import: importState,
    products: productsState,
    category: categoryState,
    customer: customerState,
    cashDrawer: cashDrawerSate,
    transaction: transactionState,
    order: orderState,
    report: reportState,
    integration: integrationState
  },
});
