import { Form, useFormik } from "formik";
import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createProductRequest,
  getSingleProduct,
  updateProductRequest,
} from "../../store/inventory/product/actions";
import { useLocation, useNavigate } from "react-router-dom";
import { setAllAttributes } from "../../store/inventory/variants/actions";
import { loyaltyPointsOptions, transformAttributes } from "../../utils/utils";
import { productState } from "../../store/inventory/product/state";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

export const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const productId = new URLSearchParams(location.search).get("id");

  const { isLoading, singleData, allAttributes } = useSelector((store) => ({
    isLoading: store?.product?.singleProduct?.loading,
    singleData: store?.product?.singleProduct?.data,
    allAttributes: store?.variant?.allAttributes,
  }));

  console.log(singleData, "singleDatasingleDatasingleData...........");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      product_name: singleData?.product_name || "",
      description: singleData?.description || "",
      loyalty_points_type: singleData
        ? {
            label: t(singleData?.loyalty_points_type),
            value: singleData?.loyalty_points_type,
          }
        : loyaltyPointsOptions(t)[0],
      quantities:
        singleData && singleData?.variants_possibilities?.length <= 0
          ? singleData?.quantities
          : "",
      selling_price:
        singleData && singleData?.variants_possibilities?.length <= 0
          ? singleData?.selling_price
          : "",
      cost_price:
        singleData && singleData?.variants_possibilities?.length <= 0
          ? singleData?.cost_price
          : "",
      scan_code: singleData?.scan_code || "",
      internal_code: singleData?.internal_code || "",
      brand_id: singleData?.brand_id?._id || "",
      brand: singleData?.brand_id || "",
      categories_tag:
        singleData?.categories_tag?.map((item) => item?._id) || [],
      categories: singleData?.categories_tag || [],
      vendor_id: singleData?.vendor_id?._id || "",
      vendor: singleData?.vendor_id || "",
      variants_possibilities: singleData?.variants_possibilities || [],
      image: singleData?.image || null,
    },
    validationSchema: Yup.object().shape({
      product_name: Yup.string().required(t("Please enter product name")),
      scan_code: Yup.string().required(t("Please enter scan code")),
      internal_code: Yup.string().required(t("Please enter internal code")),
      variants_possibilities: Yup.array().of(Yup.object()),
      quantities: Yup.number().when(
        "variants_possibilities",
        (variants_possibilities, schema) => {
          return variants_possibilities && variants_possibilities[0].length > 0
            ? schema.notRequired()
            : schema.required("Please enter product quantities");
        }
      ),
      selling_price: Yup.number().when(
        "variants_possibilities",
        (variants_possibilities, schema) => {
          return variants_possibilities && variants_possibilities[0].length > 0
            ? schema.notRequired()
            : schema.required("Please enter selling price");
        }
      ),
      cost_price: Yup.number().when(
        "variants_possibilities",
        (variants_possibilities, schema) => {
          return variants_possibilities && variants_possibilities[0].length > 0
            ? schema.notRequired()
            : schema.required("Please enter cost price");
        }
      ),
      brand_id: Yup.string().required(t("Please select brand")),
      vendor_id: Yup.string().required(t("Please select vendor")),
      categories_tag: Yup.array()
        .of(Yup.string())
        .min(1, t("Please select at least one category"))
        .required(t("Please select categories")),
    }),
    onSubmit: (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("product_name", values.product_name);
      formData.append("description", values.description);
      formData.append(
        "loyalty_points_type",
        values.loyalty_points_type?.value || ""
      );
      values.quantities && formData.append("quantities", values.quantities);
      values.selling_price &&
        formData.append("selling_price", values.selling_price);
      values.cost_price && formData.append("cost_price", values.cost_price);
      formData.append("scan_code", values.scan_code);
      formData.append("internal_code", values.internal_code);
      formData.append("brand_id", values.brand_id);
      formData.append("vendor_id", values.vendor_id);
      values.image &&
        typeof values.image === "object" &&
        formData.append("image", values.image);
      values.categories_tag.forEach((value, index) => {
        formData.append("categories_tag[]", value);
      });
      allAttributes.forEach((attribute) => {
        const key = `attributes[${attribute.attribute_name}][]`;
        attribute.options.forEach((option) => {
          formData.append(key, option.val);
        });
      });
      !productId &&
        values.variants_possibilities.forEach((value, index) => {
          formData.append(`variants_possibilities[${index}][name]`, value.name);
          formData.append(
            `variants_possibilities[${index}][price]`,
            value.price
          );
          formData.append(
            `variants_possibilities[${index}][stock]`,
            value.stock
          );
          formData.append(
            `variants_possibilities[${index}][cost_price]`,
            value.cost_price
          );
        });
      if (productId) {
        dispatch(
          updateProductRequest({
            productId,
            body: formData,
            resetForm,
            navigate,
          })
        );
      } else {
        dispatch(createProductRequest({ body: formData, resetForm, navigate }));
      }
    },
  });

  useEffect(() => {
    if (productId) {
      dispatch(getSingleProduct({ productId }));
    }
  }, [productId, dispatch]);

  useEffect(() => {
    if (singleData && singleData?.attributes) {
      const newValue = transformAttributes(singleData?.attributes);
      dispatch(setAllAttributes(newValue));
    }
  }, [singleData, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(productState({ key: "singleProduct.data", value: null }));
      dispatch(setAllAttributes([]));
    };
  }, [dispatch]);

  return (
    <ProductContext.Provider
      value={{ formik, isLoading, singleData, productId }}
    >
      {children}
    </ProductContext.Provider>
  );
};
