import indexedDB from "./idb";

export const createRecord = async (storeName, data) => {
  console.log(storeName, "data tata ta", data);
  await indexedDB.table(storeName).add(data);
};

export const createMultipleRecord = async (storeName, data) => {
  await indexedDB.table(storeName).bulkAdd(data);
};

export const getAllRecord = async (storeName) => {
  return await indexedDB.table(storeName).toArray();
};

export const paginationWiseRecords = async (storeName, params) => {
  const { page, limit } = params;
  const skip = (Number(page) - 1) * Number(limit);
  return await indexedDB.table(storeName).offset(skip).limit(limit).toArray();
};

export const deleteRecord = async (storeName, key) => {
  return await indexedDB.table(storeName).delete(key);
};

export const deleteAllRecord = async (storeName) => {
  return await indexedDB.table(storeName).bulkDelete();
};
