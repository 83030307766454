import { useSelector } from "react-redux";
import DataTable from "../../components/common/DataTable";
import Layout from "../../layout";
import { Avatar } from "@material-tailwind/react";

const Dashboard = () => {
  const { user, role } = useSelector((store) => store.auth);

  const handleEdit = (props) => {
    console.log({ props });
  };

  const data = [
    {
      name: "John Doe",
      age: 28,
      job: { role: "Developer" },
      image:
        "https://img.freepik.com/free-psd/studio-portrait-young-teenage-girl_23-2150162484.jpg",
    },
    {
      name: "John Doe",
      age: 28,
      job: { role: "Developer" },
      image:
        "https://img.freepik.com/free-psd/studio-portrait-young-teenage-girl_23-2150162484.jpg",
    },
    {
      name: "John Doe",
      age: 28,
      job: { role: "Developer" },
      image:
        "https://img.freepik.com/free-psd/studio-portrait-young-teenage-girl_23-2150162484.jpg",
    },
    // Add more data
  ];

  const columns = () => [
    { Header: "Name", key: "name" },
    { Header: "Age", key: "age" },
    { Header: "Job", key: "job.role" },
    { Header: "image", key: "", render: (row) => <Avatar src={row.image} /> },
    {
      Header: "Action",
      key: "",
      render: (rowData) => {
        return (
          <button className="" onClick={() => handleEdit(rowData)}>
            Edit
          </button>
        );
      },
    },
  ];

  return (
    <Layout>
      <div>
        <div></div>
        <DataTable columns={columns()} data={data} search={""} />
      </div>
    </Layout>
  );
};

export default Dashboard;
