import { useFormik } from "formik";
import React, { createContext } from "react";
import { useDispatch } from "react-redux";
import { addToCartRequest } from "../../store/order/actions";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { discountType } from "../../utils/enums";
export const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [query, setQuery] = useSearchParams();
  const orderId = query.get("q");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      order: {
        // order_status: "completed",
        customer_id: "",
        total_qty: 0,
        sub_total: 0,
        total_discount: 0,
        total_taxes: 0,
        grand_total: 0,
        redeemed_dollar: 0,
      },
      orderItems: [],
    },

    validationSchema: Yup.object().shape({
      orderItems: Yup.array().of(
        Yup.object().shape({
          qty: Yup.number()
            .min(1, "Quantity must be at least 1")
            .required("Quantity is required")
            .test("max-qty", "Product out of stock", function (value) {
              console.log(value, "value...........");
              const { total_qty, type } = this.parent; // Access total_qty from the parent (current order item)
              console.log(total_qty, "total_qty...............");
              if (type === "inventory") {
                return value <= total_qty; // Validate if qty is less than or equal to total_qty
              }

              return true; // Validate if qty is less than or equal to total_qty
            }),

          discount_percentage: Yup.number()

            .required("Discount is required")
            .test("validate-discount", function (value, context) {
              const { discount_type, sub_total } = context.parent;

              if (discount_type === discountType.PERCENTAGE) {
                if (!Number.isInteger(value)) {
                  return this.createError({
                    message: "Percentage must be a whole number",
                  });
                }
                if (value < 0 || value > 100) {
                  return this.createError({
                    message: "Percentage must be between 0 and 100",
                  });
                }
              } else {
                if (value < 0) {
                  return this.createError({
                    message: "Discount cannot be negative",
                  });
                } else if (value > sub_total) {
                  return this.createError({
                    message: "Discount cannot be more than Sub total",
                  });
                }
              }

              return true;
            }),
          // Add other fields validation as needed
        })
      ),
    }),
    onSubmit: (values) => {
      {
        console.log(values?.order, "values");
      }
      if (!values?.order?.customer_id)
        return toast.error("Please choose a customer to continue");
      const createCartData = {
        customer_id: orderId
          ? values.order.customer_id
          : values.order.customer_id._id,
        orderItems: values.orderItems.map((item) => {
          if (item.type === "inventory") {
            const { product_name, variant_name, total_qty, ...element } = item;

            return {
              ...element,
              discount_percentage:
                element?.discount_type === discountType.PERCENTAGE
                  ? Number(element?.discount_percentage)
                  : 0,
            };
          } else {
            return {
              type: item?.type,
              gift_card_code: item.gift_card_code,
              gift_card_amount: Number(item.gift_card_amount),
            };
          }
        }),
      };

      console.log(createCartData, "createCartData");
      dispatch(addToCartRequest({ body: createCartData, navigate, orderId }));
    },
  });

  return (
    <OrderContext.Provider value={{ formik }}>{children}</OrderContext.Provider>
  );
};
