/* -------------------------------------------------------------------------- */
/*                                get Customer list                           */
/* -------------------------------------------------------------------------- */

import { toast } from "react-toastify";
import AxiosRequest from "../../AxiosRequest";
import { customerState } from "./state";

export const getCustomerListRequest =
  ({ page, limit, search = "" }) =>
    async (dispatch) => {
      try {
        dispatch(customerState({ key: "customerList.loading", value: true }));
        const response = await AxiosRequest.get(
          `/user/customer/list?page=${page}&limit=${limit}&search=${search}`
        );
        const data = await response?.data?.data;
        if (data) {
          dispatch(
            customerState({ key: "customerList.data", value: data?.customers })
          );
          dispatch(
            customerState({
              key: "customerList.totalPages",
              value: data?.totalPages,
            })
          );
          dispatch(customerState({ key: "customerList.loading", value: false }));
        }
      } catch (error) {
        toast.error(error);
      }
    };

/* -------------------------------------------------------------------------- */
/*                           add new customer request                         */
/* -------------------------------------------------------------------------- */

export const createCustomerRequest =
  ({ body, setModalClose }) =>
    async (dispatch) => {
      try {
        dispatch(customerState({ key: "createCustomer.loading", value: true }));
        const response = await AxiosRequest.post("/user/customer/create", body);
        const data = await response.data.data;
        if (data) {
          dispatch(customerState({ key: "createCustomer.data", value: data }));
          dispatch(
            customerState({ key: "createCustomer.loading", value: false })
          );
          if (setModalClose) setModalClose();
          toast.success(response.data.message);
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(customerState({ key: "createCustomer.loading", value: false }));
        dispatch(customerState({ key: "createCustomer.error", value: true }));
      }
    };

/* -------------------------------------------------------------------------- */
/*                           get single customer detail request               */
/* -------------------------------------------------------------------------- */

export const getSingleCustomerRequest =
  ({ customerId }) =>
    async (dispatch) => {
      try {
        dispatch(
          customerState({ key: "getCustomerDetail.loading", value: true })
        );
        const response = await AxiosRequest.get(
          `/user/customer/details/${customerId}`
        );
        const data = await response.data.data;
        if (data) {
          dispatch(customerState({ key: "getCustomerDetail.data", value: data }));
          dispatch(
            customerState({ key: "getCustomerDetail.loading", value: false })
          );
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(
          customerState({ key: "getCustomerDetail.loading", value: false })
        );
        dispatch(customerState({ key: "getCustomerDetail.error", value: true }));
      }
    };

/* -------------------------------------------------------------------------- */
/*                        update adjust loyalty points                        */
/* -------------------------------------------------------------------------- */
export const updateAdjustPointRequest =
  ({ customerId, body, setModalClose }) =>
    async (dispatch) => {
      try {
        dispatch(
          customerState({ key: "updateAdjustPoint.loading", value: true })
        );
        const response = await AxiosRequest.put(
          `/user/customer/adjust-points/${customerId}`,
          body
        );

        if (response) {
          dispatch(
            customerState({ key: "updateAdjustPoint.data", value: response })
          );
          dispatch(
            customerState({
              key: "getCustomerDetail.data",
              value: response?.data?.data,
            })
          );
          dispatch(
            customerState({ key: "updateAdjustPoint.loading", value: false })
          );
          if (setModalClose) setModalClose();
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(
          customerState({ key: "updateAdjustPoint.loading", value: false })
        );
        dispatch(customerState({ key: "updateAdjustPoint.error", value: true }));
      }
    };

/* -------------------------------------------------------------------------- */
/*                        get loyalty points history                        */
/* -------------------------------------------------------------------------- */
export const getLoyaltyPointHistoryRequest =
  ({ customerId, page, limit }) =>
    async (dispatch) => {
      try {
        dispatch(customerState({ key: "getLoyaltyPoints.loading", value: true }));
        const response = await AxiosRequest.get(
          `/user/customer/point-history/${customerId}?page=${page}&limit=${limit}`
        );

        const data = await response.data.data;
        if (data) {
          dispatch(
            customerState({ key: "getLoyaltyPoints.data", value: data.history })
          );
          dispatch(
            customerState({
              key: "getLoyaltyPoints.totalPages",
              value: data.totalPages,
            })
          );
          dispatch(
            customerState({ key: "getLoyaltyPoints.loading", value: false })
          );
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(
          customerState({ key: "getLoyaltyPoints.loading", value: false })
        );
        dispatch(customerState({ key: "getLoyaltyPoints.error", value: true }));
      }
    };

/* -------------------------------------------------------------------------- */
/*                        delete customer request                        */
/* -------------------------------------------------------------------------- */
export const deleteCustomerRequest =
  ({ customerId }) =>
    async (dispatch) => {
      try {
        dispatch(customerState({ key: "deleteCustomer.loading", value: true }));
        const response = await AxiosRequest.delete(
          `/user/customer/delete/${customerId}`
        );
        if (response) {
          dispatch(
            customerState({ key: "deleteCustomer.data", value: response })
          );
          dispatch(
            customerState({ key: "deleteCustomer.loading", value: false })
          );
          toast.success(response.data.message);
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(customerState({ key: "deleteCustomer.loading", value: false }));
        dispatch(customerState({ key: "deleteCustomer.error", value: true }));
      }
    };

/* -------------------------------------------------------------------------- */
/*                           update customer request                         */
/* -------------------------------------------------------------------------- */

export const updateCustomerRequest =
  ({ customerId, body, setModalClose }) =>
    async (dispatch) => {
      try {
        dispatch(customerState({ key: "updateCustomer.loading", value: true }));
        const response = await AxiosRequest.put(
          `/user/customer/update/${customerId}`,
          body
        );
        if (response) {
          dispatch(
            customerState({ key: "updateCustomer.data", value: response })
          );
          dispatch(
            customerState({ key: "updateCustomer.loading", value: false })
          );
          if (setModalClose) setModalClose();
          toast.success(response.data.message);
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(customerState({ key: "updateCustomer.loading", value: false }));
        dispatch(customerState({ key: "updateCustomer.error", value: true }));
      }
    };
