import { faUser } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dialog, DialogBody } from "@material-tailwind/react";
import { ErrorMessage, Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import ForgotPasswordImg from "../../assets/forgot-password-img.svg";
import Logo from "../../assets/logo/quetzal-logo.svg";
import Input from "../../components/common/Input";
import { forgotPassword } from "../../store/auth/auth.fetch";
import { authSetState } from "../../store/auth/auth.slice";
import { useTranslation } from "react-i18next";
const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { forgotPasswordData, loading } = useSelector((store) => ({
    forgotPasswordData: store?.auth?.forgotPassword?.data,
    loading: store?.auth?.forgotPassword?.loading,
  }));
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [userEmail, setUserEmail] = useState(null);

  useEffect(() => {
    if (forgotPasswordData) {
      setIsOpenModal(true);
      dispatch(authSetState({ key: "forgotPassword.data", value: null }));
    }
  }, [dispatch, forgotPasswordData]);

  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={{ email: "" }}
        validationSchema={Yup.object({
          email: Yup.string()
            .matches(
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              t("Please enter a valid email address.")
            )
            .required(t("Email is required")),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          dispatch(forgotPassword({ body: values }));
          setUserEmail(values.email);
          setSubmitting(false);
        }}
      >
        {({ values, isSubmitting, handleChange, handleBlur, handleSubmit }) => (
          <Form
            onSubmit={handleSubmit}
            className="w-full h-screen flex justify-center items-center"
          >
            <div className="grid md:grid-cols-2 xl:max-w-[1195px] lg:max-w-[900px] max-w-[730px] w-full mx-auto lg:rounded-2xl rounded-theme bg-white shadow-[0px_24px_101.8px_-16px_rgba(0,0,0,0.15)]">
              <div className="lg:p-[30px] py-7 px-5">
                <h1 className="text-info-400 lg:text-2xl text-lg font-semibold mb-3">
                  {t("Forgot Your Password?")}
                </h1>
                <p className="text-secondary-400 xl:text-lg lg:text-base text-sm_18 font-medium">
                  {t(
                    "Enter the email associated with your account, and we’ll send you instructions to reset your password."
                  )}
                </p>
                <div className="lg:max-w-[191px] lg:h-[223px] max-w-[170px] h-[180px] mt-4 xl:mb-[40px] mb-5">
                  <img
                    src={ForgotPasswordImg}
                    alt="forgot-password-img"
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
              <div className="xl:ml-[30px] ml-2.5 lg:p-[30px] p-5 shadow-[-3px_0px_30.1px_0px_rgba(180,180,180,0.15)] rounded-tl-2xl rounded-bl-2xl">
                <div className="xl:max-w-[147px] lg:max-w-[120px] max-w-[100px] xl:h-[54px] lg:h-[44px] h-[48px] xl:mb-10 mb-[30px]">
                  <img src={Logo} alt="logo" className="w-full h-full" />
                </div>
                <div className="xl:mb-[30px] mb-5">
                  <Input
                    type={"email"}
                    name="email"
                    label={t("Email")}
                    value={values.email}
                    onChange={handleChange}
                    placeholder={"Enter Your Email"}
                    onBlur={handleBlur}
                    iconStart={
                      <FontAwesomeIcon
                        icon={faUser}
                        className="text-secondary-150"
                        fontSize={18}
                      />
                    }
                    className="placeholder:text-sm placeholder:font-normal bg-gray-100"
                  />
                  <ErrorMessage
                    name="email"
                    component={"p"}
                    className="text-red-500 text-sm_18 my-1"
                  />
                </div>
                <Button
                  type="submit"
                  className="bg-primary-400 font-semibold capitalize text-base flex justify-center items-center"
                  fullWidth
                  disabled={loading}
                  loading={loading}
                >
                  {t("Send Reset Link")}
                </Button>
                <p className="xl:mt-[30px] lg:mt-5 mt-3 text-secondary-300 xl:text-base text-sm_18 text-center font-medium">
                  {"Return to"}
                  <Link to="/" className="text-primary-400 ml-1 underline">
                    {t("Log In")}
                  </Link>
                </p>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <Dialog size="xs" open={isOpenModal}>
        <DialogBody>
          <img src={Logo} alt="logo" className="mx-auto pb-4" />
          <h1 className="text-info-400 lg:text-xl text-lg font-semibold lg:mb-[30px] mb-6 text-center lg:max-w-[330px] max-w-[280px] w-full mx-auto">
            {t("Password Reset Link Sent to Your Email")}
          </h1>
          <div className="bg-primary-150 p-5 lg:mb-[30px] mb-6 lg:rounded-theme rounded-base">
            <h3 className="text-info-400 lg:text-base text-sm_18 text-center font-medium mb-2.5 lg:max-w-[330px]  max-w-[280px] w-full mx-auto">
              {"We’ve sent a password reset email with instructions to"}
            </h3>
            <p className="text-primary-400 lg:text-lg text-base font-bold text-center flex justify-center">
              {userEmail}
            </p>
          </div>
          <Button
            type="button"
            fullWidth
            className="bg-primary-400 font-medium text-[14px] capitalize"
            onClick={() => {
              setIsOpenModal(false);
              navigate("/");
            }}
          >
            {t("Back to Log In")}
          </Button>
        </DialogBody>
      </Dialog>
    </Fragment>
  );
};

export default ForgotPassword;
