import AxiosRequest from "../../AxiosRequest";



// get categories list 
export const getCustomerListRequest = async ({ page, limit, search = "" }) => {
    const response = await AxiosRequest.get(
        `/user/customer/list?page=${page}&limit=${limit}&search=${search}`
    );

    console.log(response, "response.........................")
    return response.data.data;
};