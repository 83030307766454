import React from "react";
import Layout from ".";
import InnerSidePanel from "../components/InnerSidePanel";
import Report from "../pages/Report/index";

const IntegrationLayout = ({ children }) => {
  return (
    <Layout>
      <div className="flex gap-2.5">
        <Report />
        {children}
      </div>
    </Layout>
  );
};

export default IntegrationLayout;
