import React from "react";

const Loader = ({ width = "30px", height = "30px" }) => {
  return (
    <div className="loader-container">
      <div className="loader--ring" style={{ width: width, height: height }}>
        <div style={{ width: width, height: height }}></div>
        <div style={{ width: width, height: height }}></div>
        <div style={{ width: width, height: height }}></div>
        <div style={{ width: width, height: height }}></div>
      </div>
    </div>
  );
};

export default Loader;
