/* eslint-disable react-hooks/exhaustive-deps */
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoutes = (props) => {
  const { children } = props;
  let location = useLocation();
  const auth_token = localStorage.getItem("auth_token");
  const pin_access = localStorage.getItem("pinAccess");

  if (!auth_token) {
    if (location.pathname === "/") {
      return children;
    } else {
      return <Navigate to="/" state={{ from: location }} />;
    }
  }

  if (auth_token && !pin_access) {
    return <Navigate to="/pin" state={{ from: location }} />;
  }

  if (auth_token && location.pathname === "/" && pin_access) {
    return <Navigate to="/register" state={{ from: location }} />;
  }

  return children;
};

export default ProtectedRoutes;
