import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  categoryList: {
    loading: false,
    data: [],
    error: false,
    totalPages: null,
  },
};

export const categorySlice = createSlice({
  name: "categories",
  initialState: initialState,
  reducers: {
    categoryState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const { categoryState } = categorySlice.actions;
export default categorySlice.reducer;
