import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  vendorList: {
    loading: false,
    data: [],
    error: false,
    totalPages: null,
  },
  deleteVendor: {
    loading: false,
    data: null,
    error: null,
  },
  createVendor: {
    loading: false,
    data: null,
    error: null,
  },
  updateVendor: {
    loading: false,
    data: null,
    error: null,
  },
  allAttributes: [],
};

export const vendorSlice = createSlice({
  name: "vendorList",
  initialState: initialState,
  reducers: {
    vendorState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});
export const { vendorState } = vendorSlice.actions;
export default vendorSlice.reducer;
