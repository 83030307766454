import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  getAllDeviceData: {
    loading: false,
    data: null,
    error: null,
  },
  updateAllDeviceData: {
    loading: false,
    data: null,
    error: null,
  },
  getThisDeviceData: {
    loading: false,
    data: null,
    error: null,
  },
  updateThisDeviceData: {
    loading: false,
    data: null,
    error: null,
  },
};

export const deviceSlice = createSlice({
  name: "device",
  initialState: initialState,
  reducers: {
    deviceState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const { deviceState } = deviceSlice.actions;

export default deviceSlice.reducer;
