import AxiosRequest from "../../../AxiosRequest";



// get categories list 
export const getCategoriesList = async ({ page, limit }) => {
    const response = await AxiosRequest.get(
        `/user/category/list?&page=${page}&limit=${limit}`, {

    }
    );

    console.log(response, "response.........................")
    return response.data.data;
};