import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  allAttributes: [],
  attributes: [{ attribute_name: "", options: [{ val: "" }] }],
  createVariant: {
    loading: false,
    data: null,
    error: false,
  },
  updateVariant: {
    loading: false,
    data: null,
    error: false,
  },
  deleteVariant: {
    loading: false,
    data: null,
    error: false,
  },
};

export const variantSlice = createSlice({
  name: "variant",
  initialState: initialState,
  reducers: {
    variantState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});
export const { variantState } = variantSlice.actions;
export default variantSlice.reducer;
