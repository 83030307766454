import {
  AllDeviceSvg,
  CartSettingSvg,
  CashDrawerSvg,
  DailySummarySvg,
  ExportSvg,
  HistorySvg,
  ImportSvg,
  IntegrationSvg,
  InventoryItemSvg,
  InventorySvg,
  LocationPinSvg,
  QuickBookSettingSvg,
  RegisterSvg,
  RegisterTransactionSvg,
  ReportSvg,
  SettingSvg,
  SystemAndLogoutSvg,
  ThisDeviceSvg,
  TransactionSvg,
  UserSvg,
  WalletSvg,
} from "../components/svgs";
import { roleTypes } from "../utils/enums";
import { getUserDetails } from "../utils/utils";

// const loggedInUser = getUserDetails();

// console.log(
//   loggedInUser,
//   " loggedInUser?.role_id?.role..........."
// );

export const navigation = (userDetail) => {
  return ([
    {
      title: "Register",
      path: "/register",
      key: "/register",
      icon: (props) => <RegisterSvg {...props} />,
      permission: "register",
    },
    {
      title: "Transactions",
      path: "/order",
      key: "/order",
      icon: (props) => <TransactionSvg {...props} />,
      permission: "transactions",
    },
    {
      title: "Inventory",
      path: "/inventory/items",
      key: "/inventory",
      icon: (props) => <InventorySvg {...props} />,
      permission: "inventory",
    },

    {
      title: "Reports",
      path: "/report/daily-summary",
      key: "/report",
      icon: (props) => <ReportSvg {...props} />,
      permission: "reports",
    },

    {
      title: "Cash Drawer",
      path: "/cash-drawer",
      key: "/cash-drawer",
      icon: (props) => <CashDrawerSvg {...props} />,
      permission: "register",
    },
    {
      title: "Settings",
      path:
        userDetail?.role_id?.role === roleTypes?.OWNER
          ? "/settings/users"
          : "/settings/this-device",
      key: "/settings",
      icon: (props) => <SettingSvg {...props} />,
      permission: "setting",
    },
  ])
};

export const registerNavigationTab = [
  {
    title: "Cash Drawer",
    path: "/cash-drawer",
    icon: (props) => <CashDrawerSvg {...props} />,
  },
  // {
  //   title: "transaction",
  //   path: "/register/transaction",
  //   icon: (props) => <RegisterTransactionSvg {...props} />,
  // },
  // {
  //   title: "daily summary",
  //   path: "/register/daily-summary",
  //   icon: (props) => <DailySummarySvg {...props} />,
  // },
];

export const inventoryNavigationTab = [
  {
    title: "items",
    path: "/inventory/items",
    key: "/inventory/items",
    icon: (props) => <InventoryItemSvg {...props} />,
  },
  {
    title: "import",
    path: "/inventory/import",
    key: "/inventory/import",
    icon: (props) => <ImportSvg {...props} />,
  },
  // {
  //   title: "export",
  //   path: "/inventory/export",
  //   key: "/inventory/export",
  //   icon: (props) => <ExportSvg {...props} />,
  // },
];

export const settingsNavigationTab = [
  {
    title: "Users",
    path: "/settings/users",
    key: "/settings/users",
    permission: [roleTypes.OWNER],
    icon: (props) => (
      <UserSvg {...props} className={`2xl:w-[26px] 2xl:h-[26px] w-5 h-5`} />
    ),
  },
  {
    title: "Location",
    path: "/settings/location",
    key: "/settings/location",
    permission: [roleTypes.OWNER],
    icon: (props) => (
      <LocationPinSvg
        {...props}
        className={`2xl:w-[26px] 2xl:h-[26px] w-5 h-5`}
      />
    ),
  },
  {
    title: "Billing & Loyalty",
    path: "/settings/all-device",
    key: "/settings/all-device",
    permission: [roleTypes.OWNER],
    icon: (props) => (
      <AllDeviceSvg
        {...props}
        className={`2xl:w-[26px] 2xl:h-[26px] w-5 h-5`}
      />
    ),
  },
  {
    title: "Preferences",
    path: "/settings/this-device",
    key: "/settings/this-device",
    permission: [roleTypes.OWNER, roleTypes.CLERK, roleTypes.STORE_MANAGER],
    icon: (props) => (
      <ThisDeviceSvg
        {...props}
        className={`2xl:w-[26px] 2xl:h-[26px] w-5 h-5`}
      />
    ),
  },
  {
    title: "Integration",
    path: "/settings/integration/payment",
    key: "/settings/integration",
    permission: [roleTypes.OWNER],
    icon: (props) => (
      <IntegrationSvg
        {...props}
        className={`2xl:w-[26px] 2xl:h-[26px] w-5 h-5`}
      />
    ),
  },
];

// export const reportNavigationTab = [
//   {
//     title: "Metrics",
//     path: "/report/metrics",
//     icon: (props) => <InventoryItemSvg {...props} />,
//   },
//   {
//     title: "History",
//     path: "/report/history",
//     icon: (props) => <HistorySvg {...props} />,
//   },
//   {
//     title: "Customers",
//     path: "/report/customers",
//     icon: (props) => <UserSvg {...props} />,
//   },
//   {
//     title: "Export",
//     path: "",
//     icon: (props) => <ExportSvg {...props} />,
//   },
// ];

export const integrationNavigationTab = [
  {
    icon: (props) => <WalletSvg {...props} />,
    title: "Payment",
    path: "/settings/integration/payment",
  },
  {
    icon: (props) => <CartSettingSvg {...props} />,
    title: "E Commerce",
    path: "/settings/integration/e-commerce",
  },
  // {
  //   icon: (props) => <QuickBookSettingSvg {...props} />,
  //   title: "Quick Books Online",
  //   path: "/settings/integration/quick-book",
  // },
  {
    icon: (props) => <SystemAndLogoutSvg {...props} />,
    title: "System & Logs",
    path: "/settings/integration/system-logout",
  },
];

export const reportNavigationTab = [
  {
    menuTitle: "Sales Report",
  },
  {
    title: "Daily Sales Summary",
    path: "/report/daily-summary",
    // icon: (props) => <DailySummarySvg {...props} />,
  },
  {
    title: "Product Sales",
    path: "/report/sales-by-product",
    // icon: (props) => <DailySummarySvg {...props} />,
  },
  {
    title: "Refunds & Returns",
    path: "/report/refunds-and-returns",
    // icon: (props) => <DailySummarySvg {...props} />,
  },
  {
    menuTitle: "Inventory Report",
  },
  {
    title: "Inventory Valuation",
    path: "/report/valuation-inventory",
    // icon: (props) => <DailySummarySvg {...props} />,
  },
  {
    title: "Stale Inventory",
    path: "/report/stale-inventory",
    // icon: (props) => <DailySummarySvg {...props} />,
  },
  {
    title: "Dead Stock",
    path: "/report/dead-stock",
    // icon: (props) => <DailySummarySvg {...props} />,
  },
  {
    menuTitle: "Financial Report",
  },
  {
    title: "Payment Report",
    path: "/report/payment-activity",
    // icon: (props) => <CashDrawerSvg {...props} />,
  },
  {
    title: "Tax Breakdown",
    path: "/report/tax-breakdown",
    // icon: (props) => <RegisterTransactionSvg {...props} />,
  },
  {
    menuTitle: "Gift Card Reports",
  },
  {
    title: "Gift Card Uses",
    path: "/report/gift-card-uses",
    // icon: (props) => <DailySummarySvg {...props} />,
  },
  {
    title: "Gift Card Sales",
    path: "/report/gift-card-sales",
    // icon: (props) => <DailySummarySvg {...props} />,
  },
  {
    menuTitle: "Store Credit Reports",
  },
  {
    title: "Store Credit",
    path: "/report/store-credit",
    // icon: (props) => <DailySummarySvg {...props} />,
  },
  // {
  //   title: "Gift Card Sales",
  //   path: "/report/gift-card-sales",
  //   // icon: (props) => <DailySummarySvg {...props} />,
  // },
];
