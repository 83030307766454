import React, { useEffect, useLayoutEffect } from "react";
import IntegrationLayout from "../../layout/IntegrationLayout";
import { getSystemAndLogRequest } from "../../store/settings/integration/actions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/common/Loader";
import { t } from "i18next";
import { getUserOrgLocationSetting } from "../../utils/utils";
import moment from "moment";

const IntegrationSystemLogout = () => {
  const { isLoading, systemAndLogsData } = useSelector((store) => ({
    isLoading: store?.integration?.getSystemLogData?.loading,
    systemAndLogsData: store?.integration?.getSystemLogData?.data,
  }));

  const getOrgDetail = getUserOrgLocationSetting();


  const dispatch = useDispatch();

  console.log(systemAndLogsData, "systemAndLogsData...........");

  useLayoutEffect(() => {
    dispatch(getSystemAndLogRequest());
  }, [dispatch]);

  return (
    <IntegrationLayout>
      <div className="grow 2xl:h-[calc(100vh-115px)] h-[calc(100vh-104px)] overflow-y-auto">
        <div className="p-5 bg-white rounded-theme ">
          <div className="text-base font-semibold text-info-500 xl:text-xl lg:text-lg">
            {t("System Logs")}
          </div>

          {!isLoading ? (
            <div className="py-5">
              <div className="flex flex-col">
                <div className="-m-1.5 overflow-x-auto">
                  <div className="p-1.5 min-w-full inline-block align-middle">
                    <div className="overflow-hidden border border-gray-300 rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-200 ">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-5 text-base font-medium text-gray-800 capitalize text-start"
                            >
                              {t("Log In User")}
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-base font-medium text-gray-800 capitalize text-start"
                            >
                              {t("Error")}
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-base font-medium text-gray-800 capitalize text-start"
                            >
                              {t("Error Action & Method")}
                            </th>

                            <th
                              scope="col"
                              className="px-6 py-3 text-base font-medium text-gray-800 capitalize text-start"
                            >
                              {t("Method")}
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-base font-medium text-gray-800 capitalize text-start"
                            >
                              {t("Date")}
                            </th>
                          </tr>
                        </thead>
                        {systemAndLogsData?.map((value) => {
                          return (
                            <tbody className="divide-y divide-gray-200">
                              <tr>
                                <td className="px-6 py-4 text-sm font-medium text-gray-800 capitalize whitespace-nowrap">
                                  {t(value.error_of_user)}
                                </td>
                                <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap ">
                                  {t(value.error)}
                                </td>
                                <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap ">
                                  {t(value.action)}
                                  {t(value.route_method)}
                                </td>

                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                                  {t(value.method)}
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                                  {/* {t(value.createdAt)} */}
                                  {moment(value?.createdAt).format(
                                    `${getOrgDetail?.date_format?.date_format}`
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="py-3">
              <Loader />
            </div>
          )}
          {/* <button className="bg-primary-400 inline-flex text-center text-white border border-primary-400 lg:text-lg text-sm_20 font-medium lg:py-3.5 py-2.5 lg:px-8 px-7 rounded-theme capitalize">
            Clear Local Storage & Log
          </button> */}
        </div>
      </div>
    </IntegrationLayout>
  );
};

export default IntegrationSystemLogout;
