import { Button } from "@material-tailwind/react";
import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { formatCurrencyWithNumberValue } from "../../utils/utils";
import Input from "../common/Input";
import { paymentTypes } from "../../utils/enums";
import { useDispatch, useSelector } from "react-redux";
import { getGiftCardRequest } from "../../store/order/actions";
import { t } from "i18next";
const ApplyGiftCard = ({ setOpenApplyGiftCard, formik, customer }) => {
  const dispatch = useDispatch();
  const { loading, coupon_details } = useSelector((store) => ({
    loading: store?.order?.getGiftCard?.loading,
    coupon_details: store?.order?.getGiftCard?.data,
  }));

  console.log(coupon_details, "coupon_details...........");

  const [giftCardBalance, setGiftCardBalance] = useState(0);

  const handleApplyCouponCode = (code) => {
    dispatch(
      getGiftCardRequest({ customer_id: customer?._id, gift_code: code })
    );
  };

  useEffect(() => {
    if (coupon_details) {
      console.log("come at here direct gift card add");
      setGiftCardBalance(coupon_details?.balance);
    }
  }, [coupon_details]);

  useEffect(() => {
    return setGiftCardBalance(0);
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        payment_type: "giftcard",
        gift_card_code: "",
        amount: "",
      }}
      validationSchema={Yup.object().shape({
        gift_card_code: Yup.string().required("Please enter gift card code"),
        amount: Yup.number()
          .max(giftCardBalance, `Max ${giftCardBalance} allowed.`)
          .required("Please enter amount"),
      })}
      onSubmit={(values, { resetForm }) => {
        console.log({ values });
        let updatedPaymentInfo = [...formik.values.paymentInfo];

        // Check if the payment type already exists in the paymentInfo array
        const existingIndex = updatedPaymentInfo.findIndex(
          (payment) => payment.payment_type === paymentTypes.GIFT_CARD
        );

        if (existingIndex !== -1) {
          // If it exists, update the existing entry
          if (values.amount === 0) {
            updatedPaymentInfo.splice(existingIndex, 1);
          } else {
            updatedPaymentInfo[existingIndex].amount = values.amount;
          }
        } else {
          // If it doesn't exist, add a new entry
          updatedPaymentInfo.push({
            payment_type: paymentTypes.GIFT_CARD,
            gift_card_id: coupon_details._id,
            amount: values.amount,
          });
        }
        formik.setFieldValue("paymentInfo", updatedPaymentInfo);
        setOpenApplyGiftCard(false);
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
        <Form className="flex flex-col gap-y-4" onSubmit={handleSubmit}>
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-bold capitalize">
              {t("Apply Gift Card")}
            </h3>
            <p className="font-medium text-info-100">
              {t("Gift Card Amount :")}
              <span className="font-semibold text-info-100">
                {formatCurrencyWithNumberValue(giftCardBalance)}
              </span>
            </p>
          </div>
          <div className="flex flex-col gap-y-3">
            <div>
              <Input
                type={"text"}
                placeholder={t(`Enter Gift Code`)}
                className={"placeholder:!font-normal placeholder:text-info-100"}
                name="gift_card_code"
                value={values.gift_card_code}
                onChange={(e) => {
                  handleChange(e);
                  setGiftCardBalance(0);
                }}
                onBlur={handleBlur}
              />
              <ErrorMessage
                name="gift_card_code"
                component={"p"}
                className="text-sm text-red-500"
              />
            </div>
            {giftCardBalance !== 0 && (
              <div>
                <Input
                  type={"number"}
                  placeholder={`${formatCurrencyWithNumberValue(0)}`}
                  className={
                    "placeholder:!font-normal placeholder:text-info-100"
                  }
                  name="amount"
                  value={values.amount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="amount"
                  component={"p"}
                  className="text-sm text-red-500"
                />
              </div>
            )}
          </div>
          <div className="flex items-center justify-end gap-x-4">
            <Button
              type="button"
              size="sm"
              onClick={() => {
                setOpenApplyGiftCard(false);
                if (giftCardBalance === 0) {
                  setFieldValue("gift_card_code", "");
                }
              }}
              className="text-sm font-medium capitalize bg-white border border-secondary-400 text-info-400"
            >
              {t("Cancel")}
            </Button>
            {giftCardBalance !== 0 ? (
              <Button
                size="sm"
                type="submit"
                className="text-sm font-medium capitalize border bg-primary-400 border-primary-400"
                disabled={!values.gift_card_code}
              >
                {t("Apply Gift Card")}
              </Button>
            ) : (
              <Button
                size="sm"
                type="button"
                className="text-sm font-medium capitalize border bg-primary-400 border-primary-400"
                onClick={() => handleApplyCouponCode(values.gift_card_code)}
                loading={loading}
                // disabled={loading}
                disabled={loading || !values.gift_card_code}
              >
                {t("Apply Gift Code")}
              </Button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ApplyGiftCard;
