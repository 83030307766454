import { t } from "i18next";
import React from "react";

const Input = ({
  label,
  labelProps,
  type,
  asterisk,
  className,
  iconStart,
  iconEnd,
  error,
  ...rest
}) => {
  return (
    <div className="flex flex-col w-full gap-y-1">
      {label && (
        <label
          className={`${labelProps} text-base font-medium text-secondary-400`}
        >
          {t(label)}
          {asterisk && <span className="text-primary-400">*</span>}
        </label>
      )}
      <div className="relative flex items-center w-full">
        {iconStart && <div className="absolute left-0 pl-4">{iconStart}</div>}
        <input
          type={type}
          className={`w-full border ${error ? "border-red-500" : "border-secondary-200"
            } font-semibold text-info-400 rounded-theme py-2.5 outline-none focus:border-primary-400 focus:shadow transition-all duration-200 ${className}
          ${iconStart ? "pl-11 pr-2" : "pl-3"}
          ${iconEnd ? "pr-11 pl-3" : ""}
          `}
          {...rest}
        />
        {iconEnd && <div className="absolute right-0 pr-4">{iconEnd}</div>}
      </div>
    </div>
  );
};

export default Input;
