import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowLeft,
  faChevronRight,
  faPercent,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, IconButton, Switch } from "@material-tailwind/react";
import { ErrorMessage, useFormik } from "formik";
import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Input from "../../components/common/Input";
import PasswordInput from "../../components/common/PasswordInput";
import SidePanel from "../../components/common/SidePanel";
import LocationsAccess from "../../components/settings/users/LocationsAccess";
import { ProfileUploadSvg } from "../../components/svgs";
import Layout from "../../layout";
import Loader from "../../components/common/Loader";
import {
  createUserRequest,
  getUsersDetailRequest,
  updateUsersDetailRequest,
} from "../../store/settings/user/actions";
import { userState } from "../../store/settings/user/state";
import { generatePin, getUserDetails } from "../../utils/utils";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

const AddUserDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userDetail = getUserDetails();
  const { t } = useTranslation();
  const userId = new URLSearchParams(location.search).get("id");
  const {
    roleList,
    locationListArr,
    createUserLoading,
    singleUserDetailLoading,
    singleUserDetailData,
  } = useSelector((store) => ({
    roleList: store?.setting?.roleList,
    locationListArr: store?.setting?.locationList?.data,
    createUserLoading: store?.user?.createUser?.loading,
    singleUserDetailLoading: store?.user?.singleUserDetail?.loading,
    singleUserDetailData: store?.user?.singleUserDetail?.data,
  }));

  const [openLocationDrawer, setOpenLocationDrawer] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    setUploadedImage(singleUserDetailData?.user_image);
  }, [singleUserDetailData]);

  console.log(singleUserDetailData, "singleUserDetailData");

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    initialValues: {
      username: singleUserDetailData?.username ?? "",
      email: singleUserDetailData?.email ?? "",
      password: "",
      pin: "",
      discount: 0,
      section_permission: singleUserDetailData?.section_permission ?? [],
      location_access_ids:
        locationListArr?.filter((item) =>
          singleUserDetailData?.location_access_ids?.includes(item._id)
        ) ?? [],
      role_id: singleUserDetailData?.role_id?._id ?? "",
      profile_image: singleUserDetailData?.image ?? null,
      location_list_arr: singleUserDetailData
        ? locationListArr.filter(
            (item) =>
              !singleUserDetailData?.location_access_ids.includes(item._id)
          )
        : locationListArr,
      user_id: userId ? true : false,
      // location_access_ids: [],
    },
    validationSchema: Yup.object().shape({
      // profile_image: Yup.mixed().required(t("Please select Image")),
      username: Yup.string().required(t("Please enter username")),
      user_id: Yup.boolean(),
      email: Yup.string()
        .email(t("Please enter your valid email"))
        .required(t("Please enter email")),
      password: Yup.string()
        .min(8, t("Must Contain 8 Characters"))
        .when("user_id", {
          is: true,
          then: () => Yup.string().notRequired(),
          otherwise: () => Yup.string().required(t("Please enter password")),
        }),
      pin: Yup.string()
        .max(6, t("Must Contain 6 Characters"))
        .when("user_id", {
          is: true,
          then: () => Yup.string().notRequired(),
          otherwise: () => Yup.string().required(t("Please enter pin")),
        }),
      role_id: Yup.string().required("Please select employee role"),
      // location_access_ids: Yup.array().min(
      //   1,
      //   "Please select at least one location"
      // ),
      discount: Yup.number()
        .min(0, "Discount cannot be less than 0%")
        .max(100, "Discount cannot be more than 100%"),
      // .required("Discount is required"),
    }),
    validate: (values) => {
      const errors = {};

      if (!userId && values.password !== values.confirmPassword) {
        errors.confirmPassword = t("Passwords must match");
      }
      if (values.discount === "" || values.discount === null) {
        values.discount = 0; // Ensure discount is set to 0 if empty
      }

      return errors;
    },

    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("username", values.username);
      formData.append("email", values.email);
      values.password && formData.append("password", values.password);
      values.pin && formData.append("pin", values.pin);
      formData.append("discount", values.discount);
      values.section_permission.forEach((value, index) => {
        formData.append("section_permission[]", value);
      });
      values.location_access_ids.forEach((value, index) => {
        formData.append("location_access_ids[]", value._id);
      });
      formData.append("role_id", values.role_id);
      typeof values.profile_image === "object" &&
        values.profile_image !== null &&
        formData.append("image", values.profile_image);

      if (userId) {
        dispatch(
          updateUsersDetailRequest({ userId, body: formData, navigate })
        );
      } else {
        dispatch(createUserRequest({ body: formData, navigate }));
      }
    },
  });

  const handleSwitchChange = (checked, roleId) => {
    formik.setFieldValue("role_id", checked ? roleId : "");
  };

  useLayoutEffect(() => {
    if (userId) {
      console.log("come at here");
      dispatch(getUsersDetailRequest({ userId }));
    }
  }, [userId, dispatch]);

  useLayoutEffect(() => {
    if (singleUserDetailData) {
      setUploadedImage(singleUserDetailData?.image);
    }
  }, [singleUserDetailData]);

  useEffect(() => {
    return () =>
      dispatch(userState({ key: "singleUserDetail.data", value: null }));
  }, [dispatch]);

  console.log(formik.errors, "formik.errors................");

  return (
    <Layout>
      <form onSubmit={formik.handleSubmit}>
        <div className="bg-white w-full xl:p-5 lg:p-4 p-3.5 rounded-theme mb-2.5">
          <div className="flex gap-1.5 items-center">
            <IconButton
              type="button"
              variant="text"
              className=""
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon icon={faArrowLeft} fontSize={18} />
            </IconButton>
            <p className="text-info-500 xl:text-xl lg:text-lg text-base font-semibold -mt-0.5">
              {t("Profile Details")}
            </p>
          </div>
        </div>
        <div className="bg-white xl:h-[calc(100vh-206px)] h-[calc(100vh-166px)] w-full py-[30px] xl:px-[50px] lg:px-7 px-5 rounded-theme">
          {singleUserDetailLoading ? (
            <div className="flex items-center justify-center h-full">
              <Loader />
            </div>
          ) : (
            <div className="grid 2xl:grid-cols-4 grid-cols-3 xl:gap-[40px] lg:gap-6 gap-5 ">
              <div className="2xl:col-span-3 col-span-2 overflow-y-auto xl:h-[calc(100vh-252px)] h-[calc(100vh-236px)] pr-2">
                <div className="flex lg:gap-[30px] gap-5 border-b border-secondary-200 w-full pb-[50px]">
                  <div className="lg:max-w-[190px] max-w-[150px] w-full">
                    <p className="text-base font-medium capitalize xl:text-xl lg:text-lg text-info-500">
                      {t("user details")}
                    </p>
                    <p className="font-normal capitalize xl:text-sm lg:text-sm_20 text-sm_18 text-secondary-700">
                      {t("identification information")}
                    </p>
                  </div>
                  <div className="max-w-[425px] w-full flex flex-col gap-y-8">
                    <div>
                      <Input
                        type={"text"}
                        label={t("username")}
                        labelProps={"capitalize text-secondary-500"}
                        className={"bg-secondary-50"}
                        name="username"
                        placeholder={t("Enter Username")}
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.username && formik.touched.username && (
                        <p
                          name="username"
                          className="my-1 text-sm text-red-500"
                        >
                          {formik.errors.username}
                        </p>
                      )}
                    </div>
                    <div>
                      <Input
                        type={"email"}
                        label={t("E-mail Address")}
                        labelProps={"normal-case text-secondary-500"}
                        className={"bg-secondary-50"}
                        placeholder={t("Enter Email Address")}
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.email && formik.touched.email && (
                        <p name="email" className="my-1 text-sm text-red-500">
                          {formik.errors.email}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex lg:gap-[30px] gap-5 border-b border-secondary-200 w-full py-[50px]">
                  <div className="lg:max-w-[190px] max-w-[150px] w-full">
                    <h2 className="text-base font-medium capitalize xl:text-xl lg:text-lg text-info-500">
                      {t("security")}
                    </h2>
                    <p className="font-normal capitalize xl:text-sm lg:text-sm_20 text-sm_18 text-secondary-700">
                      {t("Password must be at least 8 characters")}
                    </p>
                  </div>
                  <div className="max-w-[425px] w-full flex flex-col gap-y-8">
                    <div>
                      <PasswordInput
                        label={t("New Password")}
                        labelProps={"capitalize text-secondary-500"}
                        className={"bg-secondary-50"}
                        name="password"
                        placeholder={t("Enter New Password")}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.password && formik.touched.password && (
                        <p
                          name="password"
                          className="my-1 text-sm text-red-500"
                        >
                          {formik.errors.password}
                        </p>
                      )}
                    </div>

                    {!userId && (
                      <div>
                        <PasswordInput
                          label={t("Confirm Password")}
                          labelProps={"capitalize text-secondary-500"}
                          className={"bg-secondary-50"}
                          name="confirmPassword"
                          placeholder={t("Enter Confirm Password")}
                          value={formik.values.confirmPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.confirmPassword && (
                          <p
                            name="confirmPassword"
                            className="my-1 text-sm text-red-500"
                          >
                            {formik.errors.confirmPassword}
                          </p>
                        )}
                      </div>
                    )}
                    <div className="relative">
                      <Input
                        type={"number"}
                        label={t("Pin 6 Digit")}
                        labelProps={"normal-case text-secondary-500"}
                        className={"bg-secondary-50"}
                        name="pin"
                        placeholder={t("Enter 6 Digit Pin")}
                        value={formik.values.pin}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        iconEnd={
                          <IconButton
                            size="sm"
                            variant="text"
                            className="-mr-2.5"
                            onClick={() => {
                              const pin = generatePin();
                              formik.setFieldValue("pin", pin);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faRotateRight}
                              fontSize={16}
                            />
                          </IconButton>
                        }
                      />
                      {formik.errors.pin && formik.touched.pin && (
                        <p name="pin" className="my-1 text-sm text-red-500">
                          {formik.errors.pin}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex lg:gap-[30px] gap-5 border-b border-secondary-200 w-full py-[50px]">
                  <div className="lg:max-w-[190px] max-w-[150px] w-full">
                    <h2 className="text-base font-medium capitalize xl:text-xl lg:text-lg text-info-500">
                      {t("access rights")}
                    </h2>
                    <p className="font-normal capitalize xl:text-sm lg:text-sm_20 text-sm_18 text-secondary-700">
                      {t("Role-Based Access Control")}
                    </p>
                  </div>
                  <div className="max-w-[425px] w-full flex flex-col gap-y-8">
                    <div>
                      <Input
                        type="number"
                        label={t("discount")}
                        labelProps={"capitalize text-secondary-500"}
                        className={"bg-secondary-50"}
                        name="discount"
                        placeholder={t("0")}
                        value={formik.values.discount}
                        onChange={formik.handleChange}
                        onBlur={(e) => {
                          if (e.target.value === "") {
                            formik.setFieldValue("discount", 0); // Reset to 0 on blur if empty
                          }
                          formik.handleBlur(e);
                        }}
                        min={0}
                        max={100}
                        iconEnd={
                          <FontAwesomeIcon icon={faPercent} fontSize={18} />
                        }
                      />
                    </div>

                    <div className="xl:mb-9 lg:mb-8 mb-7">
                      <p className="block xl:text-base lg:text-base text-sm_18 text-secondary-500 xl:pb-6 lg:pb-3 pb-2.5 font-medium capitalize">
                        {t("employee role")}
                      </p>
                      {roleList?.map((item, index) => (
                        <div
                          key={index}
                          className="flex items-center justify-between pb-[30px]"
                        >
                          <p className="block font-medium capitalize xl:text-lg lg:text-base text-sm_18 text-info-400">
                            {t(item.role)}
                          </p>
                          <Switch
                            ripple={false}
                            className="w-full h-full bg-secondary-200/40 checked:bg-primary-400"
                            containerProps={{
                              className: "w-11 h-6",
                            }}
                            onChange={(checked) =>
                              handleSwitchChange(checked, item._id)
                            }
                            circleProps={{
                              className: "before:hidden left-0.5 border-none",
                            }}
                            checked={formik.values.role_id === String(item._id)}
                          />
                        </div>
                      ))}
                      {formik.errors.role_id && formik.touched.role_id && (
                        <p name="pin" className="text-sm text-red-500 ">
                          {formik.errors.role_id}
                        </p>
                      )}
                    </div>
                    {userDetail?.section_permission?.length > 0 && (
                      <div>
                        <p className="block xl:text-base lg:text-base text-sm_18 text-secondary-500 xl:pb-6 lg:pb-3 pb-2.5 font-medium capitalize">
                          {t("sections")}
                        </p>
                        {userDetail?.section_permission
                          .filter(
                            (item) =>
                              item !== "register" && item !== "transactions"
                          )
                          ?.map((item, index) => (
                            <div
                              key={index}
                              className="flex justify-between items-center xl:pb-[30px] lg:pb-6 pb-5"
                            >
                              <p className="block font-medium capitalize xl:text-lg lg:text-base text-sm_18 text-info-400">
                                {t(item)}
                              </p>
                              <Switch
                                ripple={false}
                                className="w-full h-full bg-secondary-200/40 checked:bg-primary-400"
                                containerProps={{
                                  className: "w-11 h-6",
                                }}
                                circleProps={{
                                  className:
                                    "before:hidden left-0.5 border-none",
                                }}
                                checked={formik.values.section_permission.includes(
                                  item
                                )}
                                onChange={() => {
                                  const updatedPermissions = formik.values.section_permission.includes(
                                    item
                                  )
                                    ? formik.values.section_permission.filter(
                                        (permission) => permission !== item
                                      )
                                    : [
                                        ...formik.values.section_permission,
                                        item,
                                      ];

                                  formik.setFieldValue(
                                    "section_permission",
                                    updatedPermissions
                                  );
                                }}
                              />
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex lg:gap-[30px] gap-5 border-b border-secondary-200 py-[50px] w-full">
                  <div className="lg:max-w-[190px] max-w-[150px] w-full">
                    <h2 className="text-base font-medium capitalize xl:text-xl lg:text-lg text-info-500">
                      {t("locations access")}
                    </h2>
                  </div>
                  <div className="max-w-[425px] w-full">
                    <Button
                      type="button"
                      fullWidth
                      className="flex items-center justify-between text-base font-medium capitalize shadow-none bg-secondary-50 text-info-600 rounded-theme hover:shadow-none"
                      onClick={() => setOpenLocationDrawer(true)}
                    >
                      {formik.values.location_access_ids.length > 0 ? (
                        <span>
                          {formik.values.location_access_ids.length}{" "}
                          {t("Locations")}
                        </span>
                      ) : (
                        <span className="text-[#94A3B8] text-[14px] ">
                          {t("Set ")}{" "}
                          <span className="lowercase">
                            {t("Employee Location")}
                          </span>
                        </span>
                      )}
                      <FontAwesomeIcon icon={faChevronRight} fontSize={16} />
                    </Button>
                    {formik.touched.location_access_ids &&
                      formik.values.location_access_ids.length === 0 && (
                        <div className="mt-2 text-sm text-red-500">
                          {t("Location is required")}
                        </div>
                      )}
                  </div>
                </div>
                <div className="lg:max-w-[645px] max-w-[510px] flex lg:gap-5 gap-4 justify-end pt-8 pb-2">
                  <Button
                    type="reset"
                    className="text-base font-medium capitalize bg-white border border-primary-400 text-primary-400 min-w-52"
                    onClick={() => navigate(-1)}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    type="submit"
                    className="flex items-center justify-center text-base font-medium capitalize border border-primary-400 bg-primary-400 min-w-52"
                    loading={createUserLoading}
                    disabled={createUserLoading}
                  >
                    {userId ? t("Update User") : t("Add New User")}
                  </Button>
                </div>
              </div>
              <div className="flex flex-col gap-y-2">
                {/* Add user  */}
                <div className="relative w-[196px] h-[196px] bg-secondary-50 rounded-theme flex justify-center items-center">
                  <label
                    htmlFor="profile-input"
                    className="flex flex-col items-center justify-center w-full h-full overflow-hidden rounded-theme gap-y-2"
                  >
                    <input
                      type="file"
                      id="profile-input"
                      name="profile_image"
                      ref={fileInputRef} // Step 2: Attach ref to input
                      onChange={(event) => {
                        if (event.target.files.length > 0) {
                          formik.setFieldValue(
                            "profile_image",
                            event.target.files[0]
                          );
                          setUploadedImage(
                            URL.createObjectURL(event.target.files[0])
                          );
                        }
                      }}
                      onBlur={formik.handleBlur}
                      className="!absolute w-full h-full top-0 left-0 opacity-0"
                    />
                    {uploadedImage ? (
                      <img
                        src={uploadedImage}
                        alt="user-profile"
                        className="object-cover w-full h-full"
                      />
                    ) : (
                      <Fragment>
                        <ProfileUploadSvg />
                        <p className="capitalize text-sm_18 text-secondary-400">
                          {t("Upload Image")}
                        </p>
                      </Fragment>
                    )}
                  </label>
                  {uploadedImage && (
                    <label
                      htmlFor="profile-input"
                      className="!absolute -bottom-3 -right-3 cursor-pointer flex justify-center bg-white items-center w-9 h-9 rounded-full shadow-[0px_4px_14px_0px_#00000040]"
                    >
                      <FontAwesomeIcon icon={faPenToSquare} fontSize={16} />
                    </label>
                  )}
                </div>
                {!uploadedImage && (
                  <Button
                    className="w-[196px] h-[60px] text-[18px] capitalize text-[#94A3B8] bg-transparent shadow-none hover:shadow-none border-2 border-dashed p"
                    onClick={handleButtonClick}
                  >
                    {t("upload image")}
                  </Button>
                )}
                {/* {formik.errors.profile_image && (
                  <p className="text-sm text-red-500">
                    {formik.errors.profile_image}
                  </p>
                )} */}
              </div>
            </div>
          )}
        </div>
      </form>

      <SidePanel
        open={openLocationDrawer}
        handler={() => setOpenLocationDrawer(false)}
        width="w-[450px]"
      >
        <LocationsAccess
          formik={formik}
          userDetail={userDetail}
          closeHandler={() => setOpenLocationDrawer(false)}
        />
      </SidePanel>
    </Layout>
  );
};

export default AddUserDetails;
