import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  getLocationData: {
    loading: false,
    data: null,
    error: false,
  },
  updateLocation: {
    loading: false,
    data: null,
    error: false,
  },
  getOrgLocationList: {
    loading: false,
    data: null,
    error: false,
  },
  updateOrgLocation: {
    loading: false,
    data: null,
    error: false,
  },
};

export const locationSlice = createSlice({
  name: "location",
  initialState: initialState,
  reducers: {
    locationState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const { locationState } = locationSlice.actions;
export default locationSlice.reducer;
