import { createSlice } from "@reduxjs/toolkit"
import _ from "lodash";


const initialState = {
    importFile:{
        loading: false,
        data: null,
        error: null
    }
}

export const importSlice = createSlice({
  name: "import",
  initialState: initialState,
  reducers: {
    importState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const {importState}= importSlice.actions;
export default importSlice.reducer;