import React, { useState } from "react";
// import DownloadIcon from "../../assets/icon/download.svg";
// import ImportFileIcon from "../../assets/icon/import-file.svg";
// import SuccessfullyUploadIcon from "../../assets/icon/successfully-upload-icon.svg";
import Layout from "../../layout";
import { Progress } from "@material-tailwind/react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { importFile } from "../../store/inventory/import/action";
import { json2csv } from "json-2-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import {
  DownloadIcon,
  FileNotFound,
  ImportFileErrorIcon,
  ImportFileIcon,
  SuccessfullyUploadFileIcon,
} from "../../components/svgs";

const UploadFile = ({ selectedCategory }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [dragActive, setDragActive] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [notImportedProducts, setnotImportedProducts] = useState([]);
  const [rowDetails, setRowdetails] = useState(null);
  const [errorPopup, setErrorPopup] = useState(true);
  const [showHelpSection, setShowHelpSection] = useState(true); // State to control the visibility of the help section

  const { importFileLoading, importFileError } = useSelector((store) => ({
    importFileLoading: store?.import?.importFile?.loading,
    importFileError: store?.import?.importFile?.error,
  }));

  console.log(importFileError, "importFileError................");

  // Validation schema for file
  const validationSchema = Yup.object({
    inventory_import: Yup.mixed()
      .required("A file is required")
      .test("fileFormat", "Unsupported Format", (value) => {
        if (value) {
          console.log(value, "valll");
          const fileType = value.type;
          return fileType === "text/csv";
        }
        return false;
      }),
    // .test("fileSize", "File Size is too large", (value) => {
    //   if (value) {
    //     return value.size <= 5 * 1024 * 1024;
    //   }
    //   return false;
    // }),
  });

  // formik
  const formik = useFormik({
    initialValues: {
      inventory_import: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values, "valll");
      const formData = new FormData();
      formData.append("inventory_import", values.inventory_import);
    },
  });

  // Convert JSON to CSV and download
  const downloadCSV = async (data) => {
    try {
      const csv = await json2csv(data);

      // Create a blob with the CSV data
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      // Create a link element
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // Create a URL for the blob and set it as the href attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "product.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (err) {
      console.error("Error converting JSON to CSV:", err);
    }
  };

  // call API onChange
  const handleFileChange = (event) => {
    const selectedFile =
      event.target?.files?.[0] || event.dataTransfer?.files?.[0];
    if (selectedFile) {
      formik.setFieldValue("inventory_import", selectedFile);
      const formData = new FormData();
      formData.append("inventory_import", selectedFile);
      dispatch(
        importFile({
          body: formData,
          onUploadProgress: (progress) => {
            setUploadProgress(progress);
            if (progress === 100) {
              setUploadSuccess(true);
            }
          },
        })
      ).then((response) => {
        const notImportFile = response?.data?.notImportedProducts;
        const rowData = response?.data?.ResponseData;
        console.log(rowData, "row data");
        setRowdetails(rowData || "");
        console.log(notImportFile);
        setnotImportedProducts(notImportFile || []);
      });
    }
  };

  // Drag and drop functionality
  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);

    const file = event.dataTransfer?.files?.[0];
    if (file) {
      formik.setFieldValue("inventory_import", file);
      handleFileChange({ dataTransfer: { files: [file] } });
      console.log("File dropped:", file);
    }
  };

  return (
    <Layout>
      <div className="bg-white lg:p-4 p-3.5 rounded-theme flex justify-between items-center gap-2 lg:mb-5 mb-4">
        <div>
          <p className="text-info-500 2xl:text-xl text-base font-semibold 2xl:pb-2.5 pb-1.5">
            {t("Need Assistance?")}
          </p>
          <p className="max-w-[696px] 2xl:text-lg text-sm_18 text-secondary-400 ">
            {t(
              "Explore our comprehensive Help Guides and CSV Examples to ensure a smooth import process."
            )}
          </p>
        </div>
        <div className="flex items-center gap-5">
          <a href="https://s3.amazonaws.com/quetzalpos.bucket.dev/sample_file/Quetzal_POS_Inventory_Import_Guide.docx">
            <div className="flex items-center justify-center gap-2 p-4 rounded-theme bg-secondary-100">
              <p className="flex-1 text-sm font-normal 2xl:text-lg">
                {t("Download Instructions")}
              </p>
              <DownloadIcon className={`2xl:w-6 2xl:h-6 w-4 h-4`} />
            </div>
          </a>
          <a
            href="https://s3.amazonaws.com/quetzalpos.bucket.dev/sample_file/Sample_Import.csv"
            target="_blank"
            rel="noreferrer"
          >
            <div className="flex items-center justify-center gap-2 p-4 rounded-theme bg-secondary-100">
              <p className="flex-1 text-sm font-normal 2xl:text-lg">
                {t("Download Sample CSV")}
              </p>
              <DownloadIcon className={`2xl:w-6 2xl:h-6 w-4 h-4`} />
            </div>
          </a>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 lg:grid-cols-11 lg:gap-5">
        {/* Upload File  */}
        <div className="bg-white lg:col-span-6 xl:p-5 lg:p-4 p-3.5 rounded-theme">
          <p className="pb-5 text-base font-bold 2xl:text-2xl text-info-500">
            {t("Upload Inventory CSV")}
          </p>
          <form onSubmit={formik.handleSubmit}>
            {/* Upload file section */}
            {!uploadSuccess && !importFileError && (
              <div
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                className={`border border-dashed border-info-50 bg-info-50/5 rounded-theme flex flex-col justify-center items-center px-5 xl:py-20 lg:py-16 py-14 mb-6 ${
                  dragActive ? "border-primary-400 " : ""
                }`}
              >
                <div className="mb-4 xl:mb-6 lg:mb-5">
                  <ImportFileIcon />
                </div>
                <label className="max-w-[308px] text-center lg:text-base text-sm_18 text-secondary-600 font-medium xl:pb-[18px] pb-4">
                  {t("Drag and drop your inventory file here, or")}
                  <a className="block font-semibold underline text-primary-400 lg:text-base text-sm_18 underline-offset-2">
                    {t("Click to browse")}
                  </a>
                  <input
                    type="file"
                    name="inventory_import"
                    onChange={handleFileChange}
                    accept=".csv"
                    className="opacity-0"
                  />
                  {formik.errors.inventory_import &&
                    formik.touched.inventory_import && (
                      <p className="text-sm text-red-500">
                        {formik.errors.inventory_import}
                      </p>
                    )}
                </label>
              </div>
            )}
            {/* Progressbar section */}
            {importFileLoading && uploadProgress && (
              <div className="flex flex-col items-center justify-center px-5 mb-6 border border-dashed border-primary-400 bg-primary-400/5 rounded-theme xl:py-20 lg:py-16 py-14">
                <div className="mb-4 xl:mb-6 lg:mb-5">
                  <ImportFileIcon />
                </div>
                <div
                  value={uploadProgress}
                  className={`  ${
                    !selectedCategory ? "bg-primary-400" : ""
                  } w-52 h-1.5 rounded-full mb-2`}
                />
                <p className="max-w-[300px] text-center lg:text-base text-sm_18 text-secondary-600 font-medium xl:pb-[18px] pb-4">
                  {t("Importing file....")}
                </p>
              </div>
            )}
            {/* Upload success section */}
            {!importFileLoading && uploadSuccess && !importFileError && (
              <div className="flex flex-col items-center justify-center px-5 mb-6 border border-dashed border-success rounded-theme xl:py-20 lg:py-16 py-14">
                <div className="mb-4 xl:mb-6 lg:mb-5">
                  <SuccessfullyUploadFileIcon />
                </div>
                <p className="text-info-500 pb-3.5 lg:text-base text-sm_18 font-bold text-center">
                  {t("Your CSV file has been successfully imported.")}
                </p>
                <p className="max-w-[300px] text-center lg:text-base text-sm_18 text-secondary-400">
                  {t(
                    "Check your product listings to ensure the imported data is accurate."
                  )}
                </p>
              </div>
            )}
            {/* Invalid file format section */}
            {importFileError && !importFileLoading && (
              <div
                className={`border border-dashed border-danger bg-info-50/5 rounded-theme flex flex-col justify-center items-center px-5 xl:py-20 lg:py-16 py-14 mb-6 ${
                  dragActive ? "border-primary-400" : ""
                }`}
              >
                <div className="mb-4 xl:mb-6 lg:mb-5">
                  <ImportFileIcon />
                </div>
                <label className="max-w-[300px] text-center lg:text-base text-sm_18 text-secondary-600 font-medium xl:pb-[18px] pb-4">
                  <span className="text-center lg:text-base text-sm_18 text-danger font-medium xl:pb-[18px] pb-4">
                    {t("Unsupported Format")}
                  </span>
                  <label className="max-w-[300px] text-center lg:text-base text-sm_18 text-secondary-600 font-medium xl:pb-[18px] pb-4">
                    <a className="block font-semibold underline lg:text-base text-sm_18 underline-offset-2">
                      {t("Change")}
                    </a>
                    <input
                      type="file"
                      name="inventory_import"
                      onChange={handleFileChange}
                      accept=".csv"
                      className="opacity-0"
                    />
                    {formik.errors.inventory_import &&
                      formik.touched.inventory_import && (
                        <p className="text-sm text-red-500">
                          {formik.errors.inventory_import}
                        </p>
                      )}
                  </label>
                </label>
              </div>
            )}
          </form>
          {/* {importFileError && errorPopup && !importFileLoading && (
            <div className="px-2.5 py-2 border border-danger/65 bg-danger/10 rounded-base mb-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <div className="inline-block border rounded-full text-danger border-danger">
                    <ImportFileErrorIcon />
                  </div>
                  <p className="text-lg font-normal text-danger">
                    {t("Invalid file type. Please import a valid Csv file.")}
                  </p>
                </div>
                <FontAwesomeIcon
                  icon={faClose}
                  className="text-danger"
                  onClick={() => setErrorPopup(false)}
                />
              </div>
            </div>
          )} */}
          <div className="flex items-center justify-between gap-2 mb-2 lg:mb-4">
            <p className="font-medium text-center xl:text-lg lg:text-base text-sm_18 text-secondary-500">
              {t("Supported format:")}
              <span className="pl-1 font-normal ">{t("CSV")}</span>
            </p>
            <p className="font-medium text-center xl:text-lg lg:text-base text-sm_18 text-secondary-500">
              {t("Maximum size:")}
              <span className="pl-1 font-normal">{t("5 MB")}</span>
            </p>
          </div>
        </div>
        {/* Import csv summery section */}
        {!importFileLoading && uploadSuccess ? (
          <div className="bg-white lg:col-span-5 xl:p-5 lg:p-4 p-3.5 rounded-theme">
            <p
              className={`2xl:text-2xl text-base font-bold text-info-500 ${
                notImportedProducts.length > 0
                  ? "xl:pb-2 pb-1"
                  : "xl:pb-9 lg:pb-7 pb-5"
              }`}
            >
              {t("CSV Import Summary")}
            </p>
            {notImportedProducts.length > 0 && (
              <div className="flex items-end justify-between px-4 py-5 rounded-theme bg-secondary-100">
                <div>
                  <p className="pb-4 text-sm font-bold lg:text-base">
                    {t("Failed while importing :")}
                  </p>
                  <span className="font-medium text-secondary-400 ">
                    {t("Products.csv")}
                  </span>
                </div>
                <div
                  onClick={() => downloadCSV(notImportedProducts)}
                  className="p-2 cursor-pointer bg-white rounded-sm shadow-[0px_4px_10px_0px_#F470480D]"
                >
                  <DownloadIcon className={`w-6 h-6`} />
                </div>
              </div>
            )}
            <div className="flex justify-between py-4 border-b border-dashed border-secondary-200">
              <div>
                <p className="text-base font-semibold text-info-500 xl:text-xl lg:text-lg">
                  {t("Products details")}
                </p>
              </div>
            </div>
            <div className="py-5 border-b border-dashed lg:py-6 border-secondary-200">
              <div className="flex justify-between items-center gap-2 lg:mb-[18px] mb-4">
                <p className="font-medium text-secondary-400 xl:text-lg lg:text-base text-sm_18">
                  {t("Total products in file")}
                </p>
                <p className="font-medium text-right xl:text-lg lg:text-base text-sm_18 text-info-500">
                  {importFileError ? 0 : rowDetails?.TotalRows}
                </p>
              </div>
              <div className="flex justify-between items-center gap-2 lg:mb-[18px] mb-4">
                <p className="font-medium text-secondary-400 xl:text-lg lg:text-base text-sm_18">
                  {t("Duplicated products in file")}
                </p>
                <p className="font-medium text-right xl:text-lg lg:text-base text-sm_18 text-info-500">
                  {importFileError ? 0 : rowDetails?.DuplicatedRows}
                </p>
              </div>
              <div className="flex items-center justify-between gap-2">
                <p className="font-medium text-secondary-400 xl:text-lg lg:text-base text-sm_18">
                  {t("Not imported products")}
                </p>
                <p className="font-medium text-right xl:text-lg lg:text-base text-sm_18 text-info-500">
                  {importFileError ? 0 : rowDetails?.NotImportedRows}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between gap-2 my-4 font-semibold text-primary-400 lg:my-6">
              <p className="text-base xl:text-xl lg:text-lg">
                {t("Total products imported")}
              </p>
              <p className="text-base text-right xl:text-xl lg:text-lg">
                {importFileError ? 0 : rowDetails?.ImportedRows}
              </p>
            </div>
          </div>
        ) : (
          <div className="bg-white lg:col-span-5 xl:p-5 lg:p-4 p-3.5 rounded-theme ">
            <p className="pb-1 text-base font-bold 2xl:text-2xl text-info-500 xl:pb-2">
              {t("CSV Import Summary")}
            </p>
            <div className="flex items-center justify-center">
              <div>
                <FileNotFound />
                <p className="block font-bold text-center 2xl:text-lg text-base_24 text-secondary-600/50 max-w-[330px] justify-center">
                  {t(
                    "Once the file is uploaded, an import summary will appear here."
                  )}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default UploadFile;
