import { toast } from "react-toastify";
import { importState } from "./state";
import AxiosRequest from "../../../AxiosRequest";

/* -------------------------------------------------------------------------- */
/*                               Import file API                              */
/* -------------------------------------------------------------------------- */
export const importFile =
  ({ body, onUploadProgress }) =>
    async (dispatch) => {
      try {
        dispatch(importState({ key: "importFile.loading", value: true }));
        const response = await AxiosRequest.post("/user/inventory/import", body, {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            onUploadProgress(progress);
          },
        });
        const data = await response;
        if (data) {
          console.log("come into successfull state")
          toast.success(response.data.message);
          dispatch(importState({ key: "importFile.loading", value: false }));
          dispatch(importState({ key: "importFile.error", value: null }));
        }
        return response.data;
      } catch (error) {

        console.log("come into error state")
        toast.error(error);
        dispatch(importState({ key: "importFile.loading", value: false }));
        dispatch(importState({ key: "importFile.error", value: error }));
      }
    };
