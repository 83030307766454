import React, { useEffect, useLayoutEffect, useState } from "react";
import Logo from "../../assets/logo/quetzal-logo.svg";
import SearchIcon from "../../assets/icon/search.svg";
import CalendarIcon from "../../assets/icon/calendar.svg";
import PrinterIcon from "../../assets/icon/printer-icon.svg";
import MailIcon from "../../assets/icon/mail-icon.svg";
import Layout from "../../layout";
import { useDispatch, useSelector } from "react-redux";
import { formatCurrencyWithNumberValue, useDebounce } from "../../utils/utils";
import { getTransactionListRequest } from "../../store/register/transaction/action";
import Input from "../../components/common/Input";
import DataTable from "../../components/common/DataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  CashPaymentIcon,
  MasterCardIcon,
  VisaCardIcon,
} from "../../components/svgs";
import { IconButton } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import SidePanel from "../../components/common/SidePanel";
import Receipt from "../../components/transaction/Receipt";
import { useTranslation } from "react-i18next";

const Transaction = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { isLoading, transactionsData, paymentData, totalPages } = useSelector(
    (store) => ({
      isLoading: store?.transaction?.transactionList?.loading,
      transactionsData: store?.transaction?.transactionList?.data,
      paymentData: store?.transaction?.transactionList?.payments,
      totalPages: store?.transaction?.transactionList?.totalPages,
    })
  );

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [search, setSearch] = useState("");
  const searchValue = useDebounce(search);
  const [selectedDate, setSelectedDate] = useState("");
  const [paymentsData, setPaymentsData] = useState(null);
  const [openReceiptDrawer, setOpenReceiptDrawer] = useState(false);
  const [orderId, setOrderId] = useState();
  console.log(orderId, "iddd");

  const handleSearch = (event) => {
    setSearch(event.target.value);
    if (page > 1) setPage(1);
  };

  const handlePageChange = (page) => {
    setPage(page.selected + 1);
  };

  const handlePerPageChange = (limit) => {
    setLimit(limit);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  useEffect(() => {
    if (page === 1 && paymentData) {
      setPaymentsData(paymentData);
    }
  }, [page, paymentData]);

  // Dispatcj get transactions API
  useLayoutEffect(() => {
    dispatch(
      getTransactionListRequest({ page, limit, search, date: selectedDate })
    );
  }, [dispatch, page, limit, searchValue, selectedDate]);

  const columns = () => [
    {
      Header: "Order",
      key: "",
      render: (row) => (
        <div>
          <p className="text-lg font-medium">{row.order_no}</p>
        </div>
      ),
    },
    // {
    //   Header: "Transaction Id",
    //   key: "",
    //   render: (row) => <div>{/* <p>{row._id}</p> */}</div>,
    // },
    {
      Header: "Total Price",
      key: "",
      render: (row) => (
        <p className="text-lg font-medium text-secondary-400">
          {formatCurrencyWithNumberValue(row.totalPrice)}
        </p>
      ),
    },
    {
      Header: "Created",
      key: "",
      render: (row) => (
        <p className="text-lg font-medium text-secondary-400">
          {row.created_time}
        </p>
      ),
    },
    {
      Header: "Payment",
      key: "",
      render: (row) => (
        <p className="text-lg font-medium capitalize text-secondary-400">
          {row.payment_type}
        </p>
      ),
    },
  ];

  return (
    <Layout>
      <div>
        <div className="bg-white rounded-theme xl:py-[30px] lg:px-5 lg:py-4 p-3 mb-4">
          <div className="flex items-center gap-1">
            <IconButton
              type="button"
              variant="text"
              className=""
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon icon={faArrowLeft} fontSize={25} />
            </IconButton>
            <p className="text-base font-semibold text-info-400 xl:text-2xl lg:text-xl">
              {t("Transaction")}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-5 mb-4">
          <div className="py-3 px-2.5 bg-white rounded-theme space-y-3">
            <p className="text-base font-normal capitalize text-info-100">
              {t("total payment")}
            </p>
            <p className="text-2xl font-bold text-primary-400">
              {formatCurrencyWithNumberValue(paymentsData?.totalSum || 0)}
            </p>
          </div>
          {paymentsData?.paymentTypes?.map((item, index) => (
            <div className="py-3 px-2.5 bg-white rounded-theme space-y-3">
              <p className="text-base font-normal capitalize text-info-100">
                {item?.payment_type + " " + t("Payment")}
              </p>
              <div className="flex items-center justify-between gap-2">
                <p className="text-2xl font-bold text-info-200">
                  {formatCurrencyWithNumberValue(item?.total)}
                </p>
                <div>
                  {item?.payment_type === "cash" ? (
                    // <CashPaymentIcon />
                    <> </>
                  ) : item?.payment_type === "Store Credit" ? (
                    ""
                  ) : item?.payment_type === "giftcard" ? (
                    ""
                  ) : item?.payment_type === "debit" ? (
                    <> </>
                  ) : // <VisaCardIcon />
                  item?.payment_type === "credit" ? (
                    <> </>
                  ) : (
                    // <MasterCardIcon />
                    ""
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="w-full">
          <div className="col-span-3 2xl:col-span-5 xl:col-span-4">
            <div className="p-5 bg-white rounded-theme">
              <div className="flex flex-wrap items-center justify-between w-full gap-4 lg:flex-nowrap">
                <div>
                  <Input
                    type={"search"}
                    className={
                      "bg-secondary-50 w-[412px] placeholder:font-medium placeholder:text-secondary-500 text-base"
                    }
                    iconStart={
                      <FontAwesomeIcon
                        icon={faSearch}
                        fontSize={16}
                        className="text-secondary-500"
                      />
                    }
                    placeholder={t("Search For Order")}
                    value={search}
                    onChange={handleSearch}
                  />
                </div>
                <div>
                  <Input
                    type={"date"}
                    className={
                      "bg-secondary-50 xl:max-w-[248px] lg:max-w-[190px] max-w-[170px] placeholder:font-medium placeholder:text-secondary-500 text-base px-2"
                    }
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                </div>
              </div>
              {/* Table */}
              <div className="py-4">
                <DataTable
                  columns={columns()}
                  data={transactionsData}
                  search={""}
                  maxHeight="max-h-[calc(100vh-190px)]"
                  loading={isLoading}
                  page={page}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                  handlePerPageChange={handlePerPageChange}
                  // handler={() => {
                  //   setOpenReceiptDrawer(true);
                  //   setOrderId(transactionsData?._id);
                  // }}
                  handler={(row) => {
                    setOpenReceiptDrawer(true);
                    setOrderId(row._id);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Receipt */}
        <SidePanel
          open={openReceiptDrawer}
          handler={() => setOpenReceiptDrawer(false)}
          width="w-[450px]"
        >
          <Receipt
            openReceiptDrawer={openReceiptDrawer}
            handler={() => setOpenReceiptDrawer(false)}
            orderId={orderId}
          />
        </SidePanel>
      </div>
    </Layout>
  );
};

export default Transaction;
