import {
  faEye,
  faPenToSquare,
  faTrashAlt,
} from "@fortawesome/free-regular-svg-icons";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, IconButton } from "@material-tailwind/react";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DataTable from "../../components/common/DataTable";
import Input from "../../components/common/Input";
import Modal from "../../components/common/Modal";
import {
  DeleteUserIllusSvg,
  ExportIcon,
  ManageDeleteProductIcon,
} from "../../components/svgs";
import Layout from "../../layout/index";
import {
  deleteProductRequest,
  getProducts,
} from "../../store/inventory/product/actions";
import { productState } from "../../store/inventory/product/state";
import { useDebounce } from "../../utils/utils";

const Inventory = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    isLoading,
    productData,
    totalPages,
    deleteLoading,
    deleteProduct,
  } = useSelector((store) => ({
    isLoading: store?.product?.productList?.loading,
    productData: store?.product?.productList?.data,
    totalPages: store?.product?.productList?.totalPages,
    deleteLoading: store?.product?.deleteProduct?.loading,
    deleteProduct: store?.product?.deleteProduct?.data,
  }));

  console.log(productData, "productData...");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const searchValue = useDebounce(search); //for search
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false); //open delete confirmation dialog
  const [selectedProductId, setSelectedProductId] = useState(null); //product id

  // Define columns for table
  const columns = () => [
    {
      Header: t("Items"),
      key: "",
      render: (row) => (
        <div className="flex items-center gap-4">
          <div className="w-10 h-10 overflow-hidden rounded-base">
            <img
              src={row.image}
              alt="product-img"
              className="object-cover w-full h-full"
            />
          </div>
          <p className="font-medium capitalize 2xl:text-lg_30 xl:text-base_24 text-info-400">
            {row.product_name}
          </p>
        </div>
      ),
    },
    {
      Header: t("SKU"),
      key: "sku",
    },
    {
      Header: t("Loyalty Points"),
      key: "",
      render: (row) => (
        <div className="capitalize">{t(row?.loyalty_points_type)}</div>
      ),
    },

    {
      Header: t("Quantity"),
      key: "total_quantity",
    },
    {
      Header: "Manage",
      key: "",
      render: (rowData) => {
        return (
          <div className="flex items-center 2xl:gap-x-4">
            <IconButton
              size="sm"
              type="button"
              variant="text"
              className="text-secondary-400"
              onClick={() =>
                navigate(`/inventory/items/view-product/${rowData?._id}`)
              }
            >
              <FontAwesomeIcon icon={faEye} className="text-sm 2xl:text-lg" />
            </IconButton>
            <IconButton
              size="sm"
              type="button"
              variant="text"
              className="text-primary-400"
              onClick={() =>
                navigate(`/inventory/items/form?id=${rowData?._id}`)
              }
            >
              <FontAwesomeIcon
                icon={faPenToSquare}
                className="text-sm 2xl:text-lg"
              />
            </IconButton>
            <IconButton
              size="sm"
              type="button"
              variant="text"
              className="text-red-500"
              onClick={() => {
                setOpenDeleteConfirmation(true);
                setSelectedProductId(rowData?._id);
              }}
            >
              {/* <FontAwesomeIcon
                icon={faTrashAlt}
                className="text-sm 2xl:text-lg"
              /> */}
              <ManageDeleteProductIcon className="w-4 h-4" />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const handleSearch = (event) => {
    setSearch(event.target.value);
    if (page > 1) setPage(1);
  };

  const handlePageChange = (page) => {
    setPage(page.selected + 1);
  };

  const handlePerPageChange = (limit) => {
    setLimit(limit);
  };

  useEffect(() => {
    if (deleteProduct !== null) {
      dispatch(getProducts({ page, limit, search }));
      dispatch(productState({ key: "deleteProduct.data", value: null }));
    }
  }, [deleteProduct, dispatch]);

  // Dispatch productList
  useLayoutEffect(() => {
    dispatch(getProducts({ page, limit, search }));
  }, [dispatch, page, limit, searchValue]);

  return (
    <Layout>
      {productData.length < 0 && !search ? (
        <div>
          <div className="bg-white w-full xl:p-5 lg:p-4 p-3.5 rounded-theme xl:h-[calc(100vh-126px)] h-[calc(100vh-100px)]">
            <div className="flex flex-col items-center justify-center h-full">
              <p className="max-w-[350px] w-full mx-auto xl:text-lg lg:text-base text-sm_18 font-medium text-center text-secondary-500 xl:pb-[30px] lg:pb-6 pb-5">
                Click the "add product" button to start adding items to your
                inventory
              </p>
              <Button
                type="button"
                size="lg"
                className="flex items-center justify-center gap-2 mx-auto text-base font-normal capitalize bg-primary-400 rounded-theme"
                onClick={() => navigate("/inventory/items/form")}
              >
                <FontAwesomeIcon icon={faPlus} fontSize={16} />
                Add Product
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-white xl:h-[calc(100vh-119px)] h-[calc(100vh-100px)] w-full lg:p-4 p-3.5 rounded-theme">
          <div className="flex flex-wrap items-center justify-between gap-5 mb-5">
            <h1 className="text-base font-semibold lg:text-lg 2xl:font-bold text-info-500 2xl:text-xl">
              {t("Products List")}
            </h1>
            <div className="flex items-center justify-end gap-3 xl:gap-5 lg:gap-4">
              <Input
                type={"search"}
                className={
                  "bg-secondary-50 min-w-80 placeholder:font-medium placeholder:text-secondary-500 placeholder:text-sm"
                }
                iconStart={
                  <FontAwesomeIcon
                    icon={faSearch}
                    fontSize={16}
                    className="text-secondary-500"
                  />
                }
                placeholder={t("Search Product")}
                value={search}
                onChange={handleSearch}
              />
              <Button
                type="button"
                className="bg-primary-400 flex items-center gap-x-3 text-sm font-medium rounded-theme w-full min-w-[158px] h-[46px] capitalize whitespace-nowrap "
                onClick={() => navigate("/inventory/items/form")}
              >
                <FontAwesomeIcon icon={faPlus} fontSize={18} />
                {t("Add product")}
              </Button>

              {/* <Button
              type="button"
              className="flex items-center w-full py-3 text-sm font-medium capitalize border border-primary-400 bg-primary-400/80 text-primary-400 gap-x-3 rounded-theme"
            >
              <ExportIcon />
              {t("Export")}
            </Button> */}
            </div>
          </div>
          {/* Product list table */}
          <DataTable
            columns={columns()}
            data={productData}
            search={""}
            maxHeight="max-h-[calc(100vh-195px)]"
            loading={isLoading}
            page={page}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
            handlePerPageChange={handlePerPageChange}
            message="No Product Found"
          />
        </div>
      )}
      {/* Dialog for delete */}
      <Modal
        open={openDeleteConfirmation}
        handler={() => {
          setOpenDeleteConfirmation(false);
          setSelectedProductId(null);
        }}
      >
        <div className="flex flex-col">
          <p className="pb-3 text-lg font-semibold text-info-400">
            {t("Delete")}
          </p>
          <p className="text-base font-medium text-secondary-400 pb-7">
            {t("Are you sure to delete this product ?")}
          </p>
          <div className="flex items-center justify-end gap-x-4">
            <Button
              type="button"
              size="sm"
              className="text-sm font-semibold capitalize border border-primary-400 bg-primary-400"
              onClick={() => {
                setOpenDeleteConfirmation(false);
                setSelectedProductId(null);
              }}
            >
              {t("No, Cancel")}
            </Button>
            <Button
              type="button"
              size="sm"
              className="text-sm font-semibold text-red-500 capitalize bg-white border border-red-500"
              loading={deleteLoading}
              disabled={deleteLoading}
              onClick={() =>
                dispatch(
                  deleteProductRequest({
                    productId: selectedProductId,
                    setModalClose: setOpenDeleteConfirmation,
                  })
                )
              }
            >
              {t("Yes, delete product")}
            </Button>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default Inventory;
