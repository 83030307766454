import moment from "moment";
import React, { useEffect, useState } from "react";
import { formatCurrencyWithNumberValue } from "../../utils/utils";
import { Button } from "@material-tailwind/react";
import * as paymentSuccess from "../../assets/json/payment-success.json";
import Lottie from "react-lottie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NewOrderIcon, PrintReceiptIcon } from "../svgs";
import { getOrderDetailRequest } from "../../store/order/action";
import SidePanel from "../common/SidePanel";
import OrderReceipt from "../order/OrderReceipt";
import { t } from "i18next";
// import { getOrderDetailRequest } from "../../rq-store/order";
import { useQueryClient } from "@tanstack/react-query";

const PaymentSuccess = ({
  formik,
  openPaymentSuccessModal,
  setOpenPaymentSuccessModal,
  totalTaxPercentage,
}) => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const queryClient = useQueryClient();

  const { isReceiptLoading, orderData } = useSelector((store) => ({
    isReceiptLoading: store?.order?.orderDetail?.loading,
    orderData: store?.order?.orderDetail?.data,
  }));

  const payable_data = useSelector((store) => store?.order?.createOrder?.data);
  console.log({ payable_data });
  console.log({ orderData });

  const [openOrderReceipt, setOpenOrderReceipt] = useState(false);
  const [orderId, setOrderId] = useState(null); // Updated to null to handle non-boolean IDs

  // Set orderId when payable_data is available
  useEffect(() => {
    if (payable_data?.orderData?._id) {
      setOrderId(payable_data?.orderData?._id);
    }
  }, [payable_data]);

  const printReceipt = () => {
    if (orderId) {
      dispatch(getOrderDetailRequest({ orderId })); // Dispatch with orderId
      setOpenOrderReceipt(true); // Open the order receipt panel
    }
  };

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: paymentSuccess,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  if (!openPaymentSuccessModal) return null;
  return (
    <>
      <div className="flex flex-col w-full gap-y-3">
        <Lottie options={defaultOptions} height={120} width={120} />
        <h5 className="text-2xl font-bold text-center">
          {t("Transaction Complete")}
        </h5>
        <p className="text-base text-center text-info-400">
          {moment(payable_data?.orderData?.order_date || "").format(
            "HH:mm A ddd, MMMM D, YYYY"
          )}
        </p>
        <p className="inline-block p-3 mx-auto text-2xl font-bold text-center rounded-md w-fit bg-primary-150 text-primary-400">
          {formatCurrencyWithNumberValue(
            payable_data?.orderData?.payable_amount || 0
          )}
        </p>
        <div className="w-full border border-dashed"></div>
        <div className="flex items-center justify-between">
          <p className="font-medium capitalize text-info-100">
            {t("customer name")}
          </p>
          <p className="font-bold capitalize text-info-100">
            {payable_data?.customer?.first_name +
              " " +
              payable_data?.customer?.last_name}
          </p>
        </div>
        <div className="flex items-center justify-between">
          <p className="font-medium capitalize text-info-100">
            {t("email id")}
          </p>
          <p className="font-bold text-info-100">
            {payable_data?.customer?.email}
          </p>
        </div>
        <div className="flex items-center justify-between">
          <p className="font-medium capitalize text-info-100">
            {t("tax amount")}
          </p>
          <p className="font-bold capitalize text-info-100">
            {formatCurrencyWithNumberValue(
              payable_data?.orderData?.total_taxes || 0
            )}
          </p>
        </div>
        <div className="flex items-center justify-between">
          <p className="font-medium capitalize text-info-100">{t("status")}</p>
          <p className="font-bold capitalize text-success">{t("Successful")}</p>
        </div>
        <div className="flex items-center justify-between">
          <p className="font-medium capitalize text-info-100">
            {t("order id")}
          </p>
          <p className="font-bold capitalize text-info-100">
            {payable_data?.orderData?.order_no || "-"}
          </p>
        </div>
        <div className="flex items-center justify-between gap-x-4">
          <Button
            type="button"
            className="flex items-center justify-center text-sm font-medium capitalize border rounded-theme gap-x-2.5 bg-primary-400 border-primary-400 px-4 py-3.5"
            fullWidth
            onClick={printReceipt}
          >
            <PrintReceiptIcon className="w-4 h-4" />
            {/* Adjust icon size if needed */}
            {t("Print Receipt")}
          </Button>

          <Button
            type="button"
            className="flex items-center justify-center text-sm font-medium capitalize bg-white border rounded-theme border-secondary-500 text-info-400 px-4 py-3.5 gap-x-2.5"
            fullWidth
            onClick={() => {
              router("/register");
              setOpenPaymentSuccessModal(false);
            }}
          >
            <NewOrderIcon className="w-4 h-4" />{" "}
            {/* Adjust icon size if needed */}
            {t("New Order")}
          </Button>
        </div>
      </div>

      <SidePanel
        customZ={"z-[9999]"}
        open={openOrderReceipt}
        handler={() => setOpenOrderReceipt(false)}
        width="w-[450px]"
      >
        <OrderReceipt
          isReceiptLoading={isReceiptLoading}
          totalOrderData={orderData || []}
          orderId={orderId} // Passing orderId to OrderReceipt
          openOrderReceipt={openOrderReceipt}
          totalTaxPercentage={totalTaxPercentage}
          handler={() => setOpenOrderReceipt(false)}
          formik={formik}
        />
      </SidePanel>
    </>
  );
};

export default PaymentSuccess;
