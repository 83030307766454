import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  customerList: {
    loading: false,
    data: [],
    error: false,
    totalPages: null,
  },
  createCustomer: {
    loading: false,
    data: null,
    error: false,
  },
  updateCustomer: {
    loading: false,
    data: null,
    error: false,
  },
  getCustomerDetail: {
    loading: false,
    data: null,
    error: false,
  },
  updateAdjustPoint: {
    loading: false,
    data: null,
    error: false,
  },
  getLoyaltyPoints: {
    loading: false,
    data: [],
    error: false,
    totalPages: null,
  },
  deleteCustomer: {
    loading: false,
    data: null,
    error: false,
  },
};

export const customerSlice = createSlice({
  name: "customer",
  initialState: initialState,
  reducers: {
    customerState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const { customerState } = customerSlice.actions;
export default customerSlice.reducer;
