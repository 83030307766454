import React from "react";
import Layout from ".";
import InnerSidePanel from "../components/InnerSidePanel";
import { integrationNavigationTab } from "../routes/navigation";

const IntegrationLayout = ({ children }) => {
  return (
    <Layout>
      <div className="flex gap-2.5">
        <InnerSidePanel data={integrationNavigationTab} />
        {children}
      </div>
    </Layout>
  );
};

export default IntegrationLayout;
