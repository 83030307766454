import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialAuthState = {
  currencyList: [],
  dateFormatList: [],
  numberFormats: [],
  minRedeemablePoints: [],
  pointsRedeemableDelay: [],
  valueOfLoyaltyPoints: [],
  languageList: [],
  organization: null,
  roleList: [],
  locationList: {
    loading: false,
    data: [],
    error: null,
  },
  defaultOrgLocation: null
};

export const settingSlice = createSlice({
  name: "setting",
  initialState: initialAuthState,
  reducers: {
    settingState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const { settingState } = settingSlice.actions;

export default settingSlice.reducer;
