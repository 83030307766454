import { Button } from "@material-tailwind/react";
import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import {
  formatCurrencyWithNumberValue,
  getUserOrgLocationSetting,
} from "../../utils/utils";
import Input from "../common/Input";
import { t } from "i18next";

const RedeemLoyaltyPoints = ({
  setOpenRedeemLoyaltyPoints,
  formik,
  customer,
}) => {
  const getOrgDetail = getUserOrgLocationSetting();

  return (
    <Formik
      enableReinitialize
      initialValues={{ point: "", notes: "" }}
      validationSchema={Yup.object().shape({
        point: Yup.number()
          .max(
            customer?.redeemable_loyalty_points *
              getOrgDetail?.value_of_loyalty_points,
            `Invalid Points (Max ${(
              customer?.redeemable_loyalty_points *
              getOrgDetail?.value_of_loyalty_points
            ).toFixed(2)} allowed)`
          )
          .required("Please enter loyalty points"),
      })}
      onSubmit={(values, { resetForm }) => {
        console.log({ values });
        formik.setFieldValue("order.redeemed_dollar", values.point);
        formik.setFieldValue("order.redeemed_notes", values.notes);
        const newValue = formik.values.order.grand_total - values.point;
        formik.setFieldValue("order.payable_amount", newValue);
        setOpenRedeemLoyaltyPoints(false);
        resetForm();
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <Form className="flex flex-col gap-y-4" onSubmit={handleSubmit}>
          <div className="flex items-center justify-between">
            <h3 className="text-base font-bold capitalize">
              {t("Redeem loyalty point")}
            </h3>
            <p className="font-medium text-info-100">
              {t("Loyalty Balance :")}
              <span className="font-semibold text-info-100">
                {formatCurrencyWithNumberValue(
                  customer?.redeemable_loyalty_points *
                    getOrgDetail?.value_of_loyalty_points
                )}
              </span>
            </p>
          </div>
          <div className="flex flex-col gap-y-3">
            <Input
              type={"number"}
              placeholder={`${formatCurrencyWithNumberValue(0)}`}
              className={
                "placeholder:!font-normal placeholder:text-info-100 bg-gray-100"
              }
              name="point"
              value={values.point}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <ErrorMessage
              name="point"
              component={"p"}
              className="text-sm text-red-500"
            />
            <div className="flex flex-col gap-y-3">
              <label
                htmlFor=""
                className="text-sm font-normal text-secondary-500"
              >
                {t("Notes")}
              </label>
              <textarea
                id=""
                rows="4"
                className="border border-secondary-200 font-semibold text-info-400 rounded-theme p-2.5 outline-none focus:border-primary-400 focus:shadow transition-all duration-200 bg-gray-100 placeholder:!font-normal placeholder:text-info-100"
                name="notes"
                value={values.notes}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={t("Enter Notes")}
              ></textarea>
            </div>
          </div>
          <div className="flex items-center justify-end gap-x-4">
            <Button
              type="reset"
              size="sm"
              onClick={() => {
                setOpenRedeemLoyaltyPoints(false);
              }}
              className="text-sm font-medium capitalize bg-white border border-secondary-400 text-info-400"
            >
              {t("Cancel")}
            </Button>
            <Button
              size="sm"
              type="submit"
              className="text-sm font-medium capitalize border bg-primary-400 border-primary-400"
            >
              {t("Redeem")}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RedeemLoyaltyPoints;
