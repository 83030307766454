/* -------------------------------------------------------------------------- */
/*                          transaction list request                          */
/* -------------------------------------------------------------------------- */

import { toast } from "react-toastify";
import { transactionState } from "./state";
import AxiosRequest from "../../../AxiosRequest";

export const getTransactionListRequest =
  ({ page, limit, search = "", date }) =>
  async (dispatch) => {
    try {
      dispatch(
        transactionState({ key: "transactionList.loading", value: true })
      );
      const response = await AxiosRequest.get(`/user/transaction/history`, {
        params: {
          page,
          limit,
          search,
          date,
        },
      });
      console.log(response, "res from get transaction list");
      const data = await response?.data?.data;
      if (data) {
        dispatch(
          transactionState({
            key: "transactionList.data",
            value: data?.transactions,
          })
        );
        dispatch(
          transactionState({
            key: "transactionList.payments",
            value: data?.payments,
          })
        );
        dispatch(
          transactionState({
            key: "transactionList.totalPages",
            value: data?.totalPages,
          })
        );
        dispatch(
          transactionState({ key: "transactionList.loading", value: false })
        );
      }
      return response;
    } catch (error) {
      console.log(error, "err from get transaction listt");
      toast.error(error);
    }
  };

/* -------------------------------------------------------------------------- */
/*                             Get receipt request                            */
/* -------------------------------------------------------------------------- */

export const getReceiptDetailRequest =
  ({ orderId }) =>
  async (dispatch) => {
    try {
      dispatch(transactionState({ key: "receiptData.loading", value: true }));
      const response = await AxiosRequest.get(`/user/order/details/${orderId}`);
      console.log(response, "res from get receipt");
      const data = await response.data.data;
      if (data) {
        dispatch(transactionState({ key: "receiptData.data", value: data }));
        dispatch(
          transactionState({ key: "receiptData.loading", value: false })
        );
      }
      return response;
    } catch (error) {
      toast.error(error);
      dispatch(transactionState({ key: "receiptData.loading", value: false }));
      dispatch(transactionState({ key: "receiptData.error", value: error }));
    }
  };
