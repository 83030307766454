export const theme = {
  checkbox: {
    defaultProps: {
      color: "themeColor",
      label: undefined,
      icon: undefined,
      ripple: true,
      className: "",
      disabled: false,
      containerProps: undefined,
      labelProps: undefined,
      iconProps: undefined,
    },
    valid: {
      colors: [
        "themeColor",
        "blue-gray",
        "gray",
        "brown",
        "deep-orange",
        "orange",
        "amber",
        "yellow",
        "lime",
        "light-green",
        "green",
        "teal",
        "cyan",
        "light-blue",
        "blue",
        "indigo",
        "deep-purple",
        "purple",
        "pink",
        "red",
      ],
    },
    styles: {
      base: {
        root: {
          display: "inline-flex",
          alignItems: "items-center",
        },
        container: {
          position: "relative",
          display: "flex",
          alignItems: "items-center",
          cursor: "cursor-pointer",
          p: "p-3",
          borderRadius: "rounded-full",
        },
        input: {
          peer: "peer",
          position: "relative",
          appearance: "appearance-none",
          width: "w-5",
          height: "h-5",
          borderWidth: "border",
          borderRadius: "rounded-md",
          borderColor: "border-blue-gray-200",
          cursor: "cursor-pointer",
          transition: "transition-all",
          before: {
            content: "before:content['']",
            display: "before:block",
            bg: "before:bg-blue-gray-500",
            width: "before:w-12",
            height: "before:h-12",
            borderRadius: "before:rounded-full",
            position: "before:absolute",
            top: "before:top-2/4",
            left: "before:left-2/4",
            transform: "before:-translate-y-2/4 before:-translate-x-2/4",
            opacity: "before:opacity-0 hover:before:opacity-10",
            transition: "before:transition-opacity",
          },
        },
        label: {
          color: "text-gray-700",
          fontWeight: "font-light",
          userSelect: "select-none",
          cursor: "cursor-pointer",
          mt: "mt-px",
        },
        icon: {
          color: "text-white",
          position: "absolute",
          top: "top-2/4",
          left: "left-2/4",
          translate: "-translate-y-2/4 -translate-x-2/4",
          pointerEvents: "pointer-events-none",
          opacity: "opacity-0 peer-checked:opacity-100",
          transition: "transition-opacity",
        },
        disabled: {
          opacity: "opacity-50",
          pointerEvents: "pointer-events-none",
        },
      },
      colors: {
        themeColor: {
          background: "checked:bg-primary-400",
          border: "checked:border-primary-400",
          before: "checked:before:bg-primary-400",
        },
        "blue-gray": {
          background: "checked:bg-blue-gray-500",
          border: "checked:border-blue-gray-500",
          before: "checked:before:bg-blue-gray-500",
        },
        gray: {
          background: "checked:bg-gray-500",
          border: "checked:border-gray-500",
          before: "checked:before:bg-gray-500",
        },
        brown: {
          background: "checked:bg-brown-500",
          border: "checked:border-brown-500",
          before: "checked:before:bg-brown-500",
        },
        "deep-orange": {
          background: "checked:bg-deep-orange-500",
          border: "checked:border-deep-orange-500",
          before: "checked:before:bg-deep-orange-500",
        },
        orange: {
          background: "checked:bg-orange-500",
          border: "checked:border-orange-500",
          before: "checked:before:bg-orange-500",
        },
        amber: {
          background: "checked:bg-amber-500",
          border: "checked:border-amber-500",
          before: "checked:before:bg-amber-500",
        },
        yellow: {
          background: "checked:bg-yellow-500",
          border: "checked:border-yellow-500",
          before: "checked:before:bg-yellow-500",
        },
        lime: {
          background: "checked:bg-lime-500",
          border: "checked:border-lime-500",
          before: "checked:before:bg-lime-500",
        },
        "light-green": {
          background: "checked:bg-light-green-500",
          border: "checked:border-light-green-500",
          before: "checked:before:bg-light-green-500",
        },
        green: {
          background: "checked:bg-green-500",
          border: "checked:border-green-500",
          before: "checked:before:bg-green-500",
        },
        teal: {
          background: "checked:bg-teal-500",
          border: "checked:border-teal-500",
          before: "checked:before:bg-teal-500",
        },
        cyan: {
          background: "checked:bg-cyan-500",
          border: "checked:border-cyan-500",
          before: "checked:before:bg-cyan-500",
        },
        "light-blue": {
          background: "checked:bg-light-blue-500",
          border: "checked:border-light-blue-500",
          before: "checked:before:bg-light-blue-500",
        },
        blue: {
          background: "checked:bg-blue-500",
          border: "checked:border-blue-500",
          before: "checked:before:bg-blue-500",
        },
        indigo: {
          background: "checked:bg-indigo-500",
          border: "checked:border-indigo-500",
          before: "checked:before:bg-indigo-500",
        },
        "deep-purple": {
          background: "checked:bg-deep-purple-500",
          border: "checked:border-deep-purple-500",
          before: "checked:before:bg-deep-purple-500",
        },
        purple: {
          background: "checked:bg-purple-500",
          border: "checked:border-purple-500",
          before: "checked:before:bg-purple-500",
        },
        pink: {
          background: "checked:bg-pink-500",
          border: "checked:border-pink-500",
          before: "checked:before:bg-pink-500",
        },
        red: {
          background: "checked:bg-red-500",
          border: "checked:border-red-500",
          before: "checked:before:bg-red-500",
        },
      },
    },
  },
  radio: {
    defaultProps: {
      color: "themeColor",
      label: undefined,
      icon: undefined,
      ripple: true,
      className: "",
      disabled: false,
      containerProps: undefined,
      labelProps: undefined,
      iconProps: undefined,
    },
    valid: {
      colors: [
        "themeColor",
        "blue-gray",
        "gray",
        "brown",
        "deep-orange",
        "orange",
        "amber",
        "yellow",
        "lime",
        "light-green",
        "green",
        "teal",
        "cyan",
        "light-blue",
        "blue",
        "indigo",
        "deep-purple",
        "purple",
        "pink",
        "red",
      ],
    },
    styles: {
      base: {
        root: {
          display: "inline-flex",
          alignItems: "items-center",
        },
        container: {
          position: "relative",
          display: "flex",
          alignItems: "items-center",
          cursor: "cursor-pointer",
          p: "p-3",
          borderRadius: "rounded-full",
        },
        input: {
          peer: "peer",
          position: "relative",
          appearance: "appearance-none",
          width: "w-5",
          height: "h-5",
          borderWidth: "border",
          borderRadius: "rounded-full",
          borderColor: "border-blue-gray-200",
          cursor: "cursor-pointer",
          transition: "transition-all",
          before: {
            content: "before:content['']",
            display: "before:block",
            bg: "before:bg-blue-gray-500",
            width: "before:w-12",
            height: "before:h-12",
            borderRadius: "before:rounded-full",
            position: "before:absolute",
            top: "before:top-2/4",
            left: "before:left-2/4",
            transform: "before:-translate-y-2/4 before:-translate-x-2/4",
            opacity: "before:opacity-0 hover:before:opacity-10",
            transition: "before:transition-opacity",
          },
        },
        label: {
          color: "text-gray-700",
          fontWeight: "font-light",
          userSelect: "select-none",
          cursor: "cursor-pointer",
          mt: "mt-px",
        },
        icon: {
          position: "absolute",
          top: "top-2/4",
          left: "left-2/4",
          translate: "-translate-y-2/4 -translate-x-2/4",
          pointerEvents: "pointer-events-none",
          opacity: "opacity-0 peer-checked:opacity-100",
          transition: "transition-opacity",
        },
        disabled: {
          opacity: "opacity-50",
          pointerEvents: "pointer-events-none",
        },
      },
      colors: {
        themeColor: {
          color: "text-primary-400",
          border: "checked:border-primary-400",
          before: "checked:before:bg-primary-400",
        },
        "blue-gray": {
          color: "text-blue-gray-500",
          border: "checked:border-blue-gray-500",
          before: "checked:before:bg-blue-gray-500",
        },
        gray: {
          color: "text-gray-500",
          border: "checked:border-gray-500",
          before: "checked:before:bg-gray-500",
        },
        brown: {
          color: "text-brown-500",
          border: "checked:border-brown-500",
          before: "checked:before:bg-brown-500",
        },
        "deep-orange": {
          color: "text-deep-orange-500",
          border: "checked:border-deep-orange-500",
          before: "checked:before:bg-deep-orange-500",
        },
        orange: {
          color: "text-orange-500",
          border: "checked:border-orange-500",
          before: "checked:before:bg-orange-500",
        },
        amber: {
          color: "text-amber-500",
          border: "checked:border-amber-500",
          before: "checked:before:bg-amber-500",
        },
        yellow: {
          color: "text-yellow-500",
          border: "checked:border-yellow-500",
          before: "checked:before:bg-yellow-500",
        },
        lime: {
          color: "text-lime-500",
          border: "checked:border-lime-500",
          before: "checked:before:bg-lime-500",
        },
        "light-green": {
          color: "text-light-green-500",
          border: "checked:border-light-green-500",
          before: "checked:before:bg-light-green-500",
        },
        green: {
          color: "text-green-500",
          border: "checked:border-green-500",
          before: "checked:before:bg-green-500",
        },
        teal: {
          color: "text-teal-500",
          border: "checked:border-teal-500",
          before: "checked:before:bg-teal-500",
        },
        cyan: {
          color: "text-cyan-500",
          border: "checked:border-cyan-500",
          before: "checked:before:bg-cyan-500",
        },
        "light-blue": {
          color: "text-light-blue-500",
          border: "checked:border-light-blue-500",
          before: "checked:before:bg-light-blue-500",
        },
        blue: {
          color: "text-blue-500",
          border: "checked:border-blue-500",
          before: "checked:before:bg-blue-500",
        },
        indigo: {
          color: "text-indigo-500",
          border: "checked:border-indigo-500",
          before: "checked:before:bg-indigo-500",
        },
        "deep-purple": {
          color: "text-deep-purple-500",
          border: "checked:border-deep-purple-500",
          before: "checked:before:bg-deep-purple-500",
        },
        purple: {
          color: "text-purple-500",
          border: "checked:border-purple-500",
          before: "checked:before:bg-purple-500",
        },
        pink: {
          color: "text-pink-500",
          border: "checked:border-pink-500",
          before: "checked:before:bg-pink-500",
        },
        red: {
          color: "text-red-500",
          border: "checked:border-red-500",
          before: "checked:before:bg-red-500",
        },
      },
    },
  },
  chip: {
    defaultProps: {
      variant: "filled",
      size: "md",
      color: "themeColor",
      icon: undefined,
      open: true,
      onClose: undefined,
      action: undefined,
      animate: {
        unmount: {},
        mount: {},
      },
      className: "",
    },
    valid: {
      variants: ["filled", "gradient", "outlined", "ghost"],
      sizes: ["sm", "md", "lg"],
      colors: [
        "themeColor",
        "success",
        "blue-gray",
        "gray",
        "brown",
        "deep-orange",
        "orange",
        "amber",
        "yellow",
        "lime",
        "light-green",
        "green",
        "teal",
        "cyan",
        "light-blue",
        "blue",
        "indigo",
        "deep-purple",
        "purple",
        "pink",
        "red",
      ],
    },
    styles: {
      base: {
        chip: {
          position: "relative",
          display: "grid",
          placeItems: "items-center",
          fontFamily: "font-sans",
          fontWeight: "font-bold",
          textTransform: "uppercase",
          lineHeight: "leading-none",
          whiteSpace: "whitespace-nowrap",
          userSelect: "select-none",
        },
        action: {
          position: "!absolute",
          top: "top-2/4",
          right: "right-1",
          translate: "-translate-y-2/4",
          mx: "mx-px",
          rounded: "rounded-md",
        },
        icon: {
          position: "absolute",
          top: "top-2/4",
          translate: "-translate-y-2/4",
        },
      },
      sizes: {
        sm: {
          chip: {
            py: "py-1",
            px: "px-2",
            fontSize: "text-xs",
            borderRadius: "rounded-md",
          },
          action: {
            width: "w-4",
            height: "h-4",
          },
          icon: {
            width: "w-4",
            height: "h-4",
            left: "left-1",
          },
        },
        md: {
          chip: {
            py: "py-1.5",
            px: "px-3",
            fontSize: "text-xs",
            borderRadius: "rounded-lg",
          },
          action: {
            width: "w-5",
            height: "h-5",
          },
          icon: {
            width: "w-5",
            height: "h-5",
            left: "left-1.5",
          },
        },
        lg: {
          chip: {
            py: "py-2",
            px: "px-4",
            fontSize: "text-xs",
            borderRadius: "rounded-lg",
          },
          action: {
            width: "w-6",
            height: "h-6",
          },
          icon: {
            width: "w-6",
            height: "h-6",
            left: "left-1.5",
          },
        },
      },
      variants: {
        filled: {
          themeColor: {
            backgroud: "bg-primary-400",
            color: "text-white",
          },
          success: {
            backgroud: "bg-success",
            color: "text-white",
          },
          "blue-gray": {
            backgroud: "bg-blue-gray-500",
            color: "text-white",
          },
          gray: {
            backgroud: "bg-gray-500",
            color: "text-white",
          },
          brown: {
            backgroud: "bg-brown-500",
            color: "text-white",
          },
          "deep-orange": {
            backgroud: "bg-deep-orange-500",
            color: "text-white",
          },
          orange: {
            backgroud: "bg-orange-500",
            color: "text-white",
          },
          amber: {
            backgroud: "bg-amber-500",
            color: "text-black",
          },
          yellow: {
            backgroud: "bg-yellow-500",
            color: "text-black",
          },
          lime: {
            backgroud: "bg-lime-500",
            color: "text-black",
          },
          "light-green": {
            backgroud: "bg-light-green-500",
            color: "text-white",
          },
          green: {
            backgroud: "bg-green-500",
            color: "text-white",
          },
          teal: {
            backgroud: "bg-teal-500",
            color: "text-white",
          },
          cyan: {
            backgroud: "bg-cyan-500",
            color: "text-white",
          },
          "light-blue": {
            backgroud: "bg-light-blue-500",
            color: "text-white",
          },
          blue: {
            backgroud: "bg-blue-500",
            color: "text-white",
          },
          indigo: {
            backgroud: "bg-indigo-500",
            color: "text-white",
          },
          "deep-purple": {
            backgroud: "bg-deep-purple-500",
            color: "text-white",
          },
          purple: {
            backgroud: "bg-purple-500",
            color: "text-white",
          },
          pink: {
            backgroud: "bg-pink-500",
            color: "text-white",
          },
          red: {
            backgroud: "bg-red-500",
            color: "text-white",
          },
        },
        gradient: {
          "blue-gray": {
            backgroud: "bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400",
            color: "text-white",
          },
          gray: {
            backgroud: "bg-gradient-to-tr from-gray-600 to-gray-400",
            color: "text-white",
          },
          brown: {
            backgroud: "bg-gradient-to-tr from-brown-600 to-brown-400",
            color: "text-white",
          },
          "deep-orange": {
            backgroud:
              "bg-gradient-to-tr from-deep-orange-600 to-deep-orange-400",
            color: "text-white",
          },
          orange: {
            backgroud: "bg-gradient-to-tr from-orange-600 to-orange-400",
            color: "text-white",
          },
          amber: {
            backgroud: "bg-gradient-to-tr from-amber-600 to-amber-400",
            color: "text-black",
          },
          yellow: {
            backgroud: "bg-gradient-to-tr from-yellow-600 to-yellow-400",
            color: "text-black",
          },
          lime: {
            backgroud: "bg-gradient-to-tr from-lime-600 to-lime-400",
            color: "text-black",
          },
          "light-green": {
            backgroud:
              "bg-gradient-to-tr from-light-green-600 to-light-green-400",
            color: "text-white",
          },
          green: {
            backgroud: "bg-gradient-to-tr from-green-600 to-green-400",
            color: "text-white",
          },
          teal: {
            backgroud: "bg-gradient-to-tr from-teal-600 to-teal-400",
            color: "text-white",
          },
          cyan: {
            backgroud: "bg-gradient-to-tr from-cyan-600 to-cyan-400",
            color: "text-white",
          },
          "light-blue": {
            backgroud:
              "bg-gradient-to-tr from-light-blue-600 to-light-blue-400",
            color: "text-white",
          },
          blue: {
            backgroud: "bg-gradient-to-tr from-blue-600 to-blue-400",
            color: "text-white",
          },
          indigo: {
            backgroud: "bg-gradient-to-tr from-indigo-600 to-indigo-400",
            color: "text-white",
          },
          "deep-purple": {
            backgroud:
              "bg-gradient-to-tr from-deep-purple-600 to-deep-purple-400",
            color: "text-white",
          },
          purple: {
            backgroud: "bg-gradient-to-tr from-purple-600 to-purple-400",
            color: "text-white",
          },
          pink: {
            backgroud: "bg-gradient-to-tr from-pink-600 to-pink-400",
            color: "text-white",
          },
          red: {
            backgroud: "bg-gradient-to-tr from-red-600 to-red-400",
            color: "text-white",
          },
        },
        outlined: {
          "blue-gray": {
            border: "border border-blue-gray-500",
            color: "text-blue-gray-700",
          },
          gray: {
            border: "border border-gray-500",
            color: "text-gray-700",
          },
          brown: {
            border: "border border-brown-500",
            color: "text-brown-700",
          },
          "deep-orange": {
            border: "border border-deep-orange-500",
            color: "text-deep-orange-700",
          },
          orange: {
            border: "border border-orange-500",
            color: "text-orange-700",
          },
          amber: {
            border: "border border-amber-500",
            color: "text-amber-700",
          },
          yellow: {
            border: "border border-yellow-500",
            color: "text-yellow-700",
          },
          lime: {
            border: "border border-lime-500",
            color: "text-lime-700",
          },
          "light-green": {
            border: "border border-light-green-500",
            color: "text-light-green-700",
          },
          green: {
            border: "border border-green-500",
            color: "text-green-700",
          },
          teal: {
            border: "border border-teal-500",
            color: "text-teal-700",
          },
          cyan: {
            border: "border border-cyan-500",
            color: "text-cyan-700",
          },
          "light-blue": {
            border: "border border-light-blue-500",
            color: "text-light-blue-700",
          },
          blue: {
            border: "border border-blue-500",
            color: "text-blue-700",
          },
          indigo: {
            border: "border border-indigo-500",
            color: "text-indigo-700",
          },
          "deep-purple": {
            border: "border border-deep-purple-500",
            color: "text-deep-purple-700",
          },
          purple: {
            border: "border border-purple-500",
            color: "text-purple-700",
          },
          pink: {
            border: "border border-pink-500",
            color: "text-pink-700",
          },
          red: {
            border: "border border-red-500",
            color: "text-red-700",
          },
        },
        ghost: {
          themeColor: {
            backgroud: "bg-themeColor/20",
            color: "text-blue-gray-900",
          },
          success: {
            backgroud: "bg-success/20",
            color: "text-success",
          },
          "blue-gray": {
            backgroud: "bg-blue-gray-500/20",
            color: "text-blue-gray-900",
          },
          gray: {
            backgroud: "bg-gray-500/20",
            color: "text-gray-900",
          },
          brown: {
            backgroud: "bg-brown-500/20",
            color: "text-brown-900",
          },
          "deep-orange": {
            backgroud: "bg-deep-orange-500/20",
            color: "text-deep-orange-900",
          },
          orange: {
            backgroud: "bg-orange-500/20",
            color: "text-orange-900",
          },
          amber: {
            backgroud: "bg-amber-500/20",
            color: "text-amber-900",
          },
          yellow: {
            backgroud: "bg-yellow-500/20",
            color: "text-yellow-900",
          },
          lime: {
            backgroud: "bg-lime-500/20",
            color: "text-lime-900",
          },
          "light-green": {
            backgroud: "bg-light-green-500/20",
            color: "text-light-green-900",
          },
          green: {
            backgroud: "bg-green-500/20",
            color: "text-green-900",
          },
          teal: {
            backgroud: "bg-teal-500/20",
            color: "text-teal-900",
          },
          cyan: {
            backgroud: "bg-cyan-500/20",
            color: "text-cyan-900",
          },
          "light-blue": {
            backgroud: "bg-light-blue-500/20",
            color: "text-light-blue-900",
          },
          blue: {
            backgroud: "bg-blue-500/20",
            color: "text-blue-900",
          },
          indigo: {
            backgroud: "bg-indigo-500/20",
            color: "text-indigo-900",
          },
          "deep-purple": {
            backgroud: "bg-deep-purple-500/20",
            color: "text-deep-purple-900",
          },
          purple: {
            backgroud: "bg-purple-500/20",
            color: "text-purple-900",
          },
          pink: {
            backgroud: "bg-pink-500/20",
            color: "text-pink-900",
          },
          red: {
            backgroud: "bg-red-500/20",
            color: "text-red-900",
          },
        },
      },
    },
  },
};
