import {
  faCirclePlus,
  faClose,
  faPencil,
  faPlus,
  faSearch,
  faTrash,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, IconButton, Radio, Spinner } from "@material-tailwind/react";
import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../common/Input";
import { useDebounce } from "../../utils/utils";
import {
  createBrandRequest,
  deleteBrandRequest,
  getBrandListRequest,
  updateBrandRequest,
} from "../../store/inventory/brands/actions";
import NoDataFound from "../common/NoDataFound";
import { brandsState } from "../../store/inventory/brands/state";
import Modal from "../common/Modal";
import Loader from "../common/Loader";
import { useTranslation } from "react-i18next";
import { ManageDeleteProductIcon, ManageRenameProductIcon } from "../svgs";

const ManageBrand = ({ openBrandDrawer, handler, formik }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    isLoading,
    brandsData,
    totalPages,
    isBrandDeleteLoading,
    isCreateBrandLoading,
    isBrandUpdateLoading,
    updateBrandData,
    deleteBrandData,
  } = useSelector((store) => ({
    isLoading: store?.brands?.brandList?.loading,
    brandsData: store?.brands?.brandList?.data,
    totalPages: store?.brands?.brandList?.totalPages,
    isBrandDeleteLoading: store?.brands?.deleteBrand?.loading,
    isCreateBrandLoading: store?.brands?.createBrand?.loading,
    isBrandUpdateLoading: store?.brands?.updateBrand?.loading,
    updateBrandData: store?.brands?.updateBrand?.data,
    deleteBrandData: store?.brands?.deleteBrand?.data,
  }));
  const [brandList, setBrandList] = useState([]);
  const [openEditInput, setOpenEditInput] = useState(false);
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const searchVal = useDebounce(search);
  const [editValue, setEditValue] = useState("");
  const [selectedBrandData, setSelectedBrandData] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [alreadyHaveBrand, setAlreadyHaveBrand] = useState(false);

  console.log(formik.values, "formik.valuesformik.values................");

  // handle search change in brands //
  const handleChangeSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    setIsSearch(true);
    if (page > 1) setPage(1);
    setBrandList([]);
  };

  // handle change edit brands //
  const handleChangeEditBrand = (e) => {
    const value = e.target.value;
    setEditValue(value);
  };

  // for create brand
  const handleCreateBrand = () => {
    setBrandList([]);
    dispatch(createBrandRequest({ name: search }));
  };

  // for update brand
  const handleUpdateBrand = () => {
    dispatch(
      updateBrandRequest({
        name: editValue,
        brandId: selectedBrandData._id,
      })
    );
  };

  const handleDeleteBrand = () => {
    dispatch(
      deleteBrandRequest({
        brandId: selectedBrandData._id,
      })
    );
  };

  const handleScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    let scrollOffset = scrollHeight - scrollTop - clientHeight;
    if (scrollOffset === 0 && totalPages > page && openBrandDrawer) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    if (openBrandDrawer) {
      setBrandList([]); // Reset local state
      !isLoading && dispatch(getBrandListRequest({ search, page, limit }));
      setIsSearch(false);
    }
  }, [openBrandDrawer, dispatch, searchVal]);

  useEffect(() => {
    if (openBrandDrawer && page > 1) {
      !isLoading && dispatch(getBrandListRequest({ search, page, limit }));
      setIsSearch(false);
    }
  }, [openBrandDrawer, dispatch, page]);

  useEffect(() => {
    if (brandsData?.length > 0) {
      setBrandList([...brandList, ...brandsData]);
    }
  }, [brandsData]);

  useEffect(() => {
    if (updateBrandData !== null) {
      const oldArr = [...brandList];
      const findIndex = oldArr?.findIndex(
        (item) => item?._id === updateBrandData?._id
      );
      if (findIndex !== -1) {
        oldArr[findIndex] = updateBrandData;
        setBrandList(oldArr);
        setEditValue("");
        setSelectedBrandData(null);
        setOpenEditInput(false);
        dispatch(brandsState({ key: "updateBrand.data", value: null }));
      }
    }
  }, [updateBrandData, dispatch]);

  useEffect(() => {
    if (deleteBrandData !== null) {
      const oldArr = [...brandList];
      const findIndex = oldArr?.findIndex(
        (item) => item?._id === selectedBrandData?._id
      );
      if (findIndex !== -1) {
        setOpenDeleteModal(false);
        oldArr.splice(findIndex, 1);
        setBrandList(oldArr);
        setSelectedBrandData(null);
        dispatch(brandsState({ key: "deleteBrand.data", value: null }));
      }
    }
  }, [deleteBrandData, dispatch]);

  useLayoutEffect(() => {
    if (!openBrandDrawer) {
      setPage(1);
      setBrandList([]);
      setSearch("");
      setEditValue("");
      setSelectedBrandData(null);
      setOpenEditInput(false);
    }
  }, [openBrandDrawer]);

  // check already have brand or not
  useEffect(() => {
    if (searchVal.trim()) {
      const matchedBrand = brandList.find((value) => {
        return value.name === searchVal.trim().toLowerCase();
      });
      setAlreadyHaveBrand(matchedBrand || null);
    } else {
      setAlreadyHaveBrand(null); // Reset if searchVal is empty
    }
  }, [searchVal, brandList]);

  useEffect(() => {
    // On component unmount, reset the customer data
    return () => {
      setBrandList([]); // Reset local state
      dispatch(brandsState({ key: "brandList.data", value: [] })); // Reset Redux state
    };
  }, [dispatch]);

  return (
    <Fragment>
      <div className="flex flex-col flex-grow h-full overflow-y-auto">
        <div className="flex items-center justify-between gap-5 px-5 py-3">
          <p className="text-lg font-semibold text-info-500">
            {/* Select Product Brand */}
            {t("Manage Your Brand")}
          </p>
          <IconButton size="sm" variant="text" onClick={handler}>
            <FontAwesomeIcon icon={faClose} fontSize={18} />
          </IconButton>
        </div>
        <div className="flex-grow px-5 py-3 overflow-y-auto">
          <div className="flex flex-col h-full gap-y-3">
            {openEditInput ? (
              <Fragment>
                <Input
                  type={"search"}
                  placeholder={t("Edit Brand")}
                  iconStart={
                    <FontAwesomeIcon
                      icon={faPencil}
                      fontSize={16}
                      className="text-secondary-500"
                    />
                  }
                  className={
                    "bg-secondary-100 text-info-400 placeholder:text-secondary-500 font-medium placeholder:font-medium"
                  }
                  value={editValue}
                  onChange={handleChangeEditBrand}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      setSelectedBrandData(null);
                      setOpenEditInput(false);
                      setEditValue("");
                    }
                  }}
                />
                {selectedBrandData?.name !== editValue && (
                  <div
                    className={`flex justify-between items-center bg-primary-150 border border-primary-400 text-primary-400 rounded-theme p-2`}
                  >
                    <span className="text-base font-medium capitalize">
                      {t("Rename")} {selectedBrandData?.name} {t("To")}{" "}
                      {editValue} !
                    </span>
                    <Button
                      type="button"
                      className="font-semibold capitalize rounded-md bg-primary-400"
                      size="sm"
                      onClick={handleUpdateBrand}
                      loading={isBrandUpdateLoading}
                      disabled={!editValue || isBrandUpdateLoading}
                    >
                      {t("Save")}
                    </Button>
                  </div>
                )}
              </Fragment>
            ) : (
              <div>
                <Input
                  type={"search"}
                  placeholder={t("Search Or Create Brand")}
                  iconStart={
                    <FontAwesomeIcon
                      icon={faSearch}
                      fontSize={16}
                      className="text-secondary-500"
                    />
                  }
                  className={
                    "bg-secondary-100 text-info-400 placeholder:text-secondary-500 font-medium placeholder:font-medium placeholder:text-sm"
                  }
                  value={search}
                  onChange={handleChangeSearch}
                />
              </div>
            )}
            {!alreadyHaveBrand && search !== "" && !isSearch && !isLoading && (
              <div
                className={`flex justify-between items-center bg-primary-150 border border-primary-400 text-primary-400 rounded-theme p-2`}
              >
                <span className="text-base font-medium capitalize">
                  {search} {t("create new brand")}
                </span>
                <IconButton
                  type="button"
                  className="rounded-md text-primary-400"
                  size="sm"
                  variant="text"
                  disabled={isCreateBrandLoading}
                  onClick={handleCreateBrand}
                >
                  <FontAwesomeIcon icon={faCirclePlus} fontSize={18} />
                </IconButton>
              </div>
            )}
            <div
              className="flex-grow overflow-hidden overflow-y-auto border rounded-lg border-secondary-200"
              onScroll={handleScroll}
            >
              <div className="sticky top-0 z-10 grid grid-cols-5 gap-6 p-3 border-b bg-secondary-50 border-secondary-200">
                <p className="col-span-3 text-base font-medium text-info-500">
                  {t("Brands")}
                </p>
                {/* <p className="text-base font-medium text-center text-info-500">
                  {t("Rename")}
                </p>
                <p className="text-base font-medium text-center text-info-500">
                  {t("Delete")}
                </p> */}
              </div>
              <div className="">
                <div className="">
                  {brandList?.length > 0 &&
                    brandList.map(
                      (brand, brandIndex) => (
                        console.log(brand._id, brand.name, "brand............"),
                        console.log(
                          formik.values.brand_id,
                          "formik.values.brand_id............"
                        ),
                        (
                          <Fragment key={brandIndex} className="">
                            <div className="px-1">
                              <div className="grid grid-cols-5 border-b gap-x-6 border-secondary-200">
                                <div className="flex items-center col-span-3 gap-2 p-1">
                                  <Radio
                                    checked={
                                      brand._id === formik.values.brand_id
                                        ? true
                                        : false
                                    }
                                    onChange={() => {
                                      formik.setFieldValue(
                                        "brand_id",
                                        brand?._id
                                      );
                                      setSelectedBrand(brand);
                                    }}
                                    label={brand.name}
                                    labelProps={{
                                      className:
                                        "text-info-400 font-medium capitalize",
                                    }}
                                  />
                                </div>
                                <div className="flex items-center justify-center">
                                  <IconButton
                                    type="button"
                                    size="sm"
                                    variant="text"
                                    onClick={() => {
                                      setSelectedBrandData(brand);
                                      setOpenEditInput(true);
                                      setEditValue(brand.name);
                                    }}
                                  >
                                    {/* <FontAwesomeIcon
                                      icon={faPencil}
                                      fontSize={16}
                                      className="text-primary-400"
                                    /> */}
                                    <ManageRenameProductIcon className="w-4 h-4" />
                                  </IconButton>
                                </div>
                                <div className="flex items-center justify-center">
                                  <IconButton
                                    type="button"
                                    size="sm"
                                    variant="text"
                                    onClick={() => {
                                      setOpenDeleteModal(true);
                                      setSelectedBrandData(brand);
                                    }}
                                  >
                                    {/* <FontAwesomeIcon
                                      icon={faTrashAlt}
                                      fontSize={16}
                                      className="text-red-500"
                                    /> */}
                                    <ManageDeleteProductIcon className="w-4 h-4" />
                                  </IconButton>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        )
                      )
                    )}
                  {(isSearch || isLoading) && (
                    <div className="flex items-center justify-center pt-6 col-span-full">
                      <Loader />
                    </div>
                  )}
                  {brandsData?.length === 0 && !isSearch && !isLoading && (
                    <div className="col-span-full">
                      <NoDataFound message={"No Brand Available"} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center gap-x-5 p-6 flex-grow-0 bg-white shadow-[0px_4px_16.3px_0px_#64748B4D]">
          <Button
            type="button"
            className="text-base font-semibold capitalize bg-white border border-secondary-400 text-secondary-400"
            fullWidth
            onClick={handler}
          >
            {t("Cancel")}
          </Button>
          <Button
            type="button"
            className="text-base font-semibold capitalize border border-primary-400 bg-primary-400"
            fullWidth
            onClick={() => {
              formik.setFieldValue("brand", selectedBrand);
              setSelectedBrand(null);
              handler();
            }}
          >
            {t("Done")}
          </Button>
        </div>
      </div>
      <Modal
        open={openDeleteModal}
        handler={() => {
          setOpenDeleteModal(false);
          setSelectedBrandData(null);
        }}
      >
        <div className="flex flex-col">
          <p className="pb-3 text-lg font-semibold text-info-400">
            {t("Delete")}
          </p>
          <p className="text-base font-medium text-secondary-400 pb-7">
            {t("Are you sure to delete this Brand ?")}
          </p>
          <div className="flex items-center justify-end gap-x-4">
            <Button
              type="button"
              size="sm"
              onClick={() => setOpenDeleteModal(false)}
              className="text-sm font-semibold capitalize border border-primary-400 bg-primary-400"
            >
              {t("No, Cancel")}
            </Button>
            <Button
              type="button"
              size="sm"
              className="text-sm font-semibold text-red-500 capitalize bg-white border border-red-500"
              loading={isBrandDeleteLoading}
              onClick={handleDeleteBrand}
            >
              {t("Yes, delete brand")}
            </Button>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default ManageBrand;
