import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "../../utils/utils";
import { Button, IconButton, Radio, Spinner } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faClose,
  faPencil,
  faSearch,
  faTrash,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import Input from "../common/Input";
import NoDataFound from "../common/NoDataFound";
import Modal from "../common/Modal";
import { vendorState } from "../../store/inventory/vendors/state";
import {
  createVendorRequest,
  deleteVendorRequest,
  getVendorsListRequest,
  updateVendorRequest,
} from "../../store/inventory/vendors/actions";
import Loader from "../common/Loader";
import { useTranslation } from "react-i18next";
import { ManageDeleteProductIcon, ManageRenameProductIcon } from "../svgs";

const ManageVendor = ({ openVendorDrawer, handler, formik }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const {
    isLoading,
    vendorData,
    totalPages,
    isVendorDeleteLoading,
    isCreateVendorLoading,
    isVendorUpdateLoading,
    updateVendorData,
    deleteVendorData,
  } = useSelector((store) => ({
    isLoading: store?.vendor?.vendorList?.loading,
    vendorData: store?.vendor?.vendorList?.data,
    totalPages: store?.vendor?.vendorList?.totalPages,
    isVendorDeleteLoading: store?.vendor?.deleteVendor?.loading,
    isCreateVendorLoading: store?.vendor?.createVendor?.loading,
    isVendorUpdateLoading: store?.vendor?.updateVendor?.loading,
    updateVendorData: store?.vendor?.updateVendor?.data,
    deleteVendorData: store?.vendor?.deleteVendor?.data,
  }));
  const [vendorList, setVendorList] = useState([]);
  const [openEditInput, setOpenEditInput] = useState(false);
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const searchVal = useDebounce(search);
  const [editValue, setEditValue] = useState("");
  const [selectedVendorData, setSelectedVendorData] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [alreadyHaveBrand, setAlreadyHaveBrand] = useState(false);

  // handle search change in vendor //
  const handleChangeSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    setIsSearch(true);
    if (page > 1) setPage(1);
    setVendorList([]);
  };

  // handle change edit vendor //
  const handleChangeEditVendor = (e) => {
    const value = e.target.value;
    setEditValue(value);
  };

  // for create vendor
  const handleCreateVendor = () => {
    dispatch(createVendorRequest({ name: search }));
  };

  // for update vendor
  const handleUpdateVendor = () => {
    dispatch(
      updateVendorRequest({
        name: editValue,
        vendorId: selectedVendorData._id,
      })
    );
  };

  const handleDeleteVendor = () => {
    dispatch(
      deleteVendorRequest({
        vendorId: selectedVendorData._id,
      })
    );
  };

  const handleScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    let scrollOffset = scrollHeight - scrollTop - clientHeight;
    if (scrollOffset === 0 && totalPages > page && openVendorDrawer) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    if (openVendorDrawer) {
      setVendorList([]);
      !isLoading && dispatch(getVendorsListRequest({ search, page, limit }));
      setIsSearch(false);
    }
  }, [openVendorDrawer, dispatch, searchVal]);

  useEffect(() => {
    if (openVendorDrawer && page > 1) {
      !isLoading && dispatch(getVendorsListRequest({ search, page, limit }));
      setIsSearch(false);
    }
  }, [openVendorDrawer, dispatch, page]);

  useEffect(() => {
    if (vendorData?.length > 0) {
      setVendorList([...vendorList, ...vendorData]);
    }
  }, [vendorData]);

  useEffect(() => {
    if (updateVendorData !== null) {
      const oldArr = [...vendorList];
      const findIndex = oldArr?.findIndex(
        (item) => item?._id === updateVendorData?._id
      );
      if (findIndex !== -1) {
        oldArr[findIndex] = updateVendorData;
        setVendorList(oldArr);
        setEditValue("");
        setSelectedVendorData(null);
        setOpenEditInput(false);
        dispatch(vendorState({ key: "updateVendor.data", value: null }));
      }
    }
  }, [updateVendorData, dispatch]);

  useEffect(() => {
    if (deleteVendorData !== null) {
      const oldArr = [...vendorList];
      const findIndex = oldArr?.findIndex(
        (item) => item?._id === selectedVendorData?._id
      );
      if (findIndex !== -1) {
        setOpenDeleteModal(false);
        oldArr.splice(findIndex, 1);
        setVendorList(oldArr);
        setSelectedVendorData(null);
        dispatch(vendorState({ key: "deleteVendor.data", value: null }));
      }
    }
  }, [deleteVendorData, dispatch]);

  useLayoutEffect(() => {
    if (!openVendorDrawer) {
      setPage(1);
      setVendorList([]);
      setSearch("");
      setEditValue("");
      setSelectedVendorData(null);
      setOpenEditInput(false);
    }
  }, [openVendorDrawer]);

  // check already have brand or not
  useEffect(() => {
    if (searchVal.trim()) {
      const matchedVendor = vendorList.find((value) => {
        return value.name === searchVal.trim().toLowerCase();
      });
      setAlreadyHaveBrand(matchedVendor || null);
    } else {
      setAlreadyHaveBrand(null); // Reset if searchVal is empty
    }
  }, [searchVal, vendorList]);

  useEffect(() => {
    // On component unmount, reset the customer data
    return () => {
      setVendorList([]); // Reset local state
      dispatch(vendorState({ key: "vendorList.data", value: [] })); // Reset Redux state
    };
  }, [dispatch]);
  return (
    <Fragment>
      <div className="flex flex-col flex-grow h-full overflow-y-auto">
        <div className="flex items-center justify-between gap-5 px-5 py-3">
          <p className="text-lg font-semibold text-info-500">
            {t("Manage Your Vendor")}
          </p>
          <IconButton size="sm" variant="text" onClick={handler}>
            <FontAwesomeIcon icon={faClose} fontSize={18} />
          </IconButton>
        </div>
        <div className="flex-grow px-5 py-3 overflow-y-auto">
          <div className="flex flex-col h-full gap-y-3">
            {openEditInput ? (
              <Fragment>
                <Input
                  type={"search"}
                  placeholder={t("Edit Brand")}
                  iconStart={
                    <FontAwesomeIcon
                      icon={faPencil}
                      fontSize={16}
                      className="text-secondary-500"
                    />
                  }
                  className={
                    "bg-secondary-100 text-info-400 placeholder:text-secondary-500 font-medium placeholder:font-medium"
                  }
                  value={editValue}
                  onChange={handleChangeEditVendor}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      setSelectedVendorData(null);
                      setOpenEditInput(false);
                      setEditValue("");
                    }
                  }}
                />
                {selectedVendorData?.name !== editValue && (
                  <div
                    className={`flex justify-between items-center bg-primary-150 border border-primary-400 text-primary-400 rounded-theme p-2`}
                  >
                    <span className="text-base font-medium capitalize">
                      {t("Rename")} {selectedVendorData?.name} {t("To")}{" "}
                      {editValue} !
                    </span>
                    <Button
                      type="button"
                      className="font-semibold capitalize rounded-md bg-primary-400"
                      size="sm"
                      onClick={handleUpdateVendor}
                      loading={isVendorUpdateLoading}
                      disabled={!editValue || isVendorUpdateLoading}
                    >
                      {t("Save")}
                    </Button>
                  </div>
                )}
              </Fragment>
            ) : (
              <div>
                <Input
                  type={"search"}
                  placeholder={t("Search Or Create Vendor")}
                  iconStart={
                    <FontAwesomeIcon
                      icon={faSearch}
                      fontSize={16}
                      className="text-secondary-500"
                    />
                  }
                  className={
                    "bg-secondary-100 text-info-400 placeholder:text-secondary-500 font-medium placeholder:font-medium placeholder:text-sm"
                  }
                  value={search}
                  onChange={handleChangeSearch}
                />
              </div>
            )}
            {!alreadyHaveBrand && search !== "" && !isSearch && !isLoading && (
              <div
                className={`flex justify-between items-center bg-primary-150 border border-primary-400 text-primary-400 rounded-theme p-2`}
              >
                <span className="text-base font-medium capitalize">
                  {search} {t("create new brand")}
                </span>
                <IconButton
                  type="button"
                  className="rounded-md text-primary-400"
                  size="sm"
                  variant="text"
                  disabled={isCreateVendorLoading}
                  onClick={handleCreateVendor}
                >
                  <FontAwesomeIcon icon={faCirclePlus} fontSize={18} />
                </IconButton>
              </div>
            )}
            <div
              className="flex-grow overflow-hidden overflow-y-auto border rounded-lg border-secondary-200"
              onScroll={handleScroll}
            >
              <div className="sticky top-0 z-10 grid grid-cols-5 gap-6 p-3 border-b bg-secondary-50 border-secondary-200">
                <p className="col-span-3 text-base font-medium text-info-500">
                  {t("Vendors")}
                </p>
                {/* <p className="text-base font-medium text-center text-info-500">
                  {t("Rename")}
                </p>
                <p className="text-base font-medium text-center text-info-500">
                  {t("Delete")}
                </p> */}
              </div>
              <div className="">
                <div className="">
                  {vendorList?.length > 0 &&
                    vendorList.map((item, index) => (
                      <Fragment key={index}>
                        <div className="px-1">
                          <div className="grid grid-cols-5 border-b gap-x-6 border-secondary-200">
                            <div className="flex items-center col-span-3 gap-2 p-1">
                              <Radio
                                checked={
                                  item._id === formik.values.vendor_id
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  formik.setFieldValue("vendor_id", item._id);
                                  setSelectedVendor(item);
                                }}
                                label={item.name}
                                labelProps={{
                                  className:
                                    "text-info-400 font-medium capitalize",
                                }}
                              />
                            </div>
                            <div className="flex items-center justify-center">
                              <IconButton
                                type="button"
                                size="sm"
                                variant="text"
                                onClick={() => {
                                  setSelectedVendorData(item);
                                  setOpenEditInput(true);
                                  setEditValue(item.name);
                                }}
                              >
                                {/* <FontAwesomeIcon
                                  icon={faPencil}
                                  fontSize={16}
                                  className="text-primary-400"
                                /> */}
                                <ManageRenameProductIcon className="w-4 h-4" />
                              </IconButton>
                            </div>
                            <div className="flex items-center justify-center">
                              <IconButton
                                type="button"
                                size="sm"
                                variant="text"
                                onClick={() => {
                                  setOpenDeleteModal(true);
                                  setSelectedVendorData(item);
                                }}
                              >
                                {/* <FontAwesomeIcon
                                  icon={faTrashAlt}
                                  fontSize={16}
                                  className="text-red-500"
                                /> */}
                                <ManageDeleteProductIcon className="w-4 h-4" />
                              </IconButton>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    ))}
                  {(isSearch || isLoading) && (
                    <div className="flex items-center justify-center pt-6 col-span-full">
                      <Loader />
                    </div>
                  )}
                  {vendorData?.length === 0 && !isSearch && !isLoading && (
                    <div className="col-span-full">
                      <NoDataFound message={"No Vendor Available"} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center gap-x-5 p-6 flex-grow-0 bg-white shadow-[0px_4px_16.3px_0px_#64748B4D]">
          <Button
            type="button"
            className="text-base font-semibold capitalize bg-white border border-secondary-400 text-secondary-400"
            fullWidth
            onClick={handler}
          >
            {t("Cancel")}
          </Button>
          <Button
            type="button"
            className="text-base font-semibold capitalize border border-primary-400 bg-primary-400"
            fullWidth
            onClick={() => {
              formik.setFieldValue("vendor", selectedVendor);
              setSelectedVendor(null);
              handler();
            }}
          >
            {t("Done")}
          </Button>
        </div>
      </div>
      <Modal
        open={openDeleteModal}
        handler={() => {
          setOpenDeleteModal(false);
          setSelectedVendorData(null);
        }}
        size="xs"
      >
        <div className="flex flex-col">
          <p className="pb-3 text-lg font-semibold text-info-400">
            {t("Delete")}
          </p>
          <p className="text-base font-medium text-secondary-400 pb-7">
            {t("Are you sure to delete this Vendor ?")}
          </p>
          <div className="flex items-center justify-end gap-x-4">
            <Button
              type="button"
              size="sm"
              className="text-sm font-semibold capitalize border border-primary-400 bg-primary-400"
              onClick={() => {
                setOpenDeleteModal(false);
                setSelectedVendorData(null);
              }}
            >
              {t("No, Cancel")}
            </Button>
            <Button
              type="button"
              size="sm"
              className="text-sm font-semibold text-red-500 capitalize bg-white border border-red-500"
              loading={isVendorDeleteLoading}
              onClick={handleDeleteVendor}
            >
              {t("Yes, delete vendor")}
            </Button>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default ManageVendor;
