import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCategoriesList } from "../../store/register/category/action";
import Loader from "../../components/common/Loader";
import { useTranslation } from "react-i18next";

const CategoryList = ({ selectedCategory, setSelectedCategory }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isLoading, categoryData, totalPages } = useSelector((store) => ({
    isLoading: store?.category?.categories?.loading,
    categoryData: store?.category?.categories?.data,
    totalPages: store?.category?.categories?.totalPages,
  }));

  const [page, setPage] = useState(1);
  const [limit] = useState(10); // Keep limit constant, so no need for setLimit
  const [category, setCategory] = useState([]);

  // Handle scroll to load more categories
  const handleScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    if (
      scrollHeight - scrollTop === clientHeight &&
      totalPages > page &&
      !isLoading
    ) {
      setPage((prevPage) => prevPage + 1); // Load next page when scrolled to bottom
    }
  };

  // Fetch categories when page changes
  useEffect(() => {
    dispatch(getCategoriesList({ page, limit }));
  }, [dispatch, page, limit]);

  // Merge new categories with existing ones
  useEffect(() => {
    if (categoryData?.length > 0) {
      setCategory((prevCategory) => [...prevCategory, ...categoryData]);
    }
  }, [categoryData]);

  // Handle tab click and category selection
  const handleTabClick = (categoryId) => {
    if (categoryId === null) {
      setSelectedCategory([]); // Clear all selections if "All" is clicked
    } else {
      setSelectedCategory(
        (prevSelected) =>
          prevSelected.includes(categoryId)
            ? prevSelected.filter((id) => id !== categoryId) // Remove if already selected
            : [...prevSelected, categoryId] // Add if not selected
      );
    }
  };

  return (
    <div
      onScroll={handleScroll}
      className="flex items-center max-w-full gap-2 pb-2 overflow-x-auto cursor-pointer lg:gap-4"
    >
      {/* "All" selection that clears all selected categories */}
      {/* <Link
                to="#"
                onClick={() => handleTabClick(null)}
                className={`border border-secondary-200 flex items-center justify-center xl:text-base text-sm_18 font-medium ${selectedCategory.length === 0
                    ? "bg-primary-400 text-white border-primary-400"
                    : "text-secondary-400"
                    } xl:rounded-theme rounded-base xl:px-[30px] lg:px-5 px-3.5 xl:py-4 py-2 hover:bg-primary-400 hover:text-white transition-all duration-300 hover:border-primary-400 text-nowrap`}
            >
                {t("All")}
            </Link> */}
      {category?.map((items) => (
        <Link
          to="#"
          key={items?._id}
          onClick={() => handleTabClick(items?._id)}
          className={`border border-secondary-200 flex items-center justify-center xl:text-base text-sm_18 font-medium ${
            selectedCategory.includes(items?._id)
              ? "bg-primary-400 text-white border-primary-400"
              : "text-secondary-400"
          } xl:rounded-theme rounded-base xl:px-[30px] lg:px-5 px-3.5 xl:py-4 py-2 hover:bg-primary-150 hover:text-primary-400 transition-all duration-300 hover:border-primary-400 text-nowrap capitalize`}
        >
          {items?.name}
        </Link>
      ))}
      {isLoading && (
        <div>
          <Loader width={23} height={23} />
        </div>
      )}
    </div>
  );
};

export default CategoryList;
