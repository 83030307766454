export const checkRoutesAvailability = {
  REGISTER: [
    "/register/cash-drawer",
    "/register/transaction",
    "/register/daily-summary",
  ],
  SETTINGS: [
    "/settings/users",
    "/settings/users/form",
    "/settings/location",
    "/settings/all-device",
    "/settings/this-device",
    "/settings/integration/payment",
    "/settings/integration/e-commerce",
    "/settings/integration/quick-book",
    "/settings/integration/system-logout",
  ],
  INVENTORY: [
    "/inventory/items",
    "/inventory/items/form",
    "/inventory/items/variant",
    "/inventory/import",
    "/inventory/export",
  ],
  REPORT: ["/report/metrics", "/report/history", "/report/customers"],
};

export const roleTypes = {
  CLERK: "Clerk",
  STORE_MANAGER: "Store Manager",
  OWNER: "Organization Owner",
};

export const numberFormat = ["1,000.00", "1 000.00", "1 000,00"];

export const redeemablePointMinimum = [100, 250, 500, 1000];

export const pointsRedeemableDelay = [
  { label: "Immediately", value: "immediately" },
  { label: "Days 7", value: "days 7" },
  { label: "Days 15", value: "days 15" },
  { label: "Days 30", value: "days 30" },
];

export const valueOfPoints = [
  "0.01",
  "0.02",
  "0.03",
  "0.04",
  "0.05",
  "0.06",
  "0.07",
  "0.08",
  "0.09",
  "0.10",
  "0.11",
  "0.12",
  "0.13",
  "0.14",
  "0.15",
  "0.16",
  "0.17",
  "0.18",
  "0.19",
  "0.20",
];

export const adjustPoints = {
  EARNED: "earned",
  REDEEMED: "redeemed",
  ADJUSTED: "adjusted",
};

export const discountType = {
  PERCENTAGE: "percentage",
  PRICE: "amount",
};

export const paymentTypes = {
  CASH: "cash",
  DEBIT: "debit",
  CREDIT: "credit",
  GIFT_CARD: "giftcard",
  STORE_CREDIT: "storecredit",
};

export const orderStatus = {
  HOLD: "hold",
  COMPLETED: "completed",
  RETURN: "return",
};
