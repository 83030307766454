import axios from "axios";
import { toast } from "react-toastify";

const AxiosRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/v1",
});

// Add request interceptor to add the access token to headers
AxiosRequest.interceptors.request.use(
  async (config) => {
    // Get the access token from your storage (e.g., localStorage)
    const accessToken = localStorage.getItem("auth_token");

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let isRefreshing = false;
let refreshSubscribers = [];

const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

const onRefreshed = (newToken) => {
  refreshSubscribers.map((cb) => cb(newToken));
};

const clearSubscribers = () => {
  refreshSubscribers = [];
};

// Add response interceptor to handle token refresh
AxiosRequest.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Check if the error is due to token expiration
    if (error.response.status === 426 && !originalRequest._retry) {
      if (isRefreshing) {
        // If a token refresh is already happening, wait for it to complete
        return new Promise((resolve) => {
          subscribeTokenRefresh((newToken) => {
            originalRequest.headers["Authorization"] = "Bearer " + newToken;
            resolve(AxiosRequest(originalRequest));
          });
        });
      }

      // Set retry flag to avoid loops
      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const refreshToken = localStorage.getItem("refresh_token"); // Get refresh token from storage
        const { data } = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_API_URL}/v1/auth/update-token`,
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        });

        const { access, refresh } = data.data;
        // Save new access token to storage
        localStorage.setItem("auth_token", access.token);
        localStorage.setItem("refresh_token", refresh.token);

        // Retry original request with new token
        originalRequest.headers["Authorization"] = `Bearer ${access.token}`;
        onRefreshed(access.token);
        clearSubscribers();
        isRefreshing = false;

        return AxiosRequest(originalRequest);
      } catch (refreshError) {
        // Handle refresh token failure (e.g., log out the user)
        console.log(refreshError, "refreshError.................")
        clearSubscribers();
        isRefreshing = false;
        return Promise.reject(refreshError);
      }
    }

    if (error.response.status === 401) {
      localStorage.removeItem("auth_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("user");
      localStorage.removeItem("pinAccess");
      window.location.href = "/";
    }
    toast.error(error?.response?.data?.message);
    return Promise.reject(error);
  }
);

export default AxiosRequest;
