import { t } from "i18next";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const InnerSidePanel = ({ data }) => {
  const location = useLocation();

  return (
    <div className="flex-shrink-0 bg-white rounded-theme overflow-hidden w-60 xl:w-72">
      {(data || []).map((item, index) => (
        <Link
          to={item.path}
          className={`px-5 py-3.5 flex justify-start items-center gap-x-3 group ${
            item.path === location.pathname
              ? "bg-primary-150"
              : "hover:bg-gray-50"
          }  transition-all ease-in-out duration-150`}
          key={index}
        >
          {item.icon({ location, path: item.path })}
          <p
            className={`${
              item.path === location.pathname
                ? "font-semibold text-primary-400"
                : "text-secondary-500 group-hover:text-primary-400 transition-all ease-in-out duration-150"
            }`}
          >
            {t(item.title)}
          </p>
        </Link>
      ))}
    </div>
  );
};

export default InnerSidePanel;
