import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, IconButton } from "@material-tailwind/react";
import { ErrorMessage, Form, Formik } from "formik";
import React from "react";
import Input from "../common/Input";
import { useDispatch, useSelector } from "react-redux";
import { updateAdjustPointRequest } from "../../store/customer/actions";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
const AdjustLoyaltyPoints = ({
  singleCustomer,
  setOpenAdjustModal,
  selectedCustomerId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((store) => ({
    isLoading: store?.customer?.updateAdjustPoint?.loading,
  }));

  return (
    <Formik
      enableReinitialize
      initialValues={{ type: "", points: "" }}
      validationSchema={Yup.object().shape({
        points: Yup.number()
          .min(1, t("Min points 1 allowed"))
          .required(t("Please add value of points")),
      })}
      onSubmit={(values, { resetForm }) => {
        const body = {
          type: values.type,
          points: Number(values.points),
        };
        dispatch(updateAdjustPointRequest({customerId: selectedCustomerId,body,
            setModalClose: () => {
              setOpenAdjustModal(false);
            },
          })).then((res) => {
          if (res?.status === 200) {
            resetForm();
          }
        });
      }}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <Form className="flex flex-col gap-y-3" onSubmit={handleSubmit}>
          <div className="flex justify-between items-center">
            <h4 className="text-base font-bold text-info-400">
              {t("Loyalty Point")}
            </h4>
            <IconButton
              size=""
              variant="text"
              onClick={() => setOpenAdjustModal(false)}
            >
              <FontAwesomeIcon
                icon={faClose}
                fontSize={18}
                className="text-secondary-500"
              />
            </IconButton>
          </div>
          <div className="">
            <Input
              type={"number"}
              className={"bg-secondary-50 placeholder:font-medium"}
              placeholder={t("Adjust your loyalty point")}
              name="points"
              value={values.points}
              onChange={(event) => setFieldValue("points", event.target.value)}
            />
            <ErrorMessage
              name="points"
              component={"p"}
              className="text-red-500 mt-1 text-sm font-medium"
            />
          </div>
          <p className="text-base font-medium capitalize text-secondary-500">
            {t("current balance")}:{" "}
            <span className="text-primary-400">
              {singleCustomer?.redeemable_loyalty_points} {t("pts")}
            </span>
          </p>
          <div className="flex items-center justify-end gap-x-4">
            <Button
              type="submit"
              className="bg-white border border-primary-400 text-primary-400 capitalize font-medium text-sm flex justify-center items-center gap-x-2"
              onClick={() => setFieldValue("type", "minus")}
              loading={values?.type === "minus" && isLoading}
              disabled={isLoading}
            >
              {t("Subtract")}
            </Button>
            <Button
              type="submit"
              className="bg-primary-400 border border-primary-400 capitalize font-medium text-sm flex justify-center items-center gap-x-2"
              loading={values?.type === "add" && isLoading}
              disabled={isLoading}
              onClick={() => {
                setFieldValue("type", "add");
              }}
            >
              {t("Add Points")}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AdjustLoyaltyPoints;
