import { toast } from "react-toastify";
import { accountSettingState } from "./state";
import AxiosRequest from "../../../AxiosRequest";

/* -------------------------------------------------------------------------- */
/*                              Get user details                              */
/* -------------------------------------------------------------------------- */
export const getUserData = () => async (dispatch) => {
  try {
    dispatch(
      accountSettingState({ key: "getAccountData.loading", value: true })
    );
    const response = await AxiosRequest.get("/user/own-details");
    const data = await response.data;
    if (data) {
      dispatch(
        accountSettingState({ key: "getAccountData.data", value: data })
      );
      dispatch(
        accountSettingState({ key: "getAccountData.loading", value: false })
      );
    }
    return response;
  } catch (error) {
    toast.error(error);
    dispatch(
      accountSettingState({ key: "getAccountData.loading", value: false })
    );
    dispatch(
      accountSettingState({ key: "getAccountData.error", value: error })
    );
  }
};

/* -------------------------------------------------------------------------- */
/*                           Update account setting                           */
/* -------------------------------------------------------------------------- */
export const updateUserData =
  ({ body, navigate }) =>
    async (dispatch) => {
      try {
        dispatch(
          accountSettingState({ key: "updateAccount.loading", value: true })
        );
        const response = await AxiosRequest.put("/user/update-profile", body);
        const data = await response.data.data;
        if (data) {
          localStorage.setItem("user", JSON.stringify(data));
          toast.success(response.data.message);
          navigate("/settings/users")
          dispatch(
            accountSettingState({ key: "updateAccount.loading", value: false })
          );
        }
      } catch (error) {
        toast.error(error);
        dispatch(
          accountSettingState({ key: "updateAccount.loading", value: false })
        );
        dispatch(
          accountSettingState({ key: "updateAccount.error", value: error })
        );
      }
    };
