// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend"; // Optional, if you are using a backend

// Import your translation files
import translationEN from "./locales/en/translation.json";
import translationFR from "./locales/fr/translation.json";

// The translations
const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};

// Initialize i18next
i18n
  .use(Backend) // Load translations from a backend server (optional)
  .use(initReactI18next)
  .init({
    resources,
    lng: "en", // Default language
    fallbackLng: "en", // Fallback language
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  });

export default i18n;
