import { toast } from "react-toastify";
import AxiosRequest from "../../../AxiosRequest";
import { categoriesState } from "./state";

/* -------------------------------------------------------------------------- */
/*                           get Categories list request                           */
/* -------------------------------------------------------------------------- */

export const getCategoriesListRequest =
  ({ search = "", page, limit }) =>
    async (dispatch) => {
      try {
        // dispatch(categoriesState({ key: "categoryList.data", value: [] }))
        dispatch(categoriesState({ key: "categoryList.loading", value: true }));
        const response = await AxiosRequest.get(
          `/user/category/list?search=${search}&page=${page}&limit=${limit}`
        );
        const data = await response.data.data;
        if (data) {
          dispatch(
            categoriesState({ key: "categoryList.data", value: data?.categories })
          );
          dispatch(
            categoriesState({
              key: "categoryList.totalPages",
              value: data?.totalPages,
            })
          );
          dispatch(
            categoriesState({ key: "categoryList.loading", value: false })
          );
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(categoriesState({ key: "categoryList.loading", value: false }));
        dispatch(categoriesState({ key: "categoryList.error", value: error }));
      }
    };

/* -------------------------------------------------------------------------- */
/*                           add new Category request                           */
/* -------------------------------------------------------------------------- */

export const createCategoryRequest =
  ({ name }) =>
    async (dispatch) => {
      try {
        dispatch(categoriesState({ key: "createCategory.loading", value: true }));
        const response = await AxiosRequest.post("/user/category/create", {
          name,
        });
        const data = await response.data.data;
        if (data) {
          dispatch(
            categoriesState({ key: "createCategory.loading", value: false })
          );
          dispatch(
            getCategoriesListRequest({ search: name, page: 1, limit: 15 })
          );
          toast.success(response.data.message);
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(
          categoriesState({ key: "createCategory.loading", value: false })
        );
        dispatch(categoriesState({ key: "createCategory.error", value: error }));
      }
    };

/* -------------------------------------------------------------------------- */
/*                           update Category request                */
/* -------------------------------------------------------------------------- */

export const updateCategoryRequest =
  ({ categoryId, name }) =>
    async (dispatch) => {
      try {
        dispatch(categoriesState({ key: "updateCategory.loading", value: true }));
        const response = await AxiosRequest.put(
          `/user/category/update/${categoryId}`,
          { name }
        );
        const data = await response.data.data;
        if (data) {
          dispatch(
            categoriesState({ key: "updateCategory.loading", value: false })
          );
          dispatch(categoriesState({ key: "updateCategory.data", value: data }));
          toast.success(response.data.message);
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(
          categoriesState({ key: "updateCategory.loading", value: false })
        );
        dispatch(categoriesState({ key: "updateCategory.error", value: error }));
      }
    };

/* -------------------------------------------------------------------------- */
/*                           delete category request                           */
/* -------------------------------------------------------------------------- */

export const deleteCategoryRequest =
  ({ categoryId }) =>
    async (dispatch) => {
      try {
        dispatch(categoriesState({ key: "deleteCategory.loading", value: true }));
        const response = await AxiosRequest.delete(
          `/user/category/delete/${categoryId}`
        );
        const data = await response.data;
        if (data) {
          dispatch(
            categoriesState({ key: "deleteCategory.loading", value: false })
          );
          dispatch(categoriesState({ key: "deleteCategory.data", value: data }));
          toast.success(response.data.message);
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(
          categoriesState({ key: "deleteCategory.loading", value: false })
        );
        dispatch(categoriesState({ key: "deleteCategory.error", value: error }));
      }
    };
