import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
    brandList: {
        loading: false,
        data: [],
        error: false,
        totalPages: null,
    },
    deleteBrand: {
        loading: false,
        data: null,
        error: null,
    },
    createBrand: {
        loading: false,
        data: null,
        error: null,
    },
    updateBrand: {
        loading: false,
        data: null,
        error: null,
    }
};

export const brandsSlice = createSlice({
    name: "brandsList",
    initialState: initialState,
    reducers: {
        brandsState: (state, { payload }) => {
            if (Array.isArray(payload)) {
                for (const obj of payload) {
                    _.set(state, obj.key, obj.value);
                }
            } else {
                _.set(state, payload.key, payload.value);
            }
        },
    },
});
export const { brandsState } = brandsSlice.actions;
export default brandsSlice.reducer;