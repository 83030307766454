import {
  faArrowLeft,
  faCirclePlus,
  faPencil,
  faSearch,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, IconButton } from "@material-tailwind/react";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCustomerRequest,
  // getCustomerListRequest,
} from "../../store/customer/actions";
import { customerState } from "../../store/customer/state";
import { getInitialsFirstCharAt, useDebounce } from "../../utils/utils";
import Input from "../common/Input";
import Loader from "../common/Loader";
import NoDataFound from "../common/NoDataFound";
import CustomerForm from "./CustomerForm";
import Modal from "../common/Modal";
import { useTranslation } from "react-i18next";
import { getCartItemsRequest } from "../../store/order/actions";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { getCustomerListRequest } from "../../rq-store/customer";
import { toast } from "react-toastify";
import { ManageDeleteProductIcon, ManageRenameProductIcon } from "../svgs";

const CustomerList = ({
  openSearchCustomer,
  setOpenSearchCustomer,
  selectedCustomer,
  setSelectedCustomer,
  formik,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    // isLoading,
    customersData,
    totalPages,
    createCustomer,
    isDeleteLoading,
    deleteCustomerData,
  } = useSelector((store) => ({
    // isLoading: store?.customer?.customerList?.loading,
    customersData: store?.customer?.customerList?.data,
    totalPages: store?.customer?.customerList?.totalPages,
    createCustomer: store?.customer?.createCustomer?.data,
    isDeleteLoading: store?.customer?.deleteCustomer?.loading,
    deleteCustomerData: store?.customer?.deleteCustomer?.data,
  }));
  // const [customersListData, setCustomersListData] = useState([]);
  const [searchCustomer, setSearchCustomer] = useState(
    selectedCustomer
      ? selectedCustomer?.first_name + " " + selectedCustomer?.last_name
      : ""
  );
  const searchValue = useDebounce(searchCustomer);
  const [isSearch, setIsSearch] = useState(false);
  const [page, setPage] = useState(1);
  const [openCustomerForm, setOpenCustomerForm] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [openDeleteCustomer, setOpenDeleteCustomer] = useState(false);
  const [alreadyHaveUser, setAlreadyHaveUser] = useState(false);

  const queryClient = useQueryClient();
  console.log(isSearch, "isSearch...............................");
  console.log(searchValue, "searchValue...............................");

  // customer list api request
  const {
    data,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteQuery({
    queryKey: ["customerList"],
    queryFn: ({ pageParam = 1 }) =>
      getCustomerListRequest({
        page: pageParam,
        limit: 15,
        search: searchCustomer,
      }),
    getNextPageParam: (lastPage, allPages) => {
      console.log(lastPage, "lastPage.............");
      console.log(allPages, "allPages.............");
      return lastPage?.customers?.length >= 15
        ? allPages?.length + 1
        : undefined;
    },
    retry: 1, // Set to retry only 1 time upon failure
    staleTime: 5 * 60 * 1000,
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const customersListData = data?.pages?.flatMap(
    (page) => page.customers || []
  );

  console.log(
    customersListData,
    "customersListDatacustomersListDatacustomersListDatacustomersListDatacustomersListDatacustomersListData............."
  );
  // add ref when first page data over for fetch new data
  const { ref, inView } = useInView();

  // fetch next page on scroll
  useEffect(() => {
    console.log(hasNextPage, "hasNextPage........");
    console.log(inView, "inView........");
    if (!isFetching && inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  console.log(alreadyHaveUser, "alreadyHaveUser..........");

  const handleChangeCustomer = (event) => {
    setSearchCustomer(event.target.value);
    // setIsSearch(true);
    if (page > 1) setPage(1);
    // setCustomersListData([]);
  };

  const handleSelectCustomer = (item) => {
    console.log(item, "item...........................");
    setSelectedCustomer(item);
    formik.setFieldValue("order.customer_id", item);
    setOpenSearchCustomer(false);
    dispatch(customerState({ key: "customerList.data", value: [] }));
    dispatch(
      customerState({
        key: "customerList.totalPages",
        value: null,
      })
    );
    if (formik.values.order.customer_id) {
      formik.setFieldValue("orderItems", []);
      dispatch(getCartItemsRequest({ id: item?._id }));
    } else {
      dispatch(getCartItemsRequest({ id: item?._id }));
    }
    // setCustomersListData([]);
  };

  const handleScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    let scrollOffset = scrollHeight - scrollTop - clientHeight;
    if (scrollOffset === 0 && totalPages > page && openSearchCustomer) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleCreateCustomer = () => {
    setOpenCustomerForm(true);
  };

  const handleDeleteCustomer = (event, item) => {
    event.stopPropagation();
    setSelectedCustomerId(item?._id);
    setOpenDeleteCustomer(true);
  };

  const handleEditCustomer = (event, item) => {
    event.stopPropagation();
    setSelectedCustomerId(item?._id);
    setOpenCustomerForm(true);
  };

  const deleteCustomerAction = () => {
    dispatch(deleteCustomerRequest({ customerId: selectedCustomerId }));
  };

  useEffect(() => {
    if (createCustomer !== null) {
      handleSelectCustomer(createCustomer);
      setSearchCustomer("");
      dispatch(customerState({ key: "createCustomer.data", value: null }));
    }
  }, [createCustomer, dispatch]);

  // useEffect(() => {
  //   if (openSearchCustomer) {
  //     console.log("Set data from 1");
  //     // setCustomersListData([]); // Reset local state

  //     !isLoading &&
  //       dispatch(
  //         getCustomerListRequest({ page, limit: 15, search: searchCustomer })
  //       );
  //     setIsSearch(false);
  //   }
  // }, [openSearchCustomer, dispatch, searchValue]);

  // useEffect(() => {
  //   if (openSearchCustomer && page > 1) {
  //     console.log("Set data from 2");
  //     !isLoading &&
  //       dispatch(
  //         getCustomerListRequest({ search: searchCustomer, page, limit: 15 })
  //       );
  //     setIsSearch(false);
  //   }
  // }, [openSearchCustomer, dispatch, page]);

  // useEffect(() => {
  //   if (customersData?.length > 0) {
  //     // setCustomersListData([...customersListData, ...customersData]);
  //   }
  // }, [customersData]);

  useEffect(() => {
    if (deleteCustomerData !== null) {
      const oldArr = [...customersListData];
      const findIndex = oldArr?.findIndex(
        (item) => item?._id === selectedCustomerId
      );
      if (findIndex !== -1) {
        setOpenDeleteCustomer(false);
        oldArr.splice(findIndex, 1);
        // setCustomersListData(oldArr);
        setSelectedCustomerId(null);
        dispatch(customerState({ key: "deleteCustomer.data", value: null }));
      }
    }
  }, [deleteCustomerData, dispatch]);

  // check already have user or not
  useEffect(() => {
    setIsSearch(true);
    if (searchValue?.trim()) {
      const matchedUser = customersListData?.find((value) => {
        const fullName = `${value.first_name} ${value.last_name}`.toLowerCase();
        return fullName === searchValue.trim().toLowerCase();
      });
      setAlreadyHaveUser(matchedUser || null);
    } else {
      setAlreadyHaveUser(null); // Reset if searchValue is empty
    }
    queryClient.removeQueries({ queryKey: ["customerList"] });
    queryClient.invalidateQueries({ queryKey: ["customerList"] });

    setIsSearch(false);
  }, [searchValue]);

  // useEffect(() => {
  //   // On component unmount, reset the customer data
  //   return () => {
  //     // setCustomersListData([]); // Reset local state
  //     dispatch(customerState({ key: "customerList.data", value: [] })); // Reset Redux state
  //   };
  // }, [dispatch]);

  return (
    <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-col w-full h-full p-4 bg-white gap-y-2">
      {openCustomerForm ? (
        <CustomerForm
          selectedCustomerId={selectedCustomerId}
          setSelectedCustomerId={setSelectedCustomerId}
          setOpenCustomerForm={setOpenCustomerForm}
        />
      ) : (
        <Fragment>
          <div className="flex items-center gap-x-2">
            <IconButton
              type="button"
              className="flex-shrink-0 border shadow-none bg-secondary-50 text-primary-400"
              onClick={() => {
                console.log("select Customer");
                setOpenSearchCustomer(false);
                dispatch(
                  customerState({ key: "customerList.data", value: [] })
                );
                dispatch(
                  customerState({
                    key: "customerList.totalPages",
                    value: null,
                  })
                );
                // setCustomersListData([]);
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} fontSize={18} />
            </IconButton>
            <h3 className="text-lg font-medium text-info-400">
              {t("Select Customer")}
            </h3>
          </div>
          <Input
            type={"search"}
            iconStart={
              <FontAwesomeIcon icon={faSearch} className="text-secondary-500" />
            }
            autoFocus
            value={searchCustomer}
            onChange={handleChangeCustomer}
            className={
              "!font-medium placeholder:text-secondary-400  w-full px-2 text-sm"
            }
            placeholder={t("Search Or Create Customer")}
          />
          {!alreadyHaveUser &&
            searchCustomer !== "" &&
            !isSearch &&
            !isLoading &&
            !isFetching && (
              <div
                className={`flex justify-between items-center bg-primary-150 border border
                  -primary-400 text-primary-400 rounded-theme py-2 pl-3 pr-2`}
              >
                <span className="text-sm font-medium capitalize">
                  {searchCustomer} {t("create new customer")}
                </span>
                <IconButton
                  type="button"
                  className="rounded-md text-primary-400"
                  size="sm"
                  variant="text"
                  onClick={handleCreateCustomer}
                >
                  <FontAwesomeIcon icon={faCirclePlus} fontSize={18} />
                </IconButton>
              </div>
            )}
          <div
            className="flex-grow overflow-hidden overflow-y-auto border rounded-lg border-secondary-200"
            onScroll={handleScroll}
          >
            {customersListData?.map((item, index) => (
              <button
                type="button"
                className="p-2.5 w-full flex gap-x-2 items-center hover:border hover:border-primary-400 "
                key={index}
                onClick={() => handleSelectCustomer(item)}
              >
                <div className="flex items-center justify-center flex-shrink-0 font-bold uppercase rounded-full w-11 h-11 bg-secondary-100 text-secondary-400">
                  {getInitialsFirstCharAt(item?.first_name, item?.last_name)}
                </div>
                <div className="grow">
                  <h3 className="text-base font-medium capitalize text-start text-info-400">
                    {item?.first_name + " " + item?.last_name}
                  </h3>
                  <h3 className="text-start text-[12px]">
                    {item?.code + " " + item?.phone}
                  </h3>
                </div>
                <div className="flex items-center flex-shrink-0 gap-x-2">
                  <IconButton
                    type="button"
                    variant="text"
                    className=""
                    size="sm"
                    onClick={(event) => handleEditCustomer(event, item)}
                  >
                    <ManageRenameProductIcon className="w-4 h-4" />
                  </IconButton>
                  <IconButton
                    type="button"
                    variant="text"
                    className=""
                    size="sm"
                    onClick={(event) => handleDeleteCustomer(event, item)}
                  >
                    <ManageDeleteProductIcon className="w-4 h-4" />
                  </IconButton>
                </div>
              </button>
            ))}
            <div
              className={`${
                !hasNextPage && "hidden"
              } flex justify-center my-1 ${isFetching && "opacity-50"}`}
              ref={ref}
            >
              {(isSearch || isLoading || isFetching) && <Loader />}
            </div>
            {(isSearch || isLoading) && (
              <div className="flex justify-center my-10">
                <Loader />
              </div>
            )}
            {customersListData?.length === 0 && !isSearch && !isLoading && (
              <div className="flex items-center justify-center h-full">
                <NoDataFound message={t("No Customers Available")} />
              </div>
            )}
          </div>
          <Modal
            open={openDeleteCustomer}
            handler={() => {
              setSelectedCustomerId(null);
              setOpenDeleteCustomer(false);
            }}
          >
            <div className="flex flex-col">
              <p className="pb-3 text-lg font-semibold text-info-400">
                {t("Delete")}
              </p>
              <p className="text-base font-medium text-secondary-400 pb-7">
                {t("Are you sure to delete this customer ?")}
              </p>
              <div className="flex items-center justify-end gap-x-4">
                <Button
                  type="button"
                  size="sm"
                  onClick={() => {
                    setSelectedCustomerId(null);
                    setOpenDeleteCustomer(false);
                  }}
                  className="text-sm font-semibold capitalize border border-primary-400 bg-primary-400"
                >
                  {t("No, Cancel")}
                </Button>
                <Button
                  type="button"
                  size="sm"
                  className="text-sm font-semibold text-red-500 capitalize bg-white border border-red-500"
                  loading={isDeleteLoading}
                  onClick={deleteCustomerAction}
                >
                  {t("Yes, delete customer")}
                </Button>
              </div>
            </div>
          </Modal>
        </Fragment>
      )}
    </div>
  );
};

export default CustomerList;
