import { toast } from "react-toastify";
import AxiosRequest from "../../AxiosRequest";
import { authSetState } from "./auth.slice";
import { createRecord, deleteRecord } from "../../database/db-operation";

export const loginAction = (params, navigate) => async (dispatch) => {
  try {
    dispatch(authSetState({ key: "loading", value: true }));

    const response = await AxiosRequest.post("/auth/login", params);
    const { data: loginData } = response.data;

    if (loginData) {
      dispatch(authSetState({ key: "loading", value: false }));
      dispatch(authSetState({ key: "user", value: loginData?.user || {} }));
      deleteRecord("loginUser", loginData?.user?._id);
      createRecord("loginUser", loginData?.user);
      localStorage.setItem("user", JSON.stringify(loginData?.user));
      localStorage.setItem(
        "user_org_setting",
        JSON.stringify(loginData?.userOrgLocationSetting)
      );
      localStorage.setItem("auth_token", loginData?.tokens?.access?.token);
      localStorage.setItem("refresh_token", loginData?.tokens?.refresh?.token);
      localStorage.setItem("primaryColor", loginData?.userDeviceSetting?.color);
      localStorage.setItem(
        "language",
        loginData?.userDeviceSetting?.language?.code
      );

      // dispatch(
      //   authSetState({ key: "role", value: loginData?.user?.role_id || {} })
      // );
      // dispatch(authSetState({ key: "isLoggedIn", value: true }));
      // dispatch(
      //   authSetState({
      //     key: "section_permissions",
      //     value: loginData?.user?.section_permissions || {},
      //   })
      // );

      navigate("/pin");
      toast.success(response.data.message);
    }
    return response;
  } catch (error) {
    navigate("/");
    toast.error(error);
    dispatch(authSetState({ key: "loading", value: false }));
  }
};

export const forgotPassword =
  ({ body }) =>
  async (dispatch) => {
    try {
      dispatch(authSetState({ key: "forgotPassword.loading", value: true }));
      const response = await AxiosRequest.post("/auth/forget-password", body);
      const data = response.data;
      if (data) {
        dispatch(authSetState({ key: "forgotPassword.data", value: data }));
        dispatch(authSetState({ key: "forgotPassword.loading", value: false }));
      }
    } catch (error) {
      toast.error(error);
      dispatch(authSetState({ key: "forgotPassword.loading", value: false }));
      dispatch(authSetState({ key: "forgotPassword.error", value: error }));
    }
  };

export const verifyPinLock =
  ({ body, navigate, setFieldValue }) =>
  async (dispatch) => {
    try {
      dispatch(authSetState({ key: "loading", value: true }));
      const response = await AxiosRequest.post("/auth/validate-pin", body);
      const data = response.data;
      if (data) {
        localStorage.setItem("pinAccess", true);
        navigate("/register");
      }
      dispatch(authSetState({ key: "loading", value: false }));
    } catch (error) {
      toast.error(error);
      setFieldValue("pin", "");
      dispatch(authSetState({ key: "loading", value: false }));
      return error;
    }
  };

export const validateTokenRequest =
  ({ uuid, navigate }) =>
  async (dispatch) => {
    try {
      dispatch(authSetState({ key: "loading", value: true }));
      const response = await AxiosRequest.post(
        "/auth/validate-reset-password",
        { uuid }
      );
      const data = response.data;
      dispatch(authSetState({ key: "loading", value: false }));
      return data;
    } catch (error) {
      toast.error(error);
      navigate("/forgot-password");
      dispatch(authSetState({ key: "loading", value: false }));
    }
  };

export const resetPasswordRequest =
  ({ body, navigate }) =>
  async (dispatch) => {
    try {
      dispatch(authSetState({ key: "loading", value: true }));
      const response = await AxiosRequest.post("/auth/change-password", body);
      const data = response.data;
      dispatch(authSetState({ key: "loading", value: false }));
      if (data) {
        navigate("/");
      }
    } catch (error) {
      toast.error(error);
      dispatch(authSetState({ key: "loading", value: false }));
    }
  };
