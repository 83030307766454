import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import StoreCreditReport from "../../components/report/StoreCreditReport";

const initialState = {
    DailySummary: {
        loading: false,
        data: [],
        error: false,
    },
    paymentSummary: {
        loading: false,
        data: [],
        error: false,
    },
    staleInventorySummary: {
        loading: false,
        data: [],
        error: false,
    },
    inventoryValuation: {
        loading: false,
        data: [],
        error: false,
    },
    deadStockSummary: {
        loading: false,
        data: [],
        error: false,
    },
    salesByProduct: {
        loading: false,
        data: [],
        error: false,
    },
    returnRefundSummary: {
        loading: false,
        data: [],
        error: false,
    },
    taxReport: {
        loading: false,
        data: [],
        error: false,
    },
    giftCardUsage: {
        loading: false,
        data: [],
        error: false,
    },
    giftCardSales: {
        loading: false,
        data: [],
        error: false,
    },
    StoreCreditReport: {
        loading: false,
        data: [],
        error: false,
    }
}


export const reportSlice = createSlice({
    name: "report",
    initialState: initialState,
    reducers: {
        reportState: (state, { payload }) => {
            if (Array.isArray(payload)) {
                for (const obj of payload) {
                    _.set(state, obj.key, obj.value);
                }
            } else {
                _.set(state, payload.key, payload.value);
            }
        },
    },
});

export const { reportState } = reportSlice.actions;
export default reportSlice.reducer;
