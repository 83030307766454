import {
  faArrowLeft,
  faChevronRight,
  faPenToSquare,
  faPercent,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, IconButton } from "@material-tailwind/react";
import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Input from "../../components/common/Input";
import Loader from "../../components/common/Loader";
import PasswordInput from "../../components/common/PasswordInput";
import { ProfileUploadSvg } from "../../components/svgs";
import Layout from "../../layout";
import {
  getUserData,
  updateUserData,
} from "../../store/settings/account/actions";
import { generatePin } from "../../utils/utils";
import { useTranslation } from "react-i18next";
import SidePanel from "../../components/common/SidePanel";
import LocationsAccess from "../../components/settings/users/LocationsAccess";
import ViewLocationsAccess from "../../components/settings/users/ViewLocationAccess";

const AccountSetting = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, accountSettingData, isLoadingUpdate } = useSelector(
    (store) => ({
      accountSettingData: store?.account?.getAccountData?.data?.data,
      isLoading: store?.account?.getAccountData?.loading,
      isLoadingUpdate: store?.account?.updateAccount?.loading,
    })
  );
  const [uploadedImage, setUploadedImage] = useState(null);
  const [isImageChanged, setIsImageChanged] = useState(false);
  const [openLocationDrawer, setOpenLocationDrawer] = useState(false);

  console.log(accountSettingData, "accountSettingData..............");

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    initialValues: {
      username: accountSettingData?.username ?? "",
      email: accountSettingData?.email ?? "",
      password: "",
      pin: "",
      discount: accountSettingData?.discount ?? "",
      role_id: accountSettingData?.role_id.role ?? "",
      location_access_ids: accountSettingData?.location_access_ids ?? "",
      image: accountSettingData?.image ?? null,
    },
    validationSchema: Yup.object({
      username: Yup.string().required(t("Username is required")),
    }),
    validate: (values) => {
      const errors = {};

      if (values.password && values.password !== values.confirmPassword) {
        errors.confirmPassword = t("Passwords must match");
      }

      return errors;
    },
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("username", values.username);
      values.password && formData.append("password", values.password);
      values.pin && formData.append("pin", values.pin);
      if (isImageChanged) {
        formData.append("image", values.image);
      }
      // Dispatch update user profile
      dispatch(updateUserData({ body: formData, navigate }));
    },
  });

  // Dispatch get user details
  useEffect(() => {
    dispatch(getUserData());
  }, [dispatch]);

  // Set user profile
  useEffect(() => {
    if (accountSettingData?.image) {
      setUploadedImage(accountSettingData.image);
    }
  }, [accountSettingData]);

  console.log(formik.errors, "formik.errors");

  return (
    <Layout>
      {isLoading && (
        <div className="absolute z-20 flex items-center justify-center w-full h-full bg-white/70 backdrop-blur-sm">
          <Loader />
        </div>
      )}
      <form onSubmit={formik.handleSubmit}>
        <div className="bg-white w-full xl:p-5 lg:p-4 p-3.5 rounded-theme mb-2.5">
          <div className="flex gap-1.5 items-center">
            <IconButton
              type="button"
              variant="text"
              className=""
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon icon={faArrowLeft} fontSize={18} />
            </IconButton>
            <p className="text-info-500 xl:text-xl lg:text-lg text-base font-semibold -mt-0.5">
              {t("Account Setting")}
            </p>
          </div>
        </div>
        <div className="bg-white xl:h-[calc(100vh-206px)] h-[calc(100vh-166px)] w-full py-[30px] xl:px-[50px] lg:px-7 px-5 rounded-theme">
          <div className="grid 2xl:grid-cols-4 grid-cols-3 xl:gap-[40px] lg:gap-6 gap-5 ">
            <div className="2xl:col-span-3 col-span-2 overflow-y-auto xl:h-[calc(100vh-252px)] h-[calc(100vh-236px)] pr-2">
              {/* user details */}
              <div className="flex lg:gap-[30px] gap-5 border-b border-secondary-200 w-full pb-[50px]">
                <div className="lg:max-w-[190px] max-w-[150px] w-full">
                  <p className="text-base font-medium capitalize xl:text-xl lg:text-lg text-info-500">
                    {t("user details")}
                  </p>
                  <p className="font-normal capitalize xl:text-sm lg:text-sm_20 text-sm_18 text-secondary-700">
                    {t("identification information")}
                  </p>
                </div>
                <div className="max-w-[425px] w-full flex flex-col gap-y-8">
                  <div>
                    <Input
                      type={"text"}
                      label={t("username")}
                      labelProps={"capitalize text-secondary-500"}
                      className={"bg-secondary-50"}
                      name="username"
                      value={formik.values.username}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      autoComplete="off" // Prevent autofill
                    />
                    <p className="text-red-600">{formik.errors.username}</p>
                  </div>
                  <div>
                    <Input
                      type={"email"}
                      label={t("Email")}
                      labelProps={"normal-case text-secondary-500"}
                      className={"bg-secondary-50"}
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled
                    />
                  </div>
                </div>
              </div>
              {/* security */}
              <div className="flex lg:gap-[30px] gap-5 border-b border-secondary-200 w-full py-[50px]">
                <div className="lg:max-w-[190px] max-w-[150px] w-full">
                  <h2 className="text-base font-medium capitalize xl:text-xl lg:text-lg text-info-500">
                    {t("security")}
                  </h2>
                  <p className="font-normal capitalize xl:text-sm lg:text-sm_20 text-sm_18 text-secondary-700">
                    {t("Password must be at least 8 characters")}
                  </p>
                </div>
                <div className="max-w-[425px] w-full flex flex-col gap-y-8">
                  <div>
                    <PasswordInput
                      label={t("New Password")}
                      labelProps={"capitalize text-secondary-500"}
                      className={
                        "bg-secondary-50 placeholder:text-sm placeholder:font-medium text-secondary-500"
                      }
                      placeholder={t("Enter New Password")}
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      autoComplete="off" // Prevent autofill
                    />
                  </div>

                  <div className="relative">
                    <PasswordInput
                      label={t("Confirm Password")}
                      labelProps={"capitalize text-secondary-500 "}
                      className={
                        "bg-secondary-50 placeholder:text-sm placeholder:font-medium text-secondary-500"
                      }
                      placeholder={t("Enter Confirm Password")}
                      name="confirmPassword"
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>

                  {formik.errors.confirmPassword &&
                    formik.touched.confirmPassword && (
                      <p
                        name="confirmPassword"
                        className="-mt-2 text-sm text-red-500 "
                      >
                        {formik.errors.confirmPassword}
                      </p>
                    )}

                  <div className="relative">
                    <Input
                      type={"number"}
                      label={t("Pin 6 Digit")}
                      labelProps={"normal-case text-secondary-500"}
                      className={
                        "bg-secondary-50 placeholder:text-sm placeholder:font-medium text-secondary-500"
                      }
                      placeholder={t("Create 6 Digit Pin")}
                      name="pin"
                      value={formik.values.pin}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      iconEnd={
                        <IconButton
                          size="sm"
                          variant="text"
                          className="-mr-2.5"
                          onClick={() => {
                            const pin = generatePin();
                            formik.setFieldValue("pin", pin);
                          }}
                        >
                          <FontAwesomeIcon icon={faRotateRight} fontSize={16} />
                        </IconButton>
                      }
                    />
                  </div>
                </div>
              </div>
              {/* access rights */}
              <div className="flex lg:gap-[30px] gap-5 border-b border-secondary-200 w-full py-[50px]">
                <div className="lg:max-w-[190px] max-w-[150px] w-full">
                  <h2 className="text-base font-medium capitalize xl:text-xl lg:text-lg text-info-500">
                    {t("access rights")}
                  </h2>
                  <p className="font-normal capitalize xl:text-sm lg:text-sm_20 text-sm_18 text-secondary-700">
                    {t("Role-Based Access Control")}
                  </p>
                </div>
                <div className="max-w-[425px] w-full flex flex-col gap-y-8">
                  <div>
                    <Input
                      type={"number"}
                      label={t("discount")}
                      labelProps={"capitalize text-secondary-500"}
                      className={"bg-secondary-50"}
                      name="discount"
                      value={formik.values.discount}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      iconEnd={
                        <FontAwesomeIcon icon={faPercent} fontSize={18} />
                      }
                      disabled
                    />
                  </div>
                  <div className="xl:mb-9 lg:mb-8 mb-7">
                    <Input
                      type={"text"}
                      label={t("employee role")}
                      labelProps={"capitalize text-secondary-500"}
                      className={"bg-secondary-50"}
                      name="role_id"
                      value={formik.values.role_id}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled
                    />
                  </div>
                </div>
              </div>
              {/*locations access*/}
              <div className="flex lg:gap-[30px] gap-5 border-b border-secondary-200 py-[50px] w-full">
                <div className="lg:max-w-[190px] max-w-[150px] w-full">
                  <h2 className="text-base font-medium capitalize xl:text-xl lg:text-lg text-info-500">
                    {t("locations access")}
                  </h2>
                </div>
                <div className="max-w-[425px] w-full ">
                  {/* <Input
                    type={"text"}
                    labelProps={"capitalize text-secondary-500"}
                    className={"bg-secondary-50"}
                    name="location_access_ids"
                    value={
                      formik.values.location_access_ids.length + " Locations"
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled
                  /> */}
                  <Button
                    type="button"
                    fullWidth
                    className="flex items-center justify-between text-base font-medium capitalize shadow-none bg-secondary-50 text-info-600 rounded-theme hover:shadow-none"
                    onClick={() => setOpenLocationDrawer(true)}
                  >
                    {
                      <span>
                        {formik?.values?.location_access_ids?.length}{" "}
                        {t("Locations")}
                      </span>
                    }
                    <FontAwesomeIcon icon={faChevronRight} fontSize={16} />
                  </Button>
                </div>
              </div>
              <div className="flex justify-center pt-8 pb-2 mx-auto ">
                <Button
                  type="submit"
                  loading={isLoadingUpdate}
                  disabled={!formik.dirty || !formik.isValid || isLoadingUpdate}
                  className="flex items-center justify-center text-base font-medium capitalize border border-primary-400 bg-primary-400 min-w-52 rounded-theme"
                >
                  {t("Save Changes")}
                </Button>
              </div>
            </div>
            <div className="flex flex-col gap-y-8">
              {/* Add user  */}
              <div className="relative w-[196px] h-[196px] bg-secondary-50 rounded-theme flex justify-center items-center">
                <label
                  htmlFor="profile-input"
                  className="flex flex-col items-center justify-center w-full h-full overflow-hidden rounded-theme gap-y-2"
                >
                  <input
                    type="file"
                    id="profile-input"
                    name="image"
                    onChange={(event) => {
                      if (event.target.files.length > 0) {
                        formik.setFieldValue("image", event.target.files[0]);
                        setUploadedImage(
                          URL.createObjectURL(event.target.files[0])
                        );
                        setIsImageChanged(true); // Set image changed to true
                      }
                    }}
                    className="!absolute w-full h-full top-0 left-0 opacity-0"
                  />
                  {uploadedImage ? (
                    <img
                      src={uploadedImage}
                      alt="user-profile"
                      className="object-cover w-full h-full"
                    />
                  ) : (
                    <Fragment>
                      <ProfileUploadSvg />
                      <p className="capitalize text-sm_18 text-secondary-400">
                        {t("Upload Image")}
                      </p>
                    </Fragment>
                  )}
                </label>
                {uploadedImage && (
                  <label
                    htmlFor="profile-input"
                    className="!absolute -bottom-3 -right-3 cursor-pointer flex justify-center bg-white items-center w-9 h-9 rounded-full shadow-[0px_4px_14px_0px_#00000040]"
                  >
                    <FontAwesomeIcon icon={faPenToSquare} fontSize={16} />
                  </label>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>

      <SidePanel
        open={openLocationDrawer}
        handler={() => setOpenLocationDrawer(false)}
        width="w-[450px]"
      >
        <ViewLocationsAccess
          // formik={formik}
          // userDetail={userDetail}
          location_access={accountSettingData?.location_access}
          closeHandler={() => setOpenLocationDrawer(false)}
        />
      </SidePanel>
    </Layout>
  );
};

export default AccountSetting;
