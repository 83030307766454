import { toast } from "react-toastify";
import AxiosRequest from "../../../AxiosRequest";
import { vendorState } from "./state";

/* -------------------------------------------------------------------------- */
/*                           get vendors list request                           */
/* -------------------------------------------------------------------------- */

export const getVendorsListRequest =
  ({ search = "", page, limit }) =>
    async (dispatch) => {
      try {
        dispatch(vendorState({ key: "vendorList.loading", value: true }));
        const response = await AxiosRequest.get(
          `/user/vendor/list?search=${search}&page=${page}&limit=${limit}`
        );
        const data = await response.data.data;
        if (data) {
          dispatch(
            vendorState({ key: "vendorList.data", value: data?.vendorTags })
          );
          dispatch(
            vendorState({ key: "vendorList.totalPages", value: data?.totalPages })
          );
          dispatch(vendorState({ key: "vendorList.loading", value: false }));
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(vendorState({ key: "vendorList.loading", value: false }));
        dispatch(vendorState({ key: "vendorList.error", value: error }));
      }
    };

/* -------------------------------------------------------------------------- */
/*                           add new vendor request                           */
/* -------------------------------------------------------------------------- */

export const createVendorRequest =
  ({ name }) =>
    async (dispatch) => {
      try {
        dispatch(vendorState({ key: "createVendor.loading", value: true }));
        const response = await AxiosRequest.post("/user/vendor/create", { name });
        const data = await response.data.data;
        if (data) {
          dispatch(vendorState({ key: "createVendor.loading", value: false }));
          dispatch(getVendorsListRequest({ search: name, page: 1, limit: 15 }));
          toast.success(response.data.message);
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(vendorState({ key: "createVendor.loading", value: false }));
        dispatch(vendorState({ key: "createVendor.error", value: error }));
      }
    };

/* -------------------------------------------------------------------------- */
/*                           update Vendor request                */
/* -------------------------------------------------------------------------- */

export const updateVendorRequest =
  ({ vendorId, name }) =>
    async (dispatch) => {
      try {
        dispatch(vendorState({ key: "updateVendor.loading", value: true }));
        const response = await AxiosRequest.put(
          `/user/vendor/update/${vendorId}`,
          { name }
        );
        const data = await response.data.data;
        if (data) {
          dispatch(vendorState({ key: "updateVendor.loading", value: false }));
          dispatch(vendorState({ key: "updateVendor.data", value: data }));

          toast.success(response.data.message);
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(vendorState({ key: "updateVendor.loading", value: false }));
        dispatch(vendorState({ key: "updateVendor.error", value: error }));
      }
    };

/* -------------------------------------------------------------------------- */
/*                           delete vendor request                           */
/* -------------------------------------------------------------------------- */

export const deleteVendorRequest =
  ({ vendorId }) =>
    async (dispatch) => {
      try {
        dispatch(vendorState({ key: "deleteVendor.loading", value: true }));
        const response = await AxiosRequest.delete(
          `/user/vendor/delete/${vendorId}`
        );
        const data = await response.data;
        if (data) {
          dispatch(vendorState({ key: "deleteVendor.loading", value: false }));
          dispatch(vendorState({ key: "deleteVendor.data", value: data }));

          toast.success(response.data.message);
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(vendorState({ key: "deleteVendor.loading", value: false }));
        dispatch(vendorState({ key: "deleteVendor.error", value: error }));
      }
    };
