import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  productList: {
    loading: false,
    data: [],
    error: false,
    totalPages: null
  },
  deleteProduct: {
    loading: false,
    data: null,
    error: null,
  },
  createProduct: {
    loading: false,
    data: null,
    error: null,
  },
  singleProduct: {
    loading: false,
    data: null,
    error: null,
  },
  updateProduct: {
    loading: false,
    data: null,
    error: null,
  },
  deleteProductImage: {
    loading: false,
    data: null,
    error: null,
  }
};

export const productSlice = createSlice({
  name: "product",
  initialState: initialState,
  reducers: {
    productState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const { productState } = productSlice.actions;
export default productSlice.reducer;
