import { toast } from "react-toastify";
import AxiosRequest from "../../AxiosRequest";
import { reportState } from "./state";





/* -------------------------------------------------------------------------- */
/*                           get Daily Summary request               */
/* -------------------------------------------------------------------------- */

export const getDailySummaryRequest =
    ({
        page,
        limit,
        dateType,
        start_date,
        end_date
    }) =>
        async (dispatch) => {
            try {
                dispatch(
                    reportState({ key: "DailySummary.loading", value: true })
                );
                const response = await AxiosRequest.get(
                    `/user/reports/sales-summary?page=${page}&limit=${limit}&dateType=${dateType}&start_date=${start_date}&end_date=${end_date}`
                );
                console.log(response, "Full API Response");
                const data = await response.data.data;
                if (data) {

                    dispatch(reportState({ key: "DailySummary.data", value: data }));
                    dispatch(
                        reportState({ key: "DailySummary.loading", value: false })
                    );
                }
                return response;
            } catch (error) {
                toast.error(error);
                dispatch(
                    reportState({ key: "DailySummary.loading", value: false })
                );
                dispatch(reportState({ key: "DailySummary.error", value: true }));
            }
        };



/* -------------------------------------------------------------------------- */
/*                           get payment Summary request               */
/* -------------------------------------------------------------------------- */

export const getPaymentSummaryRequest =
    ({ dateType, start_date, end_date, customerID }) =>
        async (dispatch) => {
            try {

                const customerIDString = customerID ? String(customerID) : "";
                console.log(customerID, "customerID........")
                dispatch(
                    reportState({ key: "paymentSummary.loading", value: true })
                );
                const response = await AxiosRequest.get(
                    `/user/reports/payment-summary?dateType=${dateType}&start_date=${start_date}&end_date=${end_date}&customerId=${customerIDString}`
                );
                console.log(response, "Full API Response");
                const data = await response.data.data;
                if (data) {

                    console.log(data, "data")
                    dispatch(reportState({ key: "paymentSummary.data", value: data }));
                    dispatch(
                        reportState({ key: "paymentSummary.loading", value: false })
                    );
                }
                return response;
            } catch (error) {
                toast.error(error);
                dispatch(
                    reportState({ key: "paymentSummary.loading", value: false })
                );
                dispatch(reportState({ key: "paymentSummary.error", value: true }));
            }
        };




/* -------------------------------------------------------------------------- */
/*                           get Stale Inventory request               */
/* -------------------------------------------------------------------------- */

export const getStaleInventorySummaryRequest =
    ({
        page,
        limit,
        dateType,
        start_date,
        end_date,
        category
    }) =>
        async (dispatch) => {
            try {
                dispatch(
                    reportState({ key: "staleInventorySummary.loading", value: true })
                );
                // const response = await AxiosRequest.get(
                //     `/user/reports/inventory-stale-summary?page=${page}&limit=${limit}&dateType=${dateType}&start_date=${start_date}&end_date=${end_date}&category=${category}`
                // );
                // console.log(response, "Full API Response");

                const categoryQuery = category.map(id => `category[]=${id}`).join("&");

                // Construct the full query URL with categories
                const url = `/user/reports/inventory-stale-summary?page=${page}&limit=${limit}&dateType=${dateType}&start_date=${start_date}&end_date=${end_date}&${categoryQuery}`;

                const response = await AxiosRequest.get(url);

                const data = await response.data.data;
                if (data) {

                    console.log(data, "data")
                    dispatch(reportState({ key: "staleInventorySummary.data", value: data }));
                    dispatch(
                        reportState({ key: "staleInventorySummary.loading", value: false })
                    );
                }
                return response;
            } catch (error) {
                toast.error(error);
                dispatch(
                    reportState({ key: "staleInventorySummary.loading", value: false })
                );
                dispatch(reportState({ key: "staleInventorySummary.error", value: true }));
            }
        };




/* -------------------------------------------------------------------------- */
/*                           get Inventory Valuation request               */
/* -------------------------------------------------------------------------- */

export const getInventoryValuationRequest =
    ({
        page,
        limit,
        dateType,
        start_date,
        end_date
    }) =>
        async (dispatch) => {
            try {
                dispatch(
                    reportState({ key: "inventoryValuation.loading", value: true })
                );
                const response = await AxiosRequest.get(
                    `/user/reports/inventory-valuation?page=${page}&limit=${limit}&dateType=${dateType}&start_date=${start_date}&end_date=${end_date}`
                );
                console.log(response, "Full API Response");
                const data = await response.data.data;
                if (data) {

                    dispatch(reportState({ key: "inventoryValuation.data", value: data }));
                    dispatch(
                        reportState({ key: "inventoryValuation.loading", value: false })
                    );
                }
                return response;
            } catch (error) {
                toast.error(error);
                dispatch(
                    reportState({ key: "inventoryValuation.loading", value: false })
                );
                dispatch(reportState({ key: "inventoryValuation.error", value: true }));
            }
        };



/* -------------------------------------------------------------------------- */
/*                           get Dead Stock request               */
/* -------------------------------------------------------------------------- */

export const getDeadStockRequest =
    ({
        page,
        limit,
        dateType,
        start_date,
        end_date,
        type
    }) =>
        async (dispatch) => {
            try {
                dispatch(
                    reportState({ key: "deadStockSummary.loading", value: true })
                );
                const response = await AxiosRequest.get(
                    `/user/reports/dead-stock-inventory?page=${page}&limit=${limit}&dateType=${dateType}&start_date=${start_date}&end_date=${end_date}&type=${type}`
                );
                console.log(response, "Full API Response");
                const data = await response.data.data;
                if (data) {

                    dispatch(reportState({ key: "deadStockSummary.data", value: data }));
                    dispatch(
                        reportState({ key: "deadStockSummary.loading", value: false })
                    );
                }
                return response;
            } catch (error) {
                toast.error(error);
                dispatch(
                    reportState({ key: "deadStockSummary.loading", value: false })
                );
                dispatch(reportState({ key: "deadStockSummary.error", value: true }));
            }
        };



/* -------------------------------------------------------------------------- */
/*                           get sales by product request               */
/* -------------------------------------------------------------------------- */

export const getSalesByProductRequest =
    ({
        page,
        limit,
        dateType,
        start_date,
        end_date,
        category
    }) =>
        async (dispatch) => {
            try {
                dispatch(
                    reportState({ key: "salesByProduct.loading", value: true })
                );
                // const response = await AxiosRequest.get(
                //     `/user/reports/sales-by-products?page=${page}&limit=${limit}&dateType=${dateType}&start_date=${start_date}&end_date=${end_date}&category=${category}`
                // );

                const categoryQuery = category.map(id => `category[]=${id}`).join("&");

                // Construct the full query URL with categories
                const url = `/user/reports/sales-by-products?page=${page}&limit=${limit}&dateType=${dateType}&start_date=${start_date}&end_date=${end_date}&${categoryQuery}`;

                const response = await AxiosRequest.get(url);


                console.log(response, "Full API Response");
                const data = await response.data.data;
                if (data) {

                    dispatch(reportState({ key: "salesByProduct.data", value: data }));
                    dispatch(
                        reportState({ key: "salesByProduct.loading", value: false })
                    );
                }
                return response;
            } catch (error) {
                toast.error(error);
                dispatch(
                    reportState({ key: "salesByProduct.loading", value: false })
                );
                dispatch(reportState({ key: "salesByProduct.error", value: true }));
            }
        };



/* -------------------------------------------------------------------------- */
/*                           get Return And Refund request               */
/* -------------------------------------------------------------------------- */

export const getReturnAndRefundRequest =
    ({
        page,
        limit,
        dateType,
        start_date,
        end_date
    }) =>
        async (dispatch) => {
            try {
                dispatch(
                    reportState({ key: "returnRefundSummary.loading", value: true })
                );
                const response = await AxiosRequest.get(
                    `/user/reports/refunds-and-returns?page=${page}&limit=${limit}&dateType=${dateType}&start_date=${start_date}&end_date=${end_date}`
                );
                console.log(response, "Full API Response");
                const data = await response.data.data;
                if (data) {
                    dispatch(reportState({ key: "returnRefundSummary.data", value: data }));
                    dispatch(
                        reportState({ key: "returnRefundSummary.loading", value: false })
                    );
                }
                return response;
            } catch (error) {
                toast.error(error);
                dispatch(
                    reportState({ key: "returnRefundSummary.loading", value: false })
                );
                dispatch(reportState({ key: "returnRefundSummary.error", value: true }));
            }
        };



/* -------------------------------------------------------------------------- */
/*                           get Tax Report request               */
/* -------------------------------------------------------------------------- */

export const getTaxReportRequest =
    ({
        page,
        limit,
        dateType,
        start_date,
        end_date
    }) =>
        async (dispatch) => {
            try {
                dispatch(
                    reportState({ key: "taxReport.loading", value: true })
                );
                const response = await AxiosRequest.get(
                    `/user/reports/tax-report?page=${page}&limit=${limit}&dateType=${dateType}&start_date=${start_date}&end_date=${end_date}`
                );
                console.log(response, "Full API Response");
                const data = await response.data.data;
                if (data) {
                    dispatch(reportState({ key: "taxReport.data", value: data }));
                    dispatch(
                        reportState({ key: "taxReport.loading", value: false })
                    );
                }
                return response;
            } catch (error) {
                toast.error(error);
                dispatch(
                    reportState({ key: "taxReport.loading", value: false })
                );
                dispatch(reportState({ key: "taxReport.error", value: true }));
            }
        };





/* -------------------------------------------------------------------------- */
/*                           get Gift Card Usage request               */
/* -------------------------------------------------------------------------- */

export const getGiftCardUsageRequest =
    ({
        page,
        limit,
        dateType,
        start_date,
        end_date
    }) =>
        async (dispatch) => {
            try {
                dispatch(
                    reportState({ key: "giftCardUsage.loading", value: true })
                );
                const response = await AxiosRequest.get(
                    `/user/reports/gift-card-usage?page=${page}&limit=${limit}&dateType=${dateType}&start_date=${start_date}&end_date=${end_date}`
                );
                console.log(response, "Full API Response");
                const data = await response.data.data;
                if (data) {

                    dispatch(reportState({ key: "giftCardUsage.data", value: data }));
                    dispatch(
                        reportState({ key: "giftCardUsage.loading", value: false })
                    );
                }
                return response;
            } catch (error) {
                toast.error(error);
                dispatch(
                    reportState({ key: "giftCardUsage.loading", value: false })
                );
                dispatch(reportState({ key: "giftCardUsage.error", value: true }));
            }
        };




/* -------------------------------------------------------------------------- */
/*                           get Gift Card Sales request               */
/* -------------------------------------------------------------------------- */

export const getGiftCardSalesRequest =
    ({
        page,
        limit,
        dateType,
        start_date,
        end_date
    }) =>
        async (dispatch) => {
            try {
                dispatch(
                    reportState({ key: "giftCardSales.loading", value: true })
                );
                const response = await AxiosRequest.get(
                    `/user/reports/gift-card-sales?page=${page}&limit=${limit}&dateType=${dateType}&start_date=${start_date}&end_date=${end_date}`
                );
                console.log(response, "Full API Response");
                const data = await response.data.data;
                if (data) {

                    dispatch(reportState({ key: "giftCardSales.data", value: data }));
                    dispatch(
                        reportState({ key: "giftCardSales.loading", value: false })
                    );
                }
                return response;
            } catch (error) {
                toast.error(error);
                dispatch(
                    reportState({ key: "giftCardSales.loading", value: false })
                );
                dispatch(reportState({ key: "giftCardSales.error", value: true }));
            }
        };



/* -------------------------------------------------------------------------- */
/*                           get Store Credit Report request               */
/* -------------------------------------------------------------------------- */

export const getStoreCreditReportRequest =
    ({
        page,
        limit,
        dateType,
        start_date,
        end_date
    }) =>
        async (dispatch) => {
            try {
                dispatch(
                    reportState({ key: "StoreCreditReport.loading", value: true })
                );
                const response = await AxiosRequest.get(
                    `/user/reports/store-credit-report?page=${page}&limit=${limit}&dateType=${dateType}&start_date=${start_date}&end_date=${end_date}`
                );
                console.log(response, "Full API Response");
                const data = await response.data.data;
                if (data) {

                    dispatch(reportState({ key: "StoreCreditReport.data", value: data }));
                    dispatch(
                        reportState({ key: "StoreCreditReport.loading", value: false })
                    );
                }
                return response;
            } catch (error) {
                toast.error(error);
                dispatch(
                    reportState({ key: "StoreCreditReport.loading", value: false })
                );
                dispatch(reportState({ key: "StoreCreditReport.error", value: true }));
            }
        };






/* -------------------------------------------------------------------------- */
/*                           get sales by product request               */
/* -------------------------------------------------------------------------- */

export const getExportedData =
    ({
        path,
        page,
        limit,
        dateType,
        start_date,
        end_date,
        category
    }) =>
        async (dispatch) => {
            try {
                dispatch(
                    reportState({ key: "exportedData.loading", value: true })
                );
                // const response = await AxiosRequest.get(
                //     `/user${path}?page=${page}&limit=${limit}&dateType=${dateType}&start_date=${start_date}&end_date=${end_date}&category=${category}`
                // );

                const categoryQuery = category.map(id => `category[]=${id}`).join("&");

                // Construct the full query URL with categories
                const url = `/user${path}?page=${page}&limit=${limit}&dateType=${dateType}&start_date=${start_date}&end_date=${end_date}&${categoryQuery}`;

                const response = await AxiosRequest.get(url);

                console.log(response, "Full API Response");
                const data = await response.data.data;
                if (data) {
                    dispatch(reportState({ key: "exportedData.data", value: data }));
                    dispatch(
                        reportState({ key: "exportedData.loading", value: false })
                    );
                }
                return response;
            } catch (error) {
                toast.error(error);
                dispatch(
                    reportState({ key: "exportedData.loading", value: false })
                );
                dispatch(reportState({ key: "exportedData.error", value: true }));
            }
        };
