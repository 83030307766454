import React from "react";
import ShopifyLogo from "../../assets/Shopify-logo.svg";
import { Link } from "react-router-dom";
import IntegrationLayout from "../../layout/IntegrationLayout";
import { t } from "i18next";

const IntegrationEcommerce = () => {
  return (
    <IntegrationLayout>
      <div className="grow 2xl:h-[calc(100vh-132px)] h-[calc(100vh-104px)] overflow-y-auto">
        <div className="bg-white rounded-theme lg:py-10 py-6 flex justify-center items-center mb-2">
          <img
            src={ShopifyLogo}
            alt="shopify-logo"
            className="2xl:max-w-[350px] 2xl:h-[104px] w-full lg:max-w-[290px] lg:h-[60px] max-w-[250px] h-[46px]"
          />
        </div>
        <div className="bg-white rounded-theme py-5 px-4 lg:mb-5 mb-3">
          <div className="text-info-500 lg:text-lg text-base font-semibold lg:mb-6 mb-3">
            {t("Sopify Integration")}
          </div>
          <div className="flex items-center justify-between mb-4 pb-4 border-b border-secondary-200/30">
            <div className="text-secondary-500 lg:text-lg text-base font-medium">
              {t("Integration Status")}
            </div>
            <div className="text-info-500 font-medium lg:text-lg text-base text-right">
              {t("Not Integrated")}
            </div>
          </div>
          <div className="flex items-center justify-between mb-4 pb-4 border-b border-secondary-200/30">
            <div className="text-secondary-500 lg:text-lg text-base font-medium">
              {t("Integrated With Card Smith")}
            </div>
            <label className="relative inline-block w-11 h-5">
              <input type="checkbox" className="opacity-0 w-0 h-0 peer" />
              <span className="absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-secondary-200/40 rounded-full transition duration-400 peer-checked:bg-[#FDC8B8] peer-checked:before:translate-x-6 before:absolute before:content-[''] before:h-[23px] before:w-6 before:left-0 before:top-1/2 before:-translate-y-1/2 before:bg-white peer-checked:before:bg-primary-400 before:rounded-full  before:shadow-[1px_0px_8.2px_0px_#0000002E] before:transition before:duration-400"></span>
            </label>
          </div>
          <div className="flex items-center justify-between lg:pb-4">
            <div className="text-secondary-500 lg:text-lg text-base font-medium">
              {t("Integrated Shop")}
            </div>
            <Link
              href="mailto: quetzal@gmail.com"
              className="text-info-500 font-medium lg:text-lg text-base text-right"
            >
              {t("quetzal@gmail.com")}
            </Link>
          </div>
        </div>
        <div className="bg-primary-400/10 rounded-theme py-5 px-4 xl:mb-5">
          <div className="text-primary-400 lg:text-lg text-base font-semibold mb-4">
            {t("How To Integrate Quetzal With Shopify")}
          </div>
          <ul className="list-decimal list-inside">
            <li className="text-info-500 lg:text-lg_30 text-sm_18 font-medium mb-2">
              Login to your shopify shop's administration area and choose "apps"
              from the left menu.
            </li>
            <li className="text-info-500 lg:text-lg_30 text-sm_18 font-medium mb-4">
              Visit the shopify App Store and search for and install Quetzal
              POS.
            </li>
          </ul>
          <ul className="list-disc list-inside">
            <li className="text-info-500 lg:text-lg_30 text-sm_18 font-medium">
              {t("You will be asked to confirm")}
              <span className="text-primary-400">
                &nbsp; {t("your 8-digit quetzal location code")} &nbsp;
              </span>
              {t("during installation.")}
            </li>
          </ul>
        </div>
        <div className="bg-white rounded-theme py-5 px-4 mb-5">
          <div className="text-info-500 lg:text-lg text-base font-semibold mb-4">
            {t("Status")}
          </div>
          <div className="flex items-center justify-between">
            <div className="text-secondary-500 lg:text-lg text-sm_18 font-medium">
              {t("Latest Sync")}
            </div>
            <div className="text-info-500 font-medium lg:text-lg text-sm_18 text-right">
              {t("25th december,2024 16:25AM")}
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <button className="bg-primary-400 xl:max-w-[315px] max-w-[200px] w-full text-center text-white border border-primary-400 lg:text-lg text-sm_20 font-medium lg:py-3.5 py-2.5 lg:px-8 px-7 rounded-theme capitalize">
            {t("Sync Now")}
          </button>
        </div>
      </div>
    </IntegrationLayout>
  );
};

export default IntegrationEcommerce;
