import React, { useEffect, useLayoutEffect, useState } from "react";
import CalendarIcon from "../../assets/icon/calendar.svg";
import FilterDarkGray from "../../assets/icon/filter-dark-grey.svg";
import { Link } from "react-router-dom";
import ReportWrapper from "../../layout/ReportWrapper";
import { useDispatch, useSelector } from "react-redux";
import {
  getDeadStockRequest,
  getExportedData,
  getInventoryValuationRequest,
  getReturnAndRefundRequest,
} from "../../store/report/actions";
import Loader from "../common/Loader";
import ReactSelect from "../../components/common/ReactSelect";
import {
  filterOptions,
  formatCurrencyWithNumberValue,
  paymentTypeReportOptions,
} from "../../utils/utils";

import {
  Button,
  Chip,
  Input,
  Popover,
  PopoverContent,
  PopoverHandler,
  Radio,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
} from "@material-tailwind/react";
import * as XLSX from "xlsx"; // Import XLSX for Excel export
import { t } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faArrowRightLong,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { ExportIcon, ShortingIcon } from "../svgs";
import DataTable from "../common/DataTable";
import moment from "moment";
import { useTranslation } from "react-i18next";

const DeadStock = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  // get staleInventory summary details
  const { isLoading, totalPages, deadStockSummary } = useSelector((store) => ({
    isLoading: store?.report?.deadStockSummary?.loading,
    totalPages: store?.report?.deadStockSummary?.data?.totalPages,
    deadStockSummary: store?.report?.deadStockSummary?.data?.inventories,
  }));
  const filterOptions = [
    { label: `${t("This Year")}`, value: "this_year" },
    { label: `${t("Last Week")}`, value: "last_week" },
    { label: `${t("Last Month")}`, value: "last_month" },
    { label: `${t("Last 6 Months")}`, value: "last_6_months" },
    { label: `${t("Last Year")}`, value: "last_year" },
  ];
  console.log(deadStockSummary, "deadStockSummary");

  const dispatch = useDispatch();

  const [selectedDateType, setSelectedDateType] = useState("today");
  const [startDate, setStartDate] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [endDate, setEndDate] = useState(null);
  const [openPopover, setOpenPopover] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  // const [selectTab, setSelectTab] = useState("all-inventory");  // Set initial value to "all-inventory"

  // Define columns for table
  const columns = () => [
    {
      Header: t("Product Name"),
      key: "product_name",
    },
    {
      Header: t("SKU"),
      key: "sku",
    },
    {
      Header: t("Variant"),
      key: "",
      render: (row) => <div>{row.variant || "-"}</div>,
    },

    {
      Header: t("Cost Price"),
      key: "",
      render: (row) => (
        <div>{formatCurrencyWithNumberValue(row.cost_price.toFixed(2))}</div>
      ),
    },
    {
      Header: "Selling price",
      key: "",
      render: (row) => (
        <div>{formatCurrencyWithNumberValue(row.selling_price.toFixed(2))}</div>
      ),
    },

    {
      Header: t("Sold Items"),
      key: "",
      render: (row) => <div>{row.itemSold || "-"}</div>,
    },
    {
      Header: t("Closed Inventory"),
      key: "closing_inventory",
    },
  ];

  console.log(selectedCategory, "selectedCategory");

  const handlePageChange = (page) => {
    setPage(page.selected + 1);
  };

  const handlePerPageChange = (limit) => {
    setLimit(limit);
  };

  const data = [
    {
      label: "All Inventory",
      value: "all-inventory",
      desc: (
        <div className="w-full px-5 bg-white">
          <DataTable
            columns={columns()} // using the updated columns function
            data={deadStockSummary}
            maxHeight="max-h-[calc(100vh-195px)]"
            loading={isLoading}
            search={""}
            page={page}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
            handlePerPageChange={handlePerPageChange}
            message={t("No Data Found")}
          />
        </div>
      ),
    },
    {
      label: "On Hand Inventory",
      value: "on-hand-inventory",
      desc: (
        <div className="w-full px-5 bg-white">
          <DataTable
            columns={columns()} // using the updated columns function
            data={deadStockSummary}
            maxHeight="max-h-[calc(100vh-195px)]"
            loading={isLoading}
            search={""}
            page={page}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
            handlePerPageChange={handlePerPageChange}
            message={t("No Data Found")}
          />
        </div>
      ),
    },
    {
      label: "Low Inventory",
      value: "low-inventory",
      desc: (
        <div className="w-full px-5 bg-white">
          <DataTable
            columns={columns()} // using the updated columns function
            data={deadStockSummary}
            maxHeight="max-h-[calc(100vh-195px)]"
            loading={isLoading}
            search={""}
            page={page}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
            handlePerPageChange={handlePerPageChange}
            message={t("No Data Found")}
          />
        </div>
      ),
    },
  ];
  const [selectTab, setSelectTab] = useState(data[0].value); // default to first tab

  // Handle tab change and update the state
  // const handleTabChange = (newValue) => {
  //   console.log("Selected Tab:", newValue);
  //   setSelectTab(newValue);
  // };

  // pass filter data in API when press filter button then call
  const applyFilter = () => {
    console.log("apply");
    dispatch(
      getDeadStockRequest({
        page: page,
        limit,
        dateType: selectedDateType?.value || selectedDateType,
        start_date: selectedDateType === "custom" ? startDate : "",
        end_date: selectedDateType === "custom" ? endDate : "",
        type: selectTab ? selectTab : "", // Using the selectTab state
      })
    );
    setOpenPopover(false);
  };

  // Function to handle Export
  const handleExport = async () => {
    const totalLimit = totalPages * limit || limit; // Calculate the total limit for the export
    setLimit(totalLimit);

    await dispatch(
      getExportedData({
        path: "/reports/dead-stock-inventory",
        page: page,
        limit: totalLimit,
        dateType: selectedDateType?.value || selectedDateType,
        start_date: selectedDateType === "custom" ? startDate : "",
        end_date: selectedDateType === "custom" ? endDate : "",
        category: selectedCategory,
        type: selectTab ? selectTab : "", // Using the selectTab state
      })
    ).then((response) => {
      const salesData = response?.data?.data?.inventories || [];

      // Format the data for Excel export
      const formattedData = salesData.map((row) => ({
        "Product Name": row.product_name,
        SKU: row.SKU,
        Variant: row.variant || "-",
        "Cost Price": row?.cost_price,
        "Selling Price": row?.selling_price,
        "Sold Items": row?.itemSold || "-",
        "Closed Inventory": row?.closing_inventory || 0,
      }));

      console.log(formattedData, "formattedData...................");

      // Convert data to Excel format
      const worksheet = XLSX.utils.json_to_sheet(formattedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "DeadStock");

      // Export the Excel file
      XLSX.writeFile(workbook, "DeadStock.xlsx");

      // Reset the limit to 10 and reload the page
      setLimit(10); // Reset limit to 10
    });
  };

  // dispatch get stale inventory summary list request
  useLayoutEffect(() => {
    dispatch(
      getDeadStockRequest({
        page: page,
        limit,
        dateType: selectedDateType?.value || selectedDateType,
        start_date: selectedDateType === "custom" ? startDate : "",
        end_date: selectedDateType === "custom" ? endDate : "",
        type: selectTab ? selectTab : "", // Using the selectTab state
      })
    );
  }, [dispatch, limit, page, selectTab]);

  console.log(selectTab, "selected......");

  return (
    <ReportWrapper>
      <div className="grow overflow-y-auto xl:h-[calc(100vh-132px)] h-[calc(100vh-106px)]">
        <div className="flex items-center justify-between p-3 mb-4 bg-white rounded-theme xl:py-5 lg:px-5 lg:py-4">
          <div className="text-base font-semibold lg:text-lg 2xl:font-bold text-info-500 2xl:text-xl">
            {t("Dead Stock")}
          </div>
          <div className="flex items-center ">
            {/* <div className="gap-2.5  xl:py-[14px] lg:py-2.5 py-1.5 px-[18px] ">
              <div>
                <ReactSelect
                  className="border-none min-w-[115px] w-full"
                  labelProps={"!text-xs font-semibold capitalize"}
                  options={paymentTypeReportOptions}
                  value={selectedPaymentType}
                  onChange={(data) => setSelectedPaymentType(data)}
                />
              </div>
            </div> */}
            <div className="flex items-center gap-3">
              <div>
                <Popover
                  placement="bottom-end"
                  open={openPopover}
                  handler={setOpenPopover}
                >
                  <PopoverHandler>
                    <Button className="flex items-center gap-3 text-sm font-semibold capitalize bg-white border border-gray-500 text-secondary-400">
                      <ShortingIcon />
                      {t("Filter")}
                      <FontAwesomeIcon
                        icon={openPopover ? faAngleUp : faAngleDown}
                      />
                    </Button>
                  </PopoverHandler>
                  <PopoverContent className="z-40  lg:w-[900px] w-[calc(100%-16px)] space-y-4 ">
                    <div className="flex items-center justify-between">
                      <p className="text-lg font-semibold capitalize text-info-500">
                        {t("Filter Options")}
                      </p>
                      <FontAwesomeIcon
                        onClick={() => setOpenPopover(false)}
                        icon={faClose}
                        className="w-5 h-5 text-info-500"
                      />
                    </div>
                    <div className="p-5 border border-secondary-200 rounded-theme">
                      <p className="text-lg font-medium capitalize text-secondary-400">
                        {t("Date Range")}
                      </p>
                      <div className="flex items-center gap-2 pt-4">
                        {[
                          "custom",
                          "today",
                          "yesterday",
                          "this_week",
                          "this_month",
                        ].map((type) => (
                          <p
                            key={type}
                            onClick={() => setSelectedDateType(type)}
                            className={`border ${
                              selectedDateType === type
                                ? "border-primary-400 bg-primary-150 text-primary-400"
                                : "border-secondary-200 text-secondary-400"
                            } flex items-center justify-center xl:text-base text-sm_18 font-medium  rounded-3xl xl:px-[30px] lg:px-5 px-3.5 xl:py-3 py-2 cursor-pointer transition-all duration-300 text-nowrap capitalize`}
                          >
                            {t(type.split("_").join(" "))}
                          </p>
                        ))}
                        <ReactSelect
                          labelProps={"!text-xs font-semibold capitalize"}
                          placeholder={language === "fr" ? "plus" : "More"}
                          options={filterOptions}
                          value={selectedDateType}
                          onChange={(data) => setSelectedDateType(data)}
                        />
                      </div>
                      {selectedDateType === "custom" && (
                        <div className="flex items-center gap-5 pt-6">
                          <div>
                            <Input
                              label={t("Start Date")}
                              type={"date"}
                              value={startDate}
                              max={new Date().toISOString().split("T")[0]}
                              onChange={(e) => setStartDate(e.target.value)}
                              className={
                                "bg-secondary-50 xl:max-w-[248px] lg:max-w-[190px] max-w-[170px] placeholder:font-medium placeholder:text-secondary-500 text-base px-2"
                              }
                            />
                          </div>
                          <div className="">
                            <FontAwesomeIcon
                              icon={faArrowRightLong}
                              className="w-5 h-5 "
                            />
                          </div>
                          <div>
                            <Input
                              label={t("End Date")}
                              type={"date"}
                              value={endDate}
                              max={new Date().toISOString().split("T")[0]}
                              onChange={(e) => setEndDate(e.target.value)}
                              className={
                                "bg-secondary-50 xl:max-w-[248px] lg:max-w-[190px] max-w-[170px] placeholder:font-medium placeholder:text-secondary-500 text-base px-2"
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <Button
                      type="submit"
                      fullWidth
                      onClick={applyFilter}
                      className="bg-primary-400 capitalize font-medium text-base w-[143px] h-[46px]"
                    >
                      {t("Filter")}
                    </Button>
                  </PopoverContent>
                </Popover>
              </div>
              <Button
                onClick={handleExport}
                className="flex items-center gap-3 text-sm font-semibold capitalize border bg-primary-150 text-primary-400 border-primary-400"
              >
                <ExportIcon />
                {/* <FontAwesomeIcon icon={faFolder} className="w-5 h-5" /> */}
                {t("Export")}
              </Button>
            </div>
          </div>
        </div>

        <Tabs value={selectTab} className="w-full bg-white ">
          <TabsHeader
            className="pl-5 mt-4 bg-transparent rounded-none border-secondary-200"
            indicatorProps={{
              className:
                "bg-transparent border-b-2 border-primary-400 shadow-none rounded-none",
            }}
          >
            {data?.map((item, index) => (
              <Tab
                key={index}
                value={item.value}
                onClick={() => setSelectTab(item.value)}
                className={`min-w-44 w-44 max-w-80   ${
                  selectTab === item.value
                    ? "text-primary-400 font-bold capitalize"
                    : "text-secondary-800 font-semibold capitalize"
                }`}
              >
                {t(item.label)}
              </Tab>
            ))}
          </TabsHeader>
          <TabsBody className="mt-5 bg-white rounded-theme">
            {data.map((item, index) => (
              <div
                key={index}
                hidden={selectTab !== item.value}
                className="transition-opacity duration-500 ease-in-out"
              >
                {item.desc}
              </div>
            ))}
          </TabsBody>
        </Tabs>
      </div>
    </ReportWrapper>
  );
};

export default DeadStock;
