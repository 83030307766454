import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  ProductContext,
  ProductProvider,
} from "../../helpers/context/ProductContext";
import { Button, IconButton } from "@material-tailwind/react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCircleXmark,
  faPlus,
  faRotateRight,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Layout from "../../layout";
import Input from "../../components/common/Input";
import ReactSelect from "../../components/common/ReactSelect";
import {
  ManageDeleteProductIcon,
  ProfileUploadSvg,
} from "../../components/svgs";
import SidePanel from "../../components/common/SidePanel";
import ManageBrand from "../../components/inventory/ManageBrand";
import ManageCategories from "../../components/inventory/ManageCategories";
import ManageVendor from "../../components/inventory/ManageVendor";
import { generateCode, loyaltyPointsOptions } from "../../utils/utils";
import { cloneDeep } from "lodash";
import Loader from "../../components/common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { deleteProductImageRequest } from "../../store/inventory/product/actions";
import { Cropper } from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";
import ImageCropperModal from "./ImageCropper";
import Modal from "../../components/common/Modal";

const ProductForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const productImageRef = useRef(null);
  const { formik, singleData, isLoading } = useContext(ProductContext);
  const dispatch = useDispatch();

  console.log(
    formik?.values,
    "formik?.values........................................"
  );

  const { isCreateLoading, isUpdateLoading } = useSelector((store) => ({
    isCreateLoading: store?.product?.createProduct?.loading,
    isUpdateLoading: store?.product?.updateProduct?.loading,
  }));

  const [uploadedImage, setUploadedImage] = useState(null);
  const [openBrandDrawer, setOpenBrandDrawer] = useState(false);
  const [openCategoriesDrawer, setOpenCategoriesDrawer] = useState(false);
  const [openVendorDrawer, setOpenVendorDrawer] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(true);
  const [openCropperImage, setOpenCropperImage] = useState(false);
  const [originalFile, setOriginalFile] = useState(null);
  const [imageAlreadyDeleted, setImageAlreadyDeleted] = useState(false);

  console.log(singleData, "singleData.................................");
  const handleDeleteImageUpload = (event) => {
    console.log("delete image.................................");
    event.stopPropagation();
    if (productImageRef.current) {
      productImageRef.current.value = "";
    }
    singleData?.image &&
      singleData?.image !==
        "https://s3.amazonaws.com/quetzalpos.bucket.dev/default_images/default-product-img.png" &&
      !imageAlreadyDeleted &&
      dispatch(
        deleteProductImageRequest({
          productId: singleData?._id,
          setImageAlreadyDeleted,
        })
      );
    const fileInput = document.getElementById("product-image");
    if (fileInput) fileInput.value = ""; // Clear the value of the input field for web
    formik.setFieldValue("image", null);

    setUploadedImage(null);
  };

  useEffect(() => {
    if (formik.values.variants_possibilities?.length > 0) {
      formik.setFieldValue("quantities", "");
      formik.setFieldValue("selling_price", "");
      formik.setFieldValue("cost_price", "");
    }
  }, [formik.values.variants_possibilities?.length]);

  useEffect(() => {
    if (singleData?.image) {
      setUploadedImage(singleData?.image);
    }
  }, [singleData]);

  useEffect(() => {
    return setImageAlreadyDeleted(false);
  }, []);

  return (
    <Layout>
      {isLoading && (
        <div className="absolute z-20 flex items-center justify-center w-full h-full bg-white/70 backdrop-blur-sm">
          <Loader />
        </div>
      )}
      <form onSubmit={formik.handleSubmit} className="">
        <div className="flex gap-1.5 items-center bg-white rounded-theme mb-2.5 xl:p-5 lg:p-4 p-3.5">
          <IconButton
            type="button"
            variant="text"
            className=""
            onClick={() => navigate(-1)}
          >
            <FontAwesomeIcon icon={faArrowLeft} fontSize={18} />
          </IconButton>
          <p className="text-base font-semibold lg:text-lg 2xl:font-bold text-info-500 2xl:text-xl">
            {singleData ? t("Edit") : t("Add")} {t("Product Details")}
          </p>
        </div>

        <div className="w-full flex gap-x-6 bg-white xl:h-[calc(100vh-205px)] h-[calc(100vh-100px)] p-5 rounded-theme">
          <div className="w-full grow flex flex-col gap-y-5 pr-2 overflow-y-auto h-[calc(100vh-241px)]">
            <div className="relative">
              <Input
                type={"text"}
                asterisk
                label={t("Brand Tags")}
                placeholder={t("Select brand tag")}
                labelProps={"text-secondary-500 text-base font-medium pb-1"}
                className={
                  "bg-secondary-50 h-[46px] text-primary-400 capitalize  "
                }
                value={formik.values.brand ? formik.values.brand?.name : ""}
                disabled
                iconEnd={
                  formik.values.brand && (
                    <IconButton
                      size="sm"
                      type="button"
                      variant="text"
                      onClick={() => {
                        formik.setFieldValue("brand", "");
                        formik.setFieldValue("brand_id", "");
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCircleXmark}
                        fontSize={20}
                        className="text-secondary-400"
                      />
                    </IconButton>
                  )
                }
              />
              {formik.errors.brand_id && formik.touched.brand_id && (
                <p className="text-sm text-red-500">{formik.errors.brand_id}</p>
              )}
              <button
                type="button"
                className="!absolute top-0 right-1 text-primary-400 capitalize text-base"
                onClick={() => setOpenBrandDrawer(true)}
              >
                {t("Manage")}
              </button>
            </div>
            <div className="">
              <Input
                type={"text"}
                asterisk
                label={t("Product Name")}
                placeholder={t("Enter Product Name")}
                labelProps={"text-secondary-500 text-base font-medium pb-1"}
                className={"bg-secondary-50 h-[46px] "}
                name="product_name"
                value={formik.values.product_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.product_name && formik.touched.product_name && (
                <p className="text-sm text-red-500">
                  {formik.errors.product_name}
                </p>
              )}
            </div>
            <div className="">
              <label className="block pb-2 text-base font-medium capitalize text-secondary-500">
                {t("description")}
              </label>
              <textarea
                type={"text"}
                name="description"
                rows="4"
                placeholder={t("Enter Description")}
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="outline-none resize-none bg-secondary-50 text-info-500 xl:text-lg lg:text-base text-sm_18 font-medium w-full  border p-2.5 rounded-theme border-secondary-200 focus:border-primary-400 focus:shadow transition-all duration-200 "
              ></textarea>
              {formik.errors.description && formik.touched.description && (
                <p name="description" className="my-1 text-sm text-red-500">
                  {formik.errors.description}
                </p>
              )}
            </div>
            <div>
              <div className="flex justify-between items-center bg-secondary-50 h-[46px] px-3 rounded-theme border border-secondary-200">
                <p className="font-medium capitalize text-secondary-500">
                  {formik.values.variants_possibilities?.length > 0
                    ? `${formik.values.variants_possibilities?.length} Variants`
                    : t("Product variant")}
                </p>
                <Button
                  size="sm"
                  className="flex items-center text-base capitalize gap-x-2 text-primary-400"
                  variant="text"
                  onClick={() => navigate("/inventory/items/variant")}
                >
                  <FontAwesomeIcon icon={faPlus} fontSize={16} />
                  <p>{t("Add Variants")}</p>
                </Button>
              </div>
            </div>
            <div className="grid gap-5 xl:grid-cols-2 xl:gap-6">
              <div>
                <ReactSelect
                  label={t("Loyalty Points")}
                  asterisk
                  labelProps={"text-secondary-500 !text-base font-medium pb-1"}
                  defaultValue={loyaltyPointsOptions(t)[0]}
                  options={loyaltyPointsOptions(t)}
                  name="loyalty_points_type"
                  value={formik.values.loyalty_points_type}
                  onChange={(data) => {
                    formik.setFieldValue("loyalty_points_type", data);
                  }}
                />
              </div>
              <div>
                <Input
                  type={"number"}
                  asterisk
                  label={t("Quantities")}
                  labelProps={
                    "text-secondary-500 capitalize text-base font-medium pb-1"
                  }
                  className={"bg-secondary-50 h-[46px]"}
                  name="quantities"
                  placeholder={t("Enter Quantities")}
                  value={formik.values.quantities}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={formik.values.variants_possibilities?.length > 0}
                />
                {formik.errors.quantities && formik.touched.quantities && (
                  <p className="text-sm text-red-500">
                    {formik.errors.quantities}
                  </p>
                )}
              </div>
              <div>
                <Input
                  type={"number"}
                  asterisk
                  label={t("Selling Price")}
                  placeholder={t("Enter Selling Price")}
                  labelProps={
                    "text-secondary-500 capitalize text-base font-medium pb-1"
                  }
                  className={"bg-secondary-50 h-[46px]"}
                  name="selling_price"
                  value={formik.values.selling_price}
                  iconStart={<p className="font-semibold">$</p>}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={formik.values.variants_possibilities?.length > 0}
                />
                {formik.errors.selling_price &&
                  formik.touched.selling_price && (
                    <p className="text-sm text-red-500">
                      {formik.errors.selling_price}
                    </p>
                  )}
              </div>
              <div>
                <Input
                  type={"number"}
                  asterisk
                  label={t("Cost price")}
                  placeholder={t("Enter Cost Price")}
                  labelProps={
                    "text-secondary-500 capitalize text-base font-medium pb-1"
                  }
                  className={"bg-secondary-50 h-[46px]"}
                  name="cost_price"
                  value={formik.values.cost_price}
                  onChange={formik.handleChange}
                  iconStart={<p className="font-semibold">$</p>}
                  onBlur={formik.handleBlur}
                  disabled={formik.values.variants_possibilities?.length > 0}
                />
                {formik.errors.cost_price && formik.touched.cost_price && (
                  <p className="text-sm text-red-500">
                    {formik.errors.cost_price}
                  </p>
                )}
              </div>
              <div>
                <Input
                  type={"text"}
                  asterisk
                  label={t("Scan code")}
                  labelProps={
                    "text-secondary-500 capitalize text-base font-medium pb-1"
                  }
                  className={"bg-secondary-50 h-[46px]"}
                  name="scan_code"
                  placeholder={t("Enter Scan Code")}
                  value={formik.values.scan_code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  iconEnd={
                    <IconButton
                      size="sm"
                      variant="text"
                      className="-mr-2.5"
                      onClick={() => {
                        const code = generateCode(6);
                        formik.setFieldValue("scan_code", code);
                      }}
                    >
                      <FontAwesomeIcon icon={faRotateRight} fontSize={16} />
                    </IconButton>
                  }
                />
                {formik.errors.scan_code && formik.touched.scan_code && (
                  <p className="text-sm text-red-500">
                    {formik.errors.scan_code}
                  </p>
                )}
              </div>
              <div>
                <Input
                  type={"text"}
                  asterisk
                  label={t("Internal code")}
                  placeholder={t("Enter Internal Code")}
                  labelProps={
                    "text-secondary-500 capitalize text-base font-medium pb-1"
                  }
                  className={"bg-secondary-50 h-[46px]"}
                  name="internal_code"
                  value={formik.values.internal_code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  iconEnd={
                    <IconButton
                      size="sm"
                      variant="text"
                      className="-mr-2.5"
                      onClick={() => {
                        const code = generateCode(6);
                        formik.setFieldValue("internal_code", code);
                      }}
                    >
                      <FontAwesomeIcon icon={faRotateRight} fontSize={16} />
                    </IconButton>
                  }
                />
                {formik.errors.internal_code &&
                  formik.touched.internal_code && (
                    <p className="text-sm text-red-500">
                      {formik.errors.internal_code}
                    </p>
                  )}
              </div>
              <div className="flex flex-col w-full gap-y-1">
                <div className="flex items-center justify-between pb-1 ">
                  <label
                    htmlFor=""
                    className="text-base font-medium capitalize text-secondary-500"
                  >
                    {t("Vendor Tags")}
                    <span className="text-primary-400">*</span>
                  </label>
                  <button
                    type="button"
                    className="text-base text-primary-400"
                    onClick={() => {
                      setOpenVendorDrawer(true);
                      document.body.style.overflow = "hidden"; // Disable scroll when drawer opens
                    }}
                  >
                    {t("Manage")}
                  </button>
                </div>
                <div className="flex  justify-between items-center bg-secondary-50 h-[46px] px-3 rounded-theme border border-secondary-200 ">
                  {formik.values.vendor ? (
                    formik.values.vendor.name
                  ) : (
                    <p className="font-bold text-[#ccc]">
                      {t("Search Or Create Vendor")}
                    </p>
                  )}
                </div>
                {formik.errors.vendor_id && formik.touched.vendor_id && (
                  <p className="text-sm text-red-500">
                    {formik.errors.vendor_id}
                  </p>
                )}
              </div>
              <div className="flex flex-col w-full gap-y-1">
                <div className="flex items-center justify-between pb-1">
                  <label
                    htmlFor=""
                    className="text-base font-medium capitalize text-secondary-500"
                  >
                    {t("categories Tags")}
                    <span className="text-primary-400">*</span>
                  </label>
                  <button
                    type="button"
                    className="text-base text-primary-400"
                    onClick={() => setOpenCategoriesDrawer(true)}
                  >
                    {t("Manage")}
                  </button>
                </div>
                <div className="flex items-center gap-2 flex-wrap bg-secondary-50 py-2.5 px-3 rounded-theme border border-secondary-200 justify-start h-max ">
                  {/* {console.log(formik?.values?.categories_tag, "catee")} */}
                  {formik.values.categories?.length > 0 ? (
                    formik.values.categories.map((item, ind) => (
                      <button
                        type="button"
                        className={`px-3 py-1.5 flex gap-x-2 font-semibold items-center bg-primary-400 capitalize text-white rounded-theme`}
                        key={ind}
                      >
                        {item.name}
                        <FontAwesomeIcon
                          icon={faCircleXmark}
                          fontSize={18}
                          className=""
                          onClick={() => {
                            const tempArrObj = cloneDeep(
                              formik.values.categories
                            );
                            const tempArrId = cloneDeep(
                              formik.values.categories_tag
                            );
                            tempArrObj.splice(ind, 1);
                            tempArrId.splice(ind, 1);
                            formik.setFieldValue("categories", tempArrObj);
                            formik.setFieldValue("categories_tag", tempArrId);
                          }}
                        />
                      </button>
                    ))
                  ) : (
                    <p className="font-bold text-[#ccc]">
                      {t("Search Or Create Category")}
                    </p>
                  )}
                </div>
                {formik.errors.categories_tag &&
                  formik.touched.categories_tag && (
                    <p className="text-sm text-red-500">
                      {formik.errors.categories_tag}
                    </p>
                  )}
              </div>
            </div>
          </div>
          <div className="2xl:w-[400px] xl:w-72 w-52 flex-shrink-0">
            <div className="w-full mb-6 2xl:h-96 xl:h-72 h-52">
              <label
                htmlFor=""
                className="relative z-0 flex items-center justify-center h-full mb-8 overflow-hidden bg-secondary-50 rounded-theme"
              >
                {uploadedImage ? (
                  <Fragment>
                    <img
                      src={uploadedImage}
                      alt="user-profile"
                      className="w-full h-full "
                    />

                    <IconButton
                      className="bg-white !absolute top-2 right-2 z-10 rounded-base text-red-500"
                      onClick={(event) => handleDeleteImageUpload(event)}
                    >
                      {/* <FontAwesomeIcon icon={faTrashAlt} fontSize={18} /> */}
                      <ManageDeleteProductIcon className="w-4 h-4" />
                    </IconButton>
                  </Fragment>
                ) : (
                  <Fragment>
                    <ProfileUploadSvg width="100" height="100" />
                  </Fragment>
                )}

                <input
                  type="file"
                  id="product-image"
                  ref={productImageRef}
                  name="image"
                  onChange={(event) => {
                    console.log(
                      event.target.files.length,
                      "event.target.files.length......."
                    );
                    console.log(
                      event.target.files,
                      "event.target.files......."
                    );
                    if (event.target.files.length > 0) {
                      // formik.setFieldValue("image", event.target.files[0]);
                      setOriginalFile(event.target.files[0]);
                      setUploadedImage(
                        URL.createObjectURL(event.target.files[0])
                      );
                      setOpenCropperImage(true);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  className="!absolute w-full h-full top-0 left-0 opacity-0 object-cover"
                />
              </label>
            </div>
            <Button
              type="submit"
              fullWidth
              className="flex justify-center text-base font-medium capitalize bg-primary-400"
              loading={isCreateLoading || isUpdateLoading}
              disabled={isCreateLoading || isUpdateLoading}
            >
              {t("Save Product")}
            </Button>
          </div>
        </div>
      </form>
      <SidePanel
        open={openBrandDrawer}
        handler={() => setOpenBrandDrawer(false)}
        width="w-[450px]"
      >
        <ManageBrand
          openBrandDrawer={openBrandDrawer}
          handler={() => setOpenBrandDrawer(false)}
          formik={formik}
        />
      </SidePanel>
      <SidePanel
        open={openCategoriesDrawer}
        handler={() => setOpenCategoriesDrawer(false)}
        width="w-[450px]"
      >
        <ManageCategories
          openCategoriesDrawer={openCategoriesDrawer}
          handler={() => setOpenCategoriesDrawer(false)}
          formik={formik}
        />
      </SidePanel>
      <SidePanel
        open={openVendorDrawer}
        handler={() => setOpenVendorDrawer(false)}
        width="w-[450px]"
      >
        <ManageVendor
          openVendorDrawer={openVendorDrawer}
          handler={() => setOpenVendorDrawer(false)}
          formik={formik}
        />
      </SidePanel>

      <Modal open={openCropperImage} handler={() => setOpenCropperImage(false)}>
        <ImageCropperModal
          setOpenCropperImage={setOpenCropperImage}
          formik={formik}
          originalFile={originalFile}
          uploadedImage={uploadedImage}
          setUploadedImage={setUploadedImage}
        />
      </Modal>
    </Layout>
  );
};

export default ProductForm;
