import React, { Fragment, useLayoutEffect } from "react";
import { IconButton } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { MailIcon, PrinterIcon } from "../svgs";
import { useDispatch, useSelector } from "react-redux";
import { getReceiptDetailRequest } from "../../store/register/transaction/action";
import quetzalLogo from "../../assets/quetzalLogo.png";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import { formatCurrencyWithNumberValue } from "../../utils/utils";
import Loader from "../common/Loader";

const Receipt = ({ openReceiptDrawer, handler, orderId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    isLoading,
    customerData,
    orderItems,
    orderData,
    payments,
  } = useSelector((store) => ({
    isLoading: store?.transaction?.receiptData?.loading,
    customerData: store?.transaction?.receiptData?.data?.customer,
    orderItems: store?.transaction?.receiptData?.data?.orderItems,
    orderData: store?.transaction?.receiptData?.data?.order,
    payments: store?.transaction?.receiptData?.data?.payments,
  }));
  console.log(payments, "=====data====");

  useLayoutEffect(() => {
    if (openReceiptDrawer && orderId) {
      dispatch(getReceiptDetailRequest({ orderId }));
    }
  }, [openReceiptDrawer, orderId, dispatch]);

  return (
    <div>
      {isLoading === false && (
        <div className="bg-white max-w-[445px] w-full px-2.5 py-5 absolute right-0 top-0 h-screen z-[9999] overflow-hidden">
          <div className="bg-secondary-50">
            <div className="text-end">
              <IconButton
                type="button"
                variant="text"
                className=""
                onClick={handler}
              >
                <FontAwesomeIcon icon={faClose} fontSize={25} />
              </IconButton>
            </div>
            <div className="flex justify-center mx-auto mb-[30px] px-5">
              <div className="w-[150px] h-[55px]">
                <img src={quetzalLogo} alt="logo" className="w-full h-full" />
              </div>
            </div>
            <div className="h-[calc(100vh-100px)] overflow-y-auto pb-[130px] px-5">
              <div className="mb-2.5 flex items-center gap-4">
                <span className="text-secondary-800 text-sm_18 font-bold max-w-[114px] w-full">
                  {t("Customer name")}
                </span>
                <span className="font-bold text-secondary-800">:</span>
                <span className="text-secondary-800 text-sm_18">
                  {/* {t("John Brogan")} */}
                  {customerData?.first_name + " " + customerData?.last_name}
                </span>
              </div>
              <div className="mb-2.5 flex items-center gap-4">
                <span className="text-secondary-800 text-sm_18 font-bold max-w-[114px] w-full">
                  {t("Email ID")}
                </span>
                <span className="font-bold text-secondary-800">:</span>
                <span className="text-secondary-800 text-sm_18">
                  {customerData?.email}
                </span>
              </div>
              <div className="mb-2.5 flex items-center gap-4">
                <span className="text-secondary-800 text-sm_18 font-bold max-w-[114px] w-full">
                  {t("Contact number")}
                </span>
                <span className="font-bold text-secondary-800">:</span>
                <span className="text-secondary-800 text-sm_18">
                  {customerData?.code + " " + customerData?.phone}
                </span>
              </div>
              <div className="mb-2.5 flex items-center gap-4">
                <span className="text-secondary-800 text-sm_18 font-bold max-w-[114px] w-full">
                  {t("Receipt No")}
                </span>
                <span className="font-bold text-secondary-800">:</span>
                <span className="text-secondary-800 text-sm_18">
                  {orderData?.order_no}
                </span>
              </div>
              <div className="flex items-center gap-4 mb-4">
                <span className="text-secondary-800 text-sm_18 font-bold max-w-[114px] w-full">
                  {t("Date")}
                </span>
                <span className="font-bold text-secondary-800">:</span>
                <span className="text-secondary-800 text-sm_18">
                  {moment(orderData?.order_date).format(
                    "Do MMMM, YYYY (hh:mm A)"
                  )}
                </span>
              </div>
              <div className="border-t border-dashed border-secondary-800"></div>
              <div className="border-b border-dashed border-secondary-800 mt-[1px] mb-4"></div>
              <div className="grid grid-cols-5 mb-4">
                <div className="col-span-2 font-bold text-secondary-800 text-sm_18">
                  ITEM
                </div>
                <div className="font-bold text-center text-secondary-800 text-sm_18">
                  QTY
                </div>
                <div className="font-bold text-center text-secondary-800 text-sm_18">
                  PRICE
                </div>
                <div className="font-bold text-center text-secondary-800 text-sm_18">
                  Total
                </div>
              </div>
              <div className="border-b border-dashed border-secondary-800 pb-5 mb-5 space-y-2.5">
                {orderItems?.map((item, index) => (
                  <Fragment key={index}>
                    <div className="grid grid-cols-5 ">
                      <div className="col-span-2 font-medium text-secondary-800 text-sm_18">
                        {item?.product_name}
                      </div>
                      <div className="font-medium text-center text-secondary-800 text-sm_18">
                        {item?.qty}
                      </div>
                      <div className="font-semibold text-center text-secondary-800 text-sm_18">
                        {formatCurrencyWithNumberValue(
                          item?.selling_price || 0
                        )}
                      </div>
                      <div className="font-semibold text-center text-secondary-800 text-sm_18">
                        {formatCurrencyWithNumberValue(item?.grand_total)}
                      </div>
                    </div>
                  </Fragment>
                ))}
              </div>
              {/* <div className="grid grid-cols-5 mb-2.5">
              <div className="col-span-2 font-medium text-secondary-800 text-sm_18">
                Men Slim Fit Checkered Cut Away Collar
              </div>
              <div className="font-medium text-center text-secondary-800 text-sm_18">
                3
              </div>
              <div className="font-semibold text-center text-secondary-800 text-sm_18">
                $ 65.00
              </div>
              <div className="font-semibold text-center text-secondary-800 text-sm_18">
                $ 65.00
              </div>
            </div>
            <div className="grid grid-cols-5 pb-5 mb-5 border-b border-dashed border-secondary-800">
              <div className="col-span-2 font-medium text-secondary-800 text-sm_18">
                Veirdo Oversize Men & Dye Round Pant
              </div>
              <div className="font-medium text-center text-secondary-800 text-sm_18">
                10
              </div>
              <div className="font-semibold text-center text-secondary-800 text-sm_18">
                $ 95.00
              </div>
              <div className="font-semibold text-center text-secondary-800 text-sm_18">
                $ 95.00
              </div>
            </div> */}
              <div className="pb-5 mb-5 border-b border-dashed border-secondary-800">
                <div className="mb-4 text-base font-bold text-secondary-800">
                  Payment Type
                </div>
                {payments?.map((item, index) => (
                  <Fragment key={index}>
                    <div className="flex justify-between items-center mb-2.5">
                      <div className="font-semibold text-secondary-800 text-sm_18">
                        {/* Card Type */}
                        Payment Type
                      </div>
                      <div className="font-semibold capitalize text-secondary-800 text-sm_18 ">
                        {/* Credit Card */}
                        {item?.payment_type}
                      </div>
                    </div>
                    <div className="flex justify-between items-center mb-2.5">
                      <div className="font-semibold text-secondary-800 text-sm_18">
                        {/* Card Num */}
                        Amount
                      </div>
                      <div className="font-semibold text-secondary-800 text-sm_18">
                        {/* **** **** **** 5563 */}
                        {item?.amount}
                      </div>
                    </div>
                    {/* <div className="flex justify-between items-center mb-2.5">
                    <div className="font-semibold text-secondary-800 text-sm_18">
                      Exp Date
                    </div>
                    <div className="font-semibold text-secondary-800 text-sm_18">
                      **
                    </div>
                  </div> */}
                  </Fragment>
                ))}
              </div>
              <div className="mb-5">
                <div className="mb-4 text-base font-bold text-secondary-800">
                  Payment Details
                </div>
                <div className="flex justify-between items-center mb-2.5">
                  <div className="font-semibold text-secondary-800 text-sm_18">
                    Items
                  </div>
                  <div className="font-semibold text-secondary-800 text-sm_18">
                    {"(" + orderData?.total_qty + " " + "items" + ")"}
                  </div>
                </div>
                <div className="flex justify-between items-center mb-2.5">
                  <div className="font-semibold text-secondary-800 text-sm_18">
                    Discount
                  </div>
                  <div className="font-semibold text-secondary-800 text-sm_18">
                    - {formatCurrencyWithNumberValue(orderData?.total_discount)}
                  </div>
                </div>
                <div className="flex justify-between items-center mb-2.5">
                  <div className="font-semibold text-secondary-800 text-sm_18">
                    Sub Total
                  </div>
                  <div className="font-semibold text-secondary-800 text-sm_18">
                    {formatCurrencyWithNumberValue(orderData?.sub_total)}
                  </div>
                </div>
                <div className="flex justify-between items-center mb-2.5">
                  <div className="font-semibold text-secondary-800 text-sm_18">
                    Taxes (5%)
                  </div>
                  <div className="font-semibold text-secondary-800 text-sm_18">
                    {formatCurrencyWithNumberValue(orderData?.total_taxes)}
                  </div>
                </div>
                <div className="flex justify-between items-center mb-2.5">
                  <div className="font-semibold text-secondary-800 text-sm_18">
                    Loyalty Points
                  </div>
                  <div className="font-semibold text-secondary-800 text-sm_18">
                    {formatCurrencyWithNumberValue(
                      orderData?.loyalty_point_use
                    )}
                  </div>
                </div>
                <div className="flex justify-between items-center mb-2.5">
                  <div className="font-bold text-secondary-800 text-sm_18">
                    Payments Total
                  </div>
                  <div className="font-bold text-secondary-800 text-sm_18">
                    {formatCurrencyWithNumberValue(orderData?.grand_total)}
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-5 absolute bottom-0 left-0 bg-secondary-50 px-4 mx-2.5 w-[calc(100%-20px)] py-4">
              <button className="flex items-center justify-center gap-3 border border-primary-400 rounded-base h-[46px] px-4">
                {/* <MailIcon /> */}
                <span className="text-base font-semibold text-primary-400">
                  Send E-Mail
                </span>
              </button>
              <button className="flex items-center justify-center gap-3 bg-primary-400 rounded-base h-[46px] px-4">
                {/* <PrinterIcon /> */}
                <span className="text-base font-semibold text-white">
                  Print Receipt
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
      {isLoading && (
        <div className="flex items-center justify-center h-full">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default Receipt;
