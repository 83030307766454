import { toast } from "react-toastify";
import AxiosRequest from "../../AxiosRequest";
import { orderState } from "./state";

export const setSelectedProductsRequest = (data) => async (dispatch) => {
  return dispatch(orderState({ key: "selectedProducts", value: data }));
};

export const addToCartRequest =
  ({ body, navigate, orderId }) =>
    async (dispatch) => {
      try {
        dispatch(orderState({ key: "addToCart.loading", value: true }));
        const response = await AxiosRequest.post("/user/order/add-to-cart", body);
        console.log({ response, body });
        const data = response.data;
        if (data) {
          dispatch(orderState({ key: "addToCart.loading", value: false }));
          dispatch(orderState({ key: "addToCart.data", value: data }));
          if (navigate) {
            if (orderId) {
              navigate(`/payment/${body.customer_id}?q=${orderId}`);
            } else {
              navigate(`/payment/${body.customer_id}`);
            }
          }
        }
      } catch (error) {
        toast.error(error);
        dispatch(orderState({ key: "addToCart.loading", value: false }));
        dispatch(orderState({ key: "addToCart.error", value: true }));
      }
    };

/* -------------------------- create order request -------------------------- */
export const createOrderRequest =
  ({
    body,
    handleReset,
    resetForm,
    setOpenHoldOrderModal,
    setOpenPaymentSuccessModal,
    setSelectedCustomer,
    navigate
  }) =>
    async (dispatch) => {
      try {
        dispatch(orderState({ key: "createOrder.loading", value: true }));
        const response = await AxiosRequest.post("/user/order/create", body);
        console.log({ body });
        const data = response.data.data;
        if (data) {
          if (body.order.hold_reason) {
            toast.success("Hold Order completed successfully")
            setSelectedCustomer(null);
            navigate("/register")
          }
          dispatch(orderState({ key: "createOrder.loading", value: false }));
          dispatch(orderState({ key: "createOrder.data", value: data }));
          dispatch(orderState({ key: "getGiftCard.data", value: null }));

          if (handleReset) handleReset();
          // if (resetForm) resetForm();
          if (setOpenHoldOrderModal) setOpenHoldOrderModal(false);
          if (setOpenPaymentSuccessModal) setOpenPaymentSuccessModal(true);
          if (setSelectedCustomer) setSelectedCustomer(null);

        }
      } catch (error) {
        toast.error(error);
        dispatch(orderState({ key: "createOrder.loading", value: false }));
        dispatch(orderState({ key: "createOrder.error", value: true }));
      }
    };




/* -------------------------- create return order request -------------------------- */
export const createReturnOrderRequest = ({ orderId, body, resetForm, navigate }) => async (dispatch) => {
  try {
    dispatch(orderState({ key: "returnOrder.loading", value: true }));

    // Make the API request
    const response = await AxiosRequest.put(`user/order/return-order/${orderId}`, body);

    const data = response.data.data;
    if (data) {
      dispatch(orderState({ key: "returnOrder.loading", value: false }));
      dispatch(orderState({ key: "returnOrder.data", value: data }));

      navigate("/order")

      // Reset form or perform any other action after success
      if (resetForm) resetForm();

      // Show success message
      toast.success(response.data.message);
    }
  } catch (error) {
    toast.error("Failed to process the order return.");
    dispatch(orderState({ key: "returnOrder.loading", value: false }));
    dispatch(orderState({ key: "returnOrder.error", value: true }));
  }
};

/* -------------------------- get orders cart items ------------------------- */
export const getCartItemsRequest =
  ({ id }) =>
    async (dispatch) => {
      try {
        dispatch(orderState({ key: "cartItems.loading", value: true }));
        const response = await AxiosRequest.get(`/user/order/cart-items/${id}`);
        console.log({ response });
        const data = response.data.data;
        if (data) {
          dispatch(orderState({ key: "cartItems.loading", value: false }));
          dispatch(orderState({ key: "cartItems.data", value: data }));
        }
      } catch (error) {
        toast.error(error);
        dispatch(orderState({ key: "cartItems.loading", value: false }));
        dispatch(orderState({ key: "cartItems.error", value: true }));
      }
    };

/* -------------------------- get gift card item ------------------------- */
export const getGiftCardRequest =
  ({ customer_id, gift_code }) =>
    async (dispatch) => {
      try {
        dispatch(orderState({ key: "getGiftCard.loading", value: true }));
        const response = await AxiosRequest.get(
          `/user/giftcard/validate?customer_id=${customer_id}&gift_code=${gift_code}`
        );
        console.log({ response });
        const data = response.data.data;
        if (data) {
          dispatch(orderState({ key: "getGiftCard.loading", value: false }));
          dispatch(orderState({ key: "getGiftCard.data", value: data }));
        }
      } catch (error) {
        toast.error(error);
        dispatch(orderState({ key: "getGiftCard.loading", value: false }));
        dispatch(orderState({ key: "getGiftCard.error", value: true }));
      }
    };


/* -------------------------- get gift card item ------------------------- */
export const getRemoveHoldOrderRequest =
  ({ order_id, navigate }) =>
    async (dispatch) => {
      try {
        dispatch(orderState({ key: "deleteHoldOrder.loading", value: true }));
        const response = await AxiosRequest.delete(
          `/user/order/remove-hold-order/${order_id}`
        );
        console.log({ response });
        const data = response.data.data;
        if (data) {
          dispatch(orderState({ key: "deleteHoldOrder.loading", value: false }));
          dispatch(orderState({ key: "deleteHoldOrder.data", value: data }));
          navigate('/order')
        }
      } catch (error) {
        toast.error(error);
        dispatch(orderState({ key: "deleteHoldOrder.loading", value: false }));
        dispatch(orderState({ key: "deleteHoldOrder.error", value: true }));
      }
    };