import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import { navigation } from "../routes/navigation";
import { getUserDetails } from "../utils/utils";
import { t } from "i18next";

function Sidebar() {
  const location = useLocation();
  const userDetail = getUserDetails();
  return (
    <div>
      <aside className="bg-white xl:max-w-[147px] lg:max-w-[110px] max-w-[100px] w-full xl:h-[calc(100vh-116px)] h-[calc(100vh-90px)] rounded-r-theme mt-2.5 fixed left-0 overflow-y-auto overflow-x-hidden">
        <div className="flex flex-col xl:pt-[60px] lg:pt-[30px] pt-7 rounded-tr-theme">
          {navigation(userDetail).map((items, index) => (
            <Fragment>
              {userDetail?.section_permission.includes(items.permission) && (
                <Link
                  to={items.path}
                  key={index}
                  className={`xl:ml-[20px]  ml-3 relative group rounded-l-theme transition-all duration-500 before:absolute before:content-[''] before:top-1/2 before:-translate-y-1/2 xl:before:left-[-20px] lg:before:left-[-14px] before:left-[-12px] lg:before:w-[5px] before:w-[3px] lg:before:h-[52px] before:h-[40px] before:rounded-r-theme ${
                    location.pathname.includes(items.key)
                      ? "before:bg-primary-400 bg-secondary-50  "
                      : ""
                  }`}
                >
                  <div
                    className={`relative flex flex-col items-center justify-end gap-2 pl-2 xl:pr-5 pr-3 xl:py-6 py-4 before:absolute before:content-[''] before:top-[-12px] before:right-[0]  before:bg-no-repeat before:w-[12px] before:h-[12px] after:absolute after:content-[''] after:bottom-[-12px] after:right-[0] after:bg-no-repeat after:w-[12px] after:h-[12px] after:rotate-[270deg] ${
                      location.pathname.includes(items.key)
                        ? "before:bg-radius_vector after:bg-radius_vector"
                        : ""
                    }`}
                  >
                    {items.icon({ location, path: items.key })}
                    <div
                      className={`xl:text-sm_18 text-xs transition-all duration-300 ease-in-out text-info-400 ${
                        location.pathname.includes(items.key)
                          ? "font-bold "
                          : "group-hover:font-bold"
                      }`}
                    >
                      {t(items.title)}
                    </div>
                  </div>
                </Link>
              )}
            </Fragment>
          ))}
        </div>
      </aside>
    </div>
  );
}

export default Sidebar;
