import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Radio } from "@material-tailwind/react";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrgLocationListRequest,
  updateOrgLocationRequest,
} from "../../../store/settings/location/actions";
import { useNavigate } from "react-router-dom";
import Loader from "../../common/Loader";
import { useTranslation } from "react-i18next";
import { profileAction } from "../../../store/settings/settings.fetch";
import { useQueryClient } from "@tanstack/react-query";

const SwitchLocation = ({ openSwitchLocation, handler }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { loading, locationData, isLoading } = useSelector((store) => ({
    loading: store?.location?.getOrgLocationList?.loading,
    locationData: store?.location?.getOrgLocationList?.data,
    isLoading: store?.location?.updateOrgLocation?.loading,
  }));
  const [selectedLocationId, setSelectedLocationId] = useState(null);

  useEffect(() => {
    if (openSwitchLocation) {
      dispatch(getOrgLocationListRequest());
    }
  }, [dispatch, openSwitchLocation]);
  if (!openSwitchLocation) return null;
  return (
    <Fragment>
      {(isLoading || loading) && (
        <div className="!absolute top-0 bottom-0 left-0 right-0 w-full h-full flex justify-center items-center backdrop-blur z-10 rounded-theme">
          <Loader />
        </div>
      )}
      <div className="flex flex-col gap-y-2">
        <div className="relative flex items-center justify-center">
          <h3 className="mb-2 text-lg font-semibold">{t("Switch Location")}</h3>
          <IconButton
            size="sm"
            variant="text"
            className="!absolute right-0"
            onClick={handler}
          >
            <FontAwesomeIcon icon={faClose} fontSize={18} />
          </IconButton>
        </div>
        <p className="text-base capitalize text-secondary-400">
          {t("Current location")}
        </p>
        <div className="p-3 overflow-hidden border border-primary-400 rounded-theme">
          <div className="checkbox-label-between">
            <Radio
              label={
                <div>
                  <span className="font-semibold capitalize text-info-700 xl:text-lg lg:text-base text-sm_18">
                    {locationData?.current_location?.post_code}
                    <p className="font-medium capitalize lg:text-base text-sm_18 text-secondary-400">
                      {locationData?.current_location?.location_name}
                    </p>
                  </span>
                </div>
              }
              containerProps={{
                className: "order-2",
              }}
              checked={
                selectedLocationId
                  ? false
                  : locationData?.current_location?.is_default_location
              }
            />
          </div>
        </div>
        {locationData?.other_locations.length > 0 && (
          <div>
            <p className="pb-3 text-base capitalize text-secondary-400">
              {t("Change location")}
            </p>
            <div className="p-3 overflow-hidden border border-secondary-200 rounded-theme">
              {locationData?.other_locations?.map((item, index) => (
                <Fragment>
                  <div className="py-3 checkbox-label-between">
                    <Radio
                      label={
                        <div>
                          <span className="font-semibold capitalize text-info-700 xl:text-lg lg:text-base text-sm_18">
                            {item.post_code}
                            <p className="font-medium capitalize lg:text-base text-sm_18 text-secondary-400">
                              {item.location_name}
                            </p>
                          </span>
                        </div>
                      }
                      containerProps={{
                        className: "order-2",
                      }}
                      checked={selectedLocationId === item.org_location_id}
                      onChange={() => {
                        setSelectedLocationId(item.org_location_id);
                        dispatch(
                          updateOrgLocationRequest({
                            id: item.org_location_id,
                            navigate,
                            queryClient,
                          })
                        );
                      }}
                    />
                  </div>
                  {index !== locationData?.other_locations?.length - 1 && (
                    <hr className="border-secondary-200" />
                  )}
                </Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default SwitchLocation;
