import { Button, Card, List, ListItem } from "@material-tailwind/react";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import Input from "../common/Input";
import {
  addToCartRequest,
  createOrderRequest,
} from "../../store/order/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { discountType } from "../../utils/enums";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const HoldOrder = ({
  setOpenHoldOrderModal,
  formik,
  setSelectedCustomer,
  orderId,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((store) => store?.order?.createOrder?.loading);
  const [isFocus, setIsFocus] = useState(false);
  const navigate = useNavigate();

  const options = [
    "Customer request",
    "Customer change mind",
    "Order modification",
    "Technical issues",
    "Add more product",
    "other",
  ];
  return (
    <Formik
      enableReinitialize
      initialValues={{ hold_reason: "" }}
      onSubmit={(values, { resetForm }) => {
        console.log({ values, formik });
        const data = {
          ...formik.values,
          holdOrderId: orderId || "",
          order: {
            ...formik.values.order,
            customer_id: orderId
              ? formik.values.order.customer_id
              : formik.values.order.customer_id._id,
            hold_reason: values.hold_reason,
            order_status: "hold",
          },
        };
        console.log(
          data.order.customer_id,
          "data.order.customer_id.........................."
        );
        if (!data.order.customer_id)
          return toast.error("Please choose a customer to continue");
        const createCartData = {
          ...data,
          orderItems: data.orderItems.map((item) => {
            if (item.type === "inventory") {
              const {
                product_name,
                variant_name,
                total_qty,
                ...element
              } = item;

              return {
                ...element,
                discount_percentage:
                  element?.discount_type === discountType.PERCENTAGE
                    ? Number(element?.discount_percentage)
                    : 0,
              };
            } else {
              return {
                type: item?.type,
                gift_card_code: item.gift_card_code,
                gift_card_amount: Number(item.gift_card_amount),
              };
            }
          }),
        };
        dispatch(
          createOrderRequest({
            body: createCartData,
            handleReset: formik.handleReset,
            resetForm,
            setOpenHoldOrderModal,
            setSelectedCustomer,
            navigate,
          })
        );
      }}
    >
      {({ values, setFieldValue, handleChange, handleSubmit, handleReset }) => (
        <Form className="flex flex-col gap-y-3" onSubmit={handleSubmit}>
          <h3 className="text-lg font-bold capitalize">Hold Reason</h3>
          <div
            className="relative flex flex-col gap-y-4"
            onClick={() => setIsFocus(!isFocus)}
          >
            <Input
              type={"text"}
              placeholder="Add Some Reason"
              className={"placeholder:!font-medium"}
              name="hold_reason"
              value={values.hold_reason}
              autocomplete="off"
              onChange={handleChange}
            />
            <FontAwesomeIcon
              icon={faChevronDown}
              fontSize={18}
              className={`absolute top-3.5 right-3 w-5 h-5 text-gray-400 cursor-pointer transform transition-transform duration-200 ${
                isFocus ? "rotate-180" : "rotate-0"
              }`}
            />
            {isFocus && (
              <Card className="w-full rounded-theme ">
                <List>
                  {options?.map((item, ind) => (
                    <ListItem
                      key={ind}
                      onClick={(e) => {
                        setFieldValue("hold_reason", item);
                        setIsFocus(false);
                      }}
                      className="capitalize"
                    >
                      {item}
                    </ListItem>
                  ))}
                </List>
              </Card>
            )}
          </div>
          <div className="flex items-center justify-end gap-x-4">
            <Button
              type="reset"
              size="sm"
              onClick={() => {
                handleReset();
                setOpenHoldOrderModal(false);
              }}
              className="text-sm font-medium capitalize bg-white border border-secondary-400 text-info-400"
            >
              Cancel
            </Button>
            <Button
              size="sm"
              type="submit"
              className="text-sm font-medium capitalize border bg-primary-400 border-primary-400"
              loading={isLoading}
              disabled={isLoading}
            >
              Hold Order
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default HoldOrder;
