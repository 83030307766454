import { Button } from "@material-tailwind/react";
import { ErrorMessage, Form, Formik } from "formik";
import React, { Fragment, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import LockClose from "../../assets/icon/lock-close.svg";
import Logo from "../../assets/logo/quetzal-logo.svg";
import PasswordInput from "../../components/common/PasswordInput";
import {
  resetPasswordRequest,
  validateTokenRequest,
} from "../../store/auth/auth.fetch";
import { useTranslation } from "react-i18next";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loading } = useSelector((store) => ({
    loading: store?.auth?.loading,
  }));
  useLayoutEffect(() => {
    if (id) {
      dispatch(validateTokenRequest({ uuid: id, navigate }));
    }
  }, [id, dispatch, navigate]);

  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={{
          new_password: "",
          confirm_password: "",
          uuid: id || null,
        }}
        validationSchema={Yup.object().shape({
          new_password: Yup.string().required(t("Please enter a new password")),
          confirm_password: Yup.string()
            .oneOf([Yup.ref("new_password"), null], t("Password not match"))
            .required(t("Please enter a confirm password")),
        })}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(resetPasswordRequest({ body: values, navigate }));
          setSubmitting(false);
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit }) => (
          <Form
            onSubmit={handleSubmit}
            className="flex flex-col items-center justify-center w-full h-screen bg-secondary-50"
          >
            <div className="lg:max-w-[135px] lg:h-[50px] max-w-[100px] h-[36px] mb-5">
              <img src={Logo} alt="logo" className="w-full h-full" />
            </div>
            <div className="p-[30px] shadow-[-3px_0px_30.1px_0px_#B4B4B440] rounded-theme lg:max-w-[545px] max-w-[450px] w-full bg-white">
              <h1 className="text-info-400 lg:text-2xl text-lg font-semibold lg:mb-[30px] mb-[24px] text-center">
                {"Change Your Password"}
              </h1>
              <div className="flex flex-col gap-y-4 mb-10">
                <div>
                  <PasswordInput
                    label={t("New password")}
                    name="new_password"
                    value={values.new_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    icon={<img src={LockClose} alt="user-icon" className="" />}
                  />
                  <ErrorMessage
                    name="new_password"
                    component={"p"}
                    className="my-1 text-red-500 text-sm_18"
                  />
                </div>
                <div>
                  <PasswordInput
                    label={t("Confirm password")}
                    name="confirm_password"
                    value={values.confirm_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    icon={<img src={LockClose} alt="user-icon" className="" />}
                  />
                  <ErrorMessage
                    name="confirm_password"
                    component={"p"}
                    className="my-1 text-red-500 text-sm_18"
                  />
                </div>
              </div>
              <Button
                type="submit"
                fullWidth
                className="bg-primary-400 font-semibold text-base rounded-theme shadow capitalize"
                disabled={loading}
                loading={loading}
              >
                {t("Change Password")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default ResetPassword;
