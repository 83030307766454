import { toast } from "react-toastify";
import AxiosRequest from "../../../AxiosRequest";
import { variantState } from "./state";

export const setAllAttributes = (data) => async (dispatch) => {
  return dispatch(variantState({ key: "allAttributes", value: data }));
};

export const setAttributes = (data) => async (dispatch) => {
  return dispatch(variantState({ key: "attributes", value: data }));
};

/* -------------------------------------------------------------------------- */
/*                           add new variant request                          */
/* -------------------------------------------------------------------------- */

export const createVariantRequest =
  ({ productId, body }) =>
  async (dispatch) => {
    try {
      dispatch(variantState({ key: "createVariant.loading", value: true }));
      const response = await AxiosRequest.post(
        `/user/inventory/variant/create/${productId}`,
        body
      );
      const data = await response.data.data;
      if (data) {
        dispatch(variantState({ key: "createVariant.loading", value: false }));
      }
      return response;
    } catch (error) {
      toast.error(error);
      dispatch(variantState({ key: "createVariant.loading", value: false }));
      dispatch(variantState({ key: "createVariant.error", value: error }));
    }
  };

/* -------------------------------------------------------------------------- */
/*                           update variant request                            */
/* -------------------------------------------------------------------------- */

export const updateVariantRequest =
  ({ skuId, body }) =>
  async (dispatch) => {
    try {
      dispatch(variantState({ key: "updateVariant.loading", value: true }));
      const response = await AxiosRequest.put(
        `/user/inventory/variant/update/${skuId}`,
        body
      );
      const data = await response.data.data;
      if (data) {
        dispatch(variantState({ key: "updateVariant.loading", value: false }));
      }
      return response;
    } catch (error) {
      toast.error(error);
      dispatch(variantState({ key: "updateVariant.loading", value: false }));
      dispatch(variantState({ key: "updateVariant.error", value: error }));
    }
  };

/* -------------------------------------------------------------------------- */
/*                           delete variant request                           */
/* -------------------------------------------------------------------------- */

export const deleteVariantRequest =
  ({ skuId }) =>
  async (dispatch) => {
    try {
      dispatch(variantState({ key: "deleteVariant.loading", value: true }));
      const response = await AxiosRequest.delete(
        `/user/inventory/variant/delete/${skuId}`
      );
      const data = await response.data;
      if (data) {
        dispatch(variantState({ key: "deleteVariant.loading", value: false }));
      }
      return response;
    } catch (error) {
      toast.error(error);
      dispatch(variantState({ key: "deleteVariant.loading", value: false }));
      dispatch(variantState({ key: "deleteVariant.error", value: error }));
    }
  };
