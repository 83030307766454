/* -------------------------------------------------------------------------- */
/*                           create new user request                          */

import { toast } from "react-toastify";
import AxiosRequest from "../../../AxiosRequest";
import { userState } from "./state";

/* -------------------------------------------------------------------------- */
export const createUserRequest =
  ({ body, navigate }) =>
    async (dispatch) => {
      try {
        dispatch(userState({ key: "createUser.loading", value: true }));
        const response = await AxiosRequest.post("/user/create", body);
        const data = await response.data.data;
        if (data) {
          dispatch(userState({ key: "createUser.loading", value: false }));
          navigate(-1);
        }

        toast.success(response.data.message)
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(userState({ key: "createUser.loading", value: false }));
        dispatch(userState({ key: "createUser.error", value: error }));
      }
    };

/* -------------------------------------------------------------------------- */
/*                           get users list request                           */
/* -------------------------------------------------------------------------- */

export const getUsersListRequest =
  ({ page, limit, search = "", role }) =>
    async (dispatch) => {
      try {
        dispatch(userState({ key: "usersList.loading", value: true }));
        const response = await AxiosRequest.get(
          `/user/list?page=${page}&limit=${limit}&search=${search}&role=${role}`
        );
        const data = await response.data.data;
        if (data) {
          dispatch(userState({ key: "usersList.data", value: data?.users }));
          dispatch(
            userState({ key: "usersList.totalPages", value: data?.totalPages })
          );
          dispatch(userState({ key: "usersList.loading", value: false }));
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(userState({ key: "usersList.loading", value: false }));
        dispatch(userState({ key: "usersList.error", value: error }));
      }
    };

/* -------------------------------------------------------------------------- */
/*                           get single user detail request                   */
/* ------(-------------------------------------------------------------------- */

export const getUsersDetailRequest =
  ({ userId }) =>
    async (dispatch) => {
      try {
        dispatch(userState({ key: "singleUserDetail.loading", value: true }));
        const response = await AxiosRequest.get(`/user/details/${userId}`);
        const data = await response.data.data;
        if (data) {
          dispatch(userState({ key: "singleUserDetail.data", value: data }));
          dispatch(userState({ key: "singleUserDetail.loading", value: false }));
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(userState({ key: "singleUserDetail.loading", value: false }));
        dispatch(userState({ key: "singleUserDetail.error", value: error }));
      }
    };

/* -------------------------------------------------------------------------- */
/*                           update single user detail request                */
/* -------------------------------------------------------------------------- */

export const updateUsersDetailRequest =
  ({ userId, body, navigate }) =>
    async (dispatch) => {
      try {
        dispatch(userState({ key: "createUser.loading", value: true }));
        const response = await AxiosRequest.put(`/user/update/${userId}`, body);
        const data = await response.data.data;
        if (data) {
          dispatch(userState({ key: "createUser.loading", value: false }));
          navigate(-1);
        }
        toast.success(response.data.message)
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(userState({ key: "createUser.loading", value: false }));
        dispatch(userState({ key: "createUser.error", value: error }));
      }
    };

/* -------------------------------------------------------------------------- */
/*                           delete user request                              */
/* -------------------------------------------------------------------------- */

export const deleteUserRequest =
  ({ userId, setModalClose }) =>
    async (dispatch) => {
      try {
        dispatch(userState({ key: "deleteUser.loading", value: true }));
        const response = await AxiosRequest.delete(`/user/delete/${userId}`);
        const data = await response.data;
        if (data) {
          dispatch(userState({ key: "deleteUser.loading", value: false }));
          dispatch(userState({ key: "deleteUser.data", value: data }));
          setModalClose(false);
        }
        toast.success(response.data.message)
        return response;

      } catch (error) {
        toast.error(error);
        dispatch(userState({ key: "deleteUser.loading", value: false }));
        dispatch(userState({ key: "deleteUser.error", value: error }));
      }
    };
