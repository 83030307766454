/* -------------------------------------------------------------------------- */
/*                              get category list                             */
/* -------------------------------------------------------------------------- */

import { toast } from "react-toastify";
import AxiosRequest from "../../../AxiosRequest";
import { categoryState } from "./state";

export const getCategoriesList =
  ({ page, limit, search = "" }) =>
  async (dispatch) => {
    try {
      dispatch(categoryState({ key: "categories.loading", value: true }));
      const response = await AxiosRequest.get(
        `/user/category/list?&page=${page}&limit=${limit}`
      );
      const data = await response?.data?.data;
      if (data) {
        dispatch(
          categoryState({ key: "categories.data", value: data?.categories })
        );
        dispatch(
          categoryState({
            key: "categories.totalPages",
            value: data?.totalPages,
          })
        );
        dispatch(categoryState({ key: "categories.loading", value: false }));
      }
            return response;

    } catch (error) {
      console.log(error, "err from getcategorie list");
      toast.error(error);
    }
  };
