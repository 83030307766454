import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, IconButton } from "@material-tailwind/react";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

const LocationsAccess = ({ formik, closeHandler }) => {
  const { t } = useTranslation();
  const selectedLocationCount = formik.values.location_access_ids.length;
  const totalChangeLocationCount =
    formik.values.location_list_arr.length +
    formik.values.location_access_ids.length;
  return (
    <Fragment>
      <div className="flex flex-col h-full bg-white p-7 gap-y-5">
        <div className="flex items-center justify-between">
          <div className="flex flex-col">
            <p className="font-medium capitalize text-info-500 xl:text-lg lg:text-lg text-base_24">
              {t("locations access")}
            </p>
            <p className="font-normal text-sm_20 text-secondary-300">
              {/* 02 of 07 {t("Selected")} */}
              {selectedLocationCount} of {totalChangeLocationCount}{" "}
              {t("Selected")}
            </p>
          </div>
          <IconButton
            size="sm"
            type="button"
            variant="text"
            className=""
            onClick={closeHandler}
          >
            <FontAwesomeIcon icon={faClose} fontSize={16} />
          </IconButton>
        </div>
        {formik.values.location_access_ids?.length > 0 && (
          <div>
            <p className="mb-2 font-medium capitalize text-base_24 text-info-500">
              {t("selected location")}
            </p>
            <div className="p-3 overflow-hidden border border-secondary-200 rounded-theme">
              {formik.values.location_access_ids?.map((item, index) => (
                <Fragment>
                  <div className="py-3 checkbox-label-between">
                    <Checkbox
                      label={
                        <div>
                          <span className="font-semibold capitalize text-info-700 xl:text-lg lg:text-base text-sm_18">
                            {item.location_address}
                            <p className="font-medium capitalize lg:text-base text-sm_18 text-secondary-400">
                              {item.location_name}
                            </p>
                          </span>
                        </div>
                      }
                      containerProps={{
                        className: "order-2",
                      }}
                      checked={formik.values.location_access_ids.includes(item)}
                      onChange={() => {
                        const updatedLocation = [
                          ...formik.values.location_list_arr,
                          item,
                        ];
                        const oldArr = [...formik.values.location_access_ids];
                        const filtered = oldArr.filter(
                          (location) => location?._id !== item?._id
                        );
                        formik.setFieldValue(
                          "location_list_arr",
                          updatedLocation
                        );
                        formik.setFieldValue("location_access_ids", filtered);
                      }}
                    />
                  </div>
                  {index !== formik.values.location_access_ids?.length - 1 && (
                    <hr className="border-secondary-200" />
                  )}
                </Fragment>
              ))}
            </div>
          </div>
        )}
        {formik.values.location_list_arr.length > 0 && (
          <div>
            <p className="mb-2 font-medium capitalize text-base_24 text-info-500">
              {t("Change location")}
            </p>
            <div className="p-3 overflow-hidden border border-secondary-200 rounded-theme">
              {formik.values.location_list_arr.map((item, index) => (
                <Fragment>
                  <div key={index} className="py-3 checkbox-label-between">
                    <Checkbox
                      label={
                        <div>
                          <span className="font-semibold capitalize text-info-700 xl:text-lg lg:text-base text-sm_18">
                            {item?.location_address}
                            <p className="font-medium capitalize lg:text-base text-sm_18 text-secondary-400">
                              {item?.location_name}
                            </p>
                          </span>
                        </div>
                      }
                      containerProps={{
                        className: "order-2",
                      }}
                      checked={false}
                      onChange={() => {
                        const updatedSelectedLocation = [
                          ...formik.values.location_access_ids,
                          item,
                        ];
                        const oldArr = [...formik.values.location_list_arr];
                        const filtered = oldArr.filter(
                          (location) => location?._id !== item?._id
                        );
                        formik.setFieldValue("location_list_arr", filtered);
                        formik.setFieldValue(
                          "location_access_ids",
                          updatedSelectedLocation
                        );
                      }}
                    />
                  </div>
                  {index !== formik.values.location_list_arr?.length - 1 && (
                    <hr className="border-secondary-200" />
                  )}
                </Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default LocationsAccess;
