import React, { useState } from "react";
import Select, { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
const ReactSelect = ({
  label,
  labelProps,
  options,
  asterisk,
  refs,
  classNamePrefix = "my-react-select",
  ...rest
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  // Custom Dropdown Indicator
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon
          icon={menuIsOpen ? faChevronUp : faChevronDown}
          className="text-black"
        />
      </components.DropdownIndicator>
    );
  };

  return (
    <div className="flex flex-col w-full h-full gap-y-1 ">
      {label && (
        <label
          htmlFor=""
          className={`${labelProps} text-base xl:text-lg text-black font-normal`}
        >
          {label}
          {asterisk && (
            <span className="text-primary-400 px-0.5 translate-y-2">*</span>
          )}
        </label>
      )}
      <Select
        ref={refs}
        options={options}
        className="my-react-select-container "
        classNamePrefix={classNamePrefix}
        components={{ ...rest.components, DropdownIndicator }}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        {...rest}
      />
    </div>
  );
};

export default ReactSelect;
