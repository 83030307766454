import React from "react";
import { NoDataFoundSvg } from "../svgs/NoDataFoundSvg";
import { t } from "i18next";

const NoDataFound = ({ message }) => {
  return (
    <div className="flex flex-col gap-y-2 items-center">
      <NoDataFoundSvg />
      <p className="text-secondary-500 font-medium">{t(message)}</p>
    </div>
  );
};

export default NoDataFound;
