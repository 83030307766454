/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useLayoutEffect, useState } from "react";
import AllRoutes from "./routes/AllRoutes";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useNavigatorIsOnLine from "./hooks/NavigatorIsOnline";
import { authSetState } from "./store/auth/auth.slice";
import { getAllRecord } from "./database/db-operation";
import {
  getColorFromLocalStorage,
  getLanguageFromLocalStorage,
  getUserDetails,
  hexToRgba,
  setColorInLocalStorage,
} from "./utils/utils";
import {
  getLocationListRequest,
  profileAction,
} from "./store/settings/settings.fetch";
import { roleTypes } from "./utils/enums";
import { useTranslation } from "react-i18next";
import 'react-advanced-cropper/dist/style.css';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'


function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const loggedInUser = getUserDetails();
  const auth_token = localStorage.getItem("auth_token");
  const isOnline = useNavigatorIsOnLine();
  const { i18n, t } = useTranslation();
  const initialColor = getColorFromLocalStorage();
  const language = getLanguageFromLocalStorage();
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 0,
          },
        },
      })
  );

  console.log(language, "language...........")

  const fetchOfflineData = async () => {
    const data = await getAllRecord("loginUser");
    dispatch(authSetState({ key: "user", value: data[0] || {} }));
    dispatch(
      authSetState({
        key: "role",
        value: data[0]?.role_id || {},
      })
    );
  };

  useLayoutEffect(() => {
    setColorInLocalStorage(initialColor);
    document.documentElement.style.setProperty("--primary-color", initialColor);

    const rgbaColor = hexToRgba(initialColor, 0.1);
    document.documentElement.style.setProperty("--primary-color-rgba", rgbaColor);
  }, [initialColor]);


  useLayoutEffect(() => {
    i18n.changeLanguage(language);
  }, [language])

  useEffect(() => {
    if (isOnline) {
      // if (location.pathname !== "/" && auth_token) {
      //   dispatch(profileAction(navigate));
      // }
    } else {
      fetchOfflineData();
    }
  }, []);

  useLayoutEffect(() => {
    if (auth_token) {
      dispatch(profileAction());
      if (loggedInUser?.role_id?.role === roleTypes.OWNER)
        dispatch(getLocationListRequest());
    }
  }, [auth_token]);

  return (
    <div className="App bg-bg_image">
      <QueryClientProvider client={queryClient}>
        <AllRoutes />
      </QueryClientProvider>
    </div>
  );
}

export default App;
