import { toast } from "react-toastify";
import AxiosRequest from "../../../AxiosRequest";
import { locationState } from "./state";
import { profileAction } from "../settings.fetch";

/* -------------------------------------------------------------------------- */
/*                                Get location                                */
/* -------------------------------------------------------------------------- */
export const getLocation =
  ({ locationId }) =>
    async (dispatch) => {
      try {
        dispatch(locationState({ key: "getLocationData.loading", value: true }));
        const response = await AxiosRequest.get(
          `/admin/orgsLocation/details/${locationId}`
        );
        const data = await response.data.data;
        if (data) {
          dispatch(locationState({ key: "getLocationData.data", value: data }));
          dispatch(
            locationState({ key: "getLocationData.loading", value: false })
          );
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(locationState({ key: "getLocationData.loading", value: false }));
        dispatch(locationState({ key: "getLocationData.error", value: error }));
      }
    };

/* -------------------------------------------------------------------------- */
/*                               Update location                              */
/* -------------------------------------------------------------------------- */
export const updateLocation =
  ({ locationId, body }) =>
    async (dispatch) => {
      try {
        dispatch(locationState({ key: "updateLocation.loading", value: true }));
        const response = await AxiosRequest.put(
          `/admin/orgsLocation/tax-update/${locationId}`,
          body
        );
        const data = await response.data;
        if (data) {
          toast.success(response.data.message);
          dispatch(profileAction());
          dispatch(
            locationState({ key: "updateLocation.loading", value: false })
          );
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(locationState({ key: "updateLocation.loading", value: false }));
        dispatch(locationState({ key: "updateLocation.error", value: error }));
      }
    };

/* -------------------------------------------------------------------------- */
/*                     get org location list for owner                        */
/* -------------------------------------------------------------------------- */
export const getOrgLocationListRequest = () => async (dispatch) => {
  try {
    dispatch(locationState({ key: "getOrgLocationList.loading", value: true }));
    const response = await AxiosRequest.get(
      `/admin/orgsLocation/switch-account-list`
    );
    const data = await response.data.data;
    if (data) {
      dispatch(
        locationState({ key: "getOrgLocationList.loading", value: false })
      );
      dispatch(locationState({ key: "getOrgLocationList.data", value: data }));
    }
    return response;
  } catch (error) {
    toast.error(error);
    dispatch(
      locationState({ key: "getOrgLocationList.loading", value: false })
    );
    dispatch(locationState({ key: "getOrgLocationList.error", value: error }));
  }
};

/* -------------------------------------------------------------------------- */
/*                      get org location list for owner                       */
/* -------------------------------------------------------------------------- */
export const updateOrgLocationRequest =
  ({ id, navigate, queryClient }) =>
    async (dispatch) => {
      try {
        dispatch(
          locationState({ key: "updateOrgLocation.loading", value: true })
        );
        const response = await AxiosRequest.post(
          `/admin/orgsLocation/switch-account/${id}`
        );
        const data = await response.data.data;
        if (data) {
          dispatch(
            locationState({ key: "updateOrgLocation.loading", value: false })
          );
          localStorage.setItem("user", JSON.stringify(data));
          localStorage.removeItem("pinAccess");
          navigate("/pin");
          dispatch(profileAction());
          queryClient.removeQueries()
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(
          locationState({ key: "updateOrgLocation.loading", value: false })
        );
        dispatch(locationState({ key: "updateOrgLocation.error", value: error }));
      }
    };
