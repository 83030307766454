import AxiosRequest from "../../../AxiosRequest";



// get categories list 
export const getProductListRequest = async ({ page, limit, search = "", category_ids }) => {
    const response = await AxiosRequest.get(
        `/user/inventory/list`, {
        params: {
            page,
            limit,
            search,
            category_ids,
        },
    }
    );

    console.log(response, "response.........................")
    return response.data.data;
};