import { faPenToSquare, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Button, IconButton } from "@material-tailwind/react";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DataTable from "../../components/common/DataTable";
import Input from "../../components/common/Input";
import Modal from "../../components/common/Modal";
import {
  DeleteUserIllusSvg,
  ManageDeleteProductIcon,
} from "../../components/svgs";
import Layout from "../../layout";
import { roleTypes } from "../../utils/enums";
import { useDebounce } from "../../utils/utils";
import {
  deleteUserRequest,
  getUsersListRequest,
} from "../../store/settings/user/actions";
import { userState } from "../../store/settings/user/state";
import { useTranslation } from "react-i18next";
import ReactSelect from "../../components/common/ReactSelect";
import { render } from "@testing-library/react";

const Settings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    isLoading,
    userData,
    totalPages,
    deleteLoading,
    deleteData,
    roleList,
  } = useSelector((store) => ({
    isLoading: store?.user?.usersList?.loading,
    userData: store?.user?.usersList?.data,
    totalPages: store?.user?.usersList?.totalPages,
    deleteLoading: store?.user?.deleteUser?.loading,
    deleteData: store?.user?.deleteUser?.data,
    roleList: store?.setting?.roleList,
  }));

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const searchValue = useDebounce(search);
  const [selectedRole, setSelectedRole] = useState({
    label: t("All Roles"),
    value: "",
  });

  const columns = () => [
    {
      Header: t("User"),
      key: "",
      render: (row) => (
        <div>
          <div className="flex items-center gap-x-2">
            <Avatar src={row.image} alt="user-image" className="w-8 h-8" />
            <div className="pl-2 space-y-1">
              <p className="text-base font-semibold">{row.username}</p>
              <p className="text-base font-semibold">{row.email}</p>
            </div>
          </div>
        </div>
      ),
    },
    {
      Header: t("Locations"),
      key: "",
      render: (row) => <p>{row.location_access_ids.length}</p>,
    },
    {
      Header: t("Role"),
      key: "",
      render: (row) => (
        <div className="flex items-center gap-x-3">
          <div
            className={`w-1.5 h-1.5 rounded-full ${
              row.role_id.role === roleTypes.CLERK
                ? " bg-amber-800"
                : "bg-purple-500"
            }`}
          ></div>
          <p className="text-base font-semibold">{t(row.role_id.role)}</p>
        </div>
      ),
    },
    {
      Header: t("Manage"),
      key: "",
      render: (rowData) => {
        return (
          <div className="flex items-center gap-x-3">
            <IconButton
              size="sm"
              type="button"
              variant="text"
              className="text-primary-400"
              onClick={() =>
                navigate(`/settings/users/form?id=${rowData?._id}`)
              }
            >
              <FontAwesomeIcon icon={faPenToSquare} fontSize={18} />
            </IconButton>
            <div className="w-0.5 h-4 bg-secondary-150"></div>
            <IconButton
              size="sm"
              type="button"
              variant="text"
              className="text-red-500"
              onClick={() => {
                setOpenDeleteConfirmation(true);
                setSelectedUserId(rowData?._id);
              }}
            >
              {/* <FontAwesomeIcon icon={faTrashAlt} fontSize={18} /> */}
              <ManageDeleteProductIcon className="w-4 h-4" />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const handleSearch = (event) => {
    setSearch(event.target.value);
    if (page > 1) setPage(1);
  };

  const handlePageChange = (page) => {
    setPage(page.selected + 1);
  };

  const handlePerPageChange = (limit) => {
    setLimit(limit);
  };

  useEffect(() => {
    if (deleteData !== null) {
      dispatch(getUsersListRequest({ page, limit, search }));
      dispatch(userState({ key: "deleteUser.data", value: null }));
    }
  }, [deleteData, dispatch]);

  useLayoutEffect(() => {
    dispatch(
      getUsersListRequest({ page, limit, search, role: selectedRole.value })
    );
  }, [dispatch, page, limit, searchValue, selectedRole]);

  return (
    <Layout>
      <div className="bg-white w-full rounded-theme xl:max-h-[calc(100vh-126px)] max-h-[calc(100vh-100px)] p-5">
        <div className="flex flex-wrap items-center justify-between gap-3 mb-5">
          <h3 className="text-base font-semibold lg:text-lg 2xl:font-bold text-info-500 2xl:text-xl">
            {t("Users")}
          </h3>
          <ul className="flex items-center gap-x-5">
            <li>
              <Input
                type={"search"}
                className={
                  "bg-secondary-50 lg:min-w-80 placeholder:font-medium placeholder:text-secondary-500 text-sm py-[11.5px] "
                }
                iconStart={
                  <FontAwesomeIcon
                    icon={faSearch}
                    fontSize={16}
                    className="text-secondary-500"
                  />
                }
                placeholder={t("Search a Name Or Email Address")}
                value={search}
                onChange={handleSearch}
              />
            </li>
            <li>
              <div className="min-w-[210px]">
                <ReactSelect
                  labelProps={"!text-xs font-semibold capitalize"}
                  options={[
                    { label: t("All Roles"), value: "" },
                    ...roleList?.map((item) => ({
                      label: t(item.role),
                      value: item._id,
                    })),
                  ]}
                  value={selectedRole}
                  onChange={(data) => setSelectedRole(data)}
                />
              </div>
            </li>
            <li>
              <Button
                type="button"
                className="flex items-center justify-center lg:text-base text-sm font-medium capitalize lg:gap-x-3 gap-x-2 rounded-theme bg-primary-400 min-w-48 py-[12.5px] "
                onClick={() => navigate("/settings/users/form")}
              >
                <FontAwesomeIcon icon={faPlus} fontSize={16} />
                {t("Add User")}
              </Button>
            </li>
          </ul>
        </div>
        <div className="">
          <DataTable
            columns={columns()}
            data={userData}
            search={""}
            maxHeight="max-h-[calc(100vh-190px)]"
            loading={isLoading}
            page={page}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
            handlePerPageChange={handlePerPageChange}
          />
        </div>
      </div>

      {/* <div className="bg-white w-full xl:p-5 lg:p-4 p-3.5 rounded-theme xl:h-[calc(100vh-126px)] h-[calc(100vh-100px)]">
           <div className="flex flex-col items-center justify-center h-full">
             <p className="max-w-[350px] w-full mx-auto xl:text-lg lg:text-base text-sm_18 font-medium text-center text-secondary-500 xl:pb-[30px] lg:pb-6 pb-5">
               Click the "create user" button to start adding Users to your
               Setting
             </p>
             <Button
               type="button"
               size="lg"
               className="flex items-center justify-center gap-2 mx-auto text-base font-normal capitalize bg-primary-400 rounded-theme"
               onClick={() => navigate("/settings/users/form")}
             >
               Create New User
             </Button>
           </div>
         </div> */}

      {/* <UserList /> */}
      {/* <AddUserDetails /> */}
      {/* <AllDeviceSettings /> */}
      {/* <ThisDevice /> */}
      {/* <LocationSettings /> */}
      {/* <IntegrationEcommerce /> */}
      {/* <IntegrationPayment /> */}
      {/* <IntegrationQuickBookTab /> */}
      {/* <IntegrationSystemLogout /> */}
      {/* <Modal size="xxs">
        <div className="flex flex-col items-center gap-y-4">
          <DeleteUserIllusSvg />
          <h4 className="text-2xl font-semibold capitalize text-info-400">
            {t("delete user profile")}
          </h4>
          <p className="text-base font-normal normal-case text-secondary-500">
            {t("Are you sure to delete profile?")}
          </p>
          <div className="flex flex-col w-full gap-y-4">
            <Button
              type="button"
              fullWidth
              className="text-base font-medium capitalize border bg-primary-400 border-primary-400"
            >
              {t("No I’ve Change My Mind")}
            </Button>
            <Button
              type="button"
              fullWidth
              className="flex items-center justify-center text-base font-medium capitalize bg-white border border-primary-400 text-primary-400"
            >
              
            </Button>
          </div>
        </div>
      </Modal> */}
      <Modal
        open={openDeleteConfirmation}
        handler={() => {
          setOpenDeleteConfirmation(false);
          setSelectedUserId(null);
        }}
      >
        <div className="flex flex-col">
          <p className="pb-3 text-lg font-semibold text-info-400">
            {t("Delete")}
          </p>
          <p className="text-base font-medium text-secondary-400 pb-7">
            {t(" Are you sure to delete this User ?")}
          </p>
          <div className="flex items-center justify-end gap-x-4">
            <Button
              type="button"
              size="sm"
              onClick={() => {
                setOpenDeleteConfirmation(false);
                setSelectedUserId(null);
              }}
              className="text-sm font-semibold capitalize border border-primary-400 bg-primary-400"
            >
              {t("No, Cancel")}
            </Button>
            <Button
              type="button"
              size="sm"
              className="text-sm font-semibold text-red-500 capitalize bg-white border border-red-500"
              loading={deleteLoading}
              disabled={deleteLoading}
              onClick={() =>
                dispatch(
                  deleteUserRequest({
                    userId: selectedUserId,
                    setModalClose: setOpenDeleteConfirmation,
                  })
                )
              }
            >
              {t("delete user profile")}
            </Button>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default Settings;
