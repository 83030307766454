import { Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";

const ProductSkeleton = () => {
  const [skeletonCount, setSkeletonCount] = useState(12);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1280) {
        setSkeletonCount(12); // Large screens (lg and above)
      } else if (window.innerWidth >= 768) {
        setSkeletonCount(9); // Medium screens (md)
      } else {
        setSkeletonCount(6); // Small screens (default)
      }
    };

    // Set initial count
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="grid gap-3 md:grid-cols-2 2xl:grid-cols-4 lg:grid-cols-3 xl:gap-5 animate-pulse ">
      {Array(skeletonCount)
        .fill()
        .map((_, index) => (
          <button
            key={index}
            className="overflow-hidden bg-white rounded-theme p-2.5"
          >
            {/* Image Container */}
            <div className="w-full xl:h-[134px] h-28 flex justify-center items-center bg-gray-100">
              <div className="w-12 h-12">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-full h-full text-gray-500"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                  />
                </svg>
              </div>
            </div>

            {/* Text Skeletons */}
            <div className=" xl:pt-2.5 p-1">
              {/* Product Name Skeleton */}
              <Typography
                as="div"
                variant="h1"
                className="h-3 mb-4 bg-gray-300 rounded-full xl:w-56 lg:w-32 md:w-32"
              >
                &nbsp;
              </Typography>

              {/* Price Skeleton */}
              <div className="flex items-center gap-1">
                <Typography
                  as="div"
                  variant="h1"
                  className="w-24 h-3 bg-gray-300 rounded-full"
                >
                  &nbsp;
                </Typography>
              </div>
            </div>
          </button>
        ))}
    </div>
  );
};

export default ProductSkeleton;
