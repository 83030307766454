import React, { useEffect, useState } from "react";
import {
  formatCurrencyWithNumberValue,
  getUserDetails,
} from "../../utils/utils";
import Input from "../common/Input";
import { t } from "i18next";

const OrderItemsTable = ({ orderId, data, totalTaxPercentage, formik }) => {
  const [totals, setTotals] = useState({
    qty: 0,
    selling_price: 0,
    sub_total: 0,
    discount: 0,
    grand_total: 0,
  });

  const userDetail = getUserDetails();

  useEffect(() => {
    if (data?.length > 0) {
      const newTotals = (data || [])?.reduce(
        (acc, item) => ({
          qty: acc.qty + item.qty,
          selling_price: acc.selling_price + item.selling_price,
          sub_total: acc.sub_total + item.sub_total,
          discount: acc.discount + item.discount,
          grand_total:
            (acc.grand_total || 0) -
            (acc.taxes || 0) +
            (item.grand_total || 0) -
            (item.taxes || 0),
        }),
        { qty: 0, selling_price: 0, sub_total: 0, discount: 0, grand_total: 0 }
      );
      formik.setFieldValue("order.total_qty", newTotals?.qty);
      formik.setFieldValue(
        "order.sub_total",
        Number(newTotals?.sub_total.toFixed(2))
      );
      formik.setFieldValue("order.total_discount", newTotals?.discount);
      setTotals(newTotals);
    }
  }, [data]);

  // Handle price change for individual items
  const handleSellingPriceChange = (sku, newPrice) => {
    const updatedOrderItems = formik.values.orderItems.map((item) => {
      if (item.sku === sku) {
        const updatedSubTotal = Number(newPrice) * item.qty;

        // Calculate the discount based on the discount type
        let discount = 0;
        if (item.discount_type === "percentage") {
          discount = (updatedSubTotal * item.discount_percentage) / 100;
        } else {
          discount = item.discount; // assuming fixed discount is already provided
        }

        // Calculate taxes after discount
        const updatedTaxes =
          (updatedSubTotal - discount) * (totalTaxPercentage / 100);
        const updatedGrandTotal = updatedSubTotal - discount + updatedTaxes;

        return {
          ...item,
          selling_price: Number(newPrice),
          sub_total: updatedSubTotal,
          discount: discount, // updated discount value
          taxes: updatedTaxes, // updated taxes after applying discount
          grand_total: updatedGrandTotal, // updated grand total
        };
      }
      return item;
    });

    // Update formik orderItems
    formik.setFieldValue("orderItems", updatedOrderItems);
  };

  const capitalizeFirstLetter = (string) => {
    return string
      ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
      : "";
  };

  return (
    <div className="h-full payment-table-container">
      <table className="w-full h-full table-auto">
        <thead className="">
          <tr>
            <th className="px-4 pt-2 pb-4 text-left capitalize min-w-80">
              {t("Items")}
            </th>
            <th className="px-4 pt-2 pb-4 text-left capitalize">{t("QTY")}</th>
            <th className="px-4 pt-2 pb-4 text-left capitalize">
              {t("Price")}
            </th>
            <th className="px-4 pt-2 pb-4 text-left capitalize">
              {t("Selling Total")}
            </th>
            <th className="px-4 pt-2 pb-4 text-left capitalize">
              {t("Discounts")}
            </th>
            <th className="px-4 pt-2 pb-4 text-left capitalize">
              {t("Total")}
            </th>
          </tr>
        </thead>
        <tbody>
          {(data || []).map((item, ind) => (
            <tr key={ind} className="align-top ">
              <td className="px-4 text-[14px] !capitalize ">
                {item?.gift_card_code
                  ? `Gift Card - ${capitalizeFirstLetter(item.gift_card_code)}`
                  : capitalizeFirstLetter(item?.inventory_id?.product_name)}
              </td>
              <td className="px-4 text-[14px] ">{item?.qty}</td>
              <td className="px-4 text-[14px]">
                {item?.type === "inventory" &&
                userDetail?.section_permission?.includes("inventory") ? (
                  <Input
                    type="number"
                    name={`selling_price`}
                    labelProps={"!text-sm !font-normal"}
                    className="max-w-[82px]"
                    value={item.selling_price || ""}
                    onChange={(e) =>
                      handleSellingPriceChange(item.sku, e.target.value)
                    }
                  />
                ) : (
                  formatCurrencyWithNumberValue(item?.selling_price)
                )}
              </td>
              <td className="px-4 text-[14px] ">
                {formatCurrencyWithNumberValue(item?.sub_total)}
              </td>
              <td className="px-4 text-[14px] ">
                {formatCurrencyWithNumberValue(item?.discount)}
              </td>
              <td className="px-4 text-[14px] ">
                {formatCurrencyWithNumberValue(item?.grand_total - item.taxes)}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot className="">
          <tr className="bg-gray-100">
            <td className="px-4 py-2 font-bold border-y">{t("Total")}</td>
            <td className="px-4 py-2 font-bold border-y">{totals?.qty}</td>
            <td className="px-4 py-2 font-bold border-y">
              {/* {formatCurrencyWithNumberValue(totals?.selling_price)} */} -
            </td>
            <td className="px-4 py-2 font-bold border-y">
              {formatCurrencyWithNumberValue(totals?.sub_total)}
            </td>
            <td className="px-4 py-2 font-bold border-y">
              {formatCurrencyWithNumberValue(totals?.discount)}
            </td>
            <td className="px-4 py-2 font-bold border-y">
              {formatCurrencyWithNumberValue(totals?.grand_total)}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default OrderItemsTable;
